import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import config from '../../config';
import mobileLogoImage from './mobile-logo.png';

import LogoIcon, { LogoWithoutIcon } from './LogoIcon';
import css from './Logo.module.css';

const Logo = props => {
  const { className, format, showBrandIcon, ...rest } = props;

  const iconClasses = classNames(css.logoIcon, className);
  const mobileClasses = classNames(css.logoMobile, className);

  const isMobileFormat = format === 'mobile';

  if (isMobileFormat) {
    return (
      <img
        className={classNames(className, mobileClasses)}
        src={mobileLogoImage}
        alt={config.siteTitle}
        {...rest}
      />
    );
  }

  return showBrandIcon ? (
    <LogoIcon className={iconClasses} />
  ) : (
    <LogoWithoutIcon className={iconClasses} />
  );
};

const { oneOf, string, bool } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
  showBrandIcon: true,
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
  showBrandIcon: bool.isRequired,
};

export default Logo;
