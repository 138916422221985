/**
 * Update a team's public data by setting the 'pending' property to false for a specific recipient.
 *
 * @param {Array} team - The team data to be updated.
 * @param {string} recipientId - The ID of the recipient for whom 'pending' should be set to false.
 * @returns {Array} - The updated team data.
 */
export function updateTeamForPublicData(team, recipientId, currentUserId) {
  // Use the `map` function to create a new array with updated 'pending' values.
  const updatedTeam = team?.map(i => {
    if (i.id === recipientId) {
      return {
        ...i,
        id: currentUserId,
        pending: false,
        existing: i.existing ? i.existing : true,
      };
    }

    return i;
  });

  return updatedTeam;
}

export function updateCreditsForPublicData(credits, listingId) {
  const hasCredits = credits?.length > 0;
  const newCredit = { id: listingId };

  return hasCredits ? [...credits, newCredit] : [newCredit];
}

/**
 * Find a team member by their ID in the team data.
 *
 * @param {Array} team - The team data to search within.
 * @param {string} recipientId - The ID of the team member to find.
 * @returns {Object|null} - The found team member object, or null if not found.
 */
export function findTeamMemberById(team, recipientId) {
  // Use the `find` function to search for a team member by their ID.
  const foundMember = team.find(i => i.id === recipientId);

  return foundMember || null; // Return the found member or null if not found.
}
