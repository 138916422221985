/**
 * Denormalise availabilityExceptions to events that
 * can be used in react/@fullcalendar.
 *
 * @param {array} availabilityExceptions
 *
 * @returns {array} events
 */
export const availabilityExceptionsToEvents = availabilityExceptions => {
  return availabilityExceptions.map(exception => {
    const currentListing = exception.listing;
    const listingId = currentListing.id;
    const { publicData } = currentListing.attributes;

    // Match the metadata from listing's publicData availabilityExceptions
    // and create the timeslotMetadata.
    const availabilityExceptionsFromPublicData = publicData.exceptions;
    const timeslotMetadata = availabilityExceptionsFromPublicData.find(
      metadata =>
        metadata.id === exception.id.uuid ||
        metadata.attributes?.repeatingExceptionIds?.includes(exception.id.uuid)
    );

    return {
      id: exception.id.uuid, // has to be ID string
      listingId,
      title: timeslotMetadata.attributes.title,
      start: exception.attributes.start,
      end: exception.attributes.end,
    };
  });
};

/**
 * Sort events by their start dates to get the
 * upcoming events.
 *
 * @param {array} events
 *
 * @returns {array} sorted events
 */
export const sortEventsByDate = events =>
  events.sort(function(a, b) {
    var c = new Date(a.start);
    var d = new Date(b.start);
    return c - d;
  });

/**
 * Get's the upcoming bookings that's ahead of the
 * current date.
 *
 * @param {array} events
 *
 * @returns {array} upcoming events
 */
export const upcomingEventsByDate = events => events.filter(e => new Date(e.start) >= new Date());
