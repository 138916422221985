import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionHowItWorks.module.css';

const exploreItem = (order, label) => {
  return (
    <div className={css.exploreItem}>
      <h1 className={css.exploreItemOrder}>{order}</h1>
      <h2 className={css.exploreItemLabel}>{label}</h2>
    </div>
  );
};

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;

  const classes = classNames(css.root, rootClassName, className);

  return (
    <div className={classes}>
      <div className={css.content}>
        <h1 className={css.title}>
          <FormattedMessage id="SectionHowItWorks.title" />
        </h1>
        <p className={css.subTitle}>
          <FormattedMessage id="SectionHowItWorks.subTitle" />
        </p>
        <div className={css.exploreItems}>
          {exploreItem('01', 'Join free live room from creators')}
          {exploreItem('02', 'Subscribe to Live Room to get more experience and perks')}
          {exploreItem('03', 'Be part of the show, support the Creators')}
        </div>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
