import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconVolume.module.css';

const IconVolume = props => {
  const { className, rootClassName, isMuted } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (isMuted) {
    return (
      <svg
        className={classes}
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        height="16"
        width="16"
      >
        <path d="M5.758 10 16 20.243V29a1 1 0 0 1-.51.871l-.107.053a.999.999 0 0 1-.99-.129l-.1-.088L6.586 22H3a2.003 2.003 0 0 1-1.995-1.851L1 20v-8c0-1.053.817-1.918 1.85-1.995L3 10h2.758zm16.456 16.458 1.924 1.924A14.22 14.22 0 0 1 20 29.977v-2.681a11.659 11.659 0 0 0 2.214-.838zM3.707 2.293l26 26-1.414 1.414-26-26 1.414-1.414zM20 2.023C26.448 3.406 31.3 9.144 31.3 16a14.21 14.21 0 0 1-2.703 8.354l-1.868-1.87A11.6 11.6 0 0 0 28.7 16c0-5.413-3.7-9.966-8.7-11.296V2.023zm0 6.247c3.096 1.206 5.3 4.212 5.3 7.73a8.226 8.226 0 0 1-1.04 4.015l-1.95-1.948c.252-.64.39-1.338.39-2.067a5.691 5.691 0 0 0-2.5-4.708l-.2-.13V8.27zm-4.617-6.194a1 1 0 0 1 .61.804L16 3v8.758L10.414 6.17l3.879-3.878a.999.999 0 0 1 1.09-.217z" />
      </svg>
    );
  }

  return (
    <svg
      className={classes}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      height="16"
      width="16"
    >
      <path d="M15.65 8c0 3.428-2.426 6.297-5.65 6.989v-1.34c2.5-.666 4.35-2.942 4.35-5.649 0-2.706-1.85-4.983-4.35-5.648v-1.34c3.224.691 5.65 3.56 5.65 6.988zm-3 0A4.153 4.153 0 0 0 10 4.135v1.446a2.848 2.848 0 0 1 1.35 2.42A2.849 2.849 0 0 1 10 10.418v1.446A4.154 4.154 0 0 0 12.65 8zM7.692 1.038a.5.5 0 0 0-.545.109L3.293 5H1.5c-.551 0-1 .449-1 1v4c0 .552.449 1 1 1h1.793l3.854 3.854A.499.499 0 0 0 8 14.5v-13a.5.5 0 0 0-.308-.462z" />
    </svg>
  );
};

const { string, bool } = PropTypes;

IconVolume.defaultProps = {
  className: null,
  rootClassName: null,
  isMuted: null,
};

IconVolume.propTypes = {
  className: string,
  rootClassName: string,
  isMuted: bool.isRequired,
};

export default IconVolume;
