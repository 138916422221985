import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconVideo.module.css';

const IconVideo = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/20e0f0c/svg"
      height="24"
      width="24"
      fill="none"
      viewBox="-0.5 -0.5 24 24"
    >
      <path
        stroke="#0e0f0c"
        d="M18.747 6.708a2.043 2.043 0 0 0-2.013-2.395H6.265a2.043 2.043 0 0 0-2.012 2.395"
      />
      <path
        stroke="#0e0f0c"
        d="M16.77 4.313c.028-.249.042-.373.042-.475a1.917 1.917 0 0 0-1.712-1.91c-.102-.011-.227-.011-.477-.011H8.377c-.25 0-.375 0-.477.01a1.917 1.917 0 0 0-1.712 1.91c0 .103.014.227.041.476"
      />
      <path
        stroke="#0e0f0c"
        strokeLinecap="round"
        d="M20.31 16.093c-.335 2.378-.502 3.567-1.362 4.278-.86.712-2.128.712-4.664.712H8.716c-2.536 0-3.804 0-4.664-.712-.86-.711-1.027-1.9-1.362-4.278l-.406-2.875c-.427-3.031-.641-4.547.267-5.528.909-.982 2.526-.982 5.76-.982h6.378c3.234 0 4.851 0 5.76.982.717.775.734 1.884.5 3.81"
      />
      <path
        stroke="#0e0f0c"
        d="M13.974 13.049c.535.331.535 1.19 0 1.522l-3.231 2.003c-.52.322-1.16-.097-1.16-.761v-4.006c0-.664.64-1.084 1.16-.761l3.23 2.003Z"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconVideo.defaultProps = {
  className: null,
  rootClassName: null,
};

IconVideo.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconVideo;
