import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconTier.module.css';

const IconTier = props => {
  const { rootClassName, className, mainColor, secondaryColor, title } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <span title={title}>
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="240"
        height="200"
        fill="none"
        viewBox="0 0 240 200"
      >
        <path
          fill={mainColor}
          d="M75.428 196.555c-1.498 2.997-5.244 4.121-8.24 2.248C-3.602 154.98-2.853 48.608 69.06 5.909c1.873-1.124 4.495-.375 5.619 1.498 1.123 1.873.374 4.12-1.499 5.244-67.044 40.077-66.295 135.962 0 176.413 2.622 1.499 3.371 4.87 2.248 7.491z"
        ></path>
        <path
          fill={secondaryColor}
          d="M72.807 30.63c-5.244 4.12-10.862 6.366-16.48 7.49-5.619 1.124-10.862.375-14.982-2.622 9.363-12.36 25.47-13.483 31.462-4.869zM49.959.664C42.47 6.658 37.6 14.149 36.475 20.516c-1.498 6.742.375 11.986 4.495 15.357C49.96 23.887 55.952 9.28 49.96.665zm4.87 47.943c-3.746 4.869-8.24 8.989-13.11 11.236-4.869 2.248-10.487 3.371-14.982 1.498 5.244-14.607 19.851-19.102 28.091-12.734zm-30.34-22.473C19.247 33.25 17 41.49 17.749 47.858c.749 6.368 3.745 11.237 8.615 13.11 5.243-13.11 6.367-28.092-1.873-34.834zm18.728 43.822C40.97 75.575 37.6 80.445 33.48 83.816c-4.12 3.37-8.99 5.992-14.233 5.243C21.12 74.077 33.48 65.837 43.217 69.957zM8.01 56.473c-2.997 8.24-2.997 16.48-.75 22.473 2.247 5.993 6.742 9.739 11.986 10.113 1.498-14.233-1.499-28.466-11.237-32.586zM37.598 93.18c-.75 5.993-2.622 11.611-5.619 16.106-2.996 4.495-7.116 7.865-12.36 8.615-2.247-14.982 7.866-26.219 17.979-24.72zM.144 89.434c-.75 8.614 1.498 16.48 5.243 21.724 3.746 5.243 8.99 7.491 14.233 6.742-2.247-14.233-8.989-26.968-19.476-28.466zm38.204 27.716c1.123 5.993.374 11.986-1.124 16.855-1.873 5.244-4.869 9.739-9.738 11.986-5.993-14.233.749-27.717 10.862-28.841z"
        ></path>
        <path
          fill={secondaryColor}
          d="M1.64 123.143c1.499 8.615 5.619 15.732 10.863 19.477 5.243 4.12 10.862 4.869 15.356 2.996-5.993-13.109-16.105-23.596-26.218-22.473zm43.823 16.855c2.622 5.618 3.746 11.237 3.371 16.855-.374 5.618-1.873 10.862-5.993 14.233-9.738-12.36-6.742-27.342 2.622-31.088zM11.38 155.729c3.745 7.866 9.738 13.859 15.731 16.481 5.993 2.622 11.986 2.247 15.731-1.124-9.364-11.611-21.724-19.102-31.462-15.357zm47.568 4.495c4.12 4.869 7.117 10.113 8.615 15.731 1.498 5.618 1.498 11.237-1.124 15.357-14.233-8.989-15.731-24.721-7.491-31.088zm-28.466 24.72c5.993 6.742 13.858 10.862 20.6 11.986 6.742 1.124 12.36-1.124 15.357-5.244-13.484-8.989-27.717-13.109-35.957-6.742z"
        ></path>
        <path
          fill={mainColor}
          d="M164.571 196.555c1.498 2.997 5.244 4.121 8.24 2.248 70.79-43.823 70.041-150.195-1.873-192.894-1.872-1.124-4.494-.375-5.618 1.498-1.124 1.873-.375 4.12 1.498 5.244 67.045 40.077 65.921 135.587-.374 176.413-2.248 1.499-2.997 4.87-1.873 7.491z"
        ></path>
        <path
          fill={secondaryColor}
          d="M167.193 30.63c5.244 4.12 10.862 6.366 16.481 7.49 5.618 1.124 10.862.375 14.982-2.622-9.364-12.36-25.47-13.483-31.463-4.869zM190.041.664c7.491 5.993 12.36 13.484 13.484 19.851 1.498 6.742-.375 11.986-4.495 15.357-8.989-11.986-14.982-26.593-8.989-35.208zm-4.869 47.943c3.745 4.869 8.24 8.989 13.109 11.236 4.869 2.248 10.488 3.371 14.982 1.498-5.244-14.607-19.851-19.102-28.091-12.734zm30.339-22.473c5.243 7.116 7.491 15.356 6.741 21.723-.749 6.368-3.745 11.237-8.614 13.11-5.244-13.11-6.368-28.092 1.873-34.834zm-18.728 43.822c2.247 5.618 5.618 10.488 9.738 13.859 4.12 3.37 8.99 5.992 14.233 5.243-1.873-14.982-14.233-23.222-23.971-19.102z"
        ></path>
        <path
          fill={secondaryColor}
          d="M231.991 56.473c2.996 8.24 2.996 16.48.749 22.473-2.247 5.993-6.742 9.739-11.986 10.113-1.498-14.233 1.499-28.466 11.237-32.586zm-29.59 36.706c.75 5.993 2.622 11.611 5.619 16.106 2.996 4.495 7.116 7.865 12.36 8.615 2.247-14.982-7.866-26.219-17.979-24.72zm37.456-3.745c.749 8.614-1.499 16.48-5.244 21.724-3.746 5.243-8.989 7.491-14.233 6.742 2.247-14.233 8.989-26.968 19.477-28.466zm-38.205 27.716c-1.123 5.993-.374 11.986 1.124 16.855 1.873 5.244 4.869 9.739 9.738 11.986 5.993-14.233-.749-27.717-10.862-28.841z"
        ></path>
        <path
          fill={secondaryColor}
          d="M238.357 123.143c-1.498 8.615-5.618 15.732-10.862 19.477-5.243 4.12-10.862 4.869-15.356 2.996 5.993-13.109 16.106-23.596 26.218-22.473zm-43.822 16.855c-2.622 5.618-3.746 11.237-3.371 16.855s1.873 10.862 5.993 14.233c9.738-12.36 6.742-27.342-2.622-31.088zM228.621 155.729c-3.746 7.866-9.739 13.859-15.731 16.481-5.993 2.621-11.986 2.247-15.732-1.124 9.364-11.611 21.724-19.102 31.463-15.357zm-47.568 4.495c-4.12 4.869-7.117 10.113-8.615 15.731-1.498 5.618-1.498 11.237 1.124 15.357 14.233-8.99 15.731-24.721 7.491-31.088zm28.466 24.72c-5.993 6.742-13.859 10.862-20.601 11.986-6.742 1.124-12.36-1.124-15.356-5.244 13.484-8.989 27.717-13.109 35.957-6.742zM154.459 118.274l7.865 47.194L120 143.369l-42.324 22.099 7.865-47.194-34.084-33.335 47.193-7.116 21.35-42.7 21.349 42.7 47.194 7.116-34.084 33.335z"
        ></path>
        <path fill={mainColor} d="M120 35.124v66.295l21.349-23.596L120 35.123z"></path>
        <path
          fill={mainColor}
          d="M188.543 84.94l-34.084 33.334L120 101.419l68.543-16.48zm-137.086 0l34.084 33.334L120 101.419l-68.543-16.48zM120 101.419v41.95l42.324 22.099L120 101.419zm0 0v41.95l-42.324 22.099L120 101.419z"
        ></path>
      </svg>
    </span>
  );
};

IconTier.defaultProps = {
  rootClassName: null,
  className: null,
  mainColor: null,
  secondaryColor: null,
};

IconTier.propTypes = {
  rootClassName: string,
  className: string,
  mainColor: string,
  secondaryColor: string,
};

export default IconTier;
