import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Effective Date: October 28, 2024</p>
      <h3>Welcome to Attractr!</h3>
      <p>
        Attractr is a dynamic platform dedicated to fostering meaningful connections within the
        performing arts. Designed to bring production companies, artists, and audiences closer, it
        offers a comprehensive suite of tools for building lasting relationships through shared
        experiences. Our mission is to empower creators and production teams, creating exclusive,
        immersive experiences that captivate and engage audiences. These terms are crafted to ensure
        a positive, collaborative environment—please review them fully to understand your rights and
        responsibilities.
      </p>

      <h3>Your Account</h3>
      <p>
        To summarize: You need to be at least 13 to use Attractr and are responsible for your
        account’s security and activity.
      </p>
      <p>
        When you create an account on Attractr, you agree to provide accurate information and keep
        it updated. Users must be at least 13 years old to participate on the platform. Creators who
        want to offer Live Rooms or other exclusive content must be 18 years or older (or have
        parental consent where legally permissible). You are responsible for any actions taken
        through your account and for keeping your login information secure. Contact us immediately
        at team@attractr.live if you suspect any unauthorized use of your account.
      </p>

      <h3>Abusive Conduct</h3>
      <p>
        To summarize: Treat others respectfully and avoid actions that harm the platform or
        community.
      </p>
      <p>
        You are accountable for all actions taken under your account. Attractr may suspend or
        terminate accounts that violate our terms, policies, or community standards. Harmful conduct
        includes any illegal activity, abuse toward others, misuse of the platform’s features, or
        exploitation of Attractr for purposes it was not designed to serve, such as unauthorized
        file storage. Our policies are comprehensive, but if we find creative ways you may exploit
        Attractr to the detriment of our community, we may take action to prevent this.
      </p>

      <h3>All About Being a Creator</h3>

      <h4>Overview</h4>
      <p>
        Creators on Attractr are artists, performers, professionals, and production companies who
        engage audiences through unique live experiences. This section explains important details
        about setting up your Creator page, managing payments, fees, and taxes, and meeting specific
        content guidelines.
      </p>

      <h4>Creating Your Pages</h4>
      <p>
        To start as a Creator on Attractr, set up a Creator account and launch your Show Pages and
        Live Rooms. This allows production companies and individual artists to engage fans through
        exclusive, interactive experiences. Membership options help build audience loyalty, enabling
        fans to support projects directly and access premium content.
      </p>

      <h4>Payments</h4>
      <p>
        Attractr currently supports one-time billing memberships, automatically adjusting for local
        currencies. Payments are processed quickly, though occasional delays may occur, especially
        during refund or dispute cases. Attractr ensures the security of both Creators and buyers by
        holding funds as needed.
      </p>

      <h4>Fees</h4>
      <p>Being a Creator on Attractr incurs several fees:</p>
      <ul>
        <li>Attractr Fee: A percentage or flat rate per transaction.</li>
        <li>Payment Processing Fee: Covers payment handling, fraud protection, and more.</li>
        <li>Payout Fee: Applied when funds are transferred to your payout method.</li>
        <li>Currency Conversion Fee: For payments in non-local currencies.</li>
        <li>VAT/GST: Where applicable.</li>
      </ul>

      <h4>Taxes</h4>
      <p>
        Attractr collects tax identification details and, where required, reports earnings to tax
        authorities. VAT or GST is calculated based on the audience member’s location, deducted
        automatically, and recorded in your earnings dashboard. Creators are responsible for
        reporting their income to tax authorities as needed.
      </p>

      <h4>Content Guidelines</h4>
      <p>
        Creators must follow Attractr’s Community Guidelines, ensuring content does not violate laws
        or policies. Special considerations apply to adult-oriented content, and all audience data
        must be securely managed per Attractr’s Data Processing Agreement.
      </p>

      <h4>Restrictions</h4>
      <p>
        Creators cannot sell or transfer their Attractr accounts. Attractr regularly enhances
        platform features, which may include beta or experimental tools subject to additional
        limitations. These updates aim to improve the user experience and platform functionality.
      </p>

      <h3>All About Being an Audience Member</h3>

      <h4>Overview</h4>
      <p>
        As an audience member, you gain both free and exclusive access to creators’ content on
        Attractr, with opportunities for live, interactive engagement through tools like Live Rooms.
        This unique platform connects you directly with creators in the performing arts.
      </p>

      <h4>Audience Membership and Purchases</h4>
      <p>
        Support creators by subscribing or making one-time purchases, which can include exclusive
        perks like behind-the-scenes footage or live interactions. Payment options vary based on the
        creator’s offerings, including one-time or auto-renewing memberships.
      </p>

      <h4>Payments and Fees</h4>
      <p>
        Charges reflect the specific membership plan you select. Attractr handles all payments, and
        currency exchange or foreign transaction fees may apply depending on your location. Taxes
        are collected on the subtotal where required.
      </p>

      <h4>Access and Limitations</h4>
      <p>
        Audience members receive a personal, non-transferable license to access creator content.
        However, access may be restricted if content is removed or a creator’s account is
        deactivated. Keeping your account information current ensures accurate tax assessments.
      </p>

      <h4>Refunds</h4>
      <p>
        Refunds are generally not provided, though exceptions may be considered at Attractr’s
        discretion.
      </p>

      <h4>Feature Testing</h4>
      <p>
        Attractr frequently experiments with new features, providing audience members with early
        access to beta functionalities that may be temporary or subject to change.
      </p>

      <h3>Attractr’s Role</h3>
      <p>
        Attractr takes an active role in maintaining a respectful community by reviewing content,
        posts, and any reported activity to enforce our Terms of Use and Community Guidelines. We
        investigate potential violations, which may involve examining activity both on and off the
        platform to protect the community, creators, and audience members.
      </p>
      <p>
        Our goal is to resolve issues collaboratively, allowing members to continue using Attractr
        whenever possible. However, we may suspend or terminate accounts to safeguard community
        integrity, which is not a decision we take lightly.
      </p>
      <p>
        As a global platform, Attractr complies with international regulations, including U.S.
        sanctions, restricting transactions involving certain sanctioned individuals, regions, or
        items. Please report any potential violations you encounter.
      </p>

      <h3>Account Deletion</h3>
      <p>
        You can delete your Attractr account anytime through your Account. Attractr also reserves
        the right to suspend, terminate, or remove accounts and content (such as memberships, posts,
        or benefits) at our discretion if necessary for platform integrity. Any attempt to claim
        damages for actions taken on another user’s account is waived under these terms.
      </p>

      <h3>Ownership of Creations</h3>
      <p>
        Creators maintain ownership of all their creations and audience members database on
        Attractr. However, Attractr requires a license to use this content across its services. This
        license enables Attractr to host, promote, and facilitate memberships effectively but does
        not transfer intellectual property rights.
      </p>
      <p>
        Creators agree to only post content they own or have permission to share, and Attractr may
        remove content that infringes intellectual property rights or violates our policies.
        Unauthorized use of creators’ content by audience members or third parties is prohibited.
      </p>

      <h3>Third-Party Apps and Services on Attractr</h3>
      <p>
        Attractr may prompt you to connect with third-party apps, websites, or services to enhance
        your experience. This integration may require access to specific account details or
        permissions to perform certain actions within your Attractr account. If authorized, Attractr
        will follow your settings to enable these functionalities. You can manage or revoke access
        to these connections as needed. For more details, refer to our Privacy Policy.
      </p>

      <h3>Attractr's Intellectual Property</h3>
      <p>
        Feedback and bug reports shared with Attractr become Attractr’s property, enabling platform
        improvements that benefit all users.
      </p>
      <p>
        Our content—including website text, app designs, logos, and platform assets—is protected by
        copyright, trademark, and patent laws. Creators may use our logo and trademarks to promote
        their Attractr Pages, as outlined in our Brand Guidelines. Any other use, reproduction, or
        distribution of our content requires written permission.
      </p>
      <p>
        We value feedback on Attractr’s features, including early-access and beta testing.
        Suggestions or bug reports provided to us are fully owned by Attractr to help continuously
        enhance the creator and audience experience.
      </p>

      <h3>Indemnity</h3>
      <p>
        If Attractr faces claims or legal issues due to your use of the platform, you agree to cover
        our losses, including legal fees. We reserve the right to lead the defense of such claims,
        with your support as needed. This applies to Attractr, including our affiliates, officers,
        employees, agents, and third-party providers.
      </p>

      <h3>Warranty Disclaimer</h3>
      <p>
        Attractr is provided “as is” without any implied warranties. We disclaim all warranties to
        the extent allowed by law, including guarantees of merchantability or fitness for a specific
        purpose. This applies to Attractr and our partners.
      </p>

      <h3>Limitation of Liability</h3>
      <p>
        Attractr’s liability for any damages is capped at the amount we’ve earned from your use. We
        aren’t liable for incidental or consequential damages, nor for losses from unfulfilled
        offerings or contractual conflicts. This includes our affiliates and service providers.
      </p>

      <h3>Dispute Resolution</h3>
      <p>
        We’re here to help if issues arise. For unresolved disputes, they will be governed by
        Canadian law, with the courts in your applicable jurisdiction. Both parties consent to this
        venue for resolving disputes.
      </p>

      <h3>Additional Terms</h3>
      <p>
        These terms, along with any referenced policies, represent the complete agreement between
        you and Attractr. They replace any prior agreements and do not establish a partnership,
        joint venture, or employment relationship. If a term is unenforceable, it will be modified
        to the extent needed; if modification is impossible, it will be removed, with the remaining
        terms unaffected. Failure to enforce any provision does not waive future rights.
      </p>
      <p>
        We may update these terms as needed. If a substantial change occurs, we’ll notify you.
        Continuing to use Attractr means you accept any changes.
      </p>
      <p>
        If you opt-in for text messages, you consent to receive automated marketing texts. Standard
        message and data rates apply, and message frequency may vary. Attractr and its providers are
        not liable for any message delays or delivery issues.
      </p>
      <p>
        For accessibility support, contact us at{' '}
        <a href="mailto:team@attractr.live" rel="noopener">
          team@attractr.live
        </a>
        .
      </p>
      <p>
        Attractr is located at: 4060 Boulevard St-Laurent, Suite 703, Montréal, QC, Canada H2W 1Y9
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
