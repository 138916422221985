import React from 'react';

import css from '../ButtonWatchlist.module.css';

const WatchlistIcon = () => {
  return (
    <svg
      className={css.watchlistIcon}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="46"
      viewBox="0 0 32 46"
      fill="none"
    >
      <g clipPath="url(#clip0_2035_3742)">
        <path d="M32 0H0V42.6667L16.3249 35.0568L32 42.3638V0Z" fill="white" fillOpacity="0.32" />
        <path
          className={css.watchlistIconShadow}
          d="M32 0H0V42.6667L16.3249 35.0568L32 42.3638V0Z"
          fill="#1F1F1F"
          fillOpacity="0.75"
        />
        <path
          className={css.watchlistIconStroke}
          d="M16.5361 34.6036L16.3249 34.5051L16.1136 34.6036L0.5 41.8819V0.5H31.5V41.579L16.5361 34.6036Z"
          stroke="white"
          strokeOpacity="0.32"
        />
        <path
          d="M32 42.3636V45.0303L16.3249 37.7233L0 45.3332V42.6665L16.3249 35.0566L32 42.3636Z"
          fill="black"
          fillOpacity="0.32"
        />
      </g>
    </svg>
  );
};

export default WatchlistIcon;
