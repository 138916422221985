import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconArrow.module.css';

const IconArrow = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M5 12h14M5 12l4 4M5 12l4-4" />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconArrow.defaultProps = {
  className: null,
  rootClassName: null,
};

IconArrow.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconArrow;
