import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../DashboardWrapper.module.css';

const StreamIcon = props => {
  const { className } = props;
  const classes = classNames(css.sideNavIcon, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        stroke="#0e0f0c"
        strokeWidth="1.5"
        d="M2 12c0-3.771 0-5.657 1.172-6.828C4.343 4 6.229 4 10 4h4c3.771 0 5.657 0 6.828 1.172C22 6.343 22 8.229 22 12v2c0 3.771 0 5.657-1.172 6.828C19.657 22 17.771 22 14 22h-4c-3.771 0-5.657 0-6.828-1.172C2 19.657 2 17.771 2 14v-2Z"
      />
      <path
        stroke="#0e0f0c"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M18 16h-2m0 0h-2m2 0v-2m0 2v2M7 4V2.5M17 4V2.5M2.5 9h19"
      />
    </svg>
  );
};

const { string } = PropTypes;

StreamIcon.defaultProps = {
  className: null,
};

StreamIcon.propTypes = {
  className: string,
};

export default StreamIcon;
