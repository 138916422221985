import React from 'react';
import { array, string, func } from 'prop-types';
import { FieldSelect } from '../../components';
import classNames from 'classnames';

import css from './FieldSelectInput.module.css';

const FieldSelectInput = props => {
  const {
    rootClassName,
    className,
    name,
    id,
    options,
    label,
    placeholder,
    validate,
    ...rest
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const renderPlaceholder = placeholder ? (
    <option disabled value="">
      {placeholder}
    </option>
  ) : null;
  return options ? (
    <FieldSelect
      className={classes}
      name={name}
      id={id}
      label={label}
      validate={validate}
      {...rest}
    >
      {renderPlaceholder}
      {options.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

FieldSelectInput.defaultProps = {
  rootClassName: null,
  className: null,
  name: null,
  id: null,
  label: null,
  placeholder: null,
  options: [],
};

FieldSelectInput.propTypes = {
  rootClassName: string,
  className: string,
  name: string,
  id: string,
  label: string,
  placeholder: string,
  options: array,
  validate: func,
};

export default FieldSelectInput;
