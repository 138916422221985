import { useState, useMemo } from 'react';
import { v4 as generateUUID } from 'uuid';

/**
 * Retrieves organization profiles for a user, including invited profiles if available.
 *
 * @param {object} user - The user object containing profile data.
 * @param {array} invitedOrganizationProfiles - List of invited organization profiles.
 *
 * @returns {array|null} - Combined organization profiles or null if none available.
 */
export const getUserOrganizationProfiles = (user, invitedOrganizationProfiles = []) => {
  const organizationProfiles = user?.attributes?.profile?.publicData?.organizationProfiles;

  if (organizationProfiles || invitedOrganizationProfiles.length > 0) {
    return (organizationProfiles || []).concat(invitedOrganizationProfiles);
  }

  return null;
};

/**
 * Converts user organization profiles to a format suitable for forms.
 *
 * @param {object} user - The user object containing profile data.
 * @param {array} invitedOrganizationProfiles - List of invited organization profiles.
 *
 * @returns {array} - Form-ready organization profiles with keys and labels.
 */
export const getFormUserOrganizationProfiles = (user, invitedOrganizationProfiles = []) => {
  const organizationProfiles = user?.attributes?.profile?.publicData?.organizationProfiles;

  const organizationProfilesForForm = organizationProfiles
    ? organizationProfiles.concat(invitedOrganizationProfiles).map(profile => ({
        key: profile.id,
        label: profile.name,
      }))
    : invitedOrganizationProfiles.map(profile => ({
        key: profile.id,
        label: profile.name,
      }));

  return organizationProfilesForForm;
};

/**
 * Fn that prepares the organization profiles before they're saved in
 * user extended data.
 *
 * @param {array} organizationProfiles
 *
 * @returns {Promise<array>} updated organization profiles
 */
export const prepareUpdatedOrganizationProfiles = async (organizationProfiles, onUploadImage) => {
  const filteredOrganizationProfiles = organizationProfiles?.filter(
    p => p !== null && typeof p !== 'undefined'
  );

  const preparedOrganizationProfiles = filteredOrganizationProfiles?.map(async p => {
    const profileImage = p.profileImage;
    const uploadedImage = profileImage?.lastModified
      ? await onUploadImage(profileImage)
      : profileImage;

    return profileImage && uploadedImage
      ? {
          ...p,
          id: p.id ? p.id : generateUUID(),
          profileImage: uploadedImage,
        }
      : {
          ...p,
          id: p.id ? p.id : generateUUID(),
        };
  });

  const promisedOrganizationProfiles = await Promise.all(preparedOrganizationProfiles);
  return promisedOrganizationProfiles;
};

/**
 * Fn that creates default organization profile during signup.
 *
 * @param {string} firstName
 *
 * @returns {array} organization profile
 */
export const createDefaultOrganizationProfile = firstName => {
  return [
    {
      id: generateUUID(),
      name: `${firstName} organization`,
    },
  ];
};

/**
 * Get the selected organization from the list of organizations based on the provided organization ID.
 * If no organization ID is provided or no matching organization is found, the default organization is returned.
 * If there are no organizations in the list, returns null.
 *
 * @param {Array} organizations - List of organizations to search through.
 * @param {string} organizationId - ID of the organization to find.
 *
 * @return {Object|null} The selected organization, the default organization, or null.
 */
export const getSelectedOrganizationProfile = (organizations, organizationId) => {
  if (!organizations || organizations.length === 0) {
    // If no organizations are provided, return null.
    return null;
  }

  if (organizationId) {
    // Find the organization with the provided ID, or return the default organization if not found.
    const selectedOrg = organizations.find(org => org.id === organizationId);
    return selectedOrg || organizations[0];
  } else {
    // If no organization ID is provided, return the default organization.
    return organizations[0];
  }
};

/**
 * Retrieves the selected organization profile ID from extended user data.
 * This function extracts the ID of the selected organization's profile from the provided user object.
 *
 * @param {object} user - The user object containing user attributes and extended data.
 * @returns {string|null} The selected organization's profile ID if available, or null if not found.
 */
export const getSelectedOrganizationProfileIdFromExtendedData = user => {
  // Check if the user object and its attributes exist, and follow the nested path to extract the selected organization profile ID.
  // If any intermediate property is missing, the function returns null.
  const selectedOrganizationProfileId =
    user?.attributes?.profile?.publicData?.selectedOrganizationProfileId;

  // Return the extracted ID or null if not found.
  return selectedOrganizationProfileId ?? null;
};

/**
 * Custom hook to generate and manage organization profiles for forms.
 * @param {object} currentUser - The current user's data.
 * @param {array} invitedOrganizationProfiles - List of organization profiles where the user is invited.
 * @returns {array} - The organization profiles to be used in forms.
 */
export const useFormOrganizationProfiles = (currentUser, invitedOrganizationProfiles) => {
  // State to hold the organization profiles for forms
  const [organizationProfiles, setOrganizationProfiles] = useState([]);

  useMemo(() => {
    // Generate organization profiles for forms
    const profiles = getUserOrganizationProfiles(currentUser, invitedOrganizationProfiles);

    // Check if there are profiles to set
    const hasProfiles = profiles?.length > 0;

    if (hasProfiles) {
      setOrganizationProfiles(profiles);
    }
  }, [currentUser, invitedOrganizationProfiles]);

  return organizationProfiles;
};
