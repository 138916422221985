import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import { propTypes } from '../../util/types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { formatDuration } from '../../util/membership';
import { IconCheckmark } from '..';
import config from '../../config';
import classNames from 'classnames';

import css from './ListingMembership.module.css';

const { Money } = sdkTypes;

const ListingMembership = props => {
  const { rootClassName, className, intl, membership, perksConfig } = props;

  const classes = classNames(rootClassName || css.root, className);
  const priceMoney = new Money(membership.price.amount, membership.price.currency);
  const formattedPrice = formatMoney(intl, priceMoney);
  const selectedPerks = membership.perks
    ? perksConfig.filter(p => membership.perks.includes(p.key))
    : [];

  const renderMembershipTitle = <h3 className={css.title}>{membership.name}</h3>;

  const renderMembershipInfo = (
    <div className={css.info}>
      <span className={css.price}>{formattedPrice}</span>
      <span className={css.separator}>·</span>
      <span className={css.duration}>{formatDuration(intl, membership.duration)}</span>
    </div>
  );

  const renderMembershipDescription = membership.description ? (
    <p className={css.description}>{membership.description}</p>
  ) : null;

  const renderMembershipPerks = (
    <ul className={css.perksList}>
      {selectedPerks.map(p => (
        <li className={css.perksListItem} key={p.key}>
          <IconCheckmark className={css.checkmarkIcon} size="small" />
          <span>{p.label}</span>
        </li>
      ))}
    </ul>
  );

  return (
    <div className={classes}>
      {renderMembershipTitle}
      {renderMembershipInfo}
      {renderMembershipDescription}
      {renderMembershipPerks}
    </div>
  );
};

ListingMembership.defaultProps = {
  rootClassName: null,
  className: null,
  membership: null,
  perksConfig: config.custom.perksConfig,
};

ListingMembership.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  membership: propTypes.membership.isRequired,
  perksConfig: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
};

export default injectIntl(ListingMembership);
