import { addMarketplaceEntities } from '../ducks/marketplaceData.duck';
import { emailAPI, integrationAPI } from '../util/api';
import { storableError } from '../util/errors';
import { types as sdkTypes } from '../util/sdkLoader';
import {
  createNewListingInvite,
  createNewOrganizationInvite,
  createListingInviteEmailParams,
  createOrganizationInviteEmailParams,
  filterPayloadUsers,
  organizationIdsToOrganizations,
  saveArrayToExtendedData,
  userEntity,
} from '../util/invites';
import { v4 as generateUUID } from 'uuid';

const { UUID } = sdkTypes;

const denormaliseUserIds = invites => invites.map(invite => invite.id);

const filterInvitesByInviteId = (invites, inviteId) =>
  invites.filter(invite => invite.id !== inviteId);
const filterInviteUserIdsByInviteId = (inviteUserIds, inviteId) =>
  inviteUserIds.filter(user => user.id.uuid !== inviteId);

const filterPendingInviteNonExistingUsers = pendingInvites =>
  pendingInvites
    .filter(user => !user.existing)
    .map(user => ({
      id: new UUID(user.id),
      email: user.email,
    }));

const nonExistingUserEntity = invite => ({
  id: new UUID(invite.id),
  attributes: {
    email: invite.email,
  },
});

// ================ Action types ================ //

/**
 * Listing invites
 */
export const SHOW_LISTING_INVITES_REQUEST = 'app/invites/SHOW_LISTING_INVITES_REQUEST';
export const SHOW_LISTING_INVITES_SUCCESS = 'app/invites/SHOW_LISTING_INVITES_SUCCESS';
export const SHOW_LISTING_INVITES_ERROR = 'app/invites/SHOW_LISTING_INVITES_ERROR';

export const SEND_LISTING_INVITE_REQUEST = 'app/invites/SEND_LISTING_INVITE_REQUEST';
export const SEND_LISTING_INVITE_SUCCESS = 'app/invites/SEND_LISTING_INVITE_SUCCESS';
export const SEND_LISTING_INVITE_ERROR = 'app/invites/SEND_LISTING_INVITE_ERROR';

export const RESEND_LISTING_INVITE_REQUEST = 'app/invites/RESEND_LISTING_INVITE_REQUEST';
export const RESEND_LISTING_INVITE_SUCCESS = 'app/invites/RESEND_LISTING_INVITE_SUCCESS';
export const RESEND_LISTING_INVITE_ERROR = 'app/invites/RESEND_LISTING_INVITE_ERROR';

export const REVOKE_LISTING_INVITE_REQUEST = 'app/invites/REVOKE_LISTING_INVITE_REQUEST';
export const REVOKE_LISTING_INVITE_SUCCESS = 'app/invites/REVOKE_LISTING_INVITE_SUCCESS';
export const REVOKE_LISTING_INVITE_ERROR = 'app/invites/REVOKE_LISTING_INVITE_ERROR';

export const REMOVE_LISTING_INVITE_REQUEST = 'app/invites/REMOVE_LISTING_INVITE_REQUEST';
export const REMOVE_LISTING_INVITE_SUCCESS = 'app/invites/REMOVE_LISTING_INVITE_SUCCESS';
export const REMOVE_LISTING_INVITE_ERROR = 'app/invites/REMOVE_LISTING_INVITE_ERROR';

/**
 * Organization invites
 */
export const SHOW_ORGANIZATION_INVITES_REQUEST = 'app/invites/SHOW_ORGANIZATION_INVITES_REQUEST';
export const SHOW_ORGANIZATION_INVITES_SUCCESS = 'app/invites/SHOW_ORGANIZATION_INVITES_SUCCESS';
export const SHOW_ORGANIZATION_INVITES_ERROR = 'app/invites/SHOW_ORGANIZATION_INVITES_ERROR';

export const SEND_ORGANIZATION_INVITE_REQUEST = 'app/invites/SEND_ORGANIZATION_INVITE_REQUEST';
export const SEND_ORGANIZATION_INVITE_SUCCESS = 'app/invites/SEND_ORGANIZATION_INVITE_SUCCESS';
export const SEND_ORGANIZATION_INVITE_ERROR = 'app/invites/SEND_ORGANIZATION_INVITE_ERROR';

export const RESEND_ORGANIZATION_INVITE_REQUEST = 'app/invites/RESEND_ORGANIZATION_INVITE_REQUEST';
export const RESEND_ORGANIZATION_INVITE_SUCCESS = 'app/invites/RESEND_ORGANIZATION_INVITE_SUCCESS';
export const RESEND_ORGANIZATION_INVITE_ERROR = 'app/invites/RESEND_ORGANIZATION_INVITE_ERROR';

export const REVOKE_ORGANIZATION_INVITE_REQUEST = 'app/invites/REVOKE_ORGANIZATION_INVITE_REQUEST';
export const REVOKE_ORGANIZATION_INVITE_SUCCESS = 'app/invites/REVOKE_ORGANIZATION_INVITE_SUCCESS';
export const REVOKE_ORGANIZATION_INVITE_ERROR = 'app/invites/REVOKE_ORGANIZATION_INVITE_ERROR';

export const REMOVE_ORGANIZATION_INVITE_REQUEST = 'app/invites/REMOVE_ORGANIZATION_INVITE_REQUEST';
export const REMOVE_ORGANIZATION_INVITE_SUCCESS = 'app/invites/REMOVE_ORGANIZATION_INVITE_SUCCESS';
export const REMOVE_ORGANIZATION_INVITE_ERROR = 'app/invites/REMOVE_ORGANIZATION_INVITE_ERROR';

// ================ Reducer ================ //

const initialState = {
  // listing
  showListingInvitesInProgress: false,
  showListingInvitesError: null,
  sendListingInviteInProgress: false,
  sendListingInviteError: null,
  resendListingInviteInProgress: false,
  resendListingInviteError: null,
  revokeListingInviteInProgress: false,
  revokeListingInviteError: null,
  removeListingInviteInProgress: false,
  removeListingInviteError: null,
  resendListingId: null,
  revokeListingId: null,
  removeListingId: null,
  listingUserRefs: [],

  // organization
  sendOrganizationInviteInProgress: false,
  sendOrganizationInviteError: null,
  resendOrganizationInviteInProgress: false,
  resendOrganizationInviteError: null,
  showOrganizationInvitesInProgress: false,
  showOrganizationInvitesError: null,
  sendOrganizationInvitesError: null,
  revokeOrganizationInviteInProgress: false,
  revokeOrganizationInviteError: null,
  removeOrganizationInviteInProgress: false,
  removeOrganizationInviteError: null,
  resendOrganizationId: null,
  revokeOrganizationId: null,
  removeOrganizationId: null,

  pendingInvites: [],
  pendingInviteUserIds: [],
  pendingInviteNonExistingUsers: [],
  organizationUserRefs: [],
};

export default function invitesReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    /**
     * Listing invites
     */
    case SHOW_LISTING_INVITES_REQUEST:
      return {
        ...state,
        showListingInvitesInProgress: true,
        showListingInvitesError: null,
      };
    case SHOW_LISTING_INVITES_SUCCESS:
      return {
        ...state,
        showListingInvitesInProgress: false,
        showListingInvitesError: null,
        listingUserRefs: payload.teamInvites.map(i =>
          i.existing ? userEntity({ id: new UUID(i.id) }) : nonExistingUserEntity(i)
        ),
      };
    case SHOW_LISTING_INVITES_ERROR:
      return {
        ...state,
        showListingInvitesInProgress: false,
        showListingInvitesError: null,
      };

    case SEND_LISTING_INVITE_REQUEST:
      return {
        ...state,
        sendListingInviteInProgress: true,
        sendListingInviteError: null,
      };
    case SEND_LISTING_INVITE_SUCCESS:
      return {
        ...state,
        sendListingInviteInProgress: false,
        sendListingInviteError: null,
        listingUserRefs: [
          ...state.listingUserRefs,
          payload.invitedUser.type ? userEntity(payload.invitedUser) : payload.invitedUser,
        ],
      };
    case SEND_LISTING_INVITE_ERROR:
      return {
        ...state,
        sendListingInviteInProgress: false,
        sendListingInviteError: null,
      };

    case RESEND_LISTING_INVITE_REQUEST:
      return {
        ...state,
        resendListingInviteInProgress: true,
        resendListingInviteError: null,
        resendListingId: payload.invitedUserId,
      };
    case RESEND_LISTING_INVITE_SUCCESS:
      return {
        ...state,
        resendListingInviteInProgress: false,
        resendListingInviteError: null,
      };
    case RESEND_LISTING_INVITE_ERROR:
      return {
        ...state,
        resendListingInviteInProgress: false,
        resendListingInviteError: null,
      };

    case REVOKE_LISTING_INVITE_REQUEST:
      return {
        ...state,
        revokeListingInviteInProgress: true,
        revokeListingInviteError: null,
        revokeListingId: payload.invitedUserId,
      };
    case REVOKE_LISTING_INVITE_SUCCESS:
      return {
        ...state,
        revokeListingInviteInProgress: false,
        revokeListingInviteError: null,
        revokeListingId: null,
        listingUserRefs: state.listingUserRefs.filter(u => u.id.uuid !== payload.invitedUserId),
      };
    case REVOKE_LISTING_INVITE_ERROR:
      return {
        ...state,
        revokeListingInviteInProgress: false,
        revokeListingInviteError: null,
      };

    case REMOVE_LISTING_INVITE_REQUEST:
      return {
        ...state,
        removeListingInviteInProgress: true,
        removeListingInviteError: null,
        removeListingId: payload.invitedUserId,
      };
    case REMOVE_LISTING_INVITE_SUCCESS:
      return {
        ...state,
        removeListingInviteInProgress: false,
        removeListingInviteError: null,
        removeListingId: null,
        listingUserRefs: state.listingUserRefs.filter(u => u.id.uuid !== payload.invitedUserId),
      };
    case REMOVE_LISTING_INVITE_ERROR:
      return {
        ...state,
        removeListingInviteInProgress: false,
        removeListingInviteError: null,
      };

    /**
     * Organization invites
     */
    case SHOW_ORGANIZATION_INVITES_REQUEST:
      return {
        ...state,
        showOrganizationInvitesInProgress: true,
        showOrganizationInvitesError: null,
      };
    case SHOW_ORGANIZATION_INVITES_SUCCESS:
      // Denormalise and only return the pending invite user ids
      const pendingInviteUserIds = denormaliseUserIds(payload.pendingInvites);
      const organizationUserRefs = denormaliseUserIds(payload.team);
      return {
        ...state,
        showOrganizationInvitesInProgress: false,
        showOrganizationInvitesError: null,
        pendingInvites: payload.pendingInvites,

        // Filter the user ids from the list of all marketplace users,
        // as there's no way to currently query users by ids.
        pendingInviteUserIds: filterPayloadUsers(payload.userIds, pendingInviteUserIds),
        pendingInviteNonExistingUsers: filterPendingInviteNonExistingUsers(payload.pendingInvites),
        organizationUserRefs: filterPayloadUsers(payload.userIds, organizationUserRefs),
      };
    case SHOW_ORGANIZATION_INVITES_ERROR:
      return {
        ...state,
        showOrganizationInvitesInProgress: false,
        showOrganizationInvitesError: null,
      };

    case SEND_ORGANIZATION_INVITE_REQUEST:
      return {
        ...state,
        sendOrganizationInviteInProgress: true,
        sendOrganizationInviteError: null,
      };
    case SEND_ORGANIZATION_INVITE_SUCCESS:
      // Create new invite that mimic the way invites are being stored
      // in the master account extended data.
      const newInvite = createNewOrganizationInvite(payload.invitedUser, payload.organizationIds);
      const savePendingUser = payload.invitedUser.type
        ? // save existing user
          { pendingInviteUserIds: [...state.pendingInviteUserIds, userEntity(payload.invitedUser)] }
        : {
            // save non existing user
            pendingInviteNonExistingUsers: [
              ...state.pendingInviteNonExistingUsers,
              { id: payload.invitedUser.id, email: payload.invitedUser.attributes.email },
            ],
          };
      return {
        ...state,
        sendOrganizationInviteInProgress: false,
        sendOrganizationInviteError: null,
        pendingInvites: [...state.pendingInvites, newInvite],
        ...savePendingUser,
      };
    case SEND_ORGANIZATION_INVITE_ERROR:
      return {
        ...state,
        sendOrganizationInviteInProgress: false,
        sendOrganizationInviteError: null,
      };

    case RESEND_ORGANIZATION_INVITE_REQUEST:
      return {
        ...state,
        resendOrganizationInviteInProgress: true,
        resendOrganizationInviteError: null,
        resendOrganizationId: payload.inviteId,
      };
    case RESEND_ORGANIZATION_INVITE_SUCCESS:
      return {
        ...state,
        resendOrganizationInviteInProgress: false,
        resendOrganizationInviteError: null,
      };
    case RESEND_ORGANIZATION_INVITE_ERROR:
      return {
        ...state,
        resendOrganizationInviteInProgress: false,
        resendOrganizationInviteError: null,
      };

    case REVOKE_ORGANIZATION_INVITE_REQUEST:
      return {
        ...state,
        revokeOrganizationInviteInProgress: true,
        revokeOrganizationInviteError: null,
        revokeOrganizationId: payload.inviteId,
      };
    case REVOKE_ORGANIZATION_INVITE_SUCCESS:
      const revokeInvite = state.pendingInviteUserIds.find(
        user => user.id.uuid === payload.inviteId
      )
        ? {
            pendingInviteUserIds: filterInviteUserIdsByInviteId(
              state.pendingInviteUserIds,
              payload.inviteId
            ),
          }
        : {
            pendingInviteNonExistingUsers: filterInviteUserIdsByInviteId(
              state.pendingInviteNonExistingUsers,
              payload.inviteId
            ),
          };
      return {
        ...state,
        revokeOrganizationInviteInProgress: false,
        revokeOrganizationInviteError: null,
        revokeOrganizationId: null,
        ...revokeInvite,
      };
    case REVOKE_ORGANIZATION_INVITE_ERROR:
      return {
        ...state,
        revokeOrganizationInviteInProgress: false,
        revokeOrganizationInviteError: null,
      };

    case REMOVE_ORGANIZATION_INVITE_REQUEST:
      return {
        ...state,
        removeOrganizationInviteInProgress: true,
        removeOrganizationInviteError: null,
        removeOrganizationId: payload.inviteId,
      };
    case REMOVE_ORGANIZATION_INVITE_SUCCESS:
      return {
        ...state,
        removeOrganizationInviteInProgress: false,
        removeOrganizationInviteError: null,
        organizationUserRefs: filterInviteUserIdsByInviteId(
          state.organizationUserRefs,
          payload.inviteId
        ),
        removeOrganizationId: null,
      };
    case REMOVE_ORGANIZATION_INVITE_ERROR:
      return {
        ...state,
        removeOrganizationInviteInProgress: false,
        removeOrganizationInviteError: null,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

/**
 * Listing invites
 */
export const showListingInvitesRequest = () => ({
  type: SHOW_LISTING_INVITES_REQUEST,
});
export const showListingInvitesSuccess = teamInvites => ({
  type: SHOW_LISTING_INVITES_SUCCESS,
  payload: { teamInvites },
});
export const showListingInvitesError = e => ({
  type: SHOW_LISTING_INVITES_ERROR,
  error: true,
  payload: e,
});

export const sendListingInviteRequest = () => ({
  type: SEND_LISTING_INVITE_REQUEST,
});
export const sendListingInviteSuccess = invitedUser => ({
  type: SEND_LISTING_INVITE_SUCCESS,
  payload: { invitedUser },
});
export const sendListingInviteError = e => ({
  type: SEND_LISTING_INVITE_ERROR,
  error: true,
  payload: e,
});

export const resendListingInviteRequest = invitedUserId => ({
  type: RESEND_LISTING_INVITE_REQUEST,
  payload: { invitedUserId },
});
export const resendListingInviteSuccess = invitedUserId => ({
  type: RESEND_LISTING_INVITE_SUCCESS,
  payload: { invitedUserId },
});
export const resendListingInviteError = e => ({
  type: RESEND_LISTING_INVITE_ERROR,
  error: true,
  payload: e,
});

export const revokeListingInviteRequest = invitedUserId => ({
  type: REVOKE_LISTING_INVITE_REQUEST,
  payload: { invitedUserId },
});
export const revokeListingInviteSuccess = invitedUserId => ({
  type: REVOKE_LISTING_INVITE_SUCCESS,
  payload: { invitedUserId },
});
export const revokeListingInviteError = e => ({
  type: REVOKE_LISTING_INVITE_ERROR,
  error: true,
  payload: e,
});

export const removeListingInviteRequest = invitedUserId => ({
  type: REMOVE_LISTING_INVITE_REQUEST,
  payload: { invitedUserId },
});
export const removeListingInviteSuccess = invitedUserId => ({
  type: REMOVE_LISTING_INVITE_SUCCESS,
  payload: { invitedUserId },
});
export const removeListingInviteError = e => ({
  type: REMOVE_LISTING_INVITE_ERROR,
  error: true,
  payload: e,
});

/**
 * Organization invites
 */
export const showOrganizationInvitesRequest = () => ({
  type: SHOW_ORGANIZATION_INVITES_REQUEST,
});
export const showOrganizationInvitesSuccess = (userIds, pendingInvites, team) => ({
  type: SHOW_ORGANIZATION_INVITES_SUCCESS,
  payload: { userIds, pendingInvites, team },
});
export const showOrganizationInvitesError = e => ({
  type: SHOW_ORGANIZATION_INVITES_ERROR,
  error: true,
  payload: e,
});

export const sendOrganizationInviteRequest = () => ({
  type: SEND_ORGANIZATION_INVITE_REQUEST,
});
export const sendOrganizationInviteSuccess = (invitedUser, organizationIds) => ({
  type: SEND_ORGANIZATION_INVITE_SUCCESS,
  payload: { invitedUser, organizationIds },
});
export const sendOrganizationInviteError = e => ({
  type: SEND_ORGANIZATION_INVITE_ERROR,
  error: true,
  payload: e,
});

export const resendOrganizationInviteRequest = inviteId => ({
  type: RESEND_ORGANIZATION_INVITE_REQUEST,
  payload: { inviteId },
});
export const resendOrganizationInviteSuccess = () => ({
  type: RESEND_ORGANIZATION_INVITE_SUCCESS,
});
export const resendOrganizationInviteError = e => ({
  type: RESEND_ORGANIZATION_INVITE_ERROR,
  error: true,
  payload: e,
});

export const revokeOrganizationInviteRequest = inviteId => ({
  type: REVOKE_ORGANIZATION_INVITE_REQUEST,
  payload: { inviteId },
});
export const revokeOrganizationInviteSuccess = inviteId => ({
  type: REVOKE_ORGANIZATION_INVITE_SUCCESS,
  payload: { inviteId },
});
export const revokeOrganizationInviteError = e => ({
  type: REVOKE_ORGANIZATION_INVITE_ERROR,
  error: true,
  payload: e,
});

export const removeOrganizationInviteRequest = inviteId => ({
  type: REMOVE_ORGANIZATION_INVITE_REQUEST,
  payload: { inviteId },
});
export const removeOrganizationInviteSuccess = inviteId => ({
  type: REMOVE_ORGANIZATION_INVITE_SUCCESS,
  payload: { inviteId },
});
export const removeOrganizationInviteError = e => ({
  type: REMOVE_ORGANIZATION_INVITE_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

/**
 * Listing fns
 */

export const showListingInvites = listingId => async (dispatch, getState, sdk) => {
  dispatch(showListingInvitesRequest());

  try {
    const response = await integrationAPI.listings.show({
      id: listingId,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    });
    const listing = response.data.data.data;
    const teamFromPublicData = listing.attributes.publicData.team || [];

    if (teamFromPublicData.length > 0) {
      const userPromises = teamFromPublicData
        .filter(i => i.existing)
        .map(async teamMember => {
          try {
            const userResponse = await integrationAPI.users.show({
              id: teamMember.id,
              include: ['profileImage'],
              'fields.image': ['variants.square-small', 'variants.square-small2x'],
            });
            dispatch(addMarketplaceEntities(userResponse.data));
          } catch (error) {
            console.error('Error fetching user:', error);
          }
        });

      await Promise.all(userPromises);
    }

    dispatch(showListingInvitesSuccess(teamFromPublicData));
    return teamFromPublicData;
  } catch (error) {
    dispatch(showListingInvitesError(storableError(error)));
  }
};

/**
 * Request to save an invite to listing extended data.
 *
 * @param {string} listingId - The ID of the listing.
 * @param {string} invitedUser - Invited user.
 */
export const requestSaveInviteToListingExtendedData = (
  listingId,
  invitedUser,
  formValues,
  isOwn
) => async (dispatch, getState, sdk) => {
  try {
    const useSdk = isOwn ? sdk.ownListings : integrationAPI.listings;

    const response = await useSdk.show({ id: listingId });
    const listing = isOwn ? response.data.data : response.data.data.data;
    const { team: teamFromPublicData = [] } = listing.attributes.publicData;

    const isPending = true;
    const newInvite = createNewListingInvite(
      invitedUser.id.uuid,
      invitedUser.attributes.email,
      formValues,
      isPending,
      !!invitedUser.type
    );

    const updatedTeamForPublicData = [...teamFromPublicData, newInvite];

    const updateResponse = await useSdk.update(
      {
        id: listingId,
        publicData: {
          team: updatedTeamForPublicData,
        },
      },
      { expand: true }
    );

    dispatch(addMarketplaceEntities(updateResponse));
    return Promise.resolve(updateResponse);
  } catch (error) {
    console.error('Error saving invite:', error);
    return Promise.reject(error);
  }
};

export const requestSendListingInvitationEmail = emailParams => {
  return emailAPI.listings.invitation(emailParams);
};

/**
 * Send an invite to the invited user.
 *
 * 1. Fetch invited user by email
 * 2. Save invite to the master account extended data
 */
export const sendListingInvite = (email, formValues, listingId, isOwn) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(sendListingInviteRequest());

  try {
    const { currentUser } = getState().user;

    let invitedUserEntity;

    try {
      const response = await integrationAPI.users.show({
        email,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      });

      invitedUserEntity = response.data.data.data;
      dispatch(addMarketplaceEntities(response.data));
    } catch (apiError) {
      invitedUserEntity = null;
    }

    const invitedUser = invitedUserEntity || {
      id: new UUID(generateUUID()),
      attributes: { email },
    };

    // listing
    const useSdk = isOwn ? sdk.ownListings : integrationAPI.listings;
    const listingResponse = await useSdk.show({ id: listingId }, { expand: true });
    const listing = isOwn ? listingResponse.data.data : listingResponse.data.data.data;
    const emailParams = createListingInviteEmailParams(
      currentUser,
      invitedUser,
      listing,
      formValues,
      !!invitedUserEntity
    );

    // To ensure the listing is updated in the marketplace state,
    // we must await the invite save to listing extended data before
    // triggering the invite success action.
    await dispatch(
      requestSaveInviteToListingExtendedData(listingId, invitedUser, formValues, isOwn)
    );

    dispatch(sendListingInviteSuccess(invitedUser));
    dispatch(requestSendListingInvitationEmail(emailParams));

    return response;
  } catch (error) {
    dispatch(sendListingInviteError(storableError(error)));
  }
};

export const resendListingInvite = (invitedUserId, listingId, email, formValues, isOwn) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(resendListingInviteRequest(invitedUserId));

  try {
    const { currentUser } = getState().user;

    let invitedUserEntity;

    try {
      const response = await integrationAPI.users.show({
        email,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      });

      invitedUserEntity = response.data.data.data;
      dispatch(addMarketplaceEntities(response.data));
    } catch (apiError) {
      invitedUserEntity = null;
    }

    const invitedUser = invitedUserEntity || {
      id: new UUID(generateUUID()),
      attributes: { email },
    };

    const useSdk = isOwn ? sdk.ownListings : integrationAPI.listings;
    const listingResponse = await useSdk.show({ id: listingId });
    const listing = isOwn ? listingResponse.data.data : listingResponse.data.data.data;

    const emailParams = createListingInviteEmailParams(
      currentUser,
      invitedUser,
      listing,
      formValues,
      !!invitedUserEntity
    );

    dispatch(resendListingInviteSuccess(invitedUser.id));
    dispatch(requestSendListingInvitationEmail(emailParams));

    return response;
  } catch (e) {
    dispatch(resendListingInviteError(storableError(e)));
  }
};

export const revokeListingInvite = (listingId, invitedUserId, isOwn) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(revokeListingInviteRequest(invitedUserId));

  const listingResponse = await integrationAPI.listings.show({ id: listingId });
  const listing = listingResponse.data.data.data;

  const teamFromPublicData = listing.attributes.publicData.team || [];
  const teamForPublicData = teamFromPublicData.filter(i => i.id !== invitedUserId);

  const useSdk = isOwn ? sdk.ownListings : integrationAPI.listings;

  return useSdk
    .update({
      id: listingId,
      publicData: {
        team: teamForPublicData,
      },
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(revokeListingInviteSuccess(invitedUserId));
      return response;
    })
    .catch(e => dispatch(revokeListingInviteError(storableError(e))));
};

/**
 * Removes an invite for a user and updates their team and organizations.
 *
 */
export const removeListingInvite = (listingId, invitedUserId, isOwn) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(removeListingInviteRequest(invitedUserId));

  const listingResponse = await integrationAPI.listings.show({ id: listingId });
  const listing = listingResponse.data.data.data;

  const teamFromPublicData = listing.attributes.publicData.team || [];
  const teamForPublicData = teamFromPublicData.filter(i => i.id !== invitedUserId);

  const useSdk = isOwn ? sdk.ownListings : integrationAPI.listings;

  return useSdk
    .update({
      id: listingId,
      publicData: {
        team: teamForPublicData,
      },
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(removeListingInviteSuccess(invitedUserId));
      return response;
    })
    .catch(e => dispatch(removeListingInviteError(storableError(e))));
};

/**
 * Organization fns
 */

export const showOrganizationInvites = () => (dispatch, getState, sdk) => {
  dispatch(showOrganizationInvitesRequest());

  return sdk.currentUser.show().then(response => {
    const currentUser = response.data.data;

    const pendingInvitesFromPublicData =
      currentUser?.attributes?.profile?.publicData?.pendingInvites || [];
    const teamFromPublicData = currentUser?.attributes?.profile?.publicData?.team || [];

    return integrationAPI.users
      .query({
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      })
      .then(response => {
        const userIds = response.data.data.data.map(user => userEntity(user));
        dispatch(addMarketplaceEntities(response.data));
        dispatch(
          showOrganizationInvitesSuccess(userIds, pendingInvitesFromPublicData, teamFromPublicData)
        );
        return response;
      })
      .catch(e => dispatch(showOrganizationInvitesError(e)));
  });
};

/**
 * Save pending invite in the master account extended data.
 *
 */
export const requestSaveInviteToExtendedData = (invitedUser, organizationIds) => (
  dispatch,
  getState,
  sdk
) => {
  return sdk.currentUser.show().then(response => {
    const currentUser = response.data.data;

    const pendingInvitesFromPublicData = currentUser.attributes.profile.publicData.pendingInvites;
    const hasPendingInvitesFromPublicData = pendingInvitesFromPublicData?.length > 0;

    const newInvite = createNewOrganizationInvite(invitedUser, organizationIds);

    const pendingInvites = hasPendingInvitesFromPublicData
      ? [...pendingInvitesFromPublicData, newInvite]
      : [newInvite];

    return sdk.currentUser.updateProfile({
      publicData: {
        pendingInvites,
      },
    });
  });
};

export const requestSendOrganizationInvitationEmail = emailParams => {
  return emailAPI.organizationProfiles.invitation(emailParams);
};

/**
 * Send invite to the invited user.
 *
 * 1. Fetch invited user by email
 * 2. Save invite to the master account extended data
 */
export const sendOrganizationInvite = (email, organizationIds) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(sendOrganizationInviteRequest());

  const { currentUser } = getState().user;
  const organizationProfiles = organizationIdsToOrganizations(currentUser, organizationIds);

  try {
    let invitedUserEntity;

    try {
      const response = await integrationAPI.users.show({
        email,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      });

      invitedUserEntity = response.data.data.data;
      dispatch(addMarketplaceEntities(response.data));
    } catch (apiError) {
      invitedUserEntity = null;
    }

    const invitedUser = invitedUserEntity || {
      id: new UUID(generateUUID()),
      attributes: { email },
    };

    const emailParams = createOrganizationInviteEmailParams(
      currentUser,
      invitedUser,
      organizationProfiles,
      !!invitedUserEntity
    );

    dispatch(sendOrganizationInviteSuccess(invitedUser, organizationIds));
    dispatch(requestSaveInviteToExtendedData(invitedUser, organizationIds));
    dispatch(requestSendOrganizationInvitationEmail(emailParams));

    return invitedUser;
  } catch (error) {
    dispatch(sendOrganizationInviteError(storableError(error)));
  }
};

export const resendOrganizationInvite = (invitedUserId, email, organizationIds) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(resendOrganizationInviteRequest(invitedUserId.uuid));

  const { currentUser } = getState().user;
  const organizationProfiles = organizationIdsToOrganizations(currentUser, organizationIds);

  try {
    let invitedUser;

    try {
      const response = await integrationAPI.users.show({
        email,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      });

      invitedUser = response.data.data.data;
      dispatch(addMarketplaceEntities(response.data));
    } catch (apiError) {
      invitedUser = null;
    }

    const nonExistingInvitedUser = {
      id: invitedUserId,
      attributes: { email },
    };

    const emailParams = createOrganizationInviteEmailParams(
      currentUser,
      invitedUser || nonExistingInvitedUser,
      organizationProfiles,
      !!invitedUser
    );

    dispatch(resendOrganizationInviteSuccess());
    dispatch(requestSendOrganizationInvitationEmail(emailParams));

    return invitedUser;
  } catch (error) {
    dispatch(resendOrganizationInviteError(storableError(error)));
  }
};

export const revokeOrganizationInvite = inviteId => (dispatch, getState, sdk) => {
  dispatch(revokeOrganizationInviteRequest(inviteId));

  const { pendingInvites } = getState().invites;

  return sdk.currentUser
    .updateProfile({
      publicData: {
        pendingInvites: filterInvitesByInviteId(pendingInvites, inviteId),
      },
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(revokeOrganizationInviteSuccess(inviteId));
      return response;
    })
    .catch(e => dispatch(revokeOrganizationInviteError(storableError(e))));
};

/**
 * Removes an invite for a user and updates their team and organizations.
 *
 */
export const removeOrganizationInvite = userId => (dispatch, getState, sdk) => {
  dispatch(removeOrganizationInviteRequest(userId));

  // Fetch current user data
  return sdk.currentUser.show().then(response => {
    const currentUser = response.data.data;
    const team = currentUser.attributes.profile.publicData.team;

    const teamArray = filterInvitesByInviteId(team, userId);
    const teamForPublicData = saveArrayToExtendedData(teamArray);

    // Update current user's team by filtering out the invite with the given userId
    return sdk.currentUser
      .updateProfile({
        publicData: {
          team: teamForPublicData,
        },
      })
      .then(() => {
        // Fetch user data from integrationAPI
        return integrationAPI.users.show({ id: userId }).then(response => {
          const user = response.data.data.data;
          const organizations = user.attributes.profile.publicData.organizations;

          const organizationsArray = filterInvitesByInviteId(
            organizations,
            getState().user.currentUser.id.uuid
          );
          const organizationsForPublicData = saveArrayToExtendedData(organizationsArray);

          // Update user's organizations by filtering out the invite with the given userId
          return integrationAPI.users
            .updateProfile({
              id: userId,
              publicData: {
                organizations: organizationsForPublicData,
              },
            })
            .then(response => {
              dispatch(addMarketplaceEntities(response));
              dispatch(removeOrganizationInviteSuccess(userId));
              return response.data;
            });
        });
      })
      .catch(e => dispatch(removeOrganizationInviteError(storableError(e))));
  });
};
