import React from 'react';
import { object, string, func } from 'prop-types';
import classNames from 'classnames';

// Import shared components
import { VideoFromFile, VideoPlayer, IconSpinner } from '../../components';

// Import modules from this directory
import { ControlMenuVideo as ControlMenu } from './ControlMenu';
import css from './ListingVideo.module.css';

const ListingVideo = props => {
  const {
    className,
    video,
    isMenuOpen,
    onToggleMenu,
    onRemoveVideo,
    aspectWidth = 1,
    aspectHeight = 1,
  } = props;

  if (video.file && !video.src) {
    // Add control menu only when the video has been uploaded and can be removed
    const controlMenu = video.videoId ? (
      <ControlMenu
        video={video}
        isMenuOpen={isMenuOpen}
        onToggleMenu={onToggleMenu}
        onRemoveVideo={onRemoveVideo}
      />
    ) : null;

    // While video is uploading we show overlay on top of thumbnail
    const uploadingOverlay = !video.videoId ? (
      <div className={css.thumbnailLoading}>
        <IconSpinner className={css.spinnerIcon} />
      </div>
    ) : null;

    return (
      <VideoFromFile
        id={video.id}
        className={className}
        file={video.file}
        aspectWidth={aspectWidth}
        aspectHeight={aspectHeight}
      >
        {controlMenu}
        {uploadingOverlay}
      </VideoFromFile>
    );
  } else {
    const classes = classNames(css.root, className);

    // This is shown when video is uploaded,
    // but the new responsive video is not yet downloaded by the browser.
    // This is absolutely positioned behind the actual video.
    const fallbackWhileDownloading = video.file ? (
      <VideoFromFile
        id={video.id}
        rootClassName={css.fallbackWhileDownloading}
        file={video.file}
        aspectWidth={aspectWidth}
        aspectHeight={aspectHeight}
      >
        <div className={css.thumbnailLoading}>
          <IconSpinner className={css.spinnerIcon} />
        </div>
      </VideoFromFile>
    ) : null;

    return (
      <div className={classes}>
        <div className={css.wrapper}>
          {fallbackWhileDownloading}
          <VideoPlayer
            width={aspectWidth}
            height={aspectHeight}
            video={video}
            settings={{ playerTime: false }}
          />
          <ControlMenu
            video={video}
            isMenuOpen={isMenuOpen}
            onToggleMenu={onToggleMenu}
            onRemoveVideo={onRemoveVideo}
          />
        </div>
      </div>
    );
  }
};

ListingVideo.defaultProps = {
  className: null,
};

ListingVideo.propTypes = {
  className: string,
  video: object.isRequired,
  onRemoveVideo: func.isRequired,
};

export default ListingVideo;
