import React from 'react';
import { func, object, string, bool } from 'prop-types';
import { propTypes } from '../../util/types';
import classNames from 'classnames';

// Import shared components
import { FormattedMessage } from '../../util/reactIntl';
import { AspectRatioWrapper, ImageFromFile, ResponsiveImage, IconSpinner } from '../../components';

// Import modules from this directory
import ControlMenu from './ControlMenu';
import css from './ListingImage.module.css';

const isMatchingImage = (image, imageId) => {
  return imageId === (image?.id?.uuid || image?.imageId?.uuid);
};

// Cropped "thumbnail" of given listing image.
// The image might be one already uploaded and attached to listing entity
// or representing local image file (before it's uploaded & attached to listing).
const ListingImage = props => {
  const {
    className,
    image,
    savedImageAltText,
    isMenuOpen,
    onToggleMenu,
    onRemoveImage,
    onPromoteImage,
    promoteImageInProgress,
    promoteImageError,
    listingCoverImageId,
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'landscape-crop2x',
  } = props;

  if (image.file && !image.attributes) {
    // Add control menu only when the image has been uploaded and can be removed
    const controlMenu = image.imageId ? (
      <ControlMenu
        image={image}
        isMenuOpen={isMenuOpen}
        onToggleMenu={onToggleMenu}
        onRemoveImage={onRemoveImage}
        showCoverButton={false}
      />
    ) : null;

    // While image is uploading we show overlay on top of thumbnail
    const uploadingOverlay = !image.imageId ? (
      <div className={css.thumbnailLoading}>
        <IconSpinner className={css.spinnerIcon} />
      </div>
    ) : null;

    return (
      <ImageFromFile
        id={image.id}
        className={className}
        file={image.file}
        aspectWidth={aspectWidth}
        aspectHeight={aspectHeight}
      >
        {controlMenu}
        {uploadingOverlay}
      </ImageFromFile>
    );
  } else {
    const classes = classNames(css.root, className);

    const variants = image
      ? Object.keys(image?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
      : [];
    const imgForResponsiveImage = image.imageId ? { ...image, id: image.imageId } : image;

    const isCoverImage = isMatchingImage(image, listingCoverImageId);
    const coverImageLabel = isCoverImage ? (
      <span className={classNames(css.imageLabel, css.imageLabelCover)}>
        <FormattedMessage id="EditExperienceContentPanel.coverImageText" />
      </span>
    ) : null;

    // Handle promoting or unsetting an image as cover.
    const handlePromoteImage = imageType => {
      const imageId = isCoverImage ? null : image.id;
      onPromoteImage(imageId, imageType);
    };

    const imageLabels = isCoverImage ? (
      <span className={css.imageLabels}>{coverImageLabel}</span>
    ) : null;

    // This is shown when image is uploaded,
    // but the new responsive image is not yet downloaded by the browser.
    // This is absolutely positioned behind the actual image.
    const fallbackWhileDownloading = image.file ? (
      <ImageFromFile
        id={image.id}
        rootClassName={css.fallbackWhileDownloading}
        file={image.file}
        aspectWidth={aspectWidth}
        aspectHeight={aspectHeight}
      >
        <div className={css.thumbnailLoading}>
          <IconSpinner className={css.spinnerIcon} />
        </div>
      </ImageFromFile>
    ) : null;

    return (
      <div className={classes}>
        <div className={css.wrapper}>
          {fallbackWhileDownloading}
          {imageLabels}

          <AspectRatioWrapper width={aspectWidth} height={aspectHeight}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              image={imgForResponsiveImage}
              alt={savedImageAltText}
              variants={variants}
            />
          </AspectRatioWrapper>
          <ControlMenu
            image={image}
            isMenuOpen={isMenuOpen}
            onToggleMenu={onToggleMenu}
            onRemoveImage={onRemoveImage}
            onPromoteImage={imageType => handlePromoteImage(imageType)}
            promoteImageInProgress={promoteImageInProgress}
            promoteImageError={promoteImageError}
            showCoverButton={!image.file} // display only for attached images
            isCoverImage={isCoverImage}
          />
        </div>
      </div>
    );
  }
};

ListingImage.defaultProps = {
  className: null,
  promoteImageInProgress: false,
  promoteImageError: null,
  listingCoverImageId: null,
};

ListingImage.propTypes = {
  className: string,
  image: object.isRequired,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
  onPromoteImage: func.isRequired,
  promoteImageInProgress: bool.isRequired,
  promoteImageError: propTypes.error,
  listingCoverImageId: string,
};

export default ListingImage;
