import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { TRANSITION_CONFIRM_PAYMENT } from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_PAYMENTS_REQUEST = 'app/OrderHistoryPage/FETCH_PAYMENTS_REQUEST';
export const FETCH_PAYMENTS_SUCCESS = 'app/OrderHistoryPage/FETCH_PAYMENTS_SUCCESS';
export const FETCH_PAYMENTS_ERROR = 'app/OrderHistoryPage/FETCH_PAYMENTS_ERROR';

export const FETCH_ALL_PAYMENTS_REQUEST = 'app/OrderHistoryPage/FETCH_ALL_PAYMENTS_REQUEST';
export const FETCH_ALL_PAYMENTS_SUCCESS = 'app/OrderHistoryPage/FETCH_ALL_PAYMENTS_SUCCESS';
export const FETCH_ALL_PAYMENTS_ERROR = 'app/OrderHistoryPage/FETCH_ALL_PAYMENTS_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchPaymentsError: null,
  pagination: null,
  transactionRefs: [],
};

export default function orderHistoryPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_PAYMENTS_REQUEST:
      return { ...state, fetchInProgress: true, fetchPaymentsError: null };
    case FETCH_PAYMENTS_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_PAYMENTS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchPaymentsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchPaymentsRequest = () => ({ type: FETCH_PAYMENTS_REQUEST });
const fetchPaymentsSuccess = response => ({
  type: FETCH_PAYMENTS_SUCCESS,
  payload: response,
});
const fetchPaymentsError = e => ({
  type: FETCH_PAYMENTS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const PAYMENTS_PAGE_SIZE = 10;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(fetchPaymentsRequest());

  const { page = 1 } = parse(search);

  const apiQueryParams = {
    only: 'order',
    lastTransitions: TRANSITION_CONFIRM_PAYMENT,
    include: ['provider', 'provider.profileImage', 'customer', 'customer.profileImage', 'booking'],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'metadata',
      'createdAt',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: PAYMENTS_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchPaymentsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchPaymentsError(storableError(e)));
      throw e;
    });
};
