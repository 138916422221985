import { DURATION_TYPE_DAYS, DURATION_TYPE_MONTHS } from './types';
import config from '../config';

/**
 * Helper function that formats the membership duration.
 *
 * @param {object} intl
 * @param {object} duration
 */
export const formatDuration = (intl, duration) => {
  const { type, length } = duration;

  let key = null;
  if (type === DURATION_TYPE_DAYS) {
    key = 'Membership.durationDays';
  } else if (type === DURATION_TYPE_MONTHS) {
    key = 'Membership.durationMonths';
  }

  return intl.formatMessage({ id: key }, { length });
};

/**
 * Helper function that ensures that the current payment is
 * of type membership.
 *
 * @param {string} type
 *
 * @return {boolean}
 */
export const ensureMembershipPayment = type => {
  return type === config.paymentTypes['membership'];
};
