import React, { Component } from 'react';
import { array, string, func } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { findOptionsForSelectFilter } from '../../util/search';
import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';
import config from '../../config';

import css from './RecentlyViewedListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const RecentlyViewedListingCardComponent = props => {
  const { className, rootClassName, listing, renderSizes, filtersConfig } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const posterImage =
    listing?.images?.find(i => i.id.uuid === publicData?.posterImageId) ?? firstImage;

  const typeOptions = findOptionsForSelectFilter('type', filtersConfig);
  const type = typeOptions.find(t => t.key === publicData?.type);

  const isExperienceListing = type.key === config.listingTypes['experience'];
  const linkPageName = isExperienceListing ? 'ExperiencePage' : 'ListingPage';

  return (
    <NamedLink className={classes} name={linkPageName} params={{ id, slug }}>
      <AspectRatioWrapper width={5} height={7}>
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={posterImage}
          variants={['portrait-crop2x', 'portrait-crop4x']}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

RecentlyViewedListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
};

RecentlyViewedListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,
};

export default injectIntl(RecentlyViewedListingCardComponent);
