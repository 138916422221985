import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPlay.module.css';

const IconPlay = props => {
  const { className, rootClassName, isPlaying } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (isPlaying) {
    return (
      <svg
        className={classes}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        height="16"
        width="16"
      >
        <path d="M13 1v14H9V1zM7 1v14H3V1z" />
      </svg>
    );
  }

  return (
    <svg
      className={classes}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      height="16"
      widht="16"
    >
      <path d="M4.504 1.007a.996.996 0 0 0-1.296.254A1 1 0 0 0 3 1.87v12.26a1.001 1.001 0 0 0 1.504.864l10.508-6.13a1.002 1.002 0 0 0 0-1.727z" />
    </svg>
  );
};

const { string, bool } = PropTypes;

IconPlay.defaultProps = {
  className: null,
  rootClassName: null,
  isPlaying: false,
};

IconPlay.propTypes = {
  className: string,
  rootClassName: string,
  isPlaying: bool.isRequired,
};

export default IconPlay;
