import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import arrayMutators from 'final-form-arrays';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, TopbarFilterComponent, PrimaryButton } from '../../components';

import css from './TopbarSearchFilterForm.module.css';

const TopbarSearchFilterFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        intl,
        form,
        values,
        invalid,
        topbarFilters,
      } = fieldRenderProps;

      const submitMessage = intl.formatMessage({
        id: 'TopbarSearchFilterForm.submit',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitDisabled = invalid || disabled;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {topbarFilters.map(config => {
            return (
              <TopbarFilterComponent
                key={config.id}
                filterConfig={config}
                form={form}
                values={values}
              />
            );
          })}

          <PrimaryButton className={css.submitButton} type="submit" disabled={submitDisabled}>
            {submitMessage}
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

TopbarSearchFilterFormComponent.defaultProps = { className: null, rootClassName: null };

const { func, string, array } = PropTypes;

TopbarSearchFilterFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  topbarFilters: array.isRequired,
};

const TopbarSearchFilterForm = compose(injectIntl)(TopbarSearchFilterFormComponent);
TopbarSearchFilterForm.displayName = 'TopbarSearchFilterForm';

export default TopbarSearchFilterForm;
