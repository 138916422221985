import React from 'react';
import moment from 'moment';

import css from './BookingPanel.module.css';

const TimeRange = props => {
  const { startDate, endDate, dateType, timeZone } = props;

  const start = moment(startDate)
    .tz(timeZone)
    .format('MMM DD');
  const startTime = moment(startDate)
    .tz(timeZone)
    .format('h:mm A');
  const endTime = moment(endDate)
    .tz(timeZone)
    .format('h:mm A');
  const shortTimeZone = moment.tz(timeZone).format('z');

  if (dateType === 'date') {
    return <span className={css.timeslotDate}>{start}</span>;
  } else if (dateType === 'time') {
    return <span className={css.timeslotTime}>{`${startTime}-${endTime} (${shortTimeZone})`}</span>;
  } else {
    return <span className={css.timeslotDate}>{start}</span>;
  }
};

export default TimeRange;
