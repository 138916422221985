import { storableError } from '../util/errors';

const mergeAndFlattenListingClips = listings =>
  listings
    .filter(l => l.attributes.publicData.videos?.length > 0)
    .map(l => l.attributes.publicData.videos)
    ?.flat(1);

// ================ Action types ================ //

export const FETCH_USER_CLIPS_REQUEST = 'app/assets/REQUEST';
export const FETCH_USER_CLIPS_SUCCESS = 'app/assets/SUCCESS';
export const FETCH_USER_CLIPS_ERROR = 'app/assets/ERROR';

// ================ Reducer ================ //

const initialState = {
  userClips: [],
  fetchUserClipsInProgress: false,
  fetchUserClipsError: null,
};

export default function assetReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_USER_CLIPS_REQUEST:
      return { ...state, fetchUserClipsInProgress: true, fetchUserClipsError: null };
    case FETCH_USER_CLIPS_SUCCESS:
      return {
        ...state,
        userClips: payload.clips,
        fetchUserClipsInProgress: false,
        fetchUserClipsError: null,
      };
    case FETCH_USER_CLIPS_ERROR:
      return { ...state, fetchUserClipsInProgress: true, fetchUserClipsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchUserClipsRequest = () => ({ type: FETCH_USER_CLIPS_REQUEST });
export const fetchUserClipsSuccess = clips => ({
  type: FETCH_USER_CLIPS_SUCCESS,
  payload: { clips },
});
export const fetchUserClipsError = error => ({
  type: FETCH_USER_CLIPS_ERROR,
  payload: error,
});

// ================ Thunks ================ //

export const fetchUserClips = userId => (dispatch, getState, sdk) => {
  dispatch(fetchUserClipsRequest());

  return sdk.listings
    .query({ authorId: userId })
    .then(response => {
      const listings = response.data.data;
      const listingClips = mergeAndFlattenListingClips(listings);

      dispatch(fetchUserClipsSuccess(listingClips));
      return listingClips;
    })
    .catch(e => dispatch(fetchUserClipsError(storableError(e))));
};
