import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../../util/types';
import { FormattedMessage } from '../../../util/reactIntl';
import { Menu, MenuLabel, MenuContent, MenuItem, InlineTextButton, IconMenu } from '../..';
import classNames from 'classnames';

import css from './ControlMenu.module.css';

const USE_ARROW = false;
const CONTENT_POSITION = 'left';

const ControlMenu = props => {
  const {
    className,
    rootClassName,
    deleteCommentInProgress,
    deleteCommentError,
    onDeleteComment,
    onEditComment,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const deleteButtonDisabled = deleteCommentInProgress || deleteCommentError;

  return (
    <Menu className={classes} useArrow={USE_ARROW} contentPosition={CONTENT_POSITION}>
      <MenuLabel className={css.menuLabel}>
        <IconMenu />
      </MenuLabel>
      <MenuContent className={css.menuContent}>
        <MenuItem key="ControlMenu.edit" className={css.menuItem}>
          <InlineTextButton className={css.menuItemButton} type="button" onClick={onEditComment}>
            <FormattedMessage id="Comments.edit" />
          </InlineTextButton>
        </MenuItem>
        <MenuItem key="ControlMenu.delete" className={css.menuItem}>
          <InlineTextButton
            className={css.menuItemButton}
            type="button"
            onClick={onDeleteComment}
            disabled={deleteButtonDisabled}
          >
            <FormattedMessage id="Comments.delete" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

ControlMenu.defaultProps = {
  className: null,
  rootClassName: null,

  deleteCommentInProgress: false,
  deleteCommentError: null,
  onDeleteComment: null,
  onEditComment: null,
};

const { func, string, bool } = PropTypes;

ControlMenu.propTypes = {
  className: string,
  rootClassName: string,

  deleteCommentInProgress: bool,
  deleteCommentError: propTypes.error,
  onEditComment: func.isRequired,
  onDeleteComment: func.isRequired,
};

export default ControlMenu;
