import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { BookingDateRangeFilter, QuantityFilter } from '..';

import css from './BookingPanel.module.css';

const QUERY_PARAM_NAMES = ['dates'];
const FILTER_SUBMIT_STATE_CANCEL = 'cancel';

const getQuantityAfterSubmit = (state, formValues, values) => {
  if (state === FILTER_SUBMIT_STATE_CANCEL) {
    return formValues.quantity;
  }

  return values?.quantity ? values.quantity : DEFAULT_INITIAL_VALUES.quantity;
};

/**
 * PanelFilters component is used to allow filtering
 * availabilityExceptions (e.g timeslots) in BookingPanel.
 */
const PanelFilters = props => {
  const {
    intl,
    idPrefix,
    availabilityExceptions,
    handleFilterAvailabilityExceptions,
    isExceptionBlocked,
    filtersTitleClassName,
    filtersTitle,
    initialValues,
    setInitialValues,
    formValues,
    setFormValues,
    ...rest
  } = props;

  const datesLabel = intl.formatMessage({ id: 'BookingPanel.datesFilterLabel' });
  const datesLabelText = intl.formatMessage({ id: 'BookingPanel.datesFilterLabelText' });

  const quantityLabel = intl.formatMessage(
    { id: 'BookingPanel.quantityFilterLabel' },
    { quantity: formValues.quantity }
  );
  const quantityLabelText = intl.formatMessage({ id: 'BookingPanel.quantityFilterLabelText' });

  const onQuantityChange = currentQuantity => {
    setInitialValues({ ...initialValues, quantity: currentQuantity });
  };

  const filterTitleClassName = filtersTitleClassName ? filtersTitleClassName : css.filtersTitle;

  return (
    <div className={css.panelFilters}>
      <h2 className={filterTitleClassName}>
        {filtersTitle ? filtersTitle : <FormattedMessage id="BookingPanel.filtersTitle" />}
      </h2>
      <div className={css.filtersWrapper}>
        <BookingDateRangeFilter
          className={css.filterButton}
          id={idPrefix ? `${idPrefix}.dates` : 'dates'}
          label={datesLabel}
          labelText={datesLabelText}
          queryParamNames={QUERY_PARAM_NAMES}
          initialValues={initialValues}
          isDayBlocked={isExceptionBlocked}
          onSubmit={values => {
            setInitialValues({ ...initialValues, ...values });
            setFormValues({ ...initialValues, ...values });

            // If the dates from initialValues are the same as the
            // date from the submittedValues, then we don't need to
            // filter availabilityExceptions.
            const datesFromInitialValues = initialValues.dates;
            const datesFromSubmittedValues = values.dates;
            if (datesFromInitialValues !== datesFromSubmittedValues) {
              handleFilterAvailabilityExceptions(values);
            }
          }}
          showAsPopup
          showAsListingButton
          {...rest}
        />
        <QuantityFilter
          className={css.filterButton}
          id={idPrefix ? `${idPrefix}.quantity` : 'quantity'}
          label={quantityLabel}
          labelText={quantityLabelText}
          queryParamNames={QUERY_PARAM_NAMES}
          initialValues={initialValues}
          formValues={formValues}
          onSubmit={(values, state) => {
            const quantity = getQuantityAfterSubmit(state, formValues, values);

            setInitialValues({ ...initialValues, quantity });
            setFormValues({ ...initialValues, quantity });
          }}
          onQuantityChange={onQuantityChange}
          showAsPopup
          showAsListingButton
          {...rest}
        />
      </div>
    </div>
  );
};

export default PanelFilters;
