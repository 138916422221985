import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { SecondaryButton, IconUpload } from '../../components';

import css from './UploadButton.module.css';

const UploadButton = props => {
  const { titleMsgId, disabled, onClick } = props;

  return (
    <div className={css.uploadContainer}>
      <h2 className={css.uploadTitle}>
        <FormattedMessage id={titleMsgId} />
      </h2>
      <SecondaryButton
        className={css.uploadButton}
        type="button"
        disabled={disabled}
        onClick={onClick}
      >
        <IconUpload className={css.uploadIcon} />
        <FormattedMessage id="EditListingContentForm.uploadImagesButton" />
      </SecondaryButton>
    </div>
  );
};

UploadButton.defaultProps = {
  disabled: false,
  onClick: null,
};

const { bool, func } = PropTypes;

UploadButton.propTypes = {
  disabled: bool.isRequired,
  onClick: func.isRequired,
};

export default UploadButton;
