import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './ContactForm.module.css';

const ContactFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        sendEmailError,
        sendEmailSuccess,
        invalid,
        intl,
      } = fieldRenderProps;

      // name
      const nameLabel = intl.formatMessage({
        id: 'ContactForm.nameLabel',
      });
      const namePlaceholder = intl.formatMessage({
        id: 'ContactForm.namePlaceholder',
      });
      const nameRequiredMessage = intl.formatMessage({
        id: 'ContactForm.nameRequired',
      });
      const nameRequired = validators.required(nameRequiredMessage);

      // email
      const emailLabel = intl.formatMessage({
        id: 'ContactForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'ContactForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'ContactForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'ContactForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // message
      const messageLabel = intl.formatMessage({
        id: 'ContactForm.messageLabel',
      });
      const messagePlaceholder = intl.formatMessage({
        id: 'ContactForm.messagePlaceholder',
      });
      const messageRequiredMessage = intl.formatMessage({
        id: 'ContactForm.messageRequired',
      });
      const messageRequired = validators.required(messageRequiredMessage);

      const submitSuccess = sendEmailSuccess ? (
        <p className={css.success}>
          <FormattedMessage id="ContactForm.sendEmailSuccess" />
        </p>
      ) : null;

      const submitError = sendEmailError ? (
        <p className={css.error}>
          <FormattedMessage id="ContactForm.sendEmailError" />
        </p>
      ) : null;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitReady = sendEmailSuccess;
      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.fields}>
            <FieldTextInput
              className={css.field}
              type="text"
              id={formId ? `${formId}.name` : 'name'}
              name="name"
              label={nameLabel}
              placeholder={namePlaceholder}
              validate={nameRequired}
            />
            <FieldTextInput
              className={css.email}
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
          </div>

          <FieldTextInput
            className={css.field}
            type="textarea"
            id={formId ? `${formId}.message` : 'message'}
            name="message"
            label={messageLabel}
            placeholder={messagePlaceholder}
            validate={messageRequired}
          />

          {submitSuccess}
          {submitError}

          <PrimaryButton
            className={css.submitButton}
            readyClassName={css.submitButtonReady}
            inProgressClassName={css.submitButtonInProgress}
            type="submit"
            checkmarkSize="small"
            inProgress={submitInProgress}
            ready={submitReady}
            disabled={submitDisabled}
          >
            <FormattedMessage id="ContactForm.submit" />
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

ContactFormComponent.defaultProps = { inProgress: false };

const { bool } = PropTypes;

ContactFormComponent.propTypes = {
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ContactForm = compose(injectIntl)(ContactFormComponent);
ContactForm.displayName = 'ContactForm';

export default ContactForm;
