import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconComments.module.css';

const IconComments = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      height="20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path d="M15 4V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h1V6a2 2 0 0 1 2-2z" />
      <path d="M17 5H6a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3.36l1.76 1.83a.5.5 0 0 0 .75 0L13.64 16H17a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1z" />
    </svg>
  );
};

const { string } = PropTypes;

IconComments.defaultProps = {
  className: null,
  rootClassName: null,
};

IconComments.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconComments;
