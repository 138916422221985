import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal } from '..';
import { TopbarSearchFilterForm } from '../../forms';

import css from './TopbarSearchFilterModal.module.css';

const TopbarSearchFilterModal = props => {
  const {
    className,
    rootClassName,
    id,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmit,
    initialValues,
    urlQueryParams,
    topbarFilters,
    topbarSearchModalContentRef,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <Modal
      id={id}
      className={css.modalRoot}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      contentRef={topbarSearchModalContentRef}
    >
      <TopbarSearchFilterForm
        topbarFilters={topbarFilters}
        initialValues={initialValues(urlQueryParams)}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

const { string, func, array, object } = PropTypes;

TopbarSearchFilterModal.defaultProps = {
  className: null,
  rootClassName: null,
  initialValues: null,
  onSubmit: null,
};

TopbarSearchFilterModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  initialValues: func.isRequired,
  onSubmit: func.isRequired,
  urlQueryParams: object.isRequired,
  topbarFilters: array.isRequired,
};

export default injectIntl(TopbarSearchFilterModal);
