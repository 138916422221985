import { useEffect } from 'react';
import { bool, func } from 'prop-types';
import { propTypes } from '../../util/types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { generateIntercomUserHash } from '../../ducks/user.duck';

// Intercom
import Intercom, { update, shutdown } from '@intercom/messenger-js-sdk';

const IntercomWidget = props => {
  const { isAuthenticated, currentUser, onGenerateIntercomUserHash } = props;

  useEffect(() => {
    const setupIntercom = async () => {
      const intercomOptions = {
        app_id: process.env.REACT_APP_INTERCOM_WIDGET_ID,
        hide_default_launcher: false,
      };

      if (isAuthenticated && currentUser) {
        intercomOptions.user_id = currentUser.id.uuid;
        intercomOptions.name = currentUser.attributes.profile.displayName;
        intercomOptions.email = currentUser.attributes.email;
        intercomOptions.created_at = Math.floor(
          new Date(currentUser.attributes.createdAt).getTime() / 1000
        );

        const userHash = await onGenerateIntercomUserHash(currentUser.id.uuid);
        intercomOptions.user_hash = userHash;
      }

      Intercom(intercomOptions);
    };

    setupIntercom();

    return () => {
      update({
        hide_default_launcher: true,
      });
      shutdown();
    };
  }, [isAuthenticated, currentUser]);

  return null;
};

IntercomWidget.defaultProps = {
  isAuthenticated: false,
  currentUser: null,
};

IntercomWidget.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,

  onGenerateIntercomUserHash: func.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  return {
    isAuthenticated,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onGenerateIntercomUserHash: userId => dispatch(generateIntercomUserHash(userId)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(IntercomWidget);
