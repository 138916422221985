import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaInstagram.module.css';

const IconSocialMediaInstagram = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M10.1111 0C11.1425 0 12.1317 0.409721 12.861 1.13903C13.5903 1.86834 14 2.85749 14 3.88889V10.1111C14 11.1425 13.5903 12.1317 12.861 12.861C12.1317 13.5903 11.1425 14 10.1111 14H3.88889C2.85749 14 1.86834 13.5903 1.13903 12.861C0.409721 12.1317 0 11.1425 0 10.1111V3.88889C0 2.85749 0.409721 1.86834 1.13903 1.13903C1.86834 0.409721 2.85749 0 3.88889 0H10.1111ZM7 3.88889C6.17488 3.88889 5.38356 4.21667 4.80011 4.80011C4.21667 5.38356 3.88889 6.17488 3.88889 7C3.88889 7.82512 4.21667 8.61644 4.80011 9.19989C5.38356 9.78333 6.17488 10.1111 7 10.1111C7.82512 10.1111 8.61644 9.78333 9.19989 9.19989C9.78333 8.61644 10.1111 7.82512 10.1111 7C10.1111 6.17488 9.78333 5.38356 9.19989 4.80011C8.61644 4.21667 7.82512 3.88889 7 3.88889ZM7 5.44444C7.41256 5.44444 7.80822 5.60833 8.09994 5.90006C8.39167 6.19178 8.55556 6.58744 8.55556 7C8.55556 7.41256 8.39167 7.80822 8.09994 8.09994C7.80822 8.39167 7.41256 8.55556 7 8.55556C6.58744 8.55556 6.19178 8.39167 5.90006 8.09994C5.60833 7.80822 5.44444 7.41256 5.44444 7C5.44444 6.58744 5.60833 6.19178 5.90006 5.90006C6.19178 5.60833 6.58744 5.44444 7 5.44444ZM10.5 2.72222C10.2937 2.72222 10.0959 2.80417 9.95003 2.95003C9.80417 3.09589 9.72222 3.29372 9.72222 3.5C9.72222 3.70628 9.80417 3.90411 9.95003 4.04997C10.0959 4.19583 10.2937 4.27778 10.5 4.27778C10.7063 4.27778 10.9041 4.19583 11.05 4.04997C11.1958 3.90411 11.2778 3.70628 11.2778 3.5C11.2778 3.29372 11.1958 3.09589 11.05 2.95003C10.9041 2.80417 10.7063 2.72222 10.5 2.72222Z"
        fill="white"
      />
    </svg>
  );
};

IconSocialMediaInstagram.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaInstagram.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaInstagram;
