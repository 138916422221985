import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconExpand.module.css';

const IconExpand = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      height="16"
      width="16"
    >
      <path d="m6.293 8.293 1.414 1.414L3.914 13.5H7v2H2a1.5 1.5 0 0 1-1.498-1.412L.5 14V9h2v3.085zM14 .5a1.5 1.5 0 0 1 1.498 1.412L15.5 2v5h-2V3.914L9.707 7.707 8.293 6.293 12.085 2.5H9v-2z" />
    </svg>
  );
};

const { string } = PropTypes;

IconExpand.defaultProps = {
  className: null,
  rootClassName: null,
};

IconExpand.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconExpand;
