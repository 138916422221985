/**
 * Extracts the watchlist IDs from the public data of a given user.
 *
 * @param {Object} currentUser - The current user object.
 * @param {Object} [currentUser.attributes] - User attributes including profile data.
 * @param {Object} [currentUser.attributes.profile] - The profile object of the user.
 * @param {Object} [currentUser.attributes.profile.publicData] - Public data within the profile.
 * @param {Array} [currentUser.attributes.profile.publicData.watchlistIds] - Array of watchlist IDs.
 *
 * @returns {Array} - Returns an array of watchlist IDs or an empty array if not found.
 */
export const getWatchlistIdsFromPublicData = (currentUser = {}) =>
  currentUser?.attributes?.profile?.publicData?.watchlistIds || [];
