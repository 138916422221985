import { fetchCurrentUser } from './user.duck';
import {
  getSelectedOrganizationProfile,
  getSelectedOrganizationProfileIdFromExtendedData,
  getUserOrganizationProfiles,
} from '../util/organizations';
import { storableError } from '../util/errors';

// ================ Action types ================ //

export const FETCH_ORGANIZATION_PROFILES_REQUEST = 'app/assets/FETCH_ORGANIZATION_PROFILES_REQUEST';
export const FETCH_ORGANIZATION_PROFILES_SUCCESS = 'app/assets/FETCH_ORGANIZATION_PROFILES_SUCCESS';
export const FETCH_ORGANIZATION_PROFILES_ERROR = 'app/assets/FETCH_ORGANIZATION_PROFILES_ERROR';

export const SELECT_ORGANIZATION_PROFILE_REQUEST = 'app/assets/SELECT_ORGANIZATION_PROFILE_REQUEST';
export const SELECT_ORGANIZATION_PROFILE_SUCCESS = 'app/assets/SELECT_ORGANIZATION_PROFILE_SUCCESS';
export const SELECT_ORGANIZATION_PROFILE_ERROR = 'app/assets/SELECT_ORGANIZATION_PROFILE_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchOrganizationProfilesInProgress: false,
  fetchOrganizationProfilesError: null,
  organizationProfiles: [],
  selectOrganizationProfileInProgress: false,
  selectOrganizationProfileError: null,
};

export default function assetReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORGANIZATION_PROFILES_REQUEST:
      return {
        ...state,
        fetchOrganizationProfilesInProgress: true,
        fetchOrganizationProfilesError: null,
      };
    case FETCH_ORGANIZATION_PROFILES_SUCCESS:
      return {
        ...state,
        fetchOrganizationProfilesInProgress: false,
        fetchOrganizationProfilesError: null,
        organizationProfiles: payload.organizationProfiles,
      };
    case FETCH_ORGANIZATION_PROFILES_ERROR:
      return {
        ...state,
        fetchOrganizationProfilesInProgress: false,
        fetchOrganizationProfilesError: payload,
      };

    case SELECT_ORGANIZATION_PROFILE_REQUEST:
      return {
        ...state,
        selectOrganizationProfileInProgress: true,
        selectOrganizationProfileError: null,
      };
    case SELECT_ORGANIZATION_PROFILE_SUCCESS:
      return {
        ...state,
        selectOrganizationProfileInProgress: false,
        selectOrganizationProfileError: null,
      };
    case SELECT_ORGANIZATION_PROFILE_ERROR:
      return {
        ...state,
        selectOrganizationProfileInProgress: false,
        selectOrganizationProfileError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchOrganizationProfilesRequest = () => ({
  type: FETCH_ORGANIZATION_PROFILES_REQUEST,
});
export const fetchOrganizationProfilesSuccess = organizationProfiles => ({
  type: FETCH_ORGANIZATION_PROFILES_SUCCESS,
  payload: { organizationProfiles },
});
export const fetchOrganizationProfilesError = error => ({
  type: FETCH_ORGANIZATION_PROFILES_ERROR,
  payload: error,
});

export const selectOrganizationProfileRequest = () => ({
  type: SELECT_ORGANIZATION_PROFILE_REQUEST,
});
export const selectOrganizationProfileSuccess = () => ({
  type: SELECT_ORGANIZATION_PROFILE_SUCCESS,
});
export const selectOrganizationProfileError = error => ({
  type: SELECT_ORGANIZATION_PROFILE_ERROR,
  payload: error,
});

// ================ Thunks ================ //

export const fetchInvitedOrganizationProfiles = currentUser => async (dispatch, getState, sdk) => {
  const invitedOrganizations = currentUser.attributes.profile.publicData.organizations;
  const invitedOrganizationProfiles = [];

  if (invitedOrganizations?.length > 0) {
    for (const organization of invitedOrganizations) {
      const userResponse = await sdk.users.show({ id: organization.id });
      const user = userResponse.data.data;

      const organizationProfiles =
        organization.organizationIds?.map(orgId => ({
          ...user?.attributes?.profile?.publicData?.organizationProfiles?.find(
            profile => profile.id === orgId
          ),
          creatorId: organization.id,
          invited: true,
        })) || [];

      invitedOrganizationProfiles.push(...organizationProfiles);
    }

    return invitedOrganizationProfiles;
  }
  return [];
};

// Queries organizations and their profiles
export const fetchOrganizationProfiles = () => async (dispatch, getState, sdk) => {
  dispatch(fetchOrganizationProfilesRequest());

  try {
    let { currentUser } = getState().user;

    if (!currentUser) {
      const currentUserResponse = await sdk.currentUser.show();
      currentUser = currentUserResponse.data.data;
    }

    const invitedOrganizationProfiles = await dispatch(
      fetchInvitedOrganizationProfiles(currentUser)
    );
    const userOrganizationProfiles = getUserOrganizationProfiles(
      currentUser,
      invitedOrganizationProfiles
    );

    dispatch(fetchOrganizationProfilesSuccess(userOrganizationProfiles));
    return userOrganizationProfiles;
  } catch (error) {
    dispatch(fetchOrganizationProfilesError(storableError(error)));
    throw error;
  }
};

export const fetchSelectedOrganization = () => async (dispatch, getState, sdk) => {
  const currentUser = await dispatch(fetchCurrentUser());
  const selectedOrganizationProfileId = getSelectedOrganizationProfileIdFromExtendedData(
    currentUser
  );

  const organizationProfiles = await dispatch(fetchOrganizationProfiles());
  return getSelectedOrganizationProfile(organizationProfiles, selectedOrganizationProfileId);
};

export const selectOrganizationProfile = organizationProfileId => (dispatch, getState, sdk) => {
  dispatch(selectOrganizationProfileRequest());

  return sdk.currentUser
    .updateProfile({
      publicData: {
        selectedOrganizationProfileId: organizationProfileId,
      },
    })
    .then(response => {
      dispatch(selectOrganizationProfileSuccess(response));
      dispatch(fetchCurrentUser());
      return response;
    })
    .catch(e => dispatch(selectOrganizationProfileError(storableError(e))));
};
