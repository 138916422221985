import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { scrollToElement } from '../../util/scroll';
import { InlineTextButton } from '../../components';
import classNames from 'classnames';

import css from './ScrollableNav.module.css';

const COMPONENT_NAME = 'ScrollableNav';

const NavItem = props => {
  const { messageId, scrollParams } = props;
  return (
    <li className={css.listItem}>
      <InlineTextButton
        className={css.listItemButton}
        onClick={() => scrollToElement(scrollParams.id, scrollParams.topOffset)}
      >
        <FormattedMessage id={messageId} />
      </InlineTextButton>
    </li>
  );
};

const ScrollableNav = props => {
  const { rootClassName, className, show, items } = props;

  if (!items) {
    throw new Error(`${COMPONENT_NAME} expects items prop.`);
  }

  const classes = classNames(rootClassName || css.root, className, {
    [css.rootVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.content}>
        <ul className={css.list}>
          {items.map(i => {
            return <NavItem key={i.id} messageId={i.messageId} scrollParams={i.scrollParams} />;
          })}
        </ul>
      </div>
    </div>
  );
};

ScrollableNav.defaultProps = {
  rootClassName: null,
  className: null,
  show: false,
  items: [],
};

const { string, bool, array } = PropTypes;

ScrollableNav.propTypes = {
  rootClassName: string,
  className: string,
  show: bool.isRequired,
  items: array.isRequired,
};

export default ScrollableNav;
