import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconMenu.module.css';

const IconMenu = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      height="16"
      width="16"
    >
      <path d="M3 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm5 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm5 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
    </svg>
  );
};

const { string } = PropTypes;

IconMenu.defaultProps = {
  className: null,
  rootClassName: null,
};

IconMenu.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconMenu;
