import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { transactionsToEvents } from './CalendarPage.helpers';

// ================ Action types ================ //

export const QUERY_EVENTS_REQUEST = 'app/CalendarPage/QUERY_EVENTS_REQUEST';
export const QUERY_EVENTS_SUCCESS = 'app/CalendarPage/QUERY_EVENTS_SUCCESS';
export const QUERY_EVENTS_ERROR = 'app/CalendarPage/QUERY_EVENTS_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryEventsInProgress: false,
  queryEventsError: null,
  events: [],
  transactionRefs: [],
};

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const resultIds = data => data.data.map(l => l.id);

const calendarPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case QUERY_EVENTS_REQUEST:
      return {
        ...state,
        queryEventsInProgress: true,
        queryEventsError: null,
      };
    case QUERY_EVENTS_SUCCESS:
      return {
        ...state,
        events: payload.events,
        transactionRefs: entityRefs(payload.data.data),
        queryEventsInProgress: false,
      };
    case QUERY_EVENTS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryEventsInProgress: false, queryEventsError: payload };

    default:
      return state;
  }
};

export default calendarPageReducer;

// ================ Action creators ================ //

export const queryEventsRequest = () => ({
  type: QUERY_EVENTS_REQUEST,
});

export const queryEventsSuccess = (response, events) => ({
  type: QUERY_EVENTS_SUCCESS,
  payload: { data: response.data, events },
});

export const queryEventsError = e => ({
  type: QUERY_EVENTS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryEvents = () => (dispatch, getState, sdk) => {
  dispatch(queryEventsRequest());

  return sdk.transactions
    .query({
      only: 'order',
      lastTransitions: ['transition/request-booking', 'transition/request-booking-after-enquiry'],
      processNames: ['flex-booking-process'],
      include: ['listing', 'listing.images', 'booking'],
      'fields.transaction': ['metadata'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      'limit.images': 1,
    })
    .then(response => {
      const denormalisedEntities = denormalisedResponseEntities(response);

      const events = transactionsToEvents(denormalisedEntities);

      dispatch(addMarketplaceEntities(response));
      dispatch(queryEventsSuccess(response, events));
      return response;
    })
    .catch(e => dispatch(queryEventsError(storableError(e))));
};

export const loadData = () => (dispatch, getState, sdk) => {
  return dispatch(queryEvents());
};
