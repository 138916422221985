import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../DashboardWrapper.module.css';

const OrganizationIcon = props => {
  const { className } = props;
  const classes = classNames(css.sideNavIcon, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        stroke="#0e0f0c"
        strokeWidth="1.5"
        d="M2 12c0-4.714 0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.536 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.464 8.536C19.07 22 16.713 22 12 22c-4.714 0-7.071 0-8.536-1.464C2 19.07 2 16.713 2 12Z"
      />
      <path
        stroke="#0e0f0c"
        strokeWidth="1.5"
        d="M6 10a2 2 0 1 0 4 0 2 2 0 1 0-4 0M14 14a2 2 0 1 1 4 0 2 2 0 1 1-4 0"
      />
      <path
        stroke="#0e0f0c"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M8 14v5M16 10V5M8 5v1M16 19v-1"
      />
    </svg>
  );
};

const { string } = PropTypes;

OrganizationIcon.defaultProps = {
  className: null,
};

OrganizationIcon.propTypes = {
  className: string,
};

export default OrganizationIcon;
