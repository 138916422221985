import React, { useRef, useState, useEffect } from 'react';
import { compose } from 'redux';
import { node, shape, object, number } from 'prop-types';
import { withViewport } from '../../util/contextHelpers';

// framer-motion
import { motion, useInView } from 'framer-motion';

const MAX_MOBILE_LAYOUT_WIDTH = 768;

// animation threshold
const THRESHOLD = 0;
const THRESHOLD_DESKTOP = 0.3;

const AnimatedDivComponent = props => {
  const { children, initial, animate, rootRef, viewport, ...rest } = props;

  if (!initial || !animate) {
    throw new Error('Missing required props: initial, animate');
  }

  const isMobileLayout = viewport.width < MAX_MOBILE_LAYOUT_WIDTH;

  const ref = useRef();
  const isInView = useInView(ref, {
    once: true,
    amount: isMobileLayout ? THRESHOLD : THRESHOLD_DESKTOP,
  });

  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    if (isInView && !hasAnimated) {
      setHasAnimated(true);
    }
  }, [isInView, hasAnimated]);

  return (
    <motion.div ref={ref} initial={initial} animate={hasAnimated ? animate : {}} {...rest}>
      {children}
    </motion.div>
  );
};

AnimatedDivComponent.defaultProps = {
  initial: null,
  animate: null,
};

AnimatedDivComponent.propTypes = {
  children: node.isRequired,
  initial: object.isRequired,
  animate: object.isRequired,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const AnimatedDiv = compose(withViewport)(AnimatedDivComponent);

export default AnimatedDiv;
