import pick from 'lodash/pick';
import { types as sdkTypes, util as sdkUtil } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { integrationAPI } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { queryRecentlyViewedListings } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

const QUERY_SIMILAR_EXPERIENCES_PAGE_SIZE = 12;

const listingsIncludeParams = {
  include: ['author', 'author.profileImage', 'images'],
  'fields.image': [
    // Listing page
    'variants.landscape-crop',
    'variants.landscape-crop2x',
    'variants.landscape-crop4x',
    'variants.landscape-crop6x',
    'variants.portrait-crop',
    'variants.portrait-crop2x',

    // Social media
    'variants.facebook',
    'variants.twitter',

    // Image carousel
    'variants.scaled-small',
    'variants.scaled-medium',
    'variants.scaled-large',
    'variants.scaled-xlarge',

    // Avatars
    'variants.square-small',
    'variants.square-small2x',
  ],
  'imageVariant.hero-scale': 'w:1440;h:400;fit:scale',
  'imageVariant.hero-crop': 'w:1440;h:400;fit:crop',
  'imageVariant.portrait-crop': sdkUtil.objectQueryString({
    w: 280,
    h: 392,
    fit: 'crop',
  }),
  'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
    w: 560,
    h: 784,
    fit: 'crop',
  }),
};

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/HostsPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/HostsPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/HostsPage/SHOW_LISTING_ERROR';

export const FETCH_USERS_REQUEST = 'app/HostsPage/FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'app/HostsPage/FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'app/HostsPage/FETCH_USERS_ERROR';

export const QUERY_SIMILAR_LISTINGS_REQUEST = 'app/HostsPage/QUERY_SIMILAR_LISTINGS_REQUEST';
export const QUERY_SIMILAR_LISTINGS_SUCCESS = 'app/HostsPage/QUERY_SIMILAR_LISTINGS_SUCCESS';
export const QUERY_SIMILAR_LISTINGS_ERROR = 'app/HostsPage/QUERY_SIMILAR_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  // listing(s)
  id: null,
  showListingError: null,
  userIds: [],
  fetchUsersInProgress: false,
  fetchUsersError: null,
  similarListingIds: [],
  querySimilarListingsInProgress: false,
  querySimilarListingsError: null,
};

const resultIds = data => data.data.map(d => d.id);

const hostsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_USERS_REQUEST:
      return {
        ...state,
        userIds: [],
        fetchUsersInProgress: true,
        fetchUsersError: null,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        userIds: resultIds(payload).filter(i => payload.fetchIds.includes(i.uuid)),
        fetchUsersInProgress: false,
        fetchUsersError: null,
      };
    case FETCH_USERS_ERROR:
      return {
        ...state,
        userIds: [],
        fetchUsersInProgress: false,
        fetchUsersError: payload,
      };

    case QUERY_SIMILAR_LISTINGS_REQUEST:
      return {
        ...state,
        querySimilarListingsInProgress: true,
        querySimilarListingsError: null,
      };
    case QUERY_SIMILAR_LISTINGS_SUCCESS:
      return {
        ...state,
        similarListingIds: resultIds(payload.data),
        querySimilarListingsInProgress: false,
        querySimilarListingsError: null,
      };
    case QUERY_SIMILAR_LISTINGS_ERROR:
      return {
        ...state,
        experienceIds: [],
        querySimilarListingsInProgress: false,
        querySimilarListingsError: payload,
      };

    default:
      return state;
  }
};

export default hostsPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});
export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchUsersRequest = () => ({
  type: FETCH_USERS_REQUEST,
});
export const fetchUsersSuccess = (response, fetchIds) => ({
  type: FETCH_USERS_SUCCESS,
  payload: { data: response.data.data, fetchIds },
});
export const fetchUsersError = e => ({
  type: FETCH_USERS_ERROR,
  error: true,
  payload: e,
});

export const querySimilarListingsRequest = () => ({
  type: QUERY_SIMILAR_LISTINGS_REQUEST,
});
export const querySimilarListingsSuccess = response => ({
  type: QUERY_SIMILAR_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const querySimilarListingsError = e => ({
  type: QUERY_SIMILAR_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const fetchListingTeamIds = (listing, dispatch) => {
  const teamFromPublicData = listing.attributes.publicData.team || [];
  const teamIds = teamFromPublicData.filter(i => !i.pending).map(i => i.id);

  return dispatch(fetchUsers(teamIds));
};

export const showListing = (listingId, isOwn = false, useIntegration = false) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(showListingRequest(listingId));

  const params = {
    id: listingId,
    ...listingsIncludeParams,
  };

  const show = isOwn
    ? useIntegration
      ? integrationAPI.listings.show({
          ...params,
          id: listingId.uuid,
        })
      : sdk.ownListings.show(params)
    : sdk.listings.show(params);

  return show
    .then(response => {
      const data = isOwn && useIntegration ? response.data : response;
      const listing = denormalisedResponseEntities(data)?.[0];

      dispatch(addMarketplaceEntities(data));
      fetchListingTeamIds(listing, dispatch);

      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchUsers = fetchIds => async (dispatch, getState, sdk) => {
  dispatch(fetchUsersRequest());

  return integrationAPI.users
    .query({
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response.data));
      dispatch(fetchUsersSuccess(response.data, fetchIds));

      return response.data;
    })
    .catch(e => dispatch(fetchUsersError(storableError(e))));
};

export const querySimilarListings = listingType => (dispatch, getState, sdk) => {
  dispatch(querySimilarListingsRequest());

  return sdk.listings
    .query({
      pub_type: listingType,
      per_page: QUERY_SIMILAR_EXPERIENCES_PAGE_SIZE,
      ...listingsIncludeParams,
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(querySimilarListingsSuccess(response));
      return response;
    })
    .catch(e => dispatch(querySimilarListingsError(storableError(e))));
};

export const loadData = params => async (dispatch, getState, sdk) => {
  const listingId = new UUID(params.id);

  const recentlyViewedListingsFromState = getState().user.recentlyViewedListingIds;
  const callRecentlyViewedListingsPromise = recentlyViewedListingsFromState.length === 0;

  return dispatch(showListing(listingId)).then(response => {
    const listing = response.data.data;
    const { publicData } = listing.attributes;
    const listingType = publicData?.type;

    return Promise.all([
      dispatch(querySimilarListings(listingType)),
      callRecentlyViewedListingsPromise
        ? dispatch(queryRecentlyViewedListings(listingId.uuid))
        : Promise.resolve(),
    ]);
  });
};
