import React from 'react';

import css from './StreamModal.module.css';

export const ExperienceIcon = () => {
  return (
    <svg
      className={css.icon}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="18"
      width="18"
    >
      <g fill="none" stroke="#333333" strokeLinecap="round" strokeLinejoin="round">
        <circle cx={7} cy={7} r={6.5} />
        <circle cx={7} cy={7} r={2.5} />
        <path d="M4.5 7V1M7 4.5h6M9.5 7v6M7 9.5H1" />
      </g>
    </svg>
  );
};

export const ShowIcon = () => {
  return (
    <svg
      className={css.icon}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="18"
      width="18"
    >
      <g fill="none" stroke="#333333" strokeLinecap="round" strokeLinejoin="round">
        <path d="M1.77 8.5A5.91 5.91 0 0 1 .5 4.84V1.58a9.65 9.65 0 0 1 8.87 0" />
        <path d="M7.79 13.44h0a2.27 2.27 0 0 1-2-.51h0a6.66 6.66 0 0 1-2.11-6.67l.73-2.92a9.88 9.88 0 0 1 9.09 2.28l-.73 2.91a6.67 6.67 0 0 1-4.98 4.91Z" />
        <path d="M5.61 6.51a1 1 0 0 1 1-.27 1 1 0 0 1 .73.69M9.57 7.5a1 1 0 0 1 1.7.43" />
      </g>
    </svg>
  );
};

export const AccessIcon = () => {
  return (
    <svg
      className={css.icon}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="18"
      width="18"
    >
      <g fill="none" stroke="#333333" strokeLinecap="round" strokeLinejoin="round">
        <path d="M5.62 7.38 11.5 1.5l2 2M9.25 3.75 11 5.5" />
        <circle cx={3.5} cy={9.5} r={3} />
      </g>
    </svg>
  );
};

export const StreamingLinkIcon = () => {
  return (
    <svg
      className={css.icon}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="18"
      width="18"
    >
      <path
        d="m13 6.81-5.95 6a2.48 2.48 0 0 1-3.54 0L1.73 11a2.53 2.53 0 0 1 0-3.55l6.34-6.36a2 2 0 0 1 2.84 0l.71.71a2 2 0 0 1 0 2.84L6 10.28a1 1 0 0 1-1.42 0l-.35-.36a1 1 0 0 1 0-1.42L8 4.76"
        fill="none"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
