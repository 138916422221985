import React from 'react';
import { string, func, bool } from 'prop-types';
import { InlineTextButton } from '..';
import classNames from 'classnames';

import css from './Slider.module.css';

const PrevArrow = props => {
  const { className, onClick, disabled } = props;
  const classes = classNames(css.sliderArrow, className);
  return (
    <InlineTextButton className={classes} onClick={onClick} disabled={disabled}>
      <svg
        className={css.sliderArrowIcon}
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
      >
        <circle cx="16" cy="16" r="15.5" fill="#fff" stroke="#E7E7E7" />
        <path
          d="m17.5 20.5-4.235-4.235a.375.375 0 0 1 0-.53L17.5 11.5"
          stroke="#0e0f0c"
          strokeWidth="1.5"
        />
      </svg>
    </InlineTextButton>
  );
};

PrevArrow.defaultProps = {
  className: null,
  onClick: null,
};

PrevArrow.propTypes = {
  className: string,
  onClick: func.isRequired,
  disabled: bool.isRequired,
};

const NextArrow = props => {
  const { className, onClick, disabled } = props;
  const classes = classNames(css.sliderArrow, className);
  return (
    <InlineTextButton className={classes} onClick={onClick} disabled={disabled}>
      <svg
        className={css.sliderArrowIcon}
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
      >
        <circle cx="16" cy="16" r="15.5" fill="#fff" stroke="#E7E7E7" />
        <path
          d="m14.5 11.5 4.235 4.235a.375.375 0 0 1 0 .53L14.5 20.5"
          stroke="#0e0f0c"
          strokeWidth="1.5"
        />
      </svg>
    </InlineTextButton>
  );
};

NextArrow.defaultProps = {
  className: null,
  onClick: null,
};

NextArrow.propTypes = {
  className: string,
  onClick: func.isRequired,
  disabled: bool.isRequired,
};

const Arrows = props => {
  const { className, onSlidePrev, onSlideNext, prevDisabled, nextDisabled } = props;
  const classes = classNames(css.sliderArrows, className);
  return (
    <div className={classes}>
      <PrevArrow onClick={onSlidePrev} disabled={prevDisabled} />
      <NextArrow onClick={onSlideNext} disabled={nextDisabled} />
    </div>
  );
};

Arrows.defaultProps = {
  className: null,
  onSlidePrev: null,
  onSlideNext: null,
  prevDisabled: false,
  nextDisabled: false,
};

Arrows.propTypes = {
  className: string,
  onSlidePrev: func.isRequired,
  onSlideNext: func.isRequired,
  prevDisabled: bool,
  nextDisabled: bool,
};

export default Arrows;
