import { util as sdkUtil } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchOrganizationProfiles } from '../../ducks/organizations.duck';
import { organizationSettingsAPI } from '../../util/api';
import { queryRecentlyViewedListings } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SHOW_ORGANIZATION_PROFILE_REQUEST =
  'app/OrganizationSettingsPage/SHOW_ORGANIZATION_PROFILE_REQUEST';
export const SHOW_ORGANIZATION_PROFILE_SUCCESS =
  'app/OrganizationSettingsPage/SHOW_ORGANIZATION_PROFILE_SUCCESS';
export const SHOW_ORGANIZATION_PROFILE_ERROR =
  'app/OrganizationSettingsPage/SHOW_ORGANIZATION_PROFILE_ERROR';

export const QUERY_ORGANIZATION_PROFILES_REQUEST =
  'app/OrganizationSettingsPage/QUERY_ORGANIZATION_PROFILES_REQUEST';
export const QUERY_ORGANIZATION_PROFILES_SUCCESS =
  'app/OrganizationSettingsPage/QUERY_ORGANIZATION_PROFILES_SUCCESS';
export const QUERY_ORGANIZATION_PROFILES_ERROR =
  'app/OrganizationSettingsPage/QUERY_ORGANIZATION_PROFILES_ERROR';

export const FETCH_LISTINGS_REQUEST = 'app/OrganizationProfilePage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/OrganizationProfilePage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/OrganizationProfilePage/FETCH_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  showOrganizationProfileInProgress: false,
  showOrganizationProfileError: null,
  listingIds: [],
  fetchListingsInProgress: false,
  fetchListingsError: null,
  organizationProfiles: [],
  queryOrganizationProfilesInProgress: false,
  queryOrganizationProfilesError: null,
  organizationProfile: null,
};

const resultIds = data => data.data.map(d => d.id);

export default function organizationProfilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_ORGANIZATION_PROFILE_REQUEST:
      return {
        ...state,
        showOrganizationProfileInProgress: true,
        showOrganizationProfileError: null,
        organizationProfile: null,
      };
    case SHOW_ORGANIZATION_PROFILE_SUCCESS:
      return {
        ...state,
        showOrganizationProfileInProgress: false,
        showOrganizationProfileError: null,
        organizationProfile: payload.data,
      };
    case SHOW_ORGANIZATION_PROFILE_ERROR:
      return {
        ...state,
        showOrganizationProfileInProgress: false,
        showOrganizationProfileError: payload,
      };

    case QUERY_ORGANIZATION_PROFILES_REQUEST:
      return {
        ...state,
        queryOrganizationProfilesInProgress: true,
        queryOrganizationProfilesError: null,
      };
    case QUERY_ORGANIZATION_PROFILES_SUCCESS:
      return {
        ...state,
        queryOrganizationProfilesInProgress: false,
        queryOrganizationProfilesError: null,
        organizationProfiles: payload.data,
      };
    case QUERY_ORGANIZATION_PROFILES_ERROR:
      return {
        ...state,
        queryOrganizationProfilesInProgress: false,
        queryOrganizationProfilesError: payload.error,
      };

    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsError: null,
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        fetchListingsInProgress: false,
        fetchListingsError: null,
        listingIds: resultIds(payload.data),
      };
    case FETCH_LISTINGS_ERROR:
      return {
        ...state,
        shfetchListingsProgress: false,
        fetchListingsError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ===== =========== //

export const showOrganizationProfileRequest = () => ({
  type: SHOW_ORGANIZATION_PROFILE_REQUEST,
});
export const showOrganizationProfileSuccess = response => ({
  type: SHOW_ORGANIZATION_PROFILE_SUCCESS,
  payload: { data: response.data.data },
});
export const showOrganizationProfileError = e => ({
  type: SHOW_ORGANIZATION_PROFILE_ERROR,
  error: true,
  payload: e,
});

export const queryOrganizationProfilesRequest = () => ({
  type: QUERY_ORGANIZATION_PROFILES_REQUEST,
});
export const queryOrganizationProfilesSuccess = response => ({
  type: QUERY_ORGANIZATION_PROFILES_SUCCESS,
  payload: { data: response.data.data },
});
export const queryOrganizationProfilesError = e => ({
  type: QUERY_ORGANIZATION_PROFILES_ERROR,
  error: true,
  payload: e,
});

export const fetchListingsRequest = () => ({
  type: FETCH_LISTINGS_REQUEST,
});
export const fetchListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const fetchListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showOrganizationProfile = id => (dispatch, getState, sdk) => {
  dispatch(showOrganizationProfileRequest());

  return organizationSettingsAPI
    .show({ id })
    .then(response => {
      dispatch(showOrganizationProfileSuccess(response));
      return response;
    })
    .catch(e => dispatch(showOrganizationProfileError(storableError(e.response))));
};

export const queryOrganizationProfiles = () => dispatch => {
  dispatch(queryOrganizationProfilesRequest());

  return organizationSettingsAPI
    .query({
      hasProfileImage: true,
    })
    .then(response => {
      dispatch(queryOrganizationProfilesSuccess(response));
      return response.data.data;
    })
    .catch(e => dispatch(queryOrganizationProfilesError(storableError(e))));
};

export const fetchListings = organizationId => (dispatch, getState, sdk) => {
  dispatch(fetchListingsRequest());

  return sdk.listings
    .query({
      pub_organizationId: organizationId,
      include: ['images', 'author'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'metadata'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [
        'variants.square-small',
        'variants.square-small2x',
        'variants.landscape-crop',
        'variants.landscape-crop2x',
      ],

      'limit.images': 1,
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchListingsSuccess(response));
      return response;
    })
    .catch(e => dispatch(fetchListingsError(storableError(e))));
};

export const loadData = params => dispatch => {
  return Promise.all([
    dispatch(showOrganizationProfile(params.id)),
    dispatch(fetchListings(params.id)),
    dispatch(queryOrganizationProfiles()),
    dispatch(queryRecentlyViewedListings()),
    dispatch(fetchOrganizationProfiles()),
  ]);
};
