import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconBin.module.css';

const IconBin = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        style={{
          fill: 'none',
          stroke: '#7c7c7c',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
        d="m11.5 5.5-1 8h-7l-1-8M1 3.5h12M4.46 3.21V1.48a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v2"
        transform="scale(1.71429)"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconBin.defaultProps = {
  className: null,
  rootClassName: null,
};

IconBin.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconBin;
