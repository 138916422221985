import React from 'react';

const CheckmarkIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" fill="none" {...props}>
    <path
      fill="#fff"
      d="M5.257 9.734l-3-3.34a.845.845 0 0 0-.275-.206.77.77 0 0 0-.65 0 .845.845 0 0 0-.275.206.951.951 0 0 0-.185.306 1.032 1.032 0 0 0 0 .724c.037.111.103.21.185.306l3.592 3.998a.794.794 0 0 0 1.208 0l9.086-10.105a.95.95 0 0 0 .186-.306 1.033 1.033 0 0 0 0-.724.95.95 0 0 0-.186-.306.844.844 0 0 0-.275-.207.77.77 0 0 0-.65 0 .845.845 0 0 0-.275.207L5.257 9.734Z"
    />
  </svg>
);

export default CheckmarkIcon;
