import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconShare.module.css';

const IconShare = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
      <g
        clipPath="url(#a)"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="m5.143 8.344-4.127 4.089a1.285 1.285 0 0 0 0 1.825l2.725 2.726a1.287 1.287 0 0 0 1.826 0l4.089-4.127M12.857 9.656l4.089-4.089a1.287 1.287 0 0 0 0-1.826l-2.687-2.725a1.285 1.285 0 0 0-1.826 0L8.344 5.143M11.571 6.429 6.43 11.57" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

IconShare.defaultProps = {
  className: null,
  rootClassName: null,
};

IconShare.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconShare;
