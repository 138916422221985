import React from 'react';
import { arrayOf, string, bool } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import classNames from 'classnames';

import css from './SectionJoin.module.css';

const SectionJoin = props => {
  const { rootClassName, className, currentUser, listings, queryListingsInProgress } = props;
  const classes = classNames(css.root, rootClassName, className);

  const showSection =
    (!queryListingsInProgress && !currentUser) ||
    (!queryListingsInProgress && currentUser && listings.length === 0);

  return showSection ? (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionJoin.title" />
      </h2>
      <div className={css.content}>
        <h3 className={css.heading}>
          <FormattedMessage id="SectionJoin.heading" />
        </h3>
        <p className={css.subHeading}>
          <FormattedMessage id="SectionJoin.subHeading" />
        </p>
        {currentUser ? null : (
          <NamedLink name="RoleSelectionPage" className={css.button}>
            <FormattedMessage id="SectionJoin.buttonText" />
          </NamedLink>
        )}
      </div>
    </div>
  ) : null;
};

SectionJoin.defaultProps = {
  rootClassName: null,
  className: null,

  queryListingsInProgress: false,
};

SectionJoin.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  listings: arrayOf(propTypes.listing),
  queryListingsInProgress: bool.isRequired,
};

export default SectionJoin;
