import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../components';
import css from './BookingPanel.module.css';

const PanelButtons = props => {
  const {
    isOwnListing,
    hasMembership,
    hasFreeMemberships,
    createFreeSubscriptionInProgress,
    onOpenMembershipModal,
    onOpenUpgradeMembershipModal,
    onOpenDonateModal,
  } = props;
  const donateButtonClasses = classNames(css.panelButton, css.donateButton);

  // Get the correct button props based on membership status
  const membershipButtonProps = hasMembership
    ? hasFreeMemberships
      ? { messageId: 'BookingPanel.upgrade', actionFn: onOpenMembershipModal }
      : { messageId: 'BookingPanel.manage', actionFn: onOpenMembershipModal }
    : {
        messageId: 'BookingPanel.subscribe',
        actionFn: onOpenUpgradeMembershipModal,
        disabled: isOwnListing,
      };

  return (
    <div className={css.panelButtons}>
      <SecondaryButton className={donateButtonClasses} onClick={onOpenDonateModal}>
        <FormattedMessage id="BookingPanel.donate" />
      </SecondaryButton>
      <PrimaryButton
        className={css.panelButton}
        onClick={membershipButtonProps.actionFn}
        inProgress={createFreeSubscriptionInProgress}
        disabled={membershipButtonProps.disabled}
      >
        <FormattedMessage id={membershipButtonProps.messageId} />
      </PrimaryButton>
    </div>
  );
};

export default PanelButtons;
