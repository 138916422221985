import React from 'react';
import { string, node } from 'prop-types';
import classNames from 'classnames';

import css from './MobileSlider.module.css';

const MobileSlider = props => {
  const { rootClassName, className, children } = props;
  const classes = classNames(rootClassName || css.root, className);
  return <div className={classes}>{children}</div>;
};

MobileSlider.defaultProps = {
  className: null,
  rootClassName: null,
};

MobileSlider.propTypes = {
  className: string,
  rootClassName: string,
  children: node.isRequired,
};

export default MobileSlider;
