import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconNotification.module.css';

const IconNotification = props => {
  const { className, rootClassName, isFilled } = props;
  const classes = classNames(rootClassName || css.root, className, {
    [css.rootFilled]: isFilled,
  });
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="-0.5 -0.5 24 24"
    >
      <path d="M11.499.23c-.78 0-1.409.63-1.409 1.409v.845a7.047 7.047 0 00-5.635 6.903v.828c0 2.069-.761 4.067-2.135 5.617l-.326.365c-.37.414-.457 1.008-.233 1.515s.73.832 1.286.832h16.905a1.411 1.411 0 001.052-2.347l-.326-.365a8.464 8.464 0 01-2.135-5.617v-.828a7.047 7.047 0 00-5.635-6.903v-.845c0-.78-.63-1.409-1.409-1.409zm1.994 21.717a2.818 2.818 0 00.824-1.995H8.682c0 .749.294 1.466.823 1.995s1.246.823 1.994.823 1.466-.295 1.994-.823z"></path>
    </svg>
  );
};

const { string, bool } = PropTypes;

IconNotification.defaultProps = {
  className: null,
  rootClassName: null,
  isFilled: false,
};

IconNotification.propTypes = {
  className: string,
  rootClassName: string,
  isFilled: bool.isRequired,
};

export default IconNotification;
