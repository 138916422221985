import React, { Component } from 'react';
import { array, string, bool } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  IconReviewStar,
  NamedLink,
  ResponsiveImage,
  ButtonWatchlist,
  AspectRatioWrapper,
} from '../../components';
import { getAverageReview } from '../../containers/ReviewsPage/ReviewsPage.duck';
import config from '../../config';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const { className, rootClassName, listing, renderSizes, filterConfig, hideRating } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', publicData, metadata } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const posterImage =
    listing?.images?.find(i => i.id.uuid === publicData?.posterImageId) ?? firstImage;

  const typeOptions = findOptionsForSelectFilter('type', filterConfig);
  const type = typeOptions.find(t => t.key === publicData?.type);

  const categoryOptions = findOptionsForSelectFilter('category', filterConfig);
  const category = categoryOptions.find(c => c.key === publicData?.category);

  const statusOptions = findOptionsForSelectFilter('status', filterConfig);
  const status = statusOptions.find(s => s.key === publicData?.status);

  const isExperienceListing = type.key === config.listingTypes['experience'];
  const linkPageName = isExperienceListing ? 'ExperiencePage' : 'ListingPage';
  const organizationProfile = publicData?.organizationProfile;

  const reviews = metadata?.reviews ? metadata.reviews : [];
  const ratings = metadata?.showRatings ? metadata.ratings : [];
  const reviewsAndRatings = reviews.concat(ratings);
  const hasReviewsOrRatings = reviewsAndRatings.length > 0;
  const averageReview = hasReviewsOrRatings ? getAverageReview(currentListing, reviews) : 0;

  return (
    <div className={classes}>
      <ButtonWatchlist listing={currentListing} />
      <NamedLink className={css.listingLink} name={linkPageName} params={{ id, slug }}>
        <AspectRatioWrapper width={5} height={7}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={posterImage}
            variants={['portrait-crop2x', 'portrait-crop4x']}
            sizes={renderSizes}
          />
        </AspectRatioWrapper>
        <div className={css.info}>
          <div className={css.mainInfo}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
            {organizationProfile ? (
              <div className={css.listingOrganization}>{organizationProfile.name}</div>
            ) : null}
            <div className={css.details}>
              {category ? <span className={css.detailsItem}>{category.label}</span> : null}
              {status ? <span className={css.detailsItem}>{status.label}</span> : null}
            </div>
            {hideRating ? null : (
              <div className={css.ratingDetails}>
                <div className={css.ratingDetailsItem}>
                  <IconReviewStar className={css.ratingDetailsItemIcon} />
                  <p className={css.ratingDetailsItemText}>
                    <FormattedMessage
                      id="ListingCard.review"
                      values={{
                        reviewLength: reviewsAndRatings.length,
                        averageReview: averageReview,
                      }}
                    />
                  </p>
                </div>
                <div className={css.ratingDetailsItem}>
                  <IconReviewStar
                    className={classNames(css.ratingDetailsItemIcon, css.ratingDetailsItemIconRed)}
                  />
                  <p className={css.ratingDetailsItemText}>
                    <FormattedMessage id="ListingCard.rating" />
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </NamedLink>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filterConfig: config.custom.filters,
  hideRating: false,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filterConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  hideRating: bool,

  // Responsive image sizes hint
  renderSizes: string,
};

const ListingCard = compose(
  withRouter,

  injectIntl
)(ListingCardComponent);

export default ListingCard;
