import React from 'react';
import { string, node } from 'prop-types';
import classNames from 'classnames';

import css from './Table.module.css';

const TableHeadings = props => {
  const { rootClassName, className, children } = props;
  const classes = classNames(rootClassName || css.headings, className);

  return <tr className={classes}>{children}</tr>;
};

TableHeadings.defaultProps = {
  rootClassName: null,
  className: null,
};

TableHeadings.propTypes = {
  rootClassName: string,
  className: string,
  children: node.isRequired,
};

export default TableHeadings;
