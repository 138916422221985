import React from 'react';

const ArrowIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="16"
      viewBox="0 0 40 16"
      fill="none"
      {...props}
    >
      <path
        d="M39.6362 8.54988C40.0268 8.15936 40.0268 7.52619 39.6362 7.13567L33.2723 0.771706C32.8818 0.381181 32.2486 0.381181 31.8581 0.771706C31.4675 1.16223 31.4675 1.79539 31.8581 2.18592L37.5149 7.84277L31.8581 13.4996C31.4675 13.8902 31.4675 14.5233 31.8581 14.9138C32.2486 15.3044 32.8818 15.3044 33.2723 14.9138L39.6362 8.54988ZM0.0629883 8.84277L38.9291 8.84277V6.84277L0.0629883 6.84277L0.0629883 8.84277Z"
        fill="black"
      />
    </svg>
  );
};

export default ArrowIcon;
