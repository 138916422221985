import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconThumb.module.css';

const DIRECTION_DOWN = 'down';
const DIRECTION_UP = 'up';

const IconThumb = props => {
  const { className, rootClassName, direction, isFilled } = props;

  const filledOrDefault = isFilled ? css.filled : css.root;
  const classes = classNames(rootClassName || filledOrDefault, className);

  const isDown = direction === DIRECTION_DOWN;
  const isUp = direction === DIRECTION_UP;

  if (isDown) {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="-0.21 -0.21 20 20"
      >
        <g stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
          <path d="M1.463 2.566h3.288v8.756H1.463a.694.694 0 0 1-.694-.694V3.26a.694.694 0 0 1 .694-.694v0ZM4.751 11.322l3.525 5.633a1.512 1.512 0 0 0 1.304.722 1.539 1.539 0 0 0 1.596-1.527v-4.037h6.09a1.596 1.596 0 0 0 1.527-1.832l-1.11-7.16a1.582 1.582 0 0 0-1.568-1.387H7.013a2.775 2.775 0 0 0-1.249.291l-.999.5M4.751 11.322V2.567" />
        </g>
      </svg>
    );
  } else if (isUp) {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="-0.21 -0.21 20 20"
      >
        <g stroke="#333333" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
          <path d="M1.463 8.131h3.288v8.755H1.463a.694.694 0 0 1-.694-.693V8.825a.694.694 0 0 1 .694-.694v0ZM4.751 8.13l3.525-5.633a1.512 1.512 0 0 1 1.304-.721 1.54 1.54 0 0 1 1.596 1.526V7.34h6.09a1.596 1.596 0 0 1 1.527 1.831l-1.11 7.16a1.582 1.582 0 0 1-1.568 1.388H7.013a2.774 2.774 0 0 1-1.249-.292l-.999-.5M4.751 8.131v8.755" />
        </g>
      </svg>
    );
  }
};

const { oneOf, string, bool } = PropTypes;

IconThumb.defaultProps = {
  className: null,
  rootClassName: null,
  isFilled: false,
};

IconThumb.propTypes = {
  className: string,
  rootClassName: string,
  direction: oneOf([DIRECTION_DOWN, DIRECTION_UP]),
  isFilled: bool.isRequired,
};

export default IconThumb;
