import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { parse } from '../../../util/urlHelpers';
import { createResourceLocatorString } from '../../../util/routes';
import { propTypes } from '../../../util/types';
import routeConfiguration from '../../../routeConfiguration';
import {
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  IconArrowHead,
  InlineTextButton,
  IconSpinner,
} from '../../../components';
import classNames from 'classnames';

import css from './OrganizationMenu.module.css';

const CONTENT_POSITION = 'right';

const OrganizationProfileImage = props => {
  const { name, profileImage } = props;
  return profileImage ? (
    <img className={css.organizationProfileImage} src={profileImage.link} alt={profileImage.name} />
  ) : (
    <div className={css.organizationProfileNoImage}>
      <span className={css.organizationProfileNoImageInitials}>{name?.[0] || ''}</span>
    </div>
  );
};

const OrganizationMenu = props => {
  const [isMenuOpen, onToggleMenu] = useState(false);
  const [isOrganizationButtonDisabled, setOrganizationButtonDisabled] = useState(false);

  const {
    className,
    rootClassName,
    currentPage,
    organizationProfiles,
    selectedOrganization,
    onSelectOrganizationProfile,
    selectOrganizationProfileInProgress,
    selectOrganizationProfileError,
    history,
    location,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const handleSelectOrganizationProfile = id => {
    if (selectedOrganization.id === id) {
      onToggleMenu(false);
      return;
    }

    setOrganizationButtonDisabled(true);
    onToggleMenu(false);
    onSelectOrganizationProfile(id).then(() => {
      setOrganizationButtonDisabled(false);

      const searchParams = parse(location.search);
      history.push(
        createResourceLocatorString(currentPage, routeConfiguration(), {}, searchParams)
      );
    });
  };

  const organizationButtonDisabled =
    isOrganizationButtonDisabled ||
    selectOrganizationProfileInProgress ||
    selectOrganizationProfileError;

  return (
    <Menu
      className={classes}
      contentPosition={CONTENT_POSITION}
      isOpen={isMenuOpen}
      useArrow={false}
      onToggleActive={onToggleMenu}
    >
      <MenuLabel className={css.menuLabel}>
        {selectOrganizationProfileInProgress ? (
          <IconSpinner className={css.spinnerIcon} />
        ) : (
          <OrganizationProfileImage
            name={selectedOrganization?.name}
            profileImage={selectedOrganization?.profileImage}
          />
        )}
        <span className={css.organizationProfileName}>{selectedOrganization?.name}</span>
        <IconArrowHead className={css.menuLabelChevron} direction="down" />
      </MenuLabel>
      <MenuContent className={css.menuContent}>
        {organizationProfiles?.map(o => {
          const isSelected = o.id === selectedOrganization.id;
          const menuLinkClasses = classNames(css.menuLink, {
            [css.menuLinkSelected]: isSelected,
          });
          return (
            <MenuItem key={o.id} className={css.menuItem}>
              <InlineTextButton
                type="button"
                className={menuLinkClasses}
                onClick={() => handleSelectOrganizationProfile(o.id)}
                disabled={organizationButtonDisabled}
              >
                <OrganizationProfileImage name={o.name} profileImage={o.profileImage} />
                {o.name}
                <span className={css.menuItemBorder} />
              </InlineTextButton>
            </MenuItem>
          );
        })}
      </MenuContent>
    </Menu>
  );
};

const { oneOfType, arrayOf, object, string } = PropTypes;

OrganizationMenu.defaultProps = {
  className: null,
  rootClassName: null,
  currentPage: null,
  organizationProfiles: [],
  selectedOrganization: null,
};

OrganizationMenu.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string,
  organizationProfiles: arrayOf(propTypes.organization),
  selectedOrganization: oneOfType([propTypes.organization, propTypes.ownOrganization]),

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
};

export default withRouter(OrganizationMenu);
