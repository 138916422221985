import React from 'react';
import PropTypes from 'prop-types';

// Scroll link that goes to the provided hash.
const ScrollLink = props => {
  const { children, ...rest } = props;
  return <a {...rest}>{children}</a>;
};

ScrollLink.defaultProps = { children: null };

const { node } = PropTypes;

ScrollLink.propTypes = { children: node };

export default ScrollLink;
