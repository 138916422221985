import React, { useState } from 'react';
import { bool, func, object, string, array } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { EditExperienceDetailsForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditExperienceDetailsPanel.module.css';

const { UUID } = sdkTypes;

const DEFAULT_AVAILABILITY_SEATS = 100;

const defaultAvailabilityPlanEntry = {
  seats: DEFAULT_AVAILABILITY_SEATS,
  startTime: '00:00',
  endTime: '00:00',
};

const defaultAvailabilityPlan = {
  type: 'availability-plan/time',
  timezone: 'Europe/Belgrade',
  entries: [
    {
      dayOfWeek: 'mon',
      ...defaultAvailabilityPlanEntry,
    },
    {
      dayOfWeek: 'tue',
      ...defaultAvailabilityPlanEntry,
    },
    {
      dayOfWeek: 'wed',
      ...defaultAvailabilityPlanEntry,
    },
    {
      dayOfWeek: 'thu',
      ...defaultAvailabilityPlanEntry,
    },
    {
      dayOfWeek: 'fri',
      ...defaultAvailabilityPlanEntry,
    },
    {
      dayOfWeek: 'sat',
      ...defaultAvailabilityPlanEntry,
    },
    {
      dayOfWeek: 'sun',
      ...defaultAvailabilityPlanEntry,
    },
  ],
};

const EditExperienceDetailsPanel = props => {
  const [updateTabInProgress, setUpdateTabInProgress] = useState(false);
  const {
    className,
    rootClassName,
    history,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    listingShows,
    queryShowsInProgress,
    onShowListing,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { title, description, availabilityPlan, publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditExperienceDetailsPanel.title"
      values={{
        listingTitle: (
          <ListingLink className={css.listingLink} listing={listing} isExperience>
            {title}
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditExperienceDetailsPanel.createListingTitle" />
  );

  const type = publicData?.type || config.listingTypes['experience'];

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const themeOptions = findOptionsForSelectFilter('theme', config.custom.filters);
  const ageOptions = findOptionsForSelectFilter('age', config.custom.filters);
  const languageOptions = findOptionsForSelectFilter('language', config.custom.filters);

  return (
    <div className={classes}>
      <EditExperienceDetailsForm
        className={css.form}
        initialValues={{
          type,
          title,
          description,
          category: publicData?.category,
          theme: publicData?.theme,
          age: publicData?.age,
          language: publicData?.language,
          show: publicData?.show?.id,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={async values => {
          const { type, title, description, category, theme, language, age, show: showId } = values;

          // Tab is being updated
          setUpdateTabInProgress(true);

          const show = showId
            ? {
                id: showId,
                name: listingShows?.find(l => l.id.uuid === showId)?.title,
              }
            : null;

          const showListing = showId ? await onShowListing(new UUID(showId), true, true) : null;
          const organizationId = showListing
            ? showListing?.attributes?.publicData?.organizationId
            : null;

          const updateValues = {
            title: title.trim(),
            description,
            availabilityPlan: availabilityPlan
              ? {
                  ...availabilityPlan,
                  entries: availabilityPlan.entries
                    ? availabilityPlan.entries
                    : defaultAvailabilityPlan.entries,
                }
              : defaultAvailabilityPlan,
            publicData: {
              type,
              category,
              theme,
              language,
              age,
              show,
              showId,
              organizationId,
            },
          };

          onSubmit(updateValues);
          setUpdateTabInProgress(false);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress || updateTabInProgress}
        fetchErrors={errors}
        history={history}
        panelTitle={panelTitle}
        listing={currentListing}
        categoryOptions={categoryOptions}
        themeOptions={themeOptions}
        ageOptions={ageOptions}
        languageOptions={languageOptions}
        listingShows={listingShows}
        queryShowsInProgress={queryShowsInProgress}
        showIdFromPublicData={publicData?.showId}
      />
    </div>
  );
};

EditExperienceDetailsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
  listingShows: [],
};

EditExperienceDetailsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  listingShows: array,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditExperienceDetailsPanel;
