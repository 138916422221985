import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { arrayOf, string, bool } from 'prop-types';
import { propTypes } from '../../util/types';
import { Slider, RecentlyViewedListingCard } from '../../components';
import classNames from 'classnames';

import css from './SectionRecentlyViewedListings.module.css';

// slidesToShow
const SLIDES_TO_SHOW_TABLET = 3;
const SLIDES_TO_SHOW_DESKTOP = 5;
// slidesToScroll
const SLIDES_TO_SCROLL_TABLET = 3;
const SLIDES_TO_SCROLL_DESKTOP = 3;

const SectionRecentlyViewedListingsComponent = props => {
  const {
    rootClassName,
    className,
    intl,
    recentlyViewedListings,
    queryRecentlyViewedListingsInProgress,
    queryRecentlyViewedListingsError,
  } = props;

  if (
    queryRecentlyViewedListingsInProgress ||
    queryRecentlyViewedListingsError ||
    recentlyViewedListings.length === 0
  ) {
    return null;
  }

  const classes = classNames(rootClassName || css.root, className);
  const sliderTitle = intl.formatMessage({ id: 'ListingPage.recentlyViewedListingsTitle' });

  return (
    <div className={classes}>
      <div className={css.listings}>
        <Slider
          id="ExperiencesSlider"
          headerClassName={css.sliderHeader}
          titleClassName={css.sliderTitle}
          title={sliderTitle}
          slidesToShow={{
            tablet: SLIDES_TO_SHOW_TABLET,
            desktop: SLIDES_TO_SHOW_DESKTOP,
          }}
          slidesToScroll={{
            tablet: SLIDES_TO_SCROLL_TABLET,
            desktop: SLIDES_TO_SCROLL_DESKTOP,
          }}
        >
          {recentlyViewedListings.map(l => {
            return (
              <RecentlyViewedListingCard
                key={l.id.uuid}
                className={css.listingCard}
                listing={l}
                hideExtraInfo
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

SectionRecentlyViewedListingsComponent.defaultProps = {
  rootClassName: null,
  classNames: null,

  recentlyViewedListings: [],
  queryRecentlyViewedListingsInProgress: false,
  queryRecentlyViewedListingsError: null,
};

SectionRecentlyViewedListingsComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,

  recentlyViewedListings: arrayOf(propTypes.listing),
  queryRecentlyViewedListingsInProgress: bool.isRequired,
  queryRecentlyViewedListingsError: propTypes.error,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

const SectionRecentlyViewedListings = compose(connect(mapStateToProps))(
  SectionRecentlyViewedListingsComponent
);

export default SectionRecentlyViewedListings;
