import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './QuantityFilter.module.css';

export const DecreaseIcon = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.quantityIcon, className);
  return (
    <svg
      className={classes}
      viewBox="0 0 32 32"
      height="12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path d="M2 16h28" />
    </svg>
  );
};

DecreaseIcon.defaultProps = {
  rootClassName: null,
  className: null,
};

DecreaseIcon.propTypes = {
  rootClassName: string,
  className: string,
};

export const IncreaseIcon = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.quantityIcon, className);
  return (
    <svg
      className={classes}
      viewBox="0 0 32 32"
      height="12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path d="M2 16h28M16 2v28" />
    </svg>
  );
};

IncreaseIcon.defaultProps = {
  rootClassName: null,
  className: null,
};

IncreaseIcon.propTypes = {
  rootClassName: string,
  className: string,
};

export default IncreaseIcon;
