import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconMoney.module.css';

const IconMoney = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M29.7143 15.8629C29.6928 18.2426 29.0131 20.5699 27.7504 22.5871C26.4876 24.6042 24.6912 26.2325 22.56 27.2915L16 30.8572L9.43999 27.4286C7.28804 26.3592 5.47783 24.7095 4.21368 22.6659C2.94952 20.6222 2.28174 18.2659 2.28571 15.8629V3.4286C2.28571 2.82239 2.52652 2.24101 2.95518 1.81235C3.38383 1.3837 3.96521 1.14288 4.57142 1.14288H27.4286C28.0348 1.14288 28.6162 1.3837 29.0448 1.81235C29.4735 2.24101 29.7143 2.82239 29.7143 3.4286V15.8629Z"
        stroke="#363636"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5714 18.2857C12.5714 19.1951 12.9326 20.0671 13.5756 20.7101C14.2186 21.3531 15.0907 21.7143 16 21.7143C16.459 21.7485 16.9199 21.6832 17.3514 21.523C17.7829 21.3627 18.1747 21.1113 18.5001 20.7859C18.8256 20.4604 19.077 20.0686 19.2372 19.6371C19.3974 19.2057 19.4627 18.7447 19.4286 18.2857C19.4286 14.8572 12.5714 14.8572 12.5714 11.4286C12.5373 10.9696 12.6025 10.5087 12.7628 10.0772C12.923 9.64574 13.1744 9.25392 13.4999 8.92847C13.8253 8.60302 14.2171 8.35161 14.6486 8.19138C15.0801 8.03115 15.541 7.96589 16 8.00003C16.9093 8.00003 17.7814 8.36125 18.4244 9.00423C19.0673 9.64722 19.4286 10.5193 19.4286 11.4286"
        stroke="#363636"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 8.00001V5.71429"
        stroke="#363636"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 24V21.7143"
        stroke="#363636"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconMoney.defaultProps = {
  className: null,
  rootClassName: null,
};

IconMoney.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconMoney;
