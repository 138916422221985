import React, { Component } from 'react';
import { arrayOf, bool, func, node, number, object, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';

import { FilterPopup, FilterPlain, Button, FieldTextInput } from '..';
import { IncreaseIcon, DecreaseIcon } from './QuantityIcon';
import css from './QuantityFilter.module.css';

const DEFAULT_QUANTITY = 1;
const MAXIMUM_QUANTITY = 10;

/**
 * QuantityForm is used in QuantityFilter as component
 * that keeps track of current quantity.
 */
const QuantityForm = props => {
  const { fieldId, label, quantity, onManageQuantity } = props;

  const inputProps = {
    id: fieldId,
    name: fieldId,
  };

  const increaseButtonDisabled = quantity >= MAXIMUM_QUANTITY;
  const decreaseButtonDisabled = quantity <= DEFAULT_QUANTITY;
  return (
    <div className={css.quantity}>
      <p className={css.quantityName}>{label}</p>
      <div className={css.quantityButtons}>
        <Button
          className={css.quantityButton}
          type="button"
          onClick={() => onManageQuantity.decrease()}
          disabled={decreaseButtonDisabled}
        >
          <DecreaseIcon />
        </Button>
        <div>
          <FieldTextInput className={css.quantityInput} type="number" {...inputProps} />
          <span className={css.quantityValue}>{quantity}</span>
        </div>
        <Button
          className={css.quantityButton}
          type="button"
          onClick={() => onManageQuantity.increase()}
          disabled={increaseButtonDisabled}
        >
          <IncreaseIcon />
        </Button>
      </div>
    </div>
  );
};

export class QuantityFilterComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: DEFAULT_QUANTITY,
    };

    this.onIncreaseQuantity = this.onIncreaseQuantity.bind(this);
    this.onDecreaseQuantity = this.onDecreaseQuantity.bind(this);
    this.onClearQuantity = this.onClearQuantity.bind(this);
    this.onCancelQuantity = this.onCancelQuantity.bind(this);
  }

  onIncreaseQuantity() {
    this.setState(
      prevState => ({
        quantity: prevState.quantity + DEFAULT_QUANTITY,
      }),
      () => {
        if (this.props.onQuantityChange) {
          this.props.onQuantityChange(this.state.quantity);
        }
      }
    );
  }

  onDecreaseQuantity() {
    this.setState(
      prevState => ({
        quantity: prevState.quantity - DEFAULT_QUANTITY,
      }),
      () => {
        if (this.props.onQuantityChange) {
          this.props.onQuantityChange(this.state.quantity);
        }
      }
    );
  }

  onClearQuantity() {
    this.setState({ quantity: DEFAULT_QUANTITY });
  }

  onCancelQuantity() {
    const { formValues } = this.props;
    this.setState({ quantity: formValues.quantity });
  }

  render() {
    const {
      className,
      rootClassName,
      showAsPopup,
      initialValues,
      id,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      label,
      labelText,
      intl,
      ...rest
    } = this.props;

    const quantityQueryParamName = 'quantity';
    const initialQuantity =
      initialValues && initialValues[quantityQueryParamName]
        ? { quantity: initialValues[quantityQueryParamName] }
        : { quantity: this.state.quantity };
    const isSelected = !!initialQuantity.quantity;

    const onManageQuantity = {
      increase: this.onIncreaseQuantity,
      decrease: this.onDecreaseQuantity,
    };

    return showAsPopup ? (
      <FilterPopup
        className={className}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        label={label}
        labelText={labelText}
        isSelected={isSelected}
        id={`${id}.popup`}
        showAsPopup
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={onSubmit}
        onClear={this.onClearQuantity}
        onCancel={this.onCancelQuantity}
        initialValues={initialQuantity}
        {...rest}
      >
        <QuantityForm
          fieldId="quantity"
          label={labelText || label}
          quantity={this.state.quantity}
          onManageQuantity={onManageQuantity}
          {...rest}
        />
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={isSelected}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={onSubmit}
        onClear={this.onClearQuantity}
        onCancel={this.onCancelQuantity}
        initialValues={initialQuantity}
        {...rest}
      >
        <QuantityForm
          fieldId="quantity"
          label={labelText || label}
          quantity={this.state.quantity}
          onManageQuantity={onManageQuantity}
          {...rest}
        />
      </FilterPlain>
    );
  }
}

QuantityFilterComponent.defaultProps = {
  rootClassName: null,
  className: null,
  showAsPopup: true,
  liveEdit: false,
  initialValues: null,
  contentPlacementOffset: 0,
};

QuantityFilterComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  showAsPopup: bool,
  liveEdit: bool,
  queryParamNames: arrayOf(string).isRequired,
  onSubmit: func.isRequired,
  initialValues: object,
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

const QuantityFilter = injectIntl(QuantityFilterComponent);

export default QuantityFilter;
