import { useState, useEffect } from 'react';
import { isWindowDefined } from '../../util/data';

const FULLSCREEN_EVENT_LISTENERS = [
  'fullscreenchange',
  'mozfullscreenchange',
  'webkitfullscreenchange',
  'msfullscreenchange',
];

/**
 * Helper function to check if window is defined
 * to avoid SSR (Server side rendering) errors.
 */
export const windowDefined = isWindowDefined();

/**
 * Helper function to allow showing the video
 * in fullscreen mode.
 *
 * @param {bool} isFullscreen
 * @param {node} videoElem
 */
export const requestFullscreen = (isFullscreen, videoElem) => {
  if (!isFullscreen) {
    if (videoElem.requestFullscreen) {
      videoElem.requestFullscreen();
    } else if (videoElem.webkitRequestFullscreen) {
      videoElem.webkitRequestFullscreen();
    } else if (videoElem.mozRequestFullScreen) {
      videoElem.mozRequestFullScreen();
    } else if (videoElem.msRequestFullscreen) {
      videoElem.msRequestFullscreen();
    }
  } else {
    cancelFullscreen();
  }
};

/**
 * Helper function to cancel the currently
 * opened fullscreen.
 */
export const cancelFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
};

/**
 * Format seconds as a time string, H:MM:SS or M:SS
 * Supplying a guide (in seconds) will force a number of leading zeros
 * to cover the length of the guide
 *
 * @param  {Number} seconds Number of seconds to be turned into a string
 * @param  {Number} guide   Number (in seconds) to model the string after
 * @return {String} Time formatted as H:MM:SS or M:SS
 * @private
 * @function formatTime
 */
export function formatPlayerTime(seconds = 0, guide = seconds) {
  let s = Math.floor(seconds % 60);
  let m = Math.floor((seconds / 60) % 60);
  let h = Math.floor(seconds / 3600);
  const gm = Math.floor((guide / 60) % 60);
  const gh = Math.floor(guide / 3600);

  // handle invalid times
  if (isNaN(seconds) || seconds === Infinity) {
    // '-' is false for all relational operators (e.g. <, >=) so this setting
    // will add the minimum number of fields specified by the guide
    h = '-';
    m = '-';
    s = '-';
  }

  // Check if we need to show hours
  h = h > 0 || gh > 0 ? `${h}:` : '';

  // If hours are showing, we may need to add a leading zero.
  // Always show at least one digit of minutes.
  m = `${(h || gm >= 10) && m < 10 ? `0${m}` : m}:`;

  // Check if leading zero is need for seconds
  s = s < 10 ? `0${s}` : s;

  return h + m + s;
}

/**
 * Mutex only allows playing one video at the time
 *
 * @param {object} e js event object
 * @param {object} player player object
 */
export const handlePlayerMutex = (e, player) => {
  // Get all video elements on the page
  const videos = [...document.getElementsByTagName('video')];

  // Loop through videos and pause all videos
  // that are not the current one
  videos?.forEach(video => {
    const shouldPause = video !== e.target && !video.paused;
    if (shouldPause) {
      video.pause();
    } else {
      player.onPause(false);
      player.onPlay(true);
    }
  });
};

/**
 * Helper function to handle calculating the video time
 * based on the current time and total video duration.
 *
 * @param {number} time current video time
 * @param {number} duration total video duration
 */
export const getSeekVideoTime = (time, duration) => Math.round((time / duration) * 100);

/**
 * Helper function that gets the element height.
 *
 * @param {node} el
 */
export const getElementHeight = el => {
  if (!el) {
    return null;
  }

  return el.offsetHeight || el.clientHeight;
};

/**
 * Handles the video player state for easier
 * manipulation.
 */
export const useVideoPlayer = () => {
  const [playerCanPlay, setPlayerCanPlay] = useState(false);
  const [playerPlaying, setPlayerPlaying] = useState(false);
  const [playerPaused, setPlayerPaused] = useState(false);
  const [playerMuted, setPlayerMuted] = useState(false);
  const [playerFullscreen, setPlayerFullscreen] = useState(false);
  const [playerSuspend, setPlayerSuspend] = useState(false);
  const [playerError, setPlayerError] = useState(false);
  const [playerTime, setPlayerTime] = useState(0);
  const [playerDuration, setPlayerDuration] = useState(0);
  const [playerSeek, setPlayerSeek] = useState(0);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setPlayerFullscreen(!!document.fullscreenElement);
    };

    FULLSCREEN_EVENT_LISTENERS.forEach(listener => {
      document.addEventListener(listener, handleFullscreenChange);
    });

    return () => {
      FULLSCREEN_EVENT_LISTENERS.forEach(listener => {
        document.removeEventListener(listener, handleFullscreenChange);
      });
    };
  }, []);

  return {
    // Video
    canPlay: playerCanPlay,
    playing: playerPlaying,
    paused: playerPaused,
    muted: playerMuted,
    time: playerTime,
    duration: playerDuration,
    fullscreen: playerFullscreen,
    seek: playerSeek,
    suspend: playerSuspend,
    error: playerError,
    // Video handler functions
    onCanPlay: setPlayerCanPlay,
    onPlay: setPlayerPlaying,
    onPause: setPlayerPaused,
    onMute: setPlayerMuted,
    onTime: setPlayerTime,
    onDuration: setPlayerDuration,
    onFullscreen: setPlayerFullscreen,
    onSeek: setPlayerSeek,
    onSuspend: setPlayerSuspend,
    onError: setPlayerError,
  };
};

/**
 * Handles the video player events for easier
 * video UI manipulation.
 *
 * @param {object} player
 *
 * @return {array} array of event functions
 */
export const useVideoPlayerEvents = player => {
  // onLoadedMetadata will preload the video, which will
  // let us access metadata such as video duration before
  // the video is even played.
  //
  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/loadedmetadata_event
  const onLoadedMetadata = e => {
    try {
      if (!player.canPlay) {
        player.onCanPlay(true);
      }

      player.onDuration(e.currentTarget.duration);
    } catch (error) {
      console.log('Error while loading metadata:', error);
      player.onError(true);
    }
  };

  const onCanPlay = () => {
    player.onCanPlay(true);
  };

  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/playing_event
  const onPlaying = e => {
    handlePlayerMutex(e, player);
  };

  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/pause_event
  const onPause = () => {
    player.onPlay(false);
    player.onPause(true);
  };

  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/timeupdate_event
  const onTimeUpdate = e => {
    const currentTime = e.currentTarget.currentTime;
    const duration = e.currentTarget.duration;

    player.onTime(currentTime);
    player.onSeek(getSeekVideoTime(currentTime, duration));
  };

  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/ended
  const onEnded = () => {
    player.onPlay(false);
    player.onPause(true);
  };

  const onSuspend = () => {
    player.onSuspend(true);
  };

  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/error
  const onError = () => {
    player.onError(true);
  };

  return {
    onLoadedMetadata,
    onCanPlay,
    onPlaying,
    onPause,
    onTimeUpdate,
    onEnded,
    onSuspend,
    onError,
  };
};
