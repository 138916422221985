import pick from 'lodash/pick';
import { types as sdkTypes, util as sdkUtil } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { integrationAPI } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { fetchCurrentUserReactionsSuccess } from '../../ducks/user.duck';
import { getListing } from '../ListingPage/ListingPage.duck';
import { queryExperiences as querySimilarExperiences } from '../ExperiencePage/ExperiencePage.duck';
import { queryRecentlyViewedListings } from '../../ducks/user.duck';
import { v4 as uuidv4 } from 'uuid';

const { UUID } = sdkTypes;

const listingsIncludeParams = {
  include: ['author', 'author.profileImage', 'images'],
  'fields.image': [
    // Listing page
    'variants.landscape-crop',
    'variants.landscape-crop2x',
    'variants.landscape-crop4x',
    'variants.landscape-crop6x',
    'variants.portrait-crop',
    'variants.portrait-crop2x',

    // Social media
    'variants.facebook',
    'variants.twitter',

    // Image carousel
    'variants.scaled-small',
    'variants.scaled-medium',
    'variants.scaled-large',
    'variants.scaled-xlarge',

    // Avatars
    'variants.square-small',
    'variants.square-small2x',
  ],
  'imageVariant.hero-scale': 'w:1440;h:400;fit:scale',
  'imageVariant.hero-crop': 'w:1440;h:400;fit:crop',
  'imageVariant.portrait-crop': sdkUtil.objectQueryString({
    w: 280,
    h: 392,
    fit: 'crop',
  }),
  'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
    w: 560,
    h: 784,
    fit: 'crop',
  }),
};

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CommentsPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/CommentsPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/CommentsPage/SHOW_LISTING_ERROR';

export const QUERY_USERS_REQUEST = 'app/CommentsPage/QUERY_USERS_REQUEST';
export const QUERY_USERS_SUCCESS = 'app/CommentsPage/QUERY_USERS_SUCCESS';
export const QUERY_USERS_ERROR = 'app/CommentsPage/QUERY_USERS_ERROR';

export const FETCH_SHOW_LISTING_REQUEST = 'app/CommentsPage/FETCH_SHOW_LISTING_REQUEST';
export const FETCH_SHOW_LISTING_SUCCESS = 'app/CommentsPage/FETCH_SHOW_LISTING_SUCCESS';
export const FETCH_SHOW_LISTING_ERROR = 'app/CommentsPage/FETCH_SHOW_LISTING_ERROR';

export const FETCH_COMMENTS_REQUEST = 'app/CommentsPage/FETCH_COMMENTS_REQUEST';
export const FETCH_COMMENTS_SUCCESS = 'app/CommentsPage/FETCH_COMMENTS_SUCCESS';
export const FETCH_COMMENTS_ERROR = 'app/CommentsPage/FETCH_COMMENTS_ERROR';

export const SEND_COMMENT_REQUEST = 'app/CommentsPage/SEND_COMMENT_REQUEST';
export const SEND_COMMENT_SUCCESS = 'app/CommentsPage/SEND_COMMENT_SUCCESS';
export const SEND_COMMENT_ERROR = 'app/CommentsPage/SEND_COMMENT_ERROR';

export const EDIT_COMMENT_REQUEST = 'app/CommentsPage/EDIT_COMMENT_REQUEST';
export const EDIT_COMMENT_SUCCESS = 'app/CommentsPage/EDIT_COMMENT_SUCCESS';
export const EDIT_COMMENT_ERROR = 'app/CommentsPage/EDIT_COMMENT_ERROR';

export const DELETE_COMMENT_REQUEST = 'app/CommentsPage/DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'app/CommentsPage/DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_ERROR = 'app/CommentsPage/DELETE_COMMENT_ERROR';

export const ADD_COMMENT_LIKE_REQUEST = 'app/CommentsPage/ADD_COMMENT_LIKE_REQUEST';
export const ADD_COMMENT_LIKE_SUCCESS = 'app/CommentsPage/ADD_COMMENT_LIKE_SUCCESS';
export const ADD_COMMENT_LIKE_ERROR = 'app/CommentsPage/ADD_COMMENT_LIKE_ERROR';

export const ADD_COMMENT_DISLIKE_REQUEST = 'app/CommentsPage/ADD_COMMENT_DISLIKE_REQUEST';
export const ADD_COMMENT_DISLIKE_SUCCESS = 'app/CommentsPage/ADD_COMMENT_DISLIKE_SUCCESS';
export const ADD_COMMENT_DISLIKE_ERROR = 'app/CommentsPage/ADD_COMMENT_DISLIKE_ERROR';

// ================ Reducer ================ //

const initialState = {
  // listing(s)
  id: null,
  showListingError: null,
  // comments
  comments: [],
  fetchCommentsInProgress: false,
  fetchCommentsError: null,
  sendCommentInProgress: false,
  sendCommentError: null,
  addCommentLikeInProgress: false,
  addCommentLikeError: null,
  addCommentDislikeInProgress: false,
  addCommentDislikeError: null,
  deleteCommentInProgress: false,
  deleteCommentError: null,
};

const resultIds = data => data.data.map(d => d.id);

const updateLikedComment = (comments, payload) =>
  comments.map(comment => {
    if (comment.id === payload.commentId) {
      const userLiked = payload.currentUserReactions.some(
        reaction => reaction.commentId === payload.commentId && reaction.type === 'like'
      );
      const userDisliked = payload.currentUserReactions.some(
        reaction => reaction.commentId === payload.commentId && reaction.type === 'dislike'
      );

      const { likes, dislikes } = comment.attributes;

      let newLikes = likes;
      let newDislikes = dislikes;

      if (userLiked) {
        // If already liked, remove the like
        newLikes = likes - 1;
      } else if (userDisliked) {
        // If disliked and now liking, remove the dislike and increment likes
        newLikes = likes + 1;
        newDislikes = dislikes - 1;
      } else {
        // If neutral and now liking, increment likes
        newLikes = likes + 1;
      }

      return {
        ...comment,
        attributes: {
          ...comment.attributes,
          likes: newLikes,
          dislikes: newDislikes,
        },
      };
    }

    return comment;
  });

const updateDislikedComment = (comments, payload) =>
  comments.map(comment => {
    if (comment.id === payload.commentId) {
      const userLiked = payload.currentUserReactions.some(
        reaction => reaction.commentId === payload.commentId && reaction.type === 'like'
      );
      const userDisliked = payload.currentUserReactions.some(
        reaction => reaction.commentId === payload.commentId && reaction.type === 'dislike'
      );

      const { likes, dislikes } = comment.attributes;

      let newLikes = likes;
      let newDislikes = dislikes;

      if (userDisliked) {
        // If already disliked, remove the dislike
        newDislikes = dislikes - 1;
      } else if (userLiked) {
        // If liked and now disliking, remove the like and increment dislikes
        newLikes = likes - 1;
        newDislikes = dislikes + 1;
      } else {
        // If neutral and now disliking, increment dislikes
        newDislikes = dislikes + 1;
      }

      return {
        ...comment,
        attributes: {
          ...comment.attributes,
          likes: newLikes,
          dislikes: newDislikes,
        },
      };
    }

    return comment;
  });

const updateLikedCommentReactions = (userReactions, commentId) => {
  const currentReaction = userReactions.find(reaction => reaction.commentId === commentId);

  const hasLiked = userReactions.some(
    reaction => reaction.commentId === commentId && reaction.type === 'like'
  );
  const hasDisliked = userReactions.some(
    reaction => reaction.commentId === commentId && reaction.type === 'dislike'
  );

  if (hasLiked) {
    return userReactions.filter(reaction => reaction.id !== currentReaction.id);
  } else if (hasDisliked) {
    return userReactions.map(reaction =>
      reaction.id === currentReaction.id ? { ...reaction, type: 'like' } : reaction
    );
  } else {
    const newReaction = { id: uuidv4(), commentId, type: 'like' };
    return [...userReactions, newReaction];
  }
};

const updateDislikedCommentReactions = (userReactions, commentId) => {
  const currentReaction = userReactions.find(reaction => reaction.commentId === commentId);

  const hasLiked = userReactions.some(
    reaction => reaction.commentId === commentId && reaction.type === 'like'
  );
  const hasDisliked = userReactions.some(
    reaction => reaction.commentId === commentId && reaction.type === 'dislike'
  );

  if (hasDisliked) {
    return userReactions.filter(reaction => reaction.id !== currentReaction.id);
  } else if (hasLiked) {
    return userReactions.map(reaction =>
      reaction.id === currentReaction.id ? { ...reaction, type: 'dislike' } : reaction
    );
  } else {
    const newReaction = { id: uuidv4(), commentId, type: 'dislike' };
    return [...userReactions, newReaction];
  }
};

const CommentsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case SEND_COMMENT_REQUEST:
      return {
        ...state,
        sendCommentInProgress: true,
        sendCommentError: null,
      };
    case SEND_COMMENT_SUCCESS:
      return {
        ...state,
        sendCommentInProgress: false,
        sendCommentError: null,
        comments: [payload.comment, ...state.comments],
      };
    case SEND_COMMENT_ERROR:
      return {
        ...state,
        sendCommentInProgress: false,
        sendCommentError: payload,
      };

    case DELETE_COMMENT_REQUEST:
      return {
        ...state,
        deleteCommentInProgress: true,
        deleteCommentError: null,
        comments: state.comments.filter(comment => comment.id !== payload.commentId),
      };
    case DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        deleteCommentInProgress: false,
        deleteCommentError: null,
      };
    case DELETE_COMMENT_ERROR:
      return {
        ...state,
        deleteCommentInProgress: false,
        deleteCommentError: payload,
      };

    case ADD_COMMENT_LIKE_REQUEST:
      return {
        ...state,
        addCommentLikeInProgress: true,
        addCommentLikeError: null,
        comments: updateLikedComment(state.comments, payload),
      };
    case ADD_COMMENT_LIKE_SUCCESS:
      return {
        ...state,
        addCommentLikeInProgress: false,
        addCommentLikeError: null,
      };
    case ADD_COMMENT_LIKE_ERROR:
      return {
        ...state,
        addCommentLikeInProgress: false,
        addCommentLikeError: payload,
      };

    case ADD_COMMENT_DISLIKE_REQUEST:
      return {
        ...state,
        addCommentDislikeInProgress: true,
        addCommentDislikeError: null,
        comments: updateDislikedComment(state.comments, payload),
      };
    case ADD_COMMENT_DISLIKE_SUCCESS:
      return {
        ...state,
        addCommentDislikeInProgress: false,
        addCommentDislikeError: null,
      };
    case ADD_COMMENT_DISLIKE_ERROR:
      return {
        ...state,
        addCommentDislikeInProgress: false,
        addCommentDislikeError: payload,
      };

    case EDIT_COMMENT_REQUEST:
      return {
        ...state,
        editCommentInProgress: true,
        editCommentError: null,
      };
    case EDIT_COMMENT_SUCCESS:
      return {
        ...state,
        editCommentInProgress: false,
        editCommentError: null,
        comments: state.comments.map(comment =>
          comment.id === payload.commentId
            ? { ...comment, attributes: { ...comment.attributes, content: payload.newComment } }
            : comment
        ),
      };
    case EDIT_COMMENT_ERROR:
      return {
        ...state,
        editCommentInProgress: false,
        editCommentError: payload,
      };

    case QUERY_USERS_REQUEST:
      return {
        ...state,
        queryUsersInProgress: true,
        queryUsersError: null,
      };
    case QUERY_USERS_SUCCESS:
      return {
        ...state,
        userIds: resultIds(payload).filter(i => payload.queryIds.includes(i.uuid)),
        queryUsersInProgress: false,
        queryUsersError: null,
      };
    case QUERY_USERS_ERROR:
      return {
        ...state,
        userIds: [],
        queryUsersInProgress: false,
        queryUsersError: payload,
      };

    case FETCH_SHOW_LISTING_REQUEST:
      return {
        ...state,
        fetchShowListingInProgress: true,
        fetchShowListingError: null,
      };
    case FETCH_SHOW_LISTING_SUCCESS:
      return {
        ...state,
        showListingId: payload.id,
        fetchShowListingInProgress: false,
        fetchShowListingError: null,
      };
    case FETCH_SHOW_LISTING_ERROR:
      return {
        ...state,
        userIds: [],
        fetchShowListingInProgress: false,
        fetchShowListingError: payload,
      };

    case FETCH_COMMENTS_REQUEST:
      return {
        ...state,
        fetchCommentsInProgress: true,
        fetchCommentsError: null,
        comments: [],
      };
    case FETCH_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: payload.comments,
        fetchCommentsInProgress: false,
        fetchCommentsError: null,
      };
    case FETCH_COMMENTS_ERROR:
      return {
        ...state,
        fetchCommentsInProgress: false,
        fetchCommentsError: payload,
      };

    default:
      return state;
  }
};

export default CommentsPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});
export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchShowListingRequest = () => ({
  type: FETCH_SHOW_LISTING_REQUEST,
});
export const fetchShowListingSuccess = id => ({
  type: FETCH_SHOW_LISTING_SUCCESS,
  payload: { id },
});
export const fetchShowListingError = e => ({
  type: FETCH_SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const sendCommentRequest = () => ({
  type: SEND_COMMENT_REQUEST,
});
export const sendCommentSuccess = comment => ({
  type: SEND_COMMENT_SUCCESS,
  payload: { comment },
});
export const sendCommentError = e => ({
  type: SEND_COMMENT_ERROR,
  error: true,
  payload: e,
});

export const editCommentRequest = (commentId, newComment) => ({
  type: EDIT_COMMENT_REQUEST,
  payload: { commentId, newComment },
});
export const editCommentSuccess = (commentId, newComment) => ({
  type: EDIT_COMMENT_SUCCESS,
  payload: { commentId, newComment },
});
export const editCommentError = e => ({
  type: EDIT_COMMENT_ERROR,
  error: true,
  payload: e,
});

export const deleteCommentRequest = commentId => ({
  type: DELETE_COMMENT_REQUEST,
  payload: { commentId },
});

export const deleteCommentSuccess = commentId => ({
  type: DELETE_COMMENT_SUCCESS,
  payload: { commentId },
});

export const deleteCommentError = e => ({
  type: DELETE_COMMENT_ERROR,
  error: true,
  payload: e,
});

export const fetchCommentsRequest = () => ({
  type: FETCH_COMMENTS_REQUEST,
});
export const fetchCommentsSuccess = comments => ({
  type: FETCH_COMMENTS_SUCCESS,
  payload: { comments },
});
export const fetchCommentsError = error => ({
  type: FETCH_COMMENTS_ERROR,
  error: true,
  payload: error,
});

export const addCommentLikeRequest = (commentId, currentUserReactions) => ({
  type: ADD_COMMENT_LIKE_REQUEST,
  payload: { commentId, currentUserReactions },
});
export const addCommentLikeSuccess = commentId => ({
  type: ADD_COMMENT_LIKE_SUCCESS,
  payload: { commentId },
});
export const addCommentLikeError = e => ({
  type: ADD_COMMENT_LIKE_ERROR,
  error: true,
  payload: e,
});

export const addCommentDislikeRequest = (commentId, currentUserReactions) => ({
  type: ADD_COMMENT_DISLIKE_REQUEST,
  payload: { commentId, currentUserReactions },
});
export const addCommentDislikeSuccess = commentId => ({
  type: ADD_COMMENT_DISLIKE_SUCCESS,
  payload: { commentId },
});
export const addCommentDislikeError = e => ({
  type: ADD_COMMENT_DISLIKE_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showListing = (listingId, isOwn = false, useIntegration = false) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(showListingRequest(listingId));

  const params = {
    id: listingId,
    ...listingsIncludeParams,
  };

  const show = isOwn
    ? useIntegration
      ? integrationAPI.listings.show({
          ...params,
          id: listingId.uuid,
        })
      : sdk.ownListings.show(params)
    : sdk.listings.show(params);

  return show
    .then(response => {
      const data = isOwn && useIntegration ? response.data : response;
      const listing = denormalisedResponseEntities(data)?.[0];
      const showId = listing.attributes.publicData.showId;

      dispatch(addMarketplaceEntities(data));
      dispatch(fetchShowListing(showId));

      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchShowListing = id => (dispatch, getState, sdk) => {
  dispatch(fetchShowListingRequest(id));

  return integrationAPI.listings
    .show({ id })
    .then(response => {
      const listing = response.data.data.data;

      dispatch(addMarketplaceEntities(response.data));
      dispatch(fetchShowListingSuccess(listing.id));
      return listing;
    })
    .catch(e => dispatch(fetchShowListingError(storableError(e))));
};

export const fetchComments = listingId => async (dispatch, getState, sdk) => {
  dispatch(fetchCommentsRequest());

  try {
    const listing = getListing(listingId, getState());
    const comments = listing.attributes.metadata.comments || [];

    const usersResponse = await integrationAPI.users.query({
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    });
    const users = denormalisedResponseEntities(usersResponse.data);
    const commentsWithUsers = comments.map(c => {
      const { authorId, ...rest } = c;
      return {
        ...rest,
        author: users.find(u => u.id.uuid === c.authorId),
      };
    });

    dispatch(fetchCommentsSuccess(commentsWithUsers));
  } catch (e) {
    dispatch(fetchCommentsError(storableError(e)));
  }
};

export const sendComment = (listingId, comment) => (dispatch, getState, sdk) => {
  dispatch(sendCommentRequest());

  return integrationAPI.comments
    .send({ listingId, comment })
    .then(newComment => {
      const { authorId, ...rest } = newComment;
      const updatedComment = {
        ...rest,
        author: getState().user.currentUser,
      };

      dispatch(sendCommentSuccess(updatedComment));
      return updatedComment;
    })
    .catch(e => dispatch(sendCommentError(storableError(e))));
};

export const editComment = (listingId, commentId, newComment) => (dispatch, getState, sdk) => {
  dispatch(editCommentRequest(commentId, newComment));

  return integrationAPI.comments
    .update({ listingId, commentId, comment: newComment })
    .then(response => {
      dispatch(editCommentSuccess(commentId, newComment));
      return response;
    })
    .catch(e => dispatch(editCommentError(storableError(e))));
};

export const deleteComment = (listingId, commentId) => (dispatch, getState, sdk) => {
  dispatch(deleteCommentRequest(commentId));

  return integrationAPI.comments
    .delete({ listingId, commentId })
    .then(() => {
      dispatch(deleteCommentSuccess(commentId));
    })
    .catch(e => dispatch(deleteCommentError(storableError(e))));
};

export const addCommentLike = (listingId, commentId) => (dispatch, getState, sdk) => {
  const { currentUserReactions } = getState().user;

  dispatch(addCommentLikeRequest(commentId, currentUserReactions));
  dispatch(
    fetchCurrentUserReactionsSuccess(updateLikedCommentReactions(currentUserReactions, commentId))
  );

  return integrationAPI.comments
    .addLike({ listingId, commentId })
    .then(response => {
      dispatch(addCommentLikeSuccess(commentId));
      return response;
    })
    .catch(e => dispatch(addCommentLikeError(storableError(e))));
};

export const addCommentDislike = (listingId, commentId) => (dispatch, getState, sdk) => {
  const { currentUserReactions } = getState().user;

  dispatch(addCommentDislikeRequest(commentId, currentUserReactions));
  dispatch(
    fetchCurrentUserReactionsSuccess(
      updateDislikedCommentReactions(currentUserReactions, commentId)
    )
  );

  return integrationAPI.comments
    .addDislike({ listingId, commentId })
    .then(response => {
      dispatch(addCommentDislikeSuccess(commentId));
      return response;
    })
    .catch(e => dispatch(addCommentDislikeError(storableError(e))));
};

export const loadData = params => async (dispatch, getState, sdk) => {
  const listingId = new UUID(params.id);

  const similarExperiencesFromState = getState().ExperiencePage.experienceIds;
  const commentsFromState = getState().CommentsPage.comments;
  const recentlyViewedListingsFromState = getState().user.recentlyViewedListingIds;

  const callSimilarExperiencesPromise = similarExperiencesFromState.length === 0;
  const callCommentsPromise = commentsFromState.length === 0;
  const callRecentlyViewedListingsPromise = recentlyViewedListingsFromState.length === 0;

  return dispatch(showListing(listingId)).then(() =>
    Promise.all([
      callSimilarExperiencesPromise ? dispatch(querySimilarExperiences()) : Promise.resolve(),
      callCommentsPromise ? dispatch(fetchComments(listingId)) : Promise.resolve(),
      callRecentlyViewedListingsPromise
        ? dispatch(queryRecentlyViewedListings(listingId.uuid))
        : Promise.resolve(),
    ])
  );
};
