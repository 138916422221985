import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaTwitter.module.css';

const IconSocialMediaTwitter = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M14 2.4128C13.4852 2.64777 12.9249 2.81688 12.3478 2.88274C12.9469 2.50614 13.3956 1.91118 13.6097 1.20947C13.0474 1.56333 12.4315 1.81146 11.7892 1.94286C11.5208 1.63923 11.1961 1.39734 10.8354 1.23227C10.4747 1.0672 10.0858 0.982487 9.69283 0.983406C8.10287 0.983406 6.82418 2.34694 6.82418 4.0202C6.82418 4.25517 6.8511 4.49014 6.89485 4.71621C4.51412 4.58448 2.39082 3.38116 0.979209 1.53879C0.721999 2.00359 0.587212 2.53282 0.588871 3.07142C0.588871 4.12522 1.0953 5.05442 1.86756 5.6009C1.41246 5.58194 0.968008 5.44959 0.570364 5.21463V5.25201C0.570364 6.72768 1.5563 7.95059 2.87033 8.23184C2.62361 8.29964 2.3698 8.33433 2.11489 8.33508C1.92813 8.33508 1.75147 8.3155 1.57313 8.2888C1.93655 9.49213 2.99483 10.3661 4.25502 10.3946C3.26908 11.2117 2.03413 11.6923 0.693186 11.6923C0.45259 11.6923 0.230501 11.6834 0 11.6549C1.27196 12.5182 2.78116 13.0167 4.40644 13.0167C9.68273 13.0167 12.5699 8.39205 12.5699 4.37799C12.5699 4.24627 12.5699 4.11454 12.5615 3.98282C13.1201 3.55026 13.6097 3.01446 14 2.4128Z"
        fill="white"
      />
    </svg>
  );
};

IconSocialMediaTwitter.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaTwitter.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaTwitter;
