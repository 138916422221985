import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { txIsEnquired } from '../../util/transaction';
import { propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import {
  NamedLink,
  ReviewModal,
  UserDisplayName,
  Modal,
  SecondaryButton,
  IconArrowHead,
} from '../../components';
import { SendMessageForm } from '../../forms';

// These are internal components that make this file more readable.
import PanelHeading from './PanelHeading';
import BreakdownMaybe from './BreakdownMaybe';
import FeedSection from './FeedSection';

import css from './TransactionPanel.module.css';
import DetailsCardImage from './DetailsCardImage';

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

const mergeTimeslotAndBooking = (timeslot, booking) => {
  return {
    ...timeslot,
    bookingDates: {
      bookingStart: booking.attributes.start,
      bookingEnd: booking.attributes.end,
    },
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      isQuickRepliesModalOpen: false,
      reviewSubmitted: false,
      selectedTimeslot: null,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.restart();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      history,
      location,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      returnPage,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      invitedToOrganization,
      intl,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const isCustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = isCustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = isCustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;
    const isEnquiryState = txIsEnquired(currentTransaction);

    const { metadata } = currentTransaction.attributes;
    const timeslot = metadata?.timeslot;
    const currentTimeslot = timeslot
      ? mergeTimeslotAndBooking(timeslot, transaction.booking)
      : null;

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const { otherUserDisplayName, otherUserDisplayNameString } = displayNames(
      currentUser,
      currentProvider,
      currentCustomer,
      intl
    );

    const quickReplies = currentProvider?.attributes?.profile?.publicData?.quickReplies;
    const showQuickReplies = isProvider && quickReplies?.length > 0;

    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const bookingTitle = currentTimeslot?.name || currentListing.attributes.title;

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const showSendMessageForm =
      !invitedToOrganization &&
      !isCustomerBanned &&
      !isCustomerDeleted &&
      !isProviderBanned &&
      !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = invitedToOrganization
      ? intl.formatMessage({
          id: 'TransactionPanel.sendingMessageNotAllowedToInvitedUser',
        })
      : intl.formatMessage({
          id: 'TransactionPanel.sendingMessageNotAllowed',
        });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            <div className={css.detailsContainerMobile}>
              <DetailsCardImage listingTitle={listingTitle} firstImage={firstImage} />
            </div>
            {returnPage ? (
              <div className={css.returnLinkContainer}>
                <NamedLink className={css.returnLink} name={returnPage}>
                  <IconArrowHead className={css.returnLinkIcon} direction="left" />
                  {returnPage === 'PaymentHistoryPage' ? (
                    <FormattedMessage id="TransactionPanel.returnToPayments" />
                  ) : (
                    <FormattedMessage id="TransactionPanel.returnToMessages" />
                  )}
                </NamedLink>
              </div>
            ) : null}
            <PanelHeading
              intl={intl}
              isEnquiryState={isEnquiryState}
              listing={currentListing}
              timeslot={timeslot}
              booking={currentTransaction.booking}
              bookingTitle={bookingTitle}
            />
            <div className={css.bookingDetailsMobile}>
              <BreakdownMaybe
                transaction={currentTransaction}
                listing={currentListing}
                timeslot={currentTimeslot}
                transactionRole={transactionRole}
              />
            </div>
            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}
            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentTimeslot={currentTimeslot}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
              isCustomer={isCustomer}
            />
            {showSendMessageForm ? (
              <SendMessageForm
                formId={this.sendMessageFormName}
                rootClassName={css.sendMessageForm}
                messagePlaceholder={sendMessagePlaceholder}
                inProgress={sendMessageInProgress}
                sendMessageError={sendMessageError}
                showQuickReplies={showQuickReplies}
                onOpenQuickRepliesModal={() => this.setState({ isQuickRepliesModalOpen: true })}
                onFocus={this.onSendMessageFormFocus}
                onBlur={this.onSendMessageFormBlur}
                onSubmit={this.onMessageSubmit}
              />
            ) : (
              <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
            )}
          </div>

          <div className={css.detailsContainerDesktop}>
            <DetailsCardImage listingTitle={listingTitle} firstImage={firstImage} />
            <BreakdownMaybe
              transaction={transaction}
              listing={currentListing}
              timeslot={currentTimeslot}
              transactionRole={transactionRole}
            />
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />

        {showQuickReplies ? (
          <Modal
            id="QuickReplies"
            isOpen={this.state.isQuickRepliesModalOpen}
            onClose={() => this.setState({ isQuickRepliesModalOpen: false })}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div>
              <h1 className={css.quickRepliesTitle}>
                <FormattedMessage id="TransactionPanel.quickRepliesTitle" />
              </h1>
              <div className={css.quickReplies}>
                {quickReplies.map((reply, index) => {
                  return (
                    <div key={`${reply.id}_${index}`} className={css.quickReply}>
                      <h3 className={css.quickReplyTitle}>{reply.name}</h3>
                      <p className={css.quickReplyMessage}>{reply.message}</p>
                      <SecondaryButton
                        className={css.quickReplyButton}
                        type="button"
                        onClick={() => {
                          this.props
                            .onSendMessage(currentTransaction.id, reply.message)
                            .then(messageId => {
                              this.setState({ isQuickRepliesModalOpen: false });
                              this.scrollToMessage(messageId);
                            });
                        }}
                      >
                        <FormattedMessage id="TransactionPanel.quickReplyButton" />
                      </SecondaryButton>
                    </div>
                  );
                })}
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  returnPage: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  authorPayoutsEnabled: false,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  returnPage: string,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  perksConfig: array.isRequired,
  authorPayoutsEnabled: bool.isRequired,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
