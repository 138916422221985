import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../DashboardWrapper.module.css';

const InboxIcon = props => {
  const { className } = props;
  const classes = classNames(css.sideNavIcon, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        stroke="0e0f0c"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M22 10c.018.727 0 1.054 0 2 0 3.771 0 5.657-1.172 6.828C19.657 20 17.771 20 14 20h-4c-3.771 0-5.657 0-6.828-1.172C2 17.657 2 15.771 2 12c0-3.771 0-5.657 1.172-6.828C4.343 4 6.229 4 10 4h3"
      />
      <path
        stroke="0e0f0c"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="m6 8 2.159 1.8c1.837 1.53 2.755 2.295 3.841 2.295s2.005-.765 3.841-2.296"
      />
      <path stroke="0e0f0c" strokeWidth="1.5" d="M16 5a3 3 0 1 0 6 0 3 3 0 1 0-6 0" />
    </svg>
  );
};

const { string } = PropTypes;

InboxIcon.defaultProps = {
  className: null,
};

InboxIcon.propTypes = {
  className: string,
};

export default InboxIcon;
