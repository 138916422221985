import React from 'react';
import { InlineTextButton } from '..';
import { requestFullscreen, cancelFullscreen, formatPlayerTime } from './VideoPlayer.helpers';
import classNames from 'classnames';

import { IconPlay, IconVolume, IconFullscreen } from './VideoIcons';
import css from './VideoPlayer.module.css';

export const ControllerOverlay = props => {
  const { height } = props;
  return <div className={css.controllerOverlay} style={{ height }} />;
};

const Controller = props => {
  const {
    // classes
    controlsClasses,
    controlsTextClasses,
    // player
    player,
    showPlayerTime,
    // refs
    videoRef,
    controllerRef,
    seekTrackRef,
    fullscreenElementRef,
    // functions
    onTogglePlay,
  } = props;

  const onHandleFullscreen = () => {
    if (player.fullscreen) {
      cancelFullscreen();
    } else {
      requestFullscreen(player.fullscreen, fullscreenElementRef.current);
    }
  };

  const onHandleSeek = e => {
    const trackElem = seekTrackRef.current;

    const trackElemWidth = trackElem.getBoundingClientRect().width;
    const clickPosition = e.pageX - trackElem.getBoundingClientRect().left;
    const progressPercentage = Math.round((clickPosition / trackElemWidth) * 100);

    const time =
      progressPercentage > 100 ? player.duration : (progressPercentage * player.duration) / 100;

    videoRef.current.currentTime = time;
    player.onTime(time);
    player.onSeek(progressPercentage);
  };

  const onToggleMute = () => {
    if (player.muted) {
      player.onMute(false);
    } else {
      player.onMute(true);
    }
  };

  // Render buttons
  const renderPlayButton = (
    <InlineTextButton
      className={classNames(css.controlButton, css.playButton)}
      type="button"
      onClick={onTogglePlay}
    >
      <IconPlay className={css.controlIcon} isPlaying={player.playing} />
    </InlineTextButton>
  );
  const renderVolumeButton = (
    <InlineTextButton
      className={classNames(css.controlButton, css.volumeButton)}
      type="button"
      onClick={onToggleMute}
    >
      <IconVolume className={css.controlIcon} isMuted={player.muted} />
    </InlineTextButton>
  );
  const renderFullscreenButton = (
    <InlineTextButton
      className={classNames(css.controlButton, css.fullscreenButton)}
      type="button"
      onClick={onHandleFullscreen}
    >
      <IconFullscreen
        className={classNames(css.controlIcon, css.fullscreenIcon)}
        isFullscreen={player.fullscreen}
      />
    </InlineTextButton>
  );

  // Render time
  const playerTime = (
    <span className={controlsTextClasses}>
      {`${formatPlayerTime(player.time)} / ${formatPlayerTime(player.duration)}`}
    </span>
  );
  const renderPlayerTime = showPlayerTime ? playerTime : player.fullscreen ? playerTime : null;

  return (
    <div className={css.controller} ref={controllerRef}>
      {player.fullscreen ? (
        <div
          className={css.seekSlider}
          role="slider"
          aria-valuenow={player.time}
          aria-valuemax={player.duration}
          onClick={onHandleSeek}
        >
          <div className={css.seekTrack} ref={seekTrackRef} />
          <div className={css.seekFilledTrack} style={{ width: `${player.seek}%` }} />
          <div className={css.seekThumb} style={{ left: `${player.seek}%` }} />
        </div>
      ) : null}
      <div className={controlsClasses}>
        <div className={css.controlsLeft}>
          {renderPlayButton}
          {renderPlayerTime}
        </div>
        <div className={css.controlsRight}>
          {renderVolumeButton}
          {renderFullscreenButton}
        </div>
      </div>
    </div>
  );
};

export default Controller;
