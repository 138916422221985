import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconFavorite.module.css';

const IconFavorite = props => {
  const { className, rootClassName, isFilled } = props;
  const classes = classNames(rootClassName || css.root, className, {
    [css.rootFilled]: isFilled,
  });
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_98_528)">
        <path
          d="M10 17.7857L2.1143 10.6429C-2.17142 6.35714 4.12858 -1.87143 10 4.78571C15.8714 -1.87143 22.1429 6.38571 17.8857 10.6429L10 17.7857Z"
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_98_528">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const { string, bool } = PropTypes;

IconFavorite.defaultProps = {
  className: null,
  rootClassName: null,
  isFilled: false,
};

IconFavorite.propTypes = {
  className: string,
  rootClassName: string,
  isFilled: bool.isRequired,
};

export default IconFavorite;
