import React, { useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, string, bool, func, oneOfType } from 'prop-types';
import { propTypes, LISTING_STATE_CLOSED } from '../../util/types';
import { parse, stringify } from '../../util/urlHelpers';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { ModalInMobile, InlineTextButton } from '../../components';
import omit from 'lodash/omit';
import classNames from 'classnames';

import PanelFilters from './PanelFilters';
import PanelButtons from './PanelButtons';
import PanelTimeslots from './PanelTimeslots';
import css from './BookingPanel.module.css';

const DEFAULT_INITIAL_VALUES = { dates: '', quantity: 1 };
const MODAL_BREAKPOINT = 1023;

const openModal = (history, location, queryParam) => {
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ ...parse(search), [queryParam]: true })}`;
  history.push(`${pathname}${searchString}`, state);
};

const closeModal = (history, location, queryParam) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), queryParam);
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const BookingPanel = props => {
  const {
    intl,
    rootClassName,
    className,
    boxRootClassName,
    boxClassName,
    filtersTitleClassName,
    filtersTitle,
    history,
    location,
    currentUserMemberships,
    currentListing,
    isOwnListing,
    isExceptionBlocked,
    availabilityExceptions,
    publicDataAvailabilityExceptions,
    fetchExceptionsInProgress,
    fetchExceptionsError,
    handleFilterAvailabilityExceptions,
    createFreeSubscriptionInProgress,
    onBooking,
    onOpenMembershipModal,
    onOpenUpgradeMembershipModal,
    onOpenFreeMembershipModal,
    onOpenDonateModal,
    onManageDisableScrolling,
  } = props;

  const [initialValues, setInitialValues] = useState(DEFAULT_INITIAL_VALUES);
  const [formValues, setFormValues] = useState(initialValues);

  const isBook = !!parse(location.search).book;
  const classes = classNames(rootClassName || css.root, className);
  const boxClasses = classNames(boxRootClassName || css.bookingPanelBox, boxClassName);

  const hasMembership = currentUserMemberships?.length > 0;
  const hasPaidMemberships = hasMembership && currentUserMemberships.every(m => m.type !== 'free');
  const hasFreeMemberships = hasMembership && currentUserMemberships.some(m => m.type === 'free');
  const isClosed = currentListing?.attributes?.state === LISTING_STATE_CLOSED;

  if (isClosed) {
    return (
      <div className={classes}>
        <div className={boxClasses}>
          <p className={css.closedListing}>
            <FormattedMessage id="BookingPanel.listingClosedMessage" />
          </p>
        </div>
      </div>
    );
  }

  const renderPanelButtons = () => (
    <PanelButtons
      isOwnListing={isOwnListing}
      hasMembership={hasMembership}
      hasPaidMemberships={hasPaidMemberships}
      hasFreeMemberships={hasFreeMemberships}
      createFreeSubscriptionInProgress={createFreeSubscriptionInProgress}
      onOpenMembershipModal={onOpenMembershipModal}
      onOpenUpgradeMembershipModal={onOpenUpgradeMembershipModal}
      onOpenDonateModal={onOpenDonateModal}
    />
  );

  return (
    <div className={classes}>
      <div className={boxClasses}>
        {renderPanelButtons()}
        <ModalInMobile
          containerClassName={css.modalContainer}
          contentClassName={css.modalContent}
          id="BookingTimeFormInModal"
          isModalOpenOnMobile={isBook}
          onClose={() => closeModal(history, location, 'book')}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <PanelFilters
            intl={intl}
            idPrefix="BookingPanel"
            availabilityExceptions={availabilityExceptions}
            handleFilterAvailabilityExceptions={handleFilterAvailabilityExceptions}
            isExceptionBlocked={isExceptionBlocked}
            filtersTitleClassName={filtersTitleClassName}
            filtersTitle={filtersTitle}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            formValues={formValues}
            setFormValues={setFormValues}
          />
          <PanelTimeslots
            currentUserMemberships={currentUserMemberships}
            hasPaidMemberships={hasPaidMemberships}
            hasFreeMemberships={hasFreeMemberships}
            isOwnListing={isOwnListing}
            timeslots={availabilityExceptions}
            timeslotsMetadata={publicDataAvailabilityExceptions}
            onOpenMembershipModal={onOpenMembershipModal}
            onOpenFreeMembershipModal={onOpenFreeMembershipModal}
            onBooking={onBooking}
            fetchInProgress={fetchExceptionsInProgress}
            fetchError={fetchExceptionsError}
            formValues={formValues}
          />
        </ModalInMobile>
      </div>
      <div className={css.openBookingForm}>
        <InlineTextButton
          className={css.openBookingButton}
          onClick={() => openModal(history, location, 'book')}
        >
          <FormattedMessage id="BookingPanel.dates" />
        </InlineTextButton>
        {renderPanelButtons()}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  boxRootClassName: null,
  boxClassName: null,
  filtersTitleClassName: null,
  filtersTitle: null,
  listing: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  boxRootClassName: string,
  boxClassName: string,
  filtersTitleClassName: string,
  filtersTitle: string,
  currentUserMemberships: arrayOf(propTypes.membership),
  isOwnListing: bool.isRequired,
  listing: oneOfType([propTypes.ownListing, propTypes.listing]),
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  publicDataAvailabilityExceptions: arrayOf(propTypes.availabilityExceptionMetadata),
  fetchExceptionsInProgress: bool.isRequired,
  fetchExceptionsError: propTypes.error,
  handleFilterAvailabilityExceptions: func.isRequired,
  onOpenMembershipModal: func.isRequired,
  onOpenFreeMembershipModal: func.isRequired,
  onBooking: func.isRequired,
  isExceptionBlocked: func.isRequired,
  onOpenMembershipModal: func,
  onOpenUpgradeMembershipModal: func,
  onOpenFreeMembershipModal: func,
  onOpenDonateModal: func,
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);
