import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './Table.module.css';

const TableWrapper = props => {
  const { children } = props;
  return <div className={css.tableWrapper}>{children}</div>;
};

const Table = props => {
  const { rootClassName, className, children } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <TableWrapper>
      <table className={classes}>{children}</table>
    </TableWrapper>
  );
};

Table.defaultProps = {
  rootClassName: null,
  className: null,
};

Table.propTypes = {
  rootClassName: string,
  className: string,
};

export default Table;
