/**
 * This component will show the experience info.
 */
import React from 'react';
import { string, object, bool } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import classNames from 'classnames';

import LineItemExperienceDetails from './LineItemExperienceDetails';
import LineItemBookingDetails from './LineItemBookingDetails';

import css from './BookingBreakdown.module.css';

export const BookingBreakdownComponent = props => {
  const { rootClassName, className, listing, timeslot, transactionRole, hideVideoLink } = props;

  const bookingDates = timeslot?.bookingDates;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {/* Experience details  */}
      <LineItemExperienceDetails
        listing={listing}
        timeslot={timeslot}
        transactionRole={transactionRole}
        hideVideoLink={hideVideoLink}
      />

      {/* Booking details  */}
      <LineItemBookingDetails timeslot={timeslot} bookingDates={bookingDates} />
    </div>
  );
};

BookingBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

BookingBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  listing: propTypes.listing.isRequired,
  timeslot: object.isRequired,
  transactionRole: string,
  hideVideoLink: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BookingBreakdown = injectIntl(BookingBreakdownComponent);

BookingBreakdown.displayName = 'BookingBreakdown';

export default BookingBreakdown;
