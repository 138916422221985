import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, oneOfType, bool, node, string, object, func } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink, NamedRedirect } from '..';
import { ensureCurrentUser } from '../../util/data';
import {
  getSelectedOrganizationProfile,
  getSelectedOrganizationProfileIdFromExtendedData,
} from '../../util/organizations';
import { DASHBOARD_PAGES } from '../../routeConfiguration';
import { selectOrganizationProfile } from '../../ducks/organizations.duck';
import classNames from 'classnames';

import CalendarIcon from './Icons/CalendarIcon';
import StreamIcon from './Icons/StreamIcon';
import InboxIcon from './Icons/InboxIcon';
import AudiencesIcon from './Icons/AudiencesIcon';
import OrganizationIcon from './Icons/OrganizationIcon';
import OrganizationMenu from './OrganizationMenu/OrganizationMenu';
import css from './DashboardWrapper.module.css';

const INBOX_TAB_PAGES = ['InboxPage', 'QuickRepliesPage'];
const ORGANIZATION_SETTINGS_PAGES = [
  'OrganizationSettingsPage',
  'PaymentHistoryPage',
  'TeamPermissionPage',
];

const Tab = props => {
  const { tab } = props;

  const sideNavItemClasses = classNames(css.sideNavItem, {
    [css.sideNavItemActive]: tab.selected,
    [css.sideNavItemDisabled]: tab.disabled,
  });

  const tabProps = {
    key: tab.id,
    className: sideNavItemClasses,
    title: tab.text,
    ...tab.linkProps,
  };
  return tab.disabled ? (
    <span key={tab.id} className={sideNavItemClasses} title={tab.text}>
      {tab.icon}
      {tab.text}
    </span>
  ) : tab.linkDisabled ? (
    <span key={tab.id} className={sideNavItemClasses} title={tab.text}>
      {tab.icon}
      {tab.text}
    </span>
  ) : (
    <NamedLink {...tabProps}>
      {tab.icon}
      {tab.text}
    </NamedLink>
  );
};

const DashboardWrapperComponent = props => {
  const {
    rootClassName,
    className,
    intl,
    children,
    currentPage,
    currentUserLoaded,
    currentUserHasListings,
    organizationProfiles,
    onSelectOrganizationProfile,
    selectOrganizationProfileInProgress,
    selectOrganizationProfileError,
    selectedOrganizationProfileId,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const selectedOrganization = getSelectedOrganizationProfile(
    organizationProfiles,
    selectedOrganizationProfileId
  );

  const showOrganizationMenu = organizationProfiles && selectedOrganization;

  const tabs = [
    {
      text: intl.formatMessage({ id: 'DashboardWrapper.listingsTabTitle' }),
      selected: currentPage === 'ManageListingsPage',
      id: 'ManageListingsPage',
      icon: <CalendarIcon />,
      linkProps: {
        name: 'ManageListingsPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'DashboardWrapper.streamTabTitle' }),
      selected: currentPage === 'StreamPage',
      id: 'StreamPageTab',
      icon: <StreamIcon />,
      linkProps: {
        name: 'StreamPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'DashboardWrapper.inboxTabTitle' }),
      selected: INBOX_TAB_PAGES.includes(currentPage),
      id: 'InboxPageTab',
      icon: <InboxIcon />,
      linkProps: {
        name: 'InboxPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'DashboardWrapper.audienceTabTitle' }),
      selected: currentPage === 'AnalyticsPage' || currentPage === 'MembersPage',
      id: 'AnalyticsPageTab',
      icon: <AudiencesIcon />,
      disabled: false,
      linkProps: {
        name: 'AnalyticsPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'DashboardWrapper.organizationSettingsTabTitle' }),
      selected: ORGANIZATION_SETTINGS_PAGES.includes(currentPage),
      id: 'OrganizationSettingsPageTab',
      icon: <OrganizationIcon />,
      linkProps: {
        name: 'OrganizationSettingsPage',
      },
      linkDisabled: ORGANIZATION_SETTINGS_PAGES.includes(currentPage),
    },
  ];

  if (currentUserLoaded && !currentUserHasListings && !DASHBOARD_PAGES.includes(currentPage)) {
    return <NamedRedirect name="LandingPage" />;
  }

  return (
    <div className={classes}>
      <div className={css.sideNav}>
        {showOrganizationMenu ? (
          <OrganizationMenu
            currentPage={currentPage}
            organizationProfiles={organizationProfiles}
            selectedOrganization={selectedOrganization}
            onSelectOrganizationProfile={onSelectOrganizationProfile}
            selectOrganizationProfileInProgress={selectOrganizationProfileInProgress}
            selectOrganizationProfileError={selectOrganizationProfileError}
          />
        ) : null}
        <div className={css.sideNavItems}>
          {tabs.map(tab => {
            return <Tab key={tab.id} tab={tab} />;
          })}
        </div>
      </div>
      <div className={css.sideContent}>{children}</div>
    </div>
  );
};

DashboardWrapperComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  currentPage: null,

  currentUserHasListings: false,
  selectedOrganization: null,
  organizationProfiles: [],
  selectOrganizationProfileInProgress: false,
  selectOrganizationProfileError: null,
  selectedOrganizationProfileId: null,
};

DashboardWrapperComponent.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  currentPage: string,
  currentUserHasListings: bool.isRequired,
  selectedOrganization: oneOfType([propTypes.organization, propTypes.ownOrganization]),
  organizationProfiles: arrayOf(propTypes.organization),
  selectOrganizationProfileInProgress: bool.isRequired,
  selectOrganizationProfileError: propTypes.error,
  selectedOrganizationProfileId: string,
  onSelectOrganizationProfile: func.isRequired,

  // from withRouter
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserHasListings } = state.user;
  const {
    organizationProfiles,
    selectOrganizationProfileInProgress,
    selectOrganizationProfileError,
  } = state.organizations;

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!ensuredCurrentUser.id;

  return {
    currentUser,
    currentUserLoaded,
    currentUserHasListings,
    organizationProfiles,
    selectOrganizationProfileInProgress,
    selectOrganizationProfileError,
    selectedOrganizationProfileId: getSelectedOrganizationProfileIdFromExtendedData(currentUser),
  };
};

const mapDispatchToProps = dispatch => ({
  onSelectOrganizationProfile: organizationProfileId =>
    dispatch(selectOrganizationProfile(organizationProfileId)),
});

const DashboardWrapper = compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(DashboardWrapperComponent);

export default DashboardWrapper;
