import config from '../../config';
import { integrationAPI } from '../../util/api';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import {
  filterPendingInvites,
  findPendingInvite,
  formatOrganizationIds,
  updateArrayWithRecipientOrSenderInfo,
} from './VerifyOrganizationInvitePage.shared';

// ================ Action types ================ //

export const VERIFY_INVITE_REQUEST = 'app/VerifyOrganizationInvitePage/VERIFY_INVITE_REQUEST';
export const VERIFY_INVITE_SUCCESS = 'app/VerifyOrganizationInvitePage/VERIFY_INVITE_SUCCESS';
export const VERIFY_INVITE_ERROR = 'app/VerifyOrganizationInvitePage/VERIFY_INVITE_ERROR';

export const VERIFICATION_INVALID_OR_EXPIRED_ERROR =
  'app/VerifyOrganizationInvitePage/VERIFICATION_INVALID_OR_EXPIRED_ERROR';
export const INVALID_PARAMS_ERROR = 'app/VerifyOrganizationInvitePage/INVALID_PARAMS_ERROR';

// ================ Reducer ================ //

const initialState = {
  verifyInviteInProgress: false,
  verifyInviteError: null,
  verificationInvalidOrExpiredError: null,
  invalidParamsError: null,
  isVerified: false,
};

export default function VerifyOrganizationInvitePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case VERIFY_INVITE_REQUEST:
      return {
        ...state,
        verifyInviteInProgress: true,
        verifyInviteError: null,
        isVerified: false,
      };
    case VERIFY_INVITE_SUCCESS:
      return {
        ...state,
        verifyInviteInProgress: false,
        verifyInviteError: null,
        isVerified: true,
      };
    case VERIFY_INVITE_ERROR:
      return {
        ...state,
        verifyInviteInProgress: false,
        verifyInviteError: payload,
        isVerified: false,
      };

    case VERIFICATION_INVALID_OR_EXPIRED_ERROR:
      return {
        ...state,
        verificationInvalidOrExpiredError: payload,
      };
    case INVALID_PARAMS_ERROR:
      return {
        ...state,
        invalidParamsError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const verifyInviteRequest = () => ({
  type: VERIFY_INVITE_REQUEST,
});
export const verifyInviteSuccess = () => ({
  type: VERIFY_INVITE_SUCCESS,
});
export const verifyInviteError = e => ({
  type: VERIFY_INVITE_ERROR,
  error: true,
  payload: e,
});

export const verificationInvalidOrExpiredError = e => ({
  type: VERIFICATION_INVALID_OR_EXPIRED_ERROR,
  error: true,
  payload: e,
});
export const invalidParamsError = e => ({
  type: INVALID_PARAMS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

/**
 * Requests an update to a pending invite for a recipient, updating their organizations in public data.
 */
export const requestUpdatePendingInviteForRecipient = urlParams => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    const { senderId, organizationIds: nonFormattedOrganizationIds } = urlParams;

    // Format the organization IDs into an array.
    const organizationIds = formatOrganizationIds(nonFormattedOrganizationIds);

    const response = await sdk.currentUser.show();
    const currentUser = response.data.data;

    // Get the list of organizations from the user's public data.
    const userOrganizations = currentUser.attributes.profile.publicData.organizations;

    // Update the organizations with the recipient or sender information.
    const updatedOrganizations = updateArrayWithRecipientOrSenderInfo(
      userOrganizations,
      senderId,
      organizationIds
    );

    await sdk.currentUser.updateProfile({
      publicData: {
        role: config.userRoles['artist'],
        organizations: updatedOrganizations,
        selectedOrganizationProfileId: organizationIds[0],
      },
    });

    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Requests an update to a pending invite for a sender, updating their team and pending invites.
 */
export const requestUpdatePendingInviteForSender = (urlParams, pendingInvites) => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    const { senderId, recipientId } = urlParams;

    // Format the organization IDs into an array.
    const organizationIds = formatOrganizationIds(urlParams.organizationIds);

    // Find current pending invite
    const currentPendingInvite =
      findPendingInvite(pendingInvites, recipientId, urlParams.organizationIds) ||
      findPendingInvite(
        pendingInvites,
        getState().user.currentUser.id.uuid,
        urlParams.organizationIds
      );

    // Filter pending invites based on recipient and organization criteria.
    const filteredPendingInvites = filterPendingInvites(
      pendingInvites,
      currentPendingInvite.id,
      organizationIds
    );

    const response = await sdk.users.show({ id: senderId });
    const user = response.data.data;

    // Get the team data from the sender's public data.
    const teamFromPublicData = user.attributes.profile.publicData.team;

    // Update the team data with recipient or sender information.
    const updatedTeam = updateArrayWithRecipientOrSenderInfo(
      teamFromPublicData,
      getState().user.currentUser.id.uuid,
      organizationIds
    );

    await integrationAPI.users.updateProfile({
      id: senderId,
      publicData: {
        team: updatedTeam,
        pendingInvites: filteredPendingInvites,
      },
    });

    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

const validateInvalidOrExpiredVerification = (pendingInvites, urlParams) => async (
  dispatch,
  getState,
  sdk
) => {
  const { recipientId, organizationIds } = urlParams;

  let { currentUser } = getState().user;
  if (!currentUser) {
    const currentUserResponse = await sdk.currentUser.show();
    currentUser = currentUserResponse.data.data;
  }

  // Check if current pending invite exists
  const currentPendingInvite =
    findPendingInvite(pendingInvites, recipientId, organizationIds) ||
    findPendingInvite(pendingInvites, currentUser.id.uuid, organizationIds);

  // Check if currentUser is he same as invited user
  const isSameUser = currentPendingInvite?.email === currentUser.attributes.email;

  if (!currentPendingInvite || !isSameUser) {
    const errorMessage = 'Verification invalid or expired.';

    // Throw error
    dispatch(verificationInvalidOrExpiredError({ message: errorMessage }));
    throw new Error(errorMessage);
  }
};

/**
 * Throw error if params are invalid.
 */
const validateVerificationParams = urlParams => dispatch => {
  const { senderId, recipientId, organizationIds } = urlParams || {};

  if (!senderId || !recipientId || !organizationIds) {
    const errorMessage = 'Invalid params error';
    console.error(errorMessage, urlParams);

    // Throw error
    dispatch(invalidParamsError({ message: errorMessage }));
    throw new Error(errorMessage);
  }
};

/**
 * Verifies an invite by updating sender's and recipient's data.
 */
export const verifyInvite = urlParams => async (dispatch, getState, sdk) => {
  dispatch(validateVerificationParams(urlParams));
  dispatch(verifyInviteRequest());

  try {
    const { senderId } = urlParams;

    const response = await sdk.users.show({ id: senderId });
    const user = response.data.data;

    // Get the pending invites from the sender's public data.
    const pendingInvites = user.attributes.profile.publicData.pendingInvites;

    // Validate expired verifications
    await dispatch(validateInvalidOrExpiredVerification(pendingInvites, urlParams));

    // Dispatch action to update sender's data using the function.
    await dispatch(requestUpdatePendingInviteForSender(urlParams, pendingInvites));

    // If the first function was successful, then proceed to the second function.
    await dispatch(requestUpdatePendingInviteForRecipient(urlParams));

    // Dispatch an action to indicate the verification process is successful.
    return dispatch(verifyInviteSuccess());
  } catch (error) {
    dispatch(verifyInviteError(storableError(error)));
  }
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const urlParams = parse(search);
  return dispatch(verifyInvite(urlParams));
};
