import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaCommunity.module.css';

const IconSocialMediaCommunity = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
      <path
        fill="#fff"
        d="M7 0a7 7 0 1 1 0 14H1.4A1.4 1.4 0 0 1 0 12.6V7a7 7 0 0 1 7-7Zm0 8.4H4.9a.7.7 0 1 0 0 1.4H7a.7.7 0 1 0 0-1.4Zm2.1-2.8H4.9a.7.7 0 0 0-.082 1.395L4.9 7h4.2a.7.7 0 0 0 .082-1.395L9.1 5.6Z"
      />
    </svg>
  );
};

IconSocialMediaCommunity.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaCommunity.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaCommunity;
