import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { EditListingTicketsForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingTicketsPanel.module.css';

const { Money } = sdkTypes;

const EditListingTicketsPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    onRedirectToPreviousTab,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { title, price, publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingTicketsPanel.title"
      values={{
        listingTitle: (
          <ListingLink className={css.listingLink} listing={listing}>
            {title}
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingTicketsPanel.createListingTitle" />
  );

  const form = (
    <EditListingTicketsForm
      className={css.form}
      initialValues={{
        price: price ? new Money(price.amount, price.currency) : null,
        currency: publicData.currency || config.currency,
        ticketLink: publicData?.ticketLink,
        ticketAvailability: publicData?.ticketAvailability || 'available',
      }}
      onSubmit={values => {
        const { price, currency, ticketLink, ticketAvailability } = values;

        const updatedValues = {
          price: { amount: price.amount, currency: currency || price.currency },
          publicData: { currency, ticketLink, ticketAvailability },
        };

        onSubmit(updatedValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      listing={currentListing}
      isPublished={isPublished}
      panelTitle={panelTitle}
      onRedirectToPreviousTab={onRedirectToPreviousTab}
    />
  );

  return <div className={classes}>{form}</div>;
};

const { func, object, string, bool } = PropTypes;

EditListingTicketsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingTicketsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingTicketsPanel;
