import { propTypes } from './types';
import { createSlug } from './urlHelpers';
import { getFormUserOrganizationProfiles } from './organizations';

export const TYPE_ORGANIZATION = 'organization';
export const TYPE_PROJECT = 'project';
export const TYPE_LIVE_ROOM = 'live-room';

/**
 * Create new listing invite for redux store.
 *
 * @param {object} invitedUserId
 * @param {array} isPending
 *
 * @return {object} new listing invite
 */
export const createNewListingInvite = (
  invitedUserId,
  email,
  formValues,
  isPending = true,
  existing = false
) => ({
  id: invitedUserId,
  email,
  pending: isPending,
  existing,
  ...formValues,
});

/**
 * Create new organization invite for redux store.
 *
 * @param {object} currentUser
 * @param {array} organizationIds
 *
 * @return {object} new organization invite
 */
export const createNewOrganizationInvite = (user, organizationIds) => ({
  id: user.id.uuid,
  email: user.attributes.email,
  existing: !!user.type,
  organizationIds,
});

/**
 * Create listing invite email params.
 *
 * @param {propTypes.currentUser} currentUser
 * @param {propTypes.user} invitedUser
 * @param {propTypes.listing} currentListing
 *
 * @return {object} listing email params
 */
export const createListingInviteEmailParams = (
  currentUser,
  invitedUser,
  currentListing,
  formValues,
  invitedUserExists
) => {
  const sender = {
    id: currentUser.id.uuid,
    email: currentUser.attributes.email,
    name: currentUser.attributes.profile.displayName,
  };

  const recipientNameMaybe = invitedUserExists
    ? { name: invitedUser.attributes.profile.displayName }
    : {};

  const recipient = {
    id: invitedUser.id.uuid,
    email: invitedUser.attributes.email,
    ...recipientNameMaybe,
  };

  const listing = {
    id: currentListing.id.uuid,
    slug: createSlug(currentListing.attributes.title),
    title: currentListing.attributes.title,
    state: currentListing.attributes.state,
    type: currentListing.attributes.publicData.type,
  };

  return {
    sender,
    recipient,
    listing,
    formValues,
  };
};

/**
 * Create organization invitation email params.
 *
 * @param {object} currentUser
 * @param {object} invitedUser
 * @param {array} organizations
 *
 * @return {object} email params
 */
export const createOrganizationInviteEmailParams = (
  currentUser,
  invitedUser,
  organizations,
  invitedUserExists
) => {
  const sender = {
    id: currentUser.id.uuid,
    email: currentUser.attributes.email,
    name: currentUser.attributes.profile.displayName,
  };

  const recipientNameMaybe = invitedUserExists
    ? { name: invitedUser.attributes.profile.displayName }
    : {};
  const recipient = {
    id: invitedUser.id.uuid,
    email: invitedUser.attributes.email,
    ...recipientNameMaybe,
  };

  return {
    sender,
    recipient,
    organizations,
  };
};

/**
 * Filter results users from payload.
 *
 * @param {array} userIds
 * @param {array} payloadIds
 *
 * @return {array} user entities
 */
export const filterPayloadUsers = (userIds, payloadIds) => {
  if (!userIds || !payloadIds) {
    return [];
  }

  return userIds.filter(u => payloadIds.includes(u.id.uuid));
};

/**
 * Returns the organization profile data based on the organization ids.
 *
 * @param {object} currentUser
 * @param {array} organizationIds
 *
 * @return {array} organization profiles
 */
export const organizationIdsToOrganizations = (currentUser, organizationIds) => {
  const organizationProfiles = getFormUserOrganizationProfiles(currentUser);
  const organizations = organizationIds.map(o => {
    const organization = organizationProfiles.find(p => p.key === o);
    return organization;
  });

  return organizations;
};

/**
 * Save array to publicData. Null will remove the extended data value
 * if the array is empty.
 *
 * @param {Array} array
 *
 * @return {Array|Null}
 */
export const saveArrayToExtendedData = array => (array.length > 0 ? array : null);

/**
 * Create user entity from the payload data.
 *
 * @param {object} data response data
 *
 * @return {object} user entity
 */
export const userEntity = data => ({
  id: data.id,
  type: data.type || 'user',
});
