import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconGrid.module.css';

const IconGrid = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 17"
      aria-hidden="true"
      height="12"
      width="12"
    >
      <circle cx={1.5} cy={1.5} r={1.5} />
      <circle cx={1.5} cy={8.5} r={1.5} />
      <circle cx={8.5} cy={1.5} r={1.5} />
      <circle cx={8.5} cy={8.5} r={1.5} />
      <circle cx={15.5} cy={1.5} r={1.5} />
      <circle cx={15.5} cy={8.5} r={1.5} />
      <circle cx={1.5} cy={15.5} r={1.5} />
      <circle cx={8.5} cy={15.5} r={1.5} />
      <circle cx={15.5} cy={15.5} r={1.5} />
    </svg>
  );
};

const { string } = PropTypes;

IconGrid.defaultProps = {
  className: null,
  rootClassName: null,
};

IconGrid.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconGrid;
