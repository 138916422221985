import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldSelect, IconSpinner } from '../../components';

import css from './PostForm.module.css';

const ACCEPT_IMAGES = 'image/*';

const PostFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        intl,
        formId,
        invalid,
        submitPostError,
        submitPostInProgress,
        categories,
        form,
        values,
      } = fieldRenderProps;

      const title = intl.formatMessage({ id: 'PostForm.titleLabel' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'PostForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'PostForm.titleRequired',
      });

      const description = intl.formatMessage({ id: 'PostForm.descriptionLabel' });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'PostForm.descriptionPlaceholder',
      });
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'PostForm.descriptionRequired',
      });

      const category = intl.formatMessage({ id: 'PostForm.categoryLabel' });
      const categoryPlaceholderMessage = intl.formatMessage({
        id: 'PostForm.categoryPlaceholder',
      });
      const categoryRequiredMessage = intl.formatMessage({
        id: 'PostForm.categoryRequired',
      });

      const errorMessage = (
        <p className={css.error}>
          <FormattedMessage id="PostForm.postError" />
        </p>
      );

      const errorArea = submitPostError ? errorMessage : null;

      const submitPostMessage = intl.formatMessage({
        id: 'PostForm.postSubmit',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = submitPostInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const uploadingOverlay =
        values.image && submitPostInProgress ? (
          <div className={css.thumbnailLoading}>
            <IconSpinner />
          </div>
        ) : null;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldTextInput
            className={css.title}
            type="text"
            id={formId ? `${formId}.title` : 'title'}
            name="title"
            label={title}
            placeholder={titlePlaceholderMessage}
            validate={required(titleRequiredMessage)}
          />

          <FieldTextInput
            className={css.description}
            type="textarea"
            id={formId ? `${formId}.description` : 'description'}
            name="description"
            label={description}
            placeholder={descriptionPlaceholderMessage}
            validate={required(descriptionRequiredMessage)}
          />

          <FieldSelect
            className={css.category}
            name="category"
            id="category"
            label={category}
            validate={required(categoryRequiredMessage)}
          >
            <option disabled value="">
              {categoryPlaceholderMessage}
            </option>

            {categories.map(c => {
              return (
                <option key={c.key} value={c.key}>
                  {c.label}
                </option>
              );
            })}
          </FieldSelect>

          <Field
            id="image"
            name="image"
            accept={ACCEPT_IMAGES}
            form={null}
            label="Image"
            type="file"
            disabled={false}
          >
            {fieldprops => {
              const { accept, input, label, disabled: fieldDisabled } = fieldprops;
              const { name, type } = input;
              const onChange = e => {
                const file = e.target.files[0];
                form.change(`image`, file);
                form.blur(`image`);
              };
              const inputProps = { accept, id: name, name, onChange, type };
              return (
                <div className={css.addDocumentWrapper}>
                  <div className={css.aspectRatioWrapper}>
                    {fieldDisabled ? null : (
                      <input {...inputProps} className={css.addDocumentInput} />
                    )}
                    <label htmlFor={name} className={css.addDocument}>
                      {values.image ? values.image.name : label}
                    </label>
                    {uploadingOverlay}
                  </div>
                </div>
              );
            }}
          </Field>

          {errorArea}
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
          >
            {submitPostMessage}
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

PostFormComponent.defaultProps = { className: null, rootClassName: null, submitPostError: null };

const { bool, func, string } = PropTypes;

PostFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  submitPostError: propTypes.error,
  submitPostInProgress: bool.isRequired,
};

const PostForm = compose(injectIntl)(PostFormComponent);
PostForm.displayName = 'PostForm';

export default PostForm;
