import React from 'react';
import { bool } from 'prop-types';
import { InlineTextButton } from '../../components';

import { IconPlay } from './VideoIcons';
import css from './VideoPlayer.module.css';

const BigPlayButton = props => {
  const { show } = props;
  return show ? (
    <InlineTextButton className={css.bigPlayButton} type="button">
      <IconPlay className={css.bigPlayIcon} />
    </InlineTextButton>
  ) : null;
};

BigPlayButton.defaultProps = {
  show: false,
};

BigPlayButton.propTypes = {
  show: bool.isRequired,
};

export default BigPlayButton;
