import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { isTransactionsTransitionAlreadyReviewed } from '../../util/errors';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import { FieldReviewRating, Form, PrimaryButton } from '../../components';

import css from './ShowRatingForm.module.css';

const STAR_RATING_COUNT = 10;

const ShowRatingFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        intl,
        formId,
        invalid,
        ratingSubmitted,
        sendRatingError,
        sendRatingInProgress,
      } = fieldRenderProps;

      const reviewRating = intl.formatMessage({ id: 'ShowRatingForm.reviewRatingLabel' });
      const reviewRatingRequiredMessage = intl.formatMessage({
        id: 'ShowRatingForm.reviewRatingRequired',
      });

      const errorMessage = isTransactionsTransitionAlreadyReviewed(sendRatingError) ? (
        <p className={css.error}>
          <FormattedMessage id="ShowRatingForm.reviewSubmitAlreadySent" />
        </p>
      ) : (
        <p className={css.error}>
          <FormattedMessage id="ShowRatingForm.reviewSubmitFailed" />
        </p>
      );
      const errorArea = sendRatingError ? errorMessage : null;

      const reviewSubmitMessage = intl.formatMessage({
        id: 'ShowRatingForm.reviewSubmit',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = sendRatingInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldReviewRating
            className={css.reviewRating}
            id={formId ? `${formId}.starRating` : 'starRating'}
            name="reviewRating"
            label={reviewRating}
            validate={required(reviewRatingRequiredMessage)}
            ratingCount={STAR_RATING_COUNT}
          />

          {errorArea}
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={ratingSubmitted}
          >
            {reviewSubmitMessage}
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

ShowRatingFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
  sendRatingError: null,
};

const { bool, func, string } = PropTypes;

ShowRatingFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  ratingSubmitted: bool.isRequired,
  sendRatingError: propTypes.error,
  sendRatingInProgress: bool.isRequired,
};

const ShowRatingForm = compose(injectIntl)(ShowRatingFormComponent);
ShowRatingForm.displayName = 'ShowRatingForm';

export default ShowRatingForm;
