import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconLock.module.css';

const IconLock = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      height="8"
      width="8"
    >
      <path d="M10.25 4a2.25 2.25 0 0 0-4.495-.154L5.75 4v2h-1.5V4a3.75 3.75 0 0 1 7.495-.2l.005.2v2H13a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h7.25V4z" />
    </svg>
  );
};

const { string } = PropTypes;

IconLock.defaultProps = {
  className: null,
  rootClassName: null,
};

IconLock.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconLock;
