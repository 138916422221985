import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import classNames from 'classnames';

import css from './BookingMessageForm.module.css';

const BookingMessageFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        authorDisplayName,
        intl,
      } = fieldRenderProps;

      const messagePlaceholder = intl.formatMessage(
        { id: 'BookingMessageForm.messagePlaceholder' },
        { name: authorDisplayName }
      );

      const messageOptionalText = intl.formatMessage({
        id: 'BookingMessageForm.messageOptionalText',
      });

      const initialMessageLabel = intl.formatMessage(
        { id: 'BookingMessageForm.messageLabel' },
        { messageOptionalText: messageOptionalText }
      );

      const bookingInfo = intl.formatMessage({ id: 'BookingMessageForm.bookingInfo' });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldTextInput
            type="text"
            id={`${formId}-message`}
            name="initialMessage"
            className={css.message}
            label={initialMessageLabel}
            placeholder={messagePlaceholder}
          />

          <div className={css.submitContainer}>
            <p className={css.bookingInfo}>{bookingInfo}</p>
            <PrimaryButton
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage id="BookingMessageForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

BookingMessageFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  authorDisplayName: null,
};

BookingMessageFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  inProgress: bool,
  authorDisplayName: string.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BookingMessageForm = compose(injectIntl)(BookingMessageFormComponent);

BookingMessageForm.displayName = 'BookingMessageForm';

export default BookingMessageForm;
