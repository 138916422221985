import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LinkTabNavHorizontal } from '..';
import { propTypes } from '../../util/types';
import classNames from 'classnames';

import css from './DashboardNav.module.css';

const DashboardNav = props => {
  const { className, rootClassName, tabs } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <LinkTabNavHorizontal
      className={classes}
      tabs={tabs}
      tabContentClassName={css.tabContent}
      selectedTabContentClassName={css.selectedTabContent}
    />
  );
};

DashboardNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string, object } = PropTypes;

DashboardNav.propTypes = {
  className: string,
  rootClassName: string,
  currentUser: propTypes.currentUser,

  // from withRouter
  location: object.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps)
)(DashboardNav);
