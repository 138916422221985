import React from 'react';

const PlusIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" {...props}>
    <path
      fill="#fff"
      d="M13 8H8v5c0 .55-.45 1-1 1s-1-.45-1-1V8H1c-.55 0-1-.45-1-1s.45-1 1-1h5V1c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1Z"
    />
  </svg>
);

export default PlusIcon;
