import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { ensureCurrentUser } from '../../util/data';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldSelect, FieldCheckbox } from '../../components';

import css from './InviteTeamMemberForm.module.css';

const START_YEAR = 1950;
const END_YEAR = new Date().getFullYear();

const generateYearsArray = (startYear = START_YEAR, endYear = END_YEAR) => {
  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push({ key: year.toString(), label: year.toString() });
  }
  return years;
};

const InviteTeamMemberFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        intl,
        formId,
        invalid,
        values,
        currentUser,
        currentOrganizationProfile,
        invitedUser,
        team,
        inviteSent,
        sendInviteError,
        sendInviteInProgress,
        creditRolesConfigOptions,
      } = fieldRenderProps;

      const ensuredCurrentUser = ensureCurrentUser(currentUser);
      const { email, profile } = ensuredCurrentUser.attributes;

      // Check if the invited user has a pending invitation to the current organization.
      const pendingInvites = profile?.publicData?.pendingInvites;
      const isOrganizationInvitePending = !!pendingInvites?.find(
        o =>
          o.id === invitedUser?.id?.uuid &&
          o.organizationIds.includes(currentOrganizationProfile?.id)
      );

      // Check if invited user is already a part of the current organization.
      const invitedUserEmails = team?.map(u => u.attributes.email);
      const invitedUserOrganizations = invitedUser?.attributes?.profile?.publicData?.organizations;
      const isOrganizationInviteAccepted = !!invitedUserOrganizations?.find(o =>
        o.organizationIds.includes(currentOrganizationProfile?.id)
      );

      const isAlreadyInvitedToOrganization =
        isOrganizationInviteAccepted || isOrganizationInvitePending;

      // email
      const emailLabel = intl.formatMessage({
        id: 'InviteTeamMemberForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'InviteTeamMemberForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'InviteTeamMemberForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'InviteTeamMemberForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);
      const ownEmailMessage = intl.formatMessage({
        id: 'InviteTeamMemberForm.ownEmail',
      });
      const ownEmailValidation = validators.ownEmail(ownEmailMessage, email);
      const uniqueEmailMessage = intl.formatMessage({
        id: 'InviteTeamMemberForm.uniqueEmail',
      });
      const uniqueEmailValidation = validators.uniqueEmail(uniqueEmailMessage, invitedUserEmails);

      // Credit
      const creditLabel = intl.formatMessage({
        id: 'InviteTeamMemberForm.creditLabel',
      });
      const creditPlaceholder = intl.formatMessage({
        id: 'InviteTeamMemberForm.creditPlaceholder',
      });
      const creditRequiredMessage = intl.formatMessage({
        id: 'InviteTeamMemberForm.creditRequired',
      });

      // Start year
      const startYearLabel = intl.formatMessage({
        id: 'InviteTeamMemberForm.startYear',
      });
      const startYearPlaceholder = intl.formatMessage({
        id: 'InviteTeamMemberForm.startYearPlaceholder',
      });
      const startYearRequiredMessage = intl.formatMessage({
        id: 'InviteTeamMemberForm.startYearRequired',
      });

      // End year
      const endYearLabel = intl.formatMessage({
        id: 'InviteTeamMemberForm.endYear',
      });
      const endYearPlaceholder = intl.formatMessage({
        id: 'InviteTeamMemberForm.endYearPlaceholder',
      });
      const endYearRequiredMessage = intl.formatMessage({
        id: 'InviteTeamMemberForm.endYearRequired',
      });

      // invite
      const inviteLabel = intl.formatMessage({
        id: 'InviteTeamMemberForm.invite',
      });
      const inviteValue = intl.formatMessage({
        id: 'InviteTeamMemberForm.inviteValue',
      });

      const errorMessage = (
        <p className={css.error}>
          <FormattedMessage id="InviteTeamMemberForm.inviteSubmitFailed" />
        </p>
      );
      const errorArea = sendInviteError ? errorMessage : null;

      const inviteSubmitMessage = intl.formatMessage({
        id: 'InviteTeamMemberForm.inviteSubmit',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = sendInviteInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const showInviteToOrganizationField =
        !currentOrganizationProfile?.invited && !isAlreadyInvitedToOrganization;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.email}>
            <label>{emailLabel}</label>
            <div className={css.inputWrapper}>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                className={css.emailInput}
                autoComplete="email"
                placeholder={emailPlaceholder}
                validate={validators.composeValidators(
                  emailRequired,
                  emailValid,
                  ownEmailValidation,
                  uniqueEmailValidation
                )}
              />
            </div>
          </div>

          <FieldSelect
            id={formId ? `${formId}.credit` : 'credit'}
            name={formId ? `${formId}.credit` : 'credit'}
            className={css.creditRole}
            label={creditLabel}
            validate={validators.required(creditRequiredMessage)}
          >
            <option value="" disabled>
              {creditPlaceholder}
            </option>
            {creditRolesConfigOptions.map(o => {
              return (
                <option key={o.value} value={o.label}>
                  {o.label}
                </option>
              );
            })}
          </FieldSelect>

          <div className={css.yearFields}>
            <FieldSelect
              className={css.yearField}
              id={formId ? `${formId}.year` : 'year'}
              name={formId ? `${formId}.year.start` : 'year.start'}
              label={startYearLabel}
              validate={validators.required(startYearRequiredMessage)}
            >
              <option disabled value="">
                {startYearPlaceholder}
              </option>

              {generateYearsArray(START_YEAR, END_YEAR).map(year => (
                <option key={year.key} value={year.key}>
                  {year.label}
                </option>
              ))}
            </FieldSelect>

            <FieldSelect
              className={css.yearField}
              id={formId ? `${formId}.end` : 'end'}
              name={formId ? `${formId}.year.end` : 'year.end'}
              label={endYearLabel}
              validate={validators.required(endYearRequiredMessage)}
            >
              <option disabled value="">
                {endYearPlaceholder}
              </option>

              {generateYearsArray(START_YEAR, END_YEAR).map(year => (
                <option
                  key={year.key}
                  value={year.key}
                  disabled={parseInt(year.key) < parseInt(values?.year?.start)}
                >
                  {year.label}
                </option>
              ))}
            </FieldSelect>
          </div>

          {showInviteToOrganizationField ? (
            <div className={css.checkboxInputContainer}>
              <FieldCheckbox
                id={formId ? `${formId}.inviteToOrganization` : 'inviteToOrganization'}
                name={formId ? `${formId}.inviteToOrganization` : 'inviteToOrganization'}
                className={css.checkboxInput}
                label={inviteLabel}
                value={inviteValue}
                useVariationColor
              />
              <p className={css.checkboxInputSmallText}>
                <FormattedMessage id="InviteTeamMemberForm.inviteSmallText" />
              </p>
            </div>
          ) : null}

          {errorArea}
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            inProgressClassName={css.submitButtonInProgress}
            disabled={submitDisabled}
            ready={inviteSent}
          >
            {inviteSubmitMessage}
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

InviteTeamMemberFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
  sendInviteError: null,
};

const { bool, func, string, arrayOf } = PropTypes;

InviteTeamMemberFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,

  currentUser: propTypes.currentUser,

  pendingInviteUsers: arrayOf(propTypes.user),
  sendInviteError: propTypes.error,
  sendInviteInProgress: bool.isRequired,
};

const InviteTeamMemberForm = compose(injectIntl)(InviteTeamMemberFormComponent);
InviteTeamMemberForm.displayName = 'InviteTeamMemberForm';

export default InviteTeamMemberForm;
