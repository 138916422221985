import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconTrash.module.css';

const IconTrash = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="24"
      width="24"
    >
      <g fill="none" stroke="#0e0f0c" strokeLinecap="round" strokeLinejoin="round">
        <path d="M1 3.5h12M2.5 3.5h9v9a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1v-9h0ZM4.5 3.5V3a2.5 2.5 0 0 1 5 0v.5M5.5 5.5V11M8.5 5.5V11" />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconTrash.defaultProps = {
  className: null,
  rootClassName: null,
};

IconTrash.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconTrash;
