import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconLaptop.module.css';

const IconLaptop = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
      <path
        d="m5.714 18.286-4.068 5.828A2.286 2.286 0 0 0 1.76 26.4a2.286 2.286 0 0 0 1.943 1.074h24.594A2.286 2.286 0 0 0 30.24 26.4a2.286 2.286 0 0 0 0-2.286l-3.954-5.828"
        stroke="#363636"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4.571a2.286 2.286 0 0 0-2.286 2.286v11.429h20.572V6.857A2.286 2.286 0 0 0 24 4.571H8Z"
        stroke="#363636"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconLaptop.defaultProps = {
  className: null,
  rootClassName: null,
};

IconLaptop.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconLaptop;
