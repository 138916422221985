import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import {
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  InlineTextButton,
  IconMenu,
} from '../../components';
import classNames from 'classnames';

import css from './ControlMenu.module.css';

// Constants for menu behavior
const USE_ARROW = false;
const CONTENT_POSITION = 'left';
const CONTENT_PLACEMENT_OFFSET = 14;

const ControlMenu = props => {
  const {
    className,
    rootClassName,
    contentPlacementOffset,
    image,
    isMenuOpen,
    onToggleMenu,
    isPosterImage,
    isCoverImage,
    showPosterAndCoverButtons,
    promoteImageInProgress,
    promoteImageError,
    onRemoveImage,
    onPromoteImage,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const promoteButtonDisabled = promoteImageInProgress || !!promoteImageError;

  // Dynamic messages for cover and poster buttons
  const coverMessage = isCoverImage ? (
    <FormattedMessage id="ControlMenu.unsetCover" />
  ) : (
    <FormattedMessage id="ControlMenu.cover" />
  );
  const posterMessage = isPosterImage ? (
    <FormattedMessage id="ControlMenu.unsetPoster" />
  ) : (
    <FormattedMessage id="ControlMenu.poster" />
  );

  return (
    <Menu
      className={classes}
      isOpen={isMenuOpen}
      onToggleActive={isOpen => {
        const menuOpen = isOpen ? image : null;
        onToggleMenu(menuOpen);
      }}
      // settings
      useArrow={USE_ARROW}
      contentPosition={CONTENT_POSITION}
      contentPlacementOffset={contentPlacementOffset}
    >
      <MenuLabel className={css.menuLabel}>
        <IconMenu />
      </MenuLabel>
      <MenuContent className={css.menuContent}>
        <MenuItem
          key="ControlMenu.poster"
          className={css.menuItem}
          isHidden={!showPosterAndCoverButtons}
        >
          <InlineTextButton
            className={css.menuItemButton}
            type="button"
            onClick={() => {
              onPromoteImage('poster');
              onToggleMenu(null);
            }}
            disabled={promoteButtonDisabled}
          >
            {posterMessage}
          </InlineTextButton>
        </MenuItem>

        <MenuItem
          key="ControlMenu.cover"
          className={css.menuItem}
          isHidden={!showPosterAndCoverButtons}
        >
          <InlineTextButton
            className={css.menuItemButton}
            type="button"
            onClick={() => {
              onPromoteImage('cover');
              onToggleMenu(null);
            }}
            disabled={promoteButtonDisabled}
          >
            {coverMessage}
          </InlineTextButton>
        </MenuItem>
        <MenuItem key="ControlMenu.delete" className={css.menuItem}>
          <InlineTextButton className={css.menuItemButton} type="button" onClick={onRemoveImage}>
            <FormattedMessage id="ControlMenu.delete" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

ControlMenu.defaultProps = {
  className: null,
  rootClassName: null,
  contentPlacementOffset: CONTENT_PLACEMENT_OFFSET,
  promoteImageInProgress: false,
  promoteImageError: null,
  showPosterAndCoverButtons: true,
  isPosterImage: false,
  isCoverImage: false,
  onRemoveImage: null,
  onPromoteImage: null,
};

const { func, string, bool, number } = PropTypes;

ControlMenu.propTypes = {
  className: string,
  rootClassName: string,
  image: propTypes.image,
  contentPlacementOffset: number,
  onRemoveImage: func.isRequired,
  onPromoteImage: func,
  promoteImageInProgress: bool,
  promoteImageError: propTypes.error,
  showPosterAndCoverButtons: bool,
  isPosterImage: bool,
  isCoverImage: bool,
};

export default ControlMenu;

export const ControlMenuVideo = props => {
  const {
    className,
    rootClassName,
    contentPlacementOffset,
    video,
    isMenuOpen,
    onToggleMenu,
    onRemoveVideo,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <Menu
      className={classes}
      isOpen={isMenuOpen}
      onToggleActive={isOpen => {
        const menuOpen = isOpen ? video : null;
        onToggleMenu(menuOpen);
      }}
      // settings
      useArrow={USE_ARROW}
      contentPosition={CONTENT_POSITION}
      contentPlacementOffset={contentPlacementOffset}
    >
      <MenuLabel className={css.menuLabel}>
        <IconMenu />
      </MenuLabel>
      <MenuContent className={css.menuContent}>
        <MenuItem key="ControlMenu.delete" className={css.menuItem}>
          <InlineTextButton className={css.menuItemButton} type="button" onClick={onRemoveVideo}>
            <FormattedMessage id="ControlMenu.delete" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

ControlMenuVideo.defaultProps = {
  className: null,
  rootClassName: null,
  contentPlacementOffset: CONTENT_PLACEMENT_OFFSET,
  onRemoveVideo: null,
};

ControlMenuVideo.propTypes = {
  className: string,
  rootClassName: string,
  video: propTypes.video,
  contentPlacementOffset: number,
  onRemoveVideo: func.isRequired,
};
