import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { resetToStartOfDay, getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { requestFetchAvailabilityExceptions } from '../ExperiencePage/ExperiencePage.duck';
import { availabilityExceptionsToEvents } from './StreamPage.helpers';
import { fetchSelectedOrganization } from '../../ducks/organizations.duck';
import config from '../../config';

// ================ Action types ================ //

export const QUERY_EVENTS_REQUEST = 'app/StreamPage/QUERY_EVENTS_REQUEST';
export const QUERY_EVENTS_SUCCESS = 'app/StreamPage/QUERY_EVENTS_SUCCESS';
export const QUERY_EVENTS_ERROR = 'app/StreamPage/QUERY_EVENTS_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryEventsInProgress: false,
  queryEventsError: null,
  events: [],
  queryListingIds: [],
};

const resultIds = data => data.data.map(l => l.id);

const streamPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case QUERY_EVENTS_REQUEST:
      return {
        ...state,
        queryEventsInProgress: true,
        queryEventsError: null,
      };
    case QUERY_EVENTS_SUCCESS:
      return {
        ...state,
        events: payload.events,
        queryListingIds: resultIds(payload.data),
        queryEventsInProgress: false,
      };
    case QUERY_EVENTS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryEventsInProgress: false, queryEventsError: payload };

    default:
      return state;
  }
};

export default streamPageReducer;

// ================ Action creators ================ //

export const queryEventsRequest = () => ({
  type: QUERY_EVENTS_REQUEST,
});

export const queryEventsSuccess = (response, events) => ({
  type: QUERY_EVENTS_SUCCESS,
  payload: { data: response.data, events },
});

export const queryEventsError = e => ({
  type: QUERY_EVENTS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const fetchAndCombineAvailabilityExceptions = async (listings, dispatch) => {
  const fetchAvailabilityExceptions = listings.map(async l => {
    const tz = typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

    const today = new Date();
    const start = resetToStartOfDay(today, tz, 0);
    // Query range: today + 364 days
    const exceptionRange = 364;
    const end = resetToStartOfDay(today, tz, exceptionRange);

    // NOTE: in this template, we don't expect more than 100 exceptions.
    // If there are more exceptions, pagination kicks in and we can't use frontend sorting.
    const fetchExceptionsParams = {
      listingId: l.id.uuid,
      start,
      end,
      include: ['listing'],
    };

    // Fetch the availabilityExceptions from an API that belongs
    // to the current mapped listing.
    const availabilityExceptions = await dispatch(
      requestFetchAvailabilityExceptions(fetchExceptionsParams)
    );
    return availabilityExceptions;
  });

  // Promise the availability exceptions so that they are all fetched
  // at the same time, and then combine them all into one array.
  const arrayOfCombinedAvailabilityExceptions = await Promise.all(fetchAvailabilityExceptions);
  const combinedAvailabilityExceptions = arrayOfCombinedAvailabilityExceptions.flat(1);

  return combinedAvailabilityExceptions;
};

export const queryEvents = (organizationId = '') => (dispatch, getState, sdk) => {
  dispatch(queryEventsRequest());

  const params = {
    pub_type: config.listingTypes['experience'],
    pub_organizationId: organizationId ? organizationId : '',
    include: ['author', 'images'],
    'fields.listing': ['title', 'description', 'publicData', 'author', 'createdAt'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': [
      'variants.square-small',
      'variants.landscape-crop',
      'variants.landscape-crop2x',
    ],
    'limit.images': 1,
  };

  return sdk.listings
    .query(params)
    .then(async response => {
      dispatch(addMarketplaceEntities(response));

      const listings = response.data.data;
      const availabilityExceptions = await fetchAndCombineAvailabilityExceptions(
        listings,
        dispatch
      );
      const events = availabilityExceptionsToEvents(availabilityExceptions);
      dispatch(queryEventsSuccess(response, events));

      return events;
    })
    .catch(e => {
      dispatch(queryEventsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  const selectedOrganization = await dispatch(fetchSelectedOrganization());
  const selectedOrganizationId = selectedOrganization ? selectedOrganization.id : '';

  return dispatch(queryEvents(selectedOrganizationId));
};
