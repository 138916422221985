import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { node, number, string, bool, shape, oneOf } from 'prop-types';
import classNames from 'classnames';
import { withViewport } from '../../util/contextHelpers';
import { usePreventHorizontalScroll } from '../../util/hooks';
import {
  totalSlidesFn,
  responsiveSettingsFn,
  slideMaxWidthFn,
  isSafariBrowser,
  SLIDES_TO_SHOW,
  SLIDES_TO_SHOW_TABLET,
  SLIDES_TO_SHOW_DESKTOP,
  SLIDES_TO_SCROLL,
  SLIDES_TO_SCROLL_TABLET,
  SLIDES_TO_SCROLL_DESKTOP,
} from './Slider.helpers';
import Arrows from './Arrows';
import css from './Slider.module.css';

// Slider helpers
const SCROLL_SNAP_ALIGN_START = 'start';
const SCROLL_SNAP_ALIGN_CENTER = 'center';
const SLIDE_DURATION_DEFAULT = 300;
const SLIDE_DURATION_SAFARI = 350;

// Slider settings
const ENABLE_ARROWS = true;
const DEFAULT_SLIDE_INDEX = 0;
const SLIDE_DURATION = isSafariBrowser ? SLIDE_DURATION_DEFAULT : SLIDE_DURATION_SAFARI;

const Slider = props => {
  const {
    className,
    rootClassName,
    headerClassName,
    titleClassName,
    itemsClassName,
    itemClassName,
    arrowsClassName,
    id,
    title,
    arrows,
    slidesToShow,
    slidesToScroll,
    scrollSnapAlign,
    sliderSpacing,
    viewport,
    children,
    autoplay,
    autoplayDelay,
  } = props;

  // Slider refs and states
  const sliderRef = useRef(null);
  const sliderItemRef = useRef(null);
  const [slideIndex, setSlideIndex] = useState(DEFAULT_SLIDE_INDEX);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const totalSlides = useMemo(() => totalSlidesFn(children), [children]);
  const responsiveSettings = useMemo(
    () => responsiveSettingsFn(slidesToShow, slidesToScroll, viewport, id),
    [slidesToShow, slidesToScroll, viewport, id]
  );
  const showSliderHeader = title || arrows;
  const showArrows = totalSlides > responsiveSettings.slidesToShow && arrows;

  // Prevent horizontal scroll with touchpad
  usePreventHorizontalScroll(sliderRef);

  const updateDisabledButtons = useCallback(
    newIndex => {
      const atLastSlide = newIndex >= totalSlides - responsiveSettings.slidesToShow;

      setPrevDisabled(newIndex === DEFAULT_SLIDE_INDEX);
      setNextDisabled(atLastSlide);
    },
    [totalSlides, responsiveSettings.slidesToShow]
  );

  const slideRight = useCallback(() => {
    if (nextDisabled) return;

    const slideIncrement = Math.ceil(responsiveSettings.slidesToScroll);
    const elementWidth = sliderItemRef.current.offsetWidth * slideIncrement;
    const finalScrollLeft = Math.min(
      sliderRef.current.scrollLeft + elementWidth,
      sliderRef.current.scrollWidth - sliderRef.current.clientWidth
    );
    const newSlideIndex = Math.min(
      slideIndex + slideIncrement,
      totalSlides - responsiveSettings.slidesToShow
    );

    setPrevDisabled(true);
    setNextDisabled(true);

    sliderRef.current.scrollTo({ left: finalScrollLeft, behavior: 'smooth' });

    setTimeout(() => {
      setSlideIndex(newSlideIndex);
      updateDisabledButtons(newSlideIndex);
    }, SLIDE_DURATION);
  }, [
    nextDisabled,
    responsiveSettings.slidesToScroll,
    responsiveSettings.slidesToShow,
    slideIndex,
    totalSlides,
    updateDisabledButtons,
  ]);

  const slideLeft = useCallback(() => {
    if (prevDisabled) return;

    const slideIncrement = Math.ceil(responsiveSettings.slidesToScroll);
    const elementWidth = sliderItemRef.current.offsetWidth * slideIncrement;
    const finalScrollLeft = Math.max(sliderRef.current.scrollLeft - elementWidth, 0);
    const newSlideIndex = Math.max(slideIndex - slideIncrement, 0);

    setPrevDisabled(true);
    setNextDisabled(true);

    sliderRef.current.scrollTo({ left: finalScrollLeft, behavior: 'smooth' });

    setTimeout(() => {
      setSlideIndex(newSlideIndex);
      updateDisabledButtons(newSlideIndex);
    }, SLIDE_DURATION);
  }, [prevDisabled, responsiveSettings.slidesToScroll, slideIndex, updateDisabledButtons]);

  useEffect(() => {
    if (!autoplay) return;

    const interval = setInterval(() => {
      if (nextDisabled) {
        setSlideIndex(DEFAULT_SLIDE_INDEX);
        sliderRef.current.scrollTo({ left: 0, behavior: 'smooth' });
        updateDisabledButtons(DEFAULT_SLIDE_INDEX);
      } else {
        slideRight();
      }
    }, autoplayDelay);

    return () => clearInterval(interval);
  }, [autoplay, nextDisabled, autoplayDelay, slideRight, updateDisabledButtons]);

  useEffect(() => {
    setSlideIndex(DEFAULT_SLIDE_INDEX);
    sliderRef.current.scrollTo({ left: 0, behavior: 'smooth' });
    updateDisabledButtons(DEFAULT_SLIDE_INDEX);
    setInitialized(true);
  }, [updateDisabledButtons]);

  useEffect(() => {
    if (autoplay && initialized) {
      const timeout = setTimeout(() => {
        slideRight();
      }, autoplayDelay);

      return () => clearTimeout(timeout);
    }
  }, [autoplay, initialized, autoplayDelay, slideRight]);

  useEffect(() => {
    const handleScroll = () => {
      const elementWidth = sliderItemRef.current.offsetWidth;
      const newSlideIndex = Math.round(sliderRef.current.scrollLeft / elementWidth);
      setSlideIndex(newSlideIndex);
      updateDisabledButtons(newSlideIndex);
    };

    const currentSliderRef = sliderRef.current;
    if (currentSliderRef) {
      currentSliderRef?.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (currentSliderRef) {
        currentSliderRef?.removeEventListener('scroll', handleScroll);
      }
    };
  }, [updateDisabledButtons]);

  const classes = classNames(rootClassName || css.root, className);
  const headerClasses = classNames(css.sliderHeader, headerClassName);
  const titleClasses = classNames(css.title, titleClassName);
  const itemsClasses = classNames(css.sliderItems, itemsClassName, {
    [css.sliderSpacing]: typeof sliderSpacing === 'boolean' && sliderSpacing,
  });
  const itemClasses = classNames(css.sliderItem, itemClassName, {
    [css.scrollSnapCenter]: scrollSnapAlign === SCROLL_SNAP_ALIGN_CENTER,
    [css.scrollSnapStart]: scrollSnapAlign === SCROLL_SNAP_ALIGN_START,
  });

  return (
    <div className={classes}>
      {showSliderHeader && (
        <div className={headerClasses}>
          {title && <h2 className={titleClasses}>{title}</h2>}
          {showArrows && (
            <Arrows
              className={arrowsClassName}
              onSlidePrev={slideLeft}
              onSlideNext={slideRight}
              prevDisabled={prevDisabled}
              nextDisabled={nextDisabled}
            />
          )}
        </div>
      )}
      <div className={itemsClasses} ref={sliderRef}>
        {React.Children.map(children, child => (
          <div
            className={itemClasses}
            ref={sliderItemRef}
            style={{
              maxWidth: slideMaxWidthFn(responsiveSettings.slidesToShow, viewport),
            }}
          >
            {React.cloneElement(child)}
          </div>
        ))}
      </div>
    </div>
  );
};

Slider.defaultProps = {
  className: null,
  rootClassName: null,
  headerClassName: null,
  titleClassName: null,
  itemsClassName: null,
  itemClassName: null,
  arrowsClassName: null,
  id: null,
  title: null,
  arrows: ENABLE_ARROWS,
  slidesToShow: shape({
    mobile: SLIDES_TO_SHOW,
    tablet: SLIDES_TO_SHOW_TABLET,
    desktop: SLIDES_TO_SHOW_DESKTOP,
  }),
  slidesToScroll: shape({
    mobile: SLIDES_TO_SCROLL,
    tablet: SLIDES_TO_SCROLL_TABLET,
    desktop: SLIDES_TO_SCROLL_DESKTOP,
  }),
  scrollSnapAlign: SCROLL_SNAP_ALIGN_START,
  sliderSpacing: false,
  autoplay: false,
  autoplayDelay: 3000,
};

Slider.propTypes = {
  className: string,
  rootClassName: string,
  headerClassName: string,
  titleClassName: string,
  itemsClassName: string,
  itemClassName: string,
  arrowsClassName: string,
  id: string,
  title: string,
  arrows: bool,
  slidesToShow: shape({
    mobile: number,
    tablet: number,
    desktop: number,
  }),
  slidesToScroll: shape({
    mobile: number,
    tablet: number,
    desktop: number,
  }),
  scrollSnapAlign: oneOf([SCROLL_SNAP_ALIGN_START, SCROLL_SNAP_ALIGN_CENTER]),
  sliderSpacing: bool,
  autoplay: bool,
  autoplayDelay: number,
  viewport: shape({
    width: number,
    height: number,
  }).isRequired,
  children: node.isRequired,
};

export default withViewport(Slider);
