import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { isTooManyEmailVerificationRequestsError } from '../../util/errors';
import { PrimaryButton, NamedLink } from '../../components';
import classNames from 'classnames';

import css from './SignupPage.module.css';

const EmailVerification = props => {
  const {
    user,
    isArtistRole,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
  } = props;
  const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
    sendVerificationEmailError
  )
    ? 'AuthenticationPage.resendFailedTooManyRequests'
    : 'AuthenticationPage.resendFailed';
  const resendErrorMessage = sendVerificationEmailError ? (
    <p className={css.error}>
      <FormattedMessage id={resendErrorTranslationId} />
    </p>
  ) : null;

  const name = user.attributes.profile.firstName;
  const email = <span className={css.email}>{user.attributes.email}</span>;
  return (
    <div className={classNames(css.content, css.emailVerificationContent)}>
      <span className={css.step}>
        <FormattedMessage id="AuthenticationPage.emailVerificationStep" />
      </span>
      <h1 className={css.modalTitle}>
        <FormattedMessage id="AuthenticationPage.verifyEmailTitle" />
      </h1>
      <p className={css.modalMessage}>
        <FormattedMessage id="AuthenticationPage.verifyEmailText" values={{ name, email }} />
      </p>
      {resendErrorMessage}

      <div className={css.bottomWrapper}>
        <PrimaryButton className={css.bottomWrapperButton} onClick={onResendVerificationEmail}>
          {sendVerificationEmailInProgress ? (
            <FormattedMessage id="AuthenticationPage.sendingEmail" />
          ) : (
            <FormattedMessage id="AuthenticationPage.resendEmail" />
          )}
        </PrimaryButton>
        <NamedLink
          className={css.bottomWrapperLink}
          name={isArtistRole ? 'ArtistDashboardPage' : 'LandingPage'}
        >
          <FormattedMessage id="AuthenticationPage.verifyEmailClose" />
        </NamedLink>
      </div>
    </div>
  );
};

export default EmailVerification;
