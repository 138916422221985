import React, { useMemo } from 'react';
import { arrayOf, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { Slider, HeroListingCard } from '../../components';
import classNames from 'classnames';
import css from './SectionHero.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;

// slidesToShow
const SLIDES_TO_SHOW_TABLET = 1.5;
const SLIDES_TO_SHOW_DESKTOP = 1.5;
// slidesToScroll
const SLIDES_TO_SCROLL_TABLET = 0.5;
const SLIDES_TO_SCROLL_DESKTOP = 0.5;

const SCROLL_SNAP_ALIGN_START = 'start';
const SCROLL_SNAP_ALIGN_CENTER = 'center';

const sliderSettings = isMobileLayout => ({
  slidesToShow: {
    tablet: SLIDES_TO_SHOW_TABLET,
    desktop: SLIDES_TO_SHOW_DESKTOP,
  },
  slidesToScroll: {
    tablet: SLIDES_TO_SCROLL_TABLET,
    desktop: SLIDES_TO_SCROLL_DESKTOP,
  },
  scrollSnapAlign: isMobileLayout ? SCROLL_SNAP_ALIGN_START : SCROLL_SNAP_ALIGN_CENTER,
  sliderSpacing: true,
  autoplay: !isMobileLayout,
  autoplayDelay: 5000,
});

const SectionHero = props => {
  const { rootClassName, className, viewport, listings } = props;
  const classes = classNames(rootClassName || css.root, className);

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  const filteredListings = useMemo(() => {
    if (listings.length > 0) {
      return listings.filter(l => l.attributes.metadata.isFeatured);
    }
    return [];
  }, [listings]);

  const listingCards = filteredListings.map((l, index) => (
    <HeroListingCard key={l.id.uuid} listing={l} listingIndex={index + 1} viewport={viewport} />
  ));

  return filteredListings.length > 0 ? (
    <div className={classes}>
      <Slider
        id="LandingPage.heroSlider"
        className={css.slider}
        headerClassName={css.sliderHeader}
        arrowsClassName={css.sliderArrows}
        // slider settings
        {...sliderSettings(isMobileLayout)}
      >
        {listingCards}
      </Slider>
    </div>
  ) : null;
};

SectionHero.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  listings: arrayOf(propTypes.listing).isRequired,
};

export default SectionHero;
