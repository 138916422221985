import React from 'react';
import { FieldRadioButton, IconCircus, IconTheater, IconLive, IconMusic } from '../../components';
import classNames from 'classnames';

import css from './TopbarFilterComponent.module.css';

const CIRCUS_SHOW_KEY = 'circus-show';
const THEATER_SHOW_KEY = 'theater-show';
const LIVE_EVENTS_KEY = 'live-events';
const MUSIC_KEY = 'music';

const filterIcon = key => {
  if (key === CIRCUS_SHOW_KEY) {
    return <IconCircus />;
  } else if (key === THEATER_SHOW_KEY) {
    return <IconTheater />;
  } else if (key === LIVE_EVENTS_KEY) {
    return <IconLive />;
  } else if (key === MUSIC_KEY) {
    return <IconMusic />;
  } else {
    return null;
  }
};

const SelectCategoryFields = props => {
  const { id, label, options, initialValue, onUpdateForm } = props;
  return (
    <div className={css.radioComponent}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <div className={css.categoryOptions}>
        {options.map((option, index) => {
          const isSelected = initialValue === option.key;
          const optionClasses = classNames(css.categoryOption, {
            [css.categoryOptionSelected]: isSelected,
          });
          return (
            <div
              key={option.key}
              className={optionClasses}
              onClick={() => onUpdateForm(isSelected ? null : option.key)}
            >
              {filterIcon(option.key)}
              <span className={css.categoryOptionLabel}>
                <FieldRadioButton
                  className={css.radioButton}
                  id={`category.${option.key}`}
                  name="category"
                  value={option.key}
                />
                {option.label}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectCategoryFields;
