import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { Form, FieldTextInput, InlineTextButton } from '../../components';
import * as validators from '../../util/validators';
import classNames from 'classnames';

import ArrowIcon from './icons/ArrowIcon';

import css from './NewsletterForm.module.css';

const NewsletterFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const { rootClassName, className, formId, handleSubmit, inProgress, intl } = fieldRenderProps;

      const emailPlaceholder = intl.formatMessage({
        id: 'NewsletterForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({ id: 'NewsletterForm.emailRequired' });
      const emailInvalidMessage = intl.formatMessage({
        id: 'NewsletterForm.emailInvalid',
      });

      const emailRequired = validators.required(emailRequiredMessage);
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldTextInput
            className={css.field}
            inputRootClass={css.fieldInput}
            type="text"
            name="email"
            id={formId ? `${formId}.email` : 'email'}
            placeholder={emailPlaceholder}
            validate={validators.composeValidators(emailRequired, emailValid)}
          />
          <InlineTextButton
            className={css.submitButton}
            spinnerClassName={css.submitButtonSpinner}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
          >
            <ArrowIcon className={css.submitButtonIcon} />
          </InlineTextButton>
        </Form>
      );
    }}
  />
);

NewsletterFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
};

NewsletterFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const NewsletterForm = compose(injectIntl)(NewsletterFormComponent);

NewsletterForm.displayName = 'NewsletterForm';

export default NewsletterForm;
