import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { subUnitDivisors } from '../../currency-config';
import {
  EditWizardButton,
  Form,
  FieldCurrencyInput,
  FieldTextInput,
  PreviewListingButton,
  FieldSelect,
  FieldRadioButtonGroup,
} from '../../components';

import css from './EditListingTicketsForm.module.css';

const { Money } = sdkTypes;

const getSupportedCurrencies = () => Object.keys(subUnitDivisors).map(c => c);

export const EditListingTicketsFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        values,
        errors,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        panelTitle,
        listing,
        onRedirectToPreviousTab,
      } = formRenderProps;

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const translationKey = isNightly
        ? 'EditListingTicketsForm.pricePerNight'
        : isDaily
        ? 'EditListingTicketsForm.pricePerDay'
        : 'EditListingTicketsForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingTicketsForm.priceInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingTicketsForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingTicketsForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const ticketLinkMessage = intl.formatMessage({
        id: 'EditListingTicketsForm.ticket',
      });
      const ticketLinkPlaceholderMessage = intl.formatMessage({
        id: 'EditListingTicketsForm.ticketPlaceholder',
      });
      const ticketLinkValidMessage = intl.formatMessage({
        id: 'EditListingTicketsForm.validLink',
      });
      const ticketLinkValidURL = validators.validURL(ticketLinkValidMessage);

      const ticketAvailabiltyMessage = intl.formatMessage({
        id: 'EditListingTicketsForm.ticketAvailability',
      });

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError, publishListingError } = fetchErrors || {};

      // NOTE: These error messages are here since Photos panel is the last visible panel
      // before creating a new listing. If that order is changed, these should be changed too.
      // Create and show listing errors are shown above submit button
      const publishListingFailed = publishListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingContentForm.publishListingFailed" />
        </p>
      ) : null;
      const showListingFailed = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingContentForm.showListingFailed" />
        </p>
      ) : null;

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          <div className={css.content}>
            <div className={css.contentWrapper}>
              <h1 className={css.title}>{panelTitle}</h1>
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingTicketsForm.updateFailed" />
                </p>
              ) : null}
              {showListingsError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingTicketsForm.showListingFailed" />
                </p>
              ) : null}
              {publishListingFailed}
              {showListingFailed}

              <div className={css.ticketPrice}>
                <label>{pricePerUnitMessage}</label>
                <div className={css.ticketPriceInputs}>
                  <FieldCurrencyInput
                    id="price"
                    name="price"
                    className={css.priceInput}
                    placeholder={pricePlaceholderMessage}
                    currencyConfig={{
                      ...config.currencyConfig,
                      currency: values.currency,
                    }}
                    validate={priceValidators}
                  />
                  <FieldSelect id="currency" name="currency">
                    {getSupportedCurrencies().map(c => {
                      return (
                        <option key={c} value={c}>
                          {c}
                        </option>
                      );
                    })}
                  </FieldSelect>
                </div>
                {errors.price && values.price ? (
                  <p className={css.priceError}>{errors.price}</p>
                ) : null}
              </div>

              <FieldTextInput
                id="ticketLink"
                name="ticketLink"
                className={css.field}
                type="text"
                label={ticketLinkMessage}
                placeholder={ticketLinkPlaceholderMessage}
                validate={ticketLinkValidURL}
              />

              <FieldRadioButtonGroup
                className={css.field}
                itemClassName={css.ticketAvailabilityItem}
                checkedClassName={css.ticketAvailabilityChecked}
                id="ticketAvailability"
                name="ticketAvailability"
                label={ticketAvailabiltyMessage}
                options={[
                  { key: 'available', label: 'Available' },
                  { key: 'notAvailable', label: 'Not available' },
                ]}
              />
            </div>
          </div>

          <div className={css.submitButtonRoot}>
            <EditWizardButton
              className={css.backButton}
              type="button"
              onClick={onRedirectToPreviousTab}
            >
              <FormattedMessage id="EditListingContentForm.backButton" />
            </EditWizardButton>
            <div className={css.submitAndPreviewButton}>
              <PreviewListingButton listing={listing} />
              <EditWizardButton
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </EditWizardButton>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

EditListingTicketsFormComponent.defaultProps = { fetchErrors: null };

EditListingTicketsFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingTicketsFormComponent);
