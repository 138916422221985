import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { createSlug } from '../../util/urlHelpers';
import { pathByRouteName } from '../../util/routes';
import { LISTING_STATE_DRAFT, propTypes } from '../../util/types';
import { ExternalLink } from '../../components';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import classNames from 'classnames';

import css from './PreviewListingButton.module.css';

const createListingLink = listing => {
  const isPublished = listing.id && listing.attributes.state !== LISTING_STATE_DRAFT;
  const isExperience = listing.attributes.publicData.type === config.listingTypes['experience'];

  const listingPagePath = pathByRouteName(
    isExperience ? 'ExperiencePage' : 'ListingPage',
    routeConfiguration(),
    {
      id: listing.id.uuid,
      slug: createSlug(listing.attributes.title),
    }
  );
  const listingPageVariantPath = pathByRouteName(
    isExperience ? 'ExperiencePageVariant' : 'ListingPageVariant',
    routeConfiguration(),
    {
      id: listing.id.uuid,
      slug: createSlug(listing.attributes.title),
      variant: listing.attributes.state,
    }
  );

  return isPublished ? listingPagePath : listingPageVariantPath;
};

const PreviewListingButton = props => {
  const { rootClassName, className, listing } = props;

  if (!listing.id) {
    return null;
  }

  const linkHref = createListingLink(listing);
  const classes = classNames(rootClassName || css.root, className);
  return (
    <ExternalLink className={classes} href={linkHref}>
      <FormattedMessage id="PreviewListingButton.preview" />
    </ExternalLink>
  );
};

const { oneOfType, string, object } = PropTypes;

PreviewListingButton.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

PreviewListingButton.propTypes = {
  className: string,
  rootClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing, object]),
};

export default PreviewListingButton;
