import React from 'react';
import { bool, node } from 'prop-types';

import css from './EditListingWizardTabNav.module.css';

const LockWrapperIcon = props => {
  const { show, children } = props;
  return show ? <div className={css.lockIconWrapper}>{children}</div> : null;
};

LockWrapperIcon.defaultProps = {
  show: false,
};

LockWrapperIcon.propTypes = {
  show: bool.isRequired,
  children: node.isRequired,
};

export default LockWrapperIcon;
