import React from 'react';
import { compose } from 'redux';
import { object, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { capitalizeFirstLetter, getUserTier } from '../../util/data';
import classNames from 'classnames';

import stuffImg from './img/badgeStuff.png';
import betaTesterImg from './img/badgeBetaTester.png';
import topReviewerImg from './img/badgeTopReviewer.png';
import yearsImg from './img/badgeYears.png';
import IconTier from './IconTier/IconTier';

import css from './Badges.module.css';

// Badge constants
export const BADGE_STUFF = 'stuff';
export const BADGE_BETA_TESTER = 'beta-tester';
export const BADGE_TOP_REVIEWER = 'top-reviewer';
export const BADGE_YEARS = 'years';
export const BADGE_TIER = 'tier';

// Export badge images and icons for reuse
export const BadgeStuff = props => <img src={stuffImg} {...props} />;
export const BadgeBetaTester = props => <img src={betaTesterImg} {...props} />;
export const BadgeTopReviewer = props => <img src={topReviewerImg} {...props} />;
export const BadgeYears = props => <img src={yearsImg} {...props} />;
export const BadgeTier = props => <IconTier {...props} />;

const badgeImages = {
  [BADGE_STUFF]: BadgeStuff,
  [BADGE_BETA_TESTER]: BadgeBetaTester,
  [BADGE_TOP_REVIEWER]: BadgeTopReviewer,
  [BADGE_YEARS]: BadgeYears,
};

const badgeAltKeys = {
  [BADGE_STUFF]: 'Badges.stuff',
  [BADGE_BETA_TESTER]: 'Badges.betaTester',
  [BADGE_TOP_REVIEWER]: 'Badges.topReviewer',
  [BADGE_YEARS]: 'Badges.years',
};

// BadgeImage component that renders images or tier icons dynamically
const BadgeImage = ({ badgeId, alt, ...props }) => {
  const BadgeComponent = badgeImages[badgeId];
  return BadgeComponent ? <BadgeComponent alt={alt} title={alt} {...props} /> : null;
};

// Main Badges component
const Badges = props => {
  const { rootClassName, className, intl, user } = props;

  const badges = user?.attributes?.profile?.metadata?.badges || [];
  const points = user?.attributes?.profile?.metadata?.points;
  const userTier = getUserTier(points);

  if (!badges.length) {
    return null;
  }

  const renderTierIcon = userTier && (
    <BadgeTier
      className={css.badgeIcon}
      title={capitalizeFirstLetter(userTier.id)}
      mainColor={userTier.mainColor}
      secondaryColor={userTier.secondaryColor}
    />
  );

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {renderTierIcon}
      {badges.map(badge => {
        const { id: badgeId } = badge;
        const altText = intl.formatMessage({ id: badgeAltKeys[badgeId] });
        return (
          <BadgeImage key={badgeId} badgeId={badgeId} alt={altText} className={css.badgeImg} />
        );
      })}
    </div>
  );
};

Badges.defaultProps = {
  rootClassName: null,
  className: null,

  user: null,
};

Badges.propTypes = {
  rootClassName: string,
  className: string,

  user: object.isRequired,
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(Badges);
