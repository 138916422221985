import React, { Component } from 'react';
import { object, string } from 'prop-types';
import classNames from 'classnames';
import config from '../../config';
import { intlShape } from '../../util/reactIntl';
import {
  resetToStartOfDay,
  dateIsAfter,
  getMonthStartInTimeZone,
  timeOfDayFromLocalToTimeZone,
  timeOfDayFromTimeZoneToLocal,
  nextMonthFn,
  prevMonthFn,
} from '../../util/dates';
import { bookingDateRequired } from '../../util/validators';
import { FieldDateInput } from '../../components';

import NextMonthIcon from '../BookingTimeForm/NextMonthIcon';
import PreviousMonthIcon from '../BookingTimeForm/PreviousMonthIcon';
import css from './FieldDatesInput.module.css';

// MAX_TIME_SLOTS_RANGE is the maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged:
// https://stripe.com/docs/connect/account-balances#holding-funds
//
// See also the API reference for querying time slots:
// https://www.sharetribe.com/api-reference/marketplace.html#query-time-slots

const MAX_TIME_SLOTS_RANGE = config.dayCountAvailableForBooking;

const TODAY = new Date();

const endOfRange = (date, timeZone) => {
  return resetToStartOfDay(date, timeZone, MAX_TIME_SLOTS_RANGE - 1);
};

const Next = props => {
  const { currentMonth, timeZone } = props;
  const nextMonthDate = nextMonthFn(currentMonth, timeZone);

  return dateIsAfter(nextMonthDate, endOfRange(TODAY, timeZone)) ? null : <NextMonthIcon />;
};
const Prev = props => {
  const { currentMonth, timeZone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, timeZone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? <PreviousMonthIcon /> : null;
};

class FieldDatesInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMonth: getMonthStartInTimeZone(TODAY, props.timeZone),
    };

    this.onMonthClick = this.onMonthClick.bind(this);
    this.isOutsideRange = this.isOutsideRange.bind(this);
  }

  onMonthClick(monthFn) {
    const { timeZone } = this.props;
    this.setState(prevState => ({ currentMonth: monthFn(prevState.currentMonth, timeZone) }));
  }

  isOutsideRange(day, startDate) {
    return dateIsAfter(startDate, day);
  }

  render() {
    const {
      rootClassName,
      className,
      formId,
      startDateInputProps,
      endDateInputProps,
      values,
      timeZone,
      disabled,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const startDate = values.startDate && values.startDate.date ? values.startDate.date : null;
    const endDateDisabled = disabled || !startDate;

    return (
      <div className={classes}>
        <div className={css.formRow}>
          <div className={classNames(css.field, css.startDate)}>
            <FieldDateInput
              className={css.fieldDateInput}
              name="startDate"
              id={formId ? `${formId}.startDate` : 'startDate'}
              label={startDateInputProps.label}
              placeholderText={startDateInputProps.placeholderText}
              format={v =>
                v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
              }
              parse={v =>
                v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
              }
              navNext={<Next currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              navPrev={<Prev currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
              onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
              useMobileMargins
              showErrorMessage={false}
              onClose={event =>
                this.setState({
                  currentMonth: getMonthStartInTimeZone(event?.date ?? TODAY, this.props.timeZone),
                })
              }
              disabled={disabled}
            />
          </div>
        </div>
        <div className={css.formRow}>
          <div className={css.field}>
            <FieldDateInput
              {...endDateInputProps}
              name="endDate"
              id={formId ? `${formId}.endDate` : 'endDate'}
              className={css.fieldDateInput}
              label={endDateInputProps.label}
              placeholderText={endDateInputProps.placeholderText}
              format={v =>
                v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
              }
              parse={v =>
                v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
              }
              navNext={<Next currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              navPrev={<Prev currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
              onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
              isOutsideRange={day => this.isOutsideRange(day, startDate)}
              useMobileMargins
              showErrorMessage={false}
              disabled={endDateDisabled}
              showLabelAsDisabled={endDateDisabled}
            />
          </div>
        </div>
      </div>
    );
  }
}

FieldDatesInput.defaultProps = {
  rootClassName: null,
  className: null,
  startDateInputProps: null,
  endDateInputProps: null,
  startTimeInputProps: null,
  endTimeInputProps: null,
};

FieldDatesInput.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  startDateInputProps: object,
  endDateInputProps: object,
  startTimeInputProps: object,
  endTimeInputProps: object,
  form: object.isRequired,
  values: object.isRequired,
  timeZone: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default FieldDatesInput;
