import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { Modal } from '../../components';
import { InviteTeamMemberForm } from '../../forms';
import classNames from 'classnames';

import css from './InviteTeamMemberModal.module.css';

const InviteTeamMemberModal = props => {
  const {
    className,
    rootClassName,
    id,
    isOpen,
    onCloseModal,
    handleSubmit,
    currentUser,
    currentOrganizationProfile,
    team,
    sendInviteInProgress,
    sendInviteError,
    emailValidInProgress,
    isEmailValid,
    onManageDisableScrolling,
    creditRolesConfigOptions,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="InviteTeamMemberModal.title" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="InviteTeamMemberModal.description" />
      </p>
      <InviteTeamMemberForm
        id="TeamPermissionPage.InviteTeamMemberForm"
        currentUser={currentUser}
        onSubmit={handleSubmit}
        isEmailValid={isEmailValid}
        team={team}
        currentOrganizationProfile={currentOrganizationProfile}
        sendInviteInProgress={sendInviteInProgress}
        sendInviteError={sendInviteError}
        emailValidInProgress={emailValidInProgress}
        creditRolesConfigOptions={creditRolesConfigOptions}
      />
    </Modal>
  );
};

const { arrayOf, string, bool, func } = PropTypes;

InviteTeamMemberModal.defaultProps = {
  className: null,
  rootClassName: null,

  sendInviteInProgress: false,
  sendInviteError: null,
  emailValidInProgress: false,
  isEmailValid: false,
};

InviteTeamMemberModal.propTypes = {
  className: string,
  rootClassName: string,

  currentUser: propTypes.currentUser,
  team: arrayOf(propTypes.user),
  sendInviteInProgress: bool.isRequired,
  sendInviteError: propTypes.error,
  emailValidInProgress: bool.isRequired,
  isEmailValid: bool.isRequired,
  handleSubmit: func.isRequired,
  onEmailValid: func.isRequired,

  intl: intlShape.isRequired,
};

export default injectIntl(InviteTeamMemberModal);
