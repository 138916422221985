/**
 * Helper functional component that allows the user to specify
 * exception repeating dates.
 */
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { FieldDateInput, IconAdd, IconClose, InlineTextButton } from '../../components';
import classNames from 'classnames';

import css from './EditExperienceAvailabilityForm.module.css';

const MAX_REPEATING_AVAILABILITY_EXCEPTIONS = 10; // API is limited to creating 10 exceptions at the time

const getFieldIndex = index => index + 1;

// Helper `RepeatingDatesButton` component that renders
// the repeating input add & remove buttons.
const RepeatingDatesButton = props => {
  const { className, children, show, onClick, disabled } = props;

  if (!show) {
    return null;
  }
  return (
    <InlineTextButton className={className} type="button" onClick={onClick} disabled={disabled}>
      {children}
    </InlineTextButton>
  );
};

const EditExperienceAvailabilityFormRepeatingDates = props => {
  const {
    rootClassName,
    className,
    fieldId,
    push,
    values,
    label,
    onChange,
    ...dateInputProps
  } = props;
  const classes = classNames(rootClassName || css.repeatingDates, className);
  return (
    <FieldArray name={fieldId}>
      {({ fields }) => (
        <div className={classes}>
          {label ? <label htmlFor={fieldId}>{label}</label> : null}
          {fields.map((name, index) => {
            const currentValue = values[index];

            const isMaximumFields = getFieldIndex(index) >= MAX_REPEATING_AVAILABILITY_EXCEPTIONS;
            const isLastField = fields.length === getFieldIndex(index);
            return (
              <div key={`${name}.${index}`} className={css.repeatDate}>
                <FieldDateInput
                  className={css.fieldRepeatingDateInput}
                  id={`${name}.date`}
                  name={`${name}.date`}
                  useMobileMargins
                  showErrorMessage={false}
                  onChange={value => onChange(name, value)}
                  {...dateInputProps}
                />
                <div className={css.repeatingButtons}>
                  <RepeatingDatesButton
                    className={css.addRepeatingDateButton}
                    show={isLastField}
                    onClick={() => push(fieldId, undefined)}
                    disabled={dateInputProps.disabled || isMaximumFields || !currentValue?.date}
                  >
                    <IconAdd className={css.addRepeatingDateIcon} />
                  </RepeatingDatesButton>
                  <RepeatingDatesButton
                    className={css.removeRepeatingDateButton}
                    show={fields.length > 1}
                    onClick={() => fields.remove(index)}
                    disabled={dateInputProps.disabled}
                  >
                    <IconClose className={css.removeRepeatingDateIcon} />
                  </RepeatingDatesButton>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </FieldArray>
  );
};

export default EditExperienceAvailabilityFormRepeatingDates;
