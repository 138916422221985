import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconFullscreen.module.css';

const IconFullscreen = props => {
  const { className, rootClassName, isFullscreen } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (isFullscreen) {
    return (
      <svg
        className={classes}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        height="16"
        width="16"
      >
        <path d="M2.207 15.207.793 13.793 4.586 10H1.5V8h5a1.5 1.5 0 0 1 1.498 1.412L8 9.5v5H6v-3.085zM9.5 8a1.5 1.5 0 0 1-1.498-1.412L8 6.5v-5h2v3.086L13.793.793l1.414 1.414L11.415 6H14.5v2z" />
      </svg>
    );
  }

  return (
    <svg
      className={classes}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      height="16"
      width="16"
    >
      <path d="m6.293 8.293 1.414 1.414L3.914 13.5H7v2H2a1.5 1.5 0 0 1-1.498-1.412L.5 14V9h2v3.085zM14 .5a1.5 1.5 0 0 1 1.498 1.412L15.5 2v5h-2V3.914L9.707 7.707 8.293 6.293 12.085 2.5H9v-2z" />
    </svg>
  );
};

const { string, bool } = PropTypes;

IconFullscreen.defaultProps = {
  className: null,
  rootClassName: null,
  isFullscreen: false,
};

IconFullscreen.propTypes = {
  className: string,
  rootClassName: string,
  isFullscreen: bool.isRequired,
};

export default IconFullscreen;
