export const supportedLanguages = [
  { code: 'en', language: 'English', country: 'United States' },
  {
    code: 'fr',
    language: 'Français',
    country: 'France',
  },
];

export const findMissingTranslations = (localTranslations, hostedTranslations) => {
  const missingTranslations = {};

  Object.keys(localTranslations).forEach(key => {
    if (!hostedTranslations.hasOwnProperty(key)) {
      missingTranslations[key] = localTranslations[key];
    }
  });

  return missingTranslations;
};
