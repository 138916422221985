import React from 'react';
import { node, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { formatDuration } from '../../util/membership';
import config from '../../config';

import css from './PaymentBreakdown.module.css';

const MembershipDetails = props => {
  const { intl, membership } = props;
  const { name: membershipName, duration: membershipDuration } = membership;
  return (
    <div>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="PaymentBreakdown.membershipDuration" />
        </span>
        <span className={css.itemValue}>{formatDuration(intl, membershipDuration)}</span>
      </div>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="PaymentBreakdown.membershipTier" />
        </span>
        <span className={css.itemValue}>{membershipName}</span>
      </div>
    </div>
  );
};

MembershipDetails.propTypes = {
  membership: propTypes.membership.isRequired,
};

const LineItemPaymentDetails = props => {
  const { intl, children, paymentType, membership } = props;

  // Check if the current payment type is a membership payment
  //
  // Supported payment types are either membership or donation
  const isMembershipPayment = paymentType === config.paymentTypes['membership'];

  const renderDetailsTitle = isMembershipPayment ? (
    <FormattedMessage id="PaymentBreakdown.membershipDetails" />
  ) : (
    <FormattedMessage id="PaymentBreakdown.donationDetails" />
  );
  return (
    <div className={css.details}>
      <h4 className={css.detailsTitle}>{renderDetailsTitle}</h4>
      <div className={css.lineItems}>
        {isMembershipPayment && membership ? (
          <MembershipDetails intl={intl} membership={membership} />
        ) : null}
        {children}
      </div>
    </div>
  );
};

LineItemPaymentDetails.propTypes = {
  children: node,
  paymentType: string.isRequired,
  membership: propTypes.membership,
};

export default LineItemPaymentDetails;
