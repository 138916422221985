import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Modal } from '../../components';
import { ShowRatingForm } from '../../forms';

import css from './ShowRatingModal.module.css';

const ShowRatingModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmitRating,
    reviewee,
    ratingSubmitted,
    sendRatingInProgress,
    sendRatingError,
    showRating,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="ShowRatingModal.title" values={{ revieweeName: reviewee }} />
      </p>
      <ShowRatingForm
        formId="ShowRatingForm"
        initialValues={{
          reviewRating: showRating ? showRating.rating : null,
        }}
        onSubmit={onSubmitRating}
        ratingSubmitted={ratingSubmitted}
        sendRatingInProgress={sendRatingInProgress}
        sendRatingError={sendRatingError}
      />
    </Modal>
  );
};

const { bool, string, object } = PropTypes;

ShowRatingModal.defaultProps = {
  className: null,
  rootClassName: null,
  ratingSubmitted: false,
  sendRatingInProgress: false,
  sendRatingError: null,
};

ShowRatingModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  ratingSubmitted: bool,
  sendRatingInProgress: bool,
  sendRatingError: propTypes.error,
  showRating: object,
};

export default injectIntl(ShowRatingModal);
