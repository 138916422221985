import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, ExpandableBio } from '../../components';
import classNames from 'classnames';

import css from './OrganizationProfileCard.module.css';

const EXPANDABLE_BIO_LENGTH = 330;

const OrganizationProfileCard = props => {
  const { rootClassName, className, organizationProfile } = props;
  const { name, bio, founded, profileImage } = organizationProfile;

  const hasBio = !!bio;

  const renderProfileImage =
    profileImage && profileImage.id ? (
      <NamedLink name="OrganizationProfilePage" params={{ id: organizationProfile.id }}>
        <img className={css.profileImage} src={profileImage.link} alt={profileImage.name} />
      </NamedLink>
    ) : (
      <NamedLink
        className={css.profileNoImage}
        name="OrganizationProfilePage"
        params={{ id: organizationProfile.id }}
      >
        <span className={css.profileNoImageInitials}>{organizationProfile?.name?.trim()[0]}</span>
      </NamedLink>
    );

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.header}>
        {renderProfileImage}
        <div className={css.headingRow}>
          <h3 className={css.heading}>{name}</h3>
          <div className={css.info}>
            {founded ? (
              <FormattedMessage id="OrganizationProfileCard.founded" values={{ date: founded }} />
            ) : null}
          </div>
        </div>
      </div>
      {hasBio ? (
        <ExpandableBio className={css.bio} bio={bio} maxLength={EXPANDABLE_BIO_LENGTH} />
      ) : null}
    </div>
  );
};

OrganizationProfileCard.defaultProps = {
  rootClassName: null,
  className: null,
};

OrganizationProfileCard.propTypes = {
  rootClassName: string,
  className: string,
};

export default OrganizationProfileCard;
