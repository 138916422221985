/**
 * This component will show the experience info and calculated total price.
 */
import React from 'react';
import { oneOf, string, object } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import classNames from 'classnames';

import LineItemExperienceDetails from './LineItemExperienceDetails';
import LineItemBookingDetails from './LineItemBookingDetails';
import LineItemPaymentDetails from './LineItemPaymentDetails';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';

import css from './PaymentBreakdown.module.css';

export const PaymentBreakdownComponent = props => {
  const {
    rootClassName,
    className,
    userRole,
    listing,
    transaction,
    paymentType,
    membership,
    timeslot,
    intl,
  } = props;

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';

  const bookingDates = timeslot?.bookingDates;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {/* Experience details  */}
      <LineItemExperienceDetails listing={listing} timeslot={timeslot} />

      {/* Booking details  */}
      <LineItemBookingDetails timeslot={timeslot} bookingDates={bookingDates} />

      {/* Payment details */}
      <LineItemPaymentDetails paymentType={paymentType} membership={membership} intl={intl}>
        <LineItemCustomerCommissionMaybe
          transaction={transaction}
          isCustomer={isCustomer}
          intl={intl}
        />
        <LineItemCustomerCommissionRefundMaybe
          transaction={transaction}
          isCustomer={isCustomer}
          intl={intl}
        />

        <LineItemTotalPrice transaction={transaction} isProvider={isProvider} intl={intl} />
      </LineItemPaymentDetails>
    </div>
  );
};

PaymentBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

PaymentBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  listing: propTypes.listing.isRequired,
  transaction: propTypes.transaction.isRequired,
  paymentType: string.isRequired,
  membership: propTypes.membership,
  timeslot: object,

  // from injectIntl
  intl: intlShape.isRequired,
};

const PaymentBreakdown = injectIntl(PaymentBreakdownComponent);

PaymentBreakdown.displayName = 'PaymentBreakdown';

export default PaymentBreakdown;
