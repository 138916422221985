import React from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT, propTypes } from '../../util/types';
import { ListingLink } from '..';
import { EditListingBasicInfoForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingBasicInfoPanel.module.css';

const { LatLng } = sdkTypes;

const EditListingBasicInfoPanel = props => {
  const {
    className,
    rootClassName,
    location,
    history,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    showListings,
    organizationProfiles,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, geolocation, publicData } = currentListing.attributes;

  // Helper function to get the location initial values
  const locationInitialValues = () => {
    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent =
      publicData && publicData.location && publicData.location.address && geolocation;
    const location = publicData && publicData.location ? publicData.location : {};
    const { address } = location;

    return {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin: new LatLng(geolocation.lat, geolocation.lng) },
          }
        : null,
    };
  };

  const dates = publicData?.dates;
  const start = dates?.start;
  const end = dates?.end;

  const startDate = start ? { date: new Date(start) } : null;
  const endDate = end ? { date: new Date(end) } : null;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingBasicInfoPanel.title"
      values={{
        listingTitle: (
          <ListingLink className={css.listingLink} listing={listing}>
            {title}
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingBasicInfoPanel.createListingTitle" />
  );

  const type = publicData?.type || config.listingTypes['show'];

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const genreOptions = findOptionsForSelectFilter('genre', config.custom.filters);
  const statusOptions = findOptionsForSelectFilter('status', config.custom.filters);
  const lengthOptions = findOptionsForSelectFilter('length', config.custom.filters);
  const ageOptions = findOptionsForSelectFilter('age', config.custom.filters);

  return (
    <div className={classes}>
      <EditListingBasicInfoForm
        className={css.form}
        initialValues={{
          type,
          title,
          description,
          category: publicData?.category,
          genre: publicData?.genre,
          status: publicData?.status,
          production: publicData?.production,
          length: publicData?.length,
          age: publicData?.age,
          launch: publicData?.launch,
          countryOfOrigin: publicData?.countryOfOrigin,
          website: publicData?.website,
          startDate,
          endDate,
          organizationId: publicData?.organizationProfile?.id || publicData?.organizationId,
          projectDates: publicData?.projectDates,
          ...locationInitialValues(),
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            title,
            description,
            location,
            startDate,
            endDate,
            projectDates,
            organizationId,
            launch,
            countryOfOrigin,
            website,
            ...restValues
          } = values;

          const {
            selectedPlace: { address, origin },
          } = location;
          const datesMaybe =
            startDate?.date && endDate?.date
              ? {
                  dates: {
                    start: startDate.date.valueOf(),
                    end: endDate.date.valueOf(),
                  },
                }
              : {};

          const organization = organizationId
            ? organizationProfiles
                .map(o => ({
                  id: o.id,
                  name: o.name,
                }))
                .find(o => o.id === organizationId)
            : null;
          const organizationProfile = organization ? organization : null;

          const updateValues = {
            title: title.trim(),
            description,
            geolocation: origin,
            publicData: {
              location: { address },
              projectDates,
              organizationProfile,
              organizationId,
              launch,
              countryOfOrigin,
              website,
              ...datesMaybe,
              ...restValues,
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        location={location}
        history={history}
        fetchErrors={errors}
        listing={currentListing}
        panelTitle={panelTitle}
        showListings={showListings}
        organizationProfiles={organizationProfiles}
        categoryOptions={categoryOptions}
        genreOptions={genreOptions}
        statusOptions={statusOptions}
        lengthOptions={lengthOptions}
        ageOptions={ageOptions}
        organizationIdFromPublicData={publicData?.organizationId}
        isPublished={isPublished}
      />
    </div>
  );
};

EditListingBasicInfoPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingBasicInfoPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  showListings: arrayOf(propTypes.listing),

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingBasicInfoPanel;
