import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPremium.module.css';

const IconPremium = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      height="24"
      width="24"
    >
      <path
        fill="#0e0f0c"
        fillRule="evenodd"
        d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12Zm11.006-7.542a.5.5 0 0 1 .444.27l1.934 3.735 4.147.677a.5.5 0 0 1 .275.844l-2.952 2.998.634 4.154a.5.5 0 0 1-.718.523l-3.764-1.881-3.753 1.88a.5.5 0 0 1-.719-.522l.633-4.154-2.96-2.997a.5.5 0 0 1 .275-.845l4.154-.677 1.926-3.735a.5.5 0 0 1 .444-.27Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconPremium.defaultProps = {
  className: null,
  rootClassName: null,
};

IconPremium.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPremium;
