import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Effective Date: October 28, 2024</p>
      <h3>Welcome to Attractr!</h3>
      <p>
        Attractr is a platform connecting audiences, creators, and production companies in the
        performing arts community. This Privacy Policy, part of our Terms of Use, explains how we
        collect, use, and share your information.
      </p>

      <h3>Information You Provide to Attractr</h3>
      <p>
        This includes details you enter directly, such as your name, payment details, and profile
        information. Required information varies based on account type and role—whether you’re an
        audience member, creator, or admin—and generally includes:
      </p>
      <ul>
        <li>First and last name;</li>
        <li>Email address;</li>
        <li>Date of birth;</li>
        <li>Phone number;</li>
        <li>Password; and</li>
        <li>Region and location</li>
      </ul>
      <p>
        You may also choose to sign up through Facebook, or Google. In that case, Attractr will
        request access to your user ID, name, email, and profile picture from these accounts, which
        you can manage or remove by updating permissions within those accounts. If you opt to enrich
        your public profile, optional information, like social media links and bio details, will be
        stored with your account data.
      </p>
      <p>
        For more information on third-party data handling, please review the respective privacy
        policies of{' '}
        <a href="https://www.facebook.com/about/privacy/" rel="noopener" target="_blank">
          Facebook
        </a>
        , or{' '}
        <a href="https://policies.google.com/privacy" rel="noopener" target="_blank">
          Google
        </a>
        .
      </p>
      <p>
        You may also have the option to add more information to your public profile, such as a
        location, languages, social media links, and an about section, which we store along with the
        other information that we collect.
      </p>

      <h3>Audience Members</h3>
      <p>
        As an Attractr audience member, you may subscribe or make purchases, often gaining access to
        exclusive benefits offered by creators. You’ll need to provide payment information to our
        secure payment partners, such as Stripe. Attractr stores only limited payment details (e.g.,
        card expiry and postal code) using a tokenized system for security.
      </p>
      <p>
        We also collect information on creators you support, purchased memberships, and associated
        benefits. For memberships offering physical perks, you may be asked for shipping
        information, including your location, phone number, or country, derived from payment data or
        IP address.
      </p>

      <h3>Creators and Production Companies</h3>
      <p>
        Creators and production companies on Attractr connect with audience members through
        memberships and offerings that support their work. To become a creator, you’ll need to set
        up a page detailing your projects and any benefits available to supporters. For payouts,
        you’ll establish an account with one of Attractr’s payment partners (e.g., Stripe), which
        may require sharing personal data for identity verification. Additionally, Attractr may
        request certain information for tax compliance, such as contact details, business or tax ID,
        citizenship, and bank details.
      </p>

      <h3>Additional Information We Collect Automatically</h3>
      <p>
        When you interact with Attractr, such as visiting our website, engaging with our emails, or
        using Attractr on mobile apps, we automatically collect certain information. This includes
        details like:
      </p>
      <ul>
        <li>Your IP address and approximate location</li>
        <li>Browser, device type, and operating system</li>
        <li>Language preferences</li>
        <li>Referring web pages</li>
        <li>Mobile carrier and device information</li>
        <li>Search terms and page interactions</li>
        <li>View time, session length, and other media engagement metrics</li>
        <li>Cookie details (see our Cookie Policy for more information).</li>
      </ul>
      <p>This data helps us improve user experience and understand engagement trends.</p>

      <h3>Messages and Comments on Attractr</h3>
      <p>
        When you send, receive, or are tagged in messages or comments on Attractr, we collect and
        store the content, timestamps, and related activity data, such as who has viewed or
        interacted with the messages and comments. This information allows Attractr to maintain an
        accurate record of interactions, enhancing communication features and supporting a seamless
        user experience.
      </p>

      <h3>User Research Studies & Surveys</h3>
      <p>
        Attractr regularly conducts research to enhance our platform for creators and audiences. We
        may invite you to participate in studies, observing how you use Attractr or recording
        insights from interviews with our research team. As part of this research, we may ask you to
        complete surveys about your experience and perceptions of Attractr, with your responses
        stored securely.
      </p>

      <h3>Event Information & Surveys</h3>
      <p>
        To improve event experiences, we may invite you to participate in surveys, optionally
        gathering demographic information. We may also collect your details at online or in-person
        events, including contact information, creation type, and feedback responses.
      </p>

      <h3>Information from Third-Party Accounts</h3>
      <p>
        After creating an Attractr account, you may connect social media accounts (e.g., Twitter,
        Facebook, YouTube, Instagram, Whatsapp, TikTok) to enhance certain features. By doing so,
        Attractr may collect and store details like profile info, follower counts, and engagement
        metrics (e.g., views, likes, comments).
      </p>
      <p>
        When connecting third-party accounts, you’ll be shown what information Attractr can access.
        Permissions can be revoked anytime through the respective platform’s settings.
      </p>

      <h3>Promotions</h3>
      <p>
        Attractr may run promotional campaigns from Attractr-operated accounts on third-party
        platforms like Instagram, Twitter, and Facebook. Any personal data collected during these
        promotions will be handled according to Attractr's Privacy Policy.
      </p>

      <h3>Information Collected by Third Parties</h3>

      <h4>Cookies</h4>
      <p>
        Third parties may use cookies on Attractr's site to enhance user experience. A cookie is a
        small file stored on your device by a website. You can adjust your cookie settings directly
        in your browser.
      </p>

      <h4>Social Media Features and Widgets</h4>
      <p>
        Attractr includes interactive social media features, such as Share button. These may collect
        data like your IP address and the pages you visit on Attractr. For details on data
        collection and usage, refer to the privacy policies of each respective platform.
      </p>
      <ul>
        <li>
          <a href="https://www.facebook.com/about/privacy/" rel="noopener" target="_blank">
            Facebook, including Instagram
          </a>
        </li>
        <li>
          <a href="https://policies.google.com/privacy" rel="noopener" target="_blank">
            Google, including YouTube
          </a>
        </li>
        <li>
          <a href="https://www.tiktok.com/legal/privacy-policy-us" rel="noopener" target="_blank">
            TikTok
          </a>
        </li>
        <li>
          <a href="https://www.twitch.tv/p/legal/privacy-notice/" rel="noopener" target="_blank">
            Twitch
          </a>
        </li>
        <li>
          <a href="https://x.com/en/privacy" rel="noopener" target="_blank">
            X
          </a>
        </li>
        <li>
          <a
            href="https://whereby.com/information/tos/privacy-policy/"
            rel="noopener"
            target="_blank"
          >
            Whereby
          </a>
        </li>
      </ul>

      <h3>How We Use Information</h3>
      <p>We use the information we collect to:</p>
      <ul>
        <li>Ensure compliance with legal and regulatory standards.</li>
        <li>Verify user identities as needed.</li>
        <li>Enable account access, subscriptions, and purchases on Attractr.</li>
        <li>Process payments, apply taxes, and send merchandise.</li>
        <li>Deliver notifications and support services.</li>
        <li>Promote creator discoverability and personalize recommendations.</li>
        <li>Market Attractr products and organize events.</li>
        <li>Analyze service use for improvement and innovation.</li>
        <li>Prevent fraud and abuse, and accommodate users with disabilities if notified.</li>
      </ul>
      <p>Each use aligns with enhancing and safeguarding the Attractr experience.</p>

      <h3>Information Shared with Creators and Production Companies</h3>
      <p>
        When subscribing, purchasing memberships, or engaging with a creator or production company
        on Attractr, you agree to share personal information relevant to the engagement, including:
      </p>
      <ul>
        <li>Full name, email address, and profile information</li>
        <li>Physical address if physical benefits are selected</li>
        <li>Phone number for text-based engagement or event notifications</li>
        <li>Details of your membership, excluding full payment card data</li>
        <li>Engagement analytics, such as views and interaction duration</li>
      </ul>
      <p>
        Creators may use third-party services for benefit delivery, which may also receive patron
        data to fulfill these services. Review privacy policies of relevant third-party providers
        for data practices.
      </p>

      <h3>Information Shared with Live Rooms on Attractr</h3>
      <p>
        Joining a Live Room through membership or page creation on Attractr connects you with a
        community associated with a creator or production. Personal data shared may include:
      </p>
      <ul>
        <li>Profile Information: full name, avatar, bio, and social links</li>
        <li>Creator Credits: show credits, reviews, and ratings</li>
        <li>Subscription Details: tier, duration of support</li>
        <li>User Badges: type, tier, and scores</li>
      </ul>
      <p>
        Interactions like comments, likes, reactions, and messages are visible to other community
        members, with visibility adjusting based on content access permissions.
      </p>

      <h3>Information Shared with the Public</h3>
      <p>Some information on Attractr may be publicly accessible, such as:</p>
      <ul>
        <li>Aggregated Data: Usage statistics may be shared in blog posts or press releases.</li>
        <li>
          Reactions: Reviews, ratings, comments, likes, and reactions match the visibility on
          relevant Pages.
        </li>
      </ul>
      <p>
        <strong>Audience Members:</strong>
      </p>
      <ul>
        <li>Public profile details, social media links, user badges, and bio</li>
        <li>Supported creators and subscription details</li>
        <li>Show reviews and ratings</li>
      </ul>
      <p>
        <strong>Creators:</strong>
      </p>
      <ul>
        <li>Public profile details, social media links, and bio</li>
        <li>Creator page content, membership tier details</li>
        <li>Show page information, including reviews and ratings</li>
        <li>Member count</li>
      </ul>

      <h3>Information Shared with Third Parties</h3>
      <p>
        Attractr prioritizes user privacy and does not sell your data. Data is shared only under the
        following conditions:
      </p>
      <ul>
        <li>
          <strong>Service Providers:</strong> Attractr may share data with contracted services for
          hosting, security, payment processing, analytics, and other support, ensuring they use
          personal data solely on Attractr’s behalf under strict confidentiality agreements.
        </li>
        <li>
          <strong>Government and Law Enforcement:</strong>
          <ul>
            <li>
              Tax Reporting: Creator earnings and transactional tax data may be shared with relevant
              authorities.
            </li>
            <li>
              Legal Obligations: Data may be disclosed to protect Attractr or in response to legal
              demands, with user notification whenever legally permissible.
            </li>
          </ul>
        </li>
        <li>
          <strong>Company Transactions:</strong> During mergers, sales, or reorganizations, data may
          be transferred under this Privacy Policy’s commitments.
        </li>
        <li>
          <strong>Payment Partners:</strong> To comply with partner requirements, Attractr may
          verify age information for certain creators, particularly for age-sensitive content.
        </li>
        <li>
          <strong>Team Permissions:</strong> Creators can grant permissions to teammates, who gain
          access to manage page settings based on selected roles.
        </li>
        <li>
          <strong>Connected Third-Party Services:</strong> When linking external accounts, you
          authorize access to specific information, and Attractr follows your settings to grant
          selected permissions.
        </li>
      </ul>

      <h3>Verification of Requests</h3>
      <p>
        To safeguard personal data, users will generally verify privacy-related requests by logging
        into their Attractr account or through an alternative identity verification method. If we
        cannot verify your identity satisfactorily, we reserve the right to deny the request to
        prevent unauthorized data access.
      </p>

      <h3>Quebec and Canadian Privacy Laws</h3>
      <p>Attractr is headquartered in Montreal, Quebec, Canada.</p>
      <p>
        The Act Respecting the Protection of Personal Information in the Private Sector (ARPPIPS) in
        Quebec, and Canada's Personal Information Protection and Electronic Documents Act (PIPEDA)
        grant Canadian residents certain rights over their data, including access to, correction,
        and deletion of personal information collected by businesses.
      </p>
      <p>
        Attractr is committed to these privacy standards and ensures all users can view, manage, and
        request deletion of their data. To exercise these rights, contact{' '}
        <a href="mailto:team@attractr.live">team@attractr.live</a> and submit a request to delete or
        review your information.
      </p>
      <p>
        For additional protection, Attractr does not disclose users’ data to third parties for
        direct marketing purposes without consent. Adjust your privacy preferences at any time by
        accessing your account settings or contacting our support team.
      </p>

      <h3>EU Privacy Laws and Data Transfers</h3>
      <p>
        Attractr is a global platform. By using Attractr, you agree that your data may be
        transferred internationally, including to Canada, and managed per this Privacy Policy.
        Attractr serves as a data controller under applicable laws.
      </p>
      <p>
        If you are an EU, UK, or Swiss resident, Attractr processes your data on legal grounds such
        as contractual necessity, legitimate interest, compliance with legal obligations, or
        consent. You may withdraw consent when applicable.
      </p>

      <h4>Contractual Safeguards</h4>
      <p>
        When transferring data outside the EU, UK, or EFTA, Attractr relies on contracts to ensure
        data protection.
      </p>

      <h3>Our Data Retention Period</h3>
      <p>
        Attractr retains your personal data as long as it’s necessary to deliver our services or
        until you request its deletion. In certain cases, we may retain some data even after account
        deletion, to meet legal obligations or comply with applicable regulations.
      </p>

      <h3>Children</h3>
      <p>
        Attractr is not intended for children under the age of 13. Users must also be old enough in
        their country to consent to data processing (or have parental consent where applicable). To
        create a page on Attractr or purchase memberships, users must be at least 18 years old or
        have permission from a parent or legal guardian. Subscriptions to creators with Adult/18+
        content are restricted to users who are at least 18.
      </p>

      <h3>Changes</h3>
      <p>
        We may update this Privacy Policy periodically. If significant changes are made, we will
        notify you before they take effect. Continued use of Attractr after updates means you agree
        to the revised policy. For questions, please contact{' '}
        <a href="mailto:team@attractr.live">team@attractr.live</a>.
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
