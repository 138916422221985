import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { Slider, ExperienceCard } from '../../components';
import classNames from 'classnames';

import css from './SectionExperiences.module.css';

// slidesToShow
const SLIDES_TO_SHOW_TABLET = 2;
const SLIDES_TO_SHOW_DESKTOP = 4;
// slidesToScroll
const SLIDES_TO_SCROLL_TABLET = 2;
const SLIDES_TO_SCROLL_DESKTOP = 2;

const SectionExperiences = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    arrowsClassName,
    intl,
    experiences,
    queryExperiencesInProgress,
    queryExperiencesError,
    slidesToShow,
    slidesToScroll,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(css.title, titleClassName);

  if (queryExperiencesInProgress || queryExperiencesError) {
    return null;
  }

  const sliderTitle = intl.formatMessage({ id: 'SectionExperiences.title' });
  return (
    <div className={classes}>
      <Slider
        id="LandingPage.experiencesSlider"
        headerClassName={css.header}
        titleClassName={titleClasses}
        title={sliderTitle}
        itemClassName={css.sliderItem}
        arrowsClassName={arrowsClassName}
        slidesToShow={{
          tablet: slidesToShow?.tablet || SLIDES_TO_SHOW_TABLET,
          desktop: slidesToShow?.desktop || SLIDES_TO_SHOW_DESKTOP,
        }}
        slidesToScroll={{
          tablet: slidesToScroll?.tablet || SLIDES_TO_SCROLL_TABLET,
          desktop: slidesToScroll?.desktop || SLIDES_TO_SCROLL_DESKTOP,
        }}
      >
        {experiences.map(l => {
          return <ExperienceCard key={l.id.uuid} listing={l} />;
        })}
      </Slider>
    </div>
  );
};

SectionExperiences.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  arrowsClassName: null,
  queryExperiencesInProgress: false,
  queryExperiencesError: null,
};

const { arrayOf, bool, string } = PropTypes;

SectionExperiences.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  arrowsClassName: string,

  experiences: arrayOf(propTypes.listing),
  queryExperiencesInProgress: bool.isRequired,
  queryExperiencesError: propTypes.error,
};

export default SectionExperiences;
