import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { TRANSITION_CONFIRM_PAYMENT } from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  fetchAndFilterTransactions,
  fetchAndFilterTransactionsWithIntegrationAPI,
} from '../InboxPage/InboxPage.duck';
import { fetchSelectedOrganization } from '../../ducks/organizations.duck';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_PAYMENTS_REQUEST = 'app/PaymentHistoryPage/FETCH_PAYMENTS_REQUEST';
export const FETCH_PAYMENTS_SUCCESS = 'app/PaymentHistoryPage/FETCH_PAYMENTS_SUCCESS';
export const FETCH_PAYMENTS_ERROR = 'app/PaymentHistoryPage/FETCH_PAYMENTS_ERROR';

export const FETCH_ALL_PAYMENTS_REQUEST = 'app/PaymentHistoryPage/FETCH_ALL_PAYMENTS_REQUEST';
export const FETCH_ALL_PAYMENTS_SUCCESS = 'app/PaymentHistoryPage/FETCH_ALL_PAYMENTS_SUCCESS';
export const FETCH_ALL_PAYMENTS_ERROR = 'app/PaymentHistoryPage/FETCH_ALL_PAYMENTS_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchPaymentsError: null,
  pagination: null,
  transactionRefs: [],
};

export default function paymentHistoryPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_PAYMENTS_REQUEST:
      return { ...state, fetchInProgress: true, fetchPaymentsError: null };
    case FETCH_PAYMENTS_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_PAYMENTS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchPaymentsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchPaymentsRequest = () => ({ type: FETCH_PAYMENTS_REQUEST });
const fetchPaymentsSuccess = response => ({
  type: FETCH_PAYMENTS_SUCCESS,
  payload: response,
});
const fetchPaymentsError = e => ({
  type: FETCH_PAYMENTS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const PAYMENTS_PAGE_SIZE = 10;

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  dispatch(fetchPaymentsRequest());

  const { page = 1 } = parse(search);

  try {
    const selectedOrganization = await dispatch(fetchSelectedOrganization());
    const selectedOrganizationId = selectedOrganization ? selectedOrganization.id : '';

    const queryParams = {
      lastTransitions: TRANSITION_CONFIRM_PAYMENT,
      page,
      perPage: PAYMENTS_PAGE_SIZE,
      organizationId: selectedOrganizationId,
    };

    const filteredTransactionsResponse = selectedOrganization?.invited
      ? await dispatch(
          fetchAndFilterTransactionsWithIntegrationAPI(queryParams, selectedOrganization)
        )
      : await dispatch(fetchAndFilterTransactions(queryParams));

    dispatch(addMarketplaceEntities(filteredTransactionsResponse));
    dispatch(fetchPaymentsSuccess(filteredTransactionsResponse));

    return filteredTransactionsResponse; // Return the filtered response
  } catch (error) {
    // Handle errors
    dispatch(fetchPaymentsError(storableError(error)));
    throw error; // Consider whether to re-throw the error or not
  }
};
