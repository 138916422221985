import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconVideos.module.css';

const IconVideos = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="37"
      viewBox="0 0 40 37"
      fill="none"
    >
      <g clipPath="url(#clip0_257_17)">
        <path
          d="M3.56472 6.55007L22.0098 2.62945C22.6658 2.49002 23.3106 2.90876 23.45 3.56474L27.3706 22.0098C27.5101 22.6658 27.0913 23.3106 26.4353 23.45L7.99026 27.3706C7.33429 27.5101 6.68948 27.0913 6.55005 26.4354L2.62943 7.99029C2.49 7.33431 2.90874 6.68951 3.56472 6.55007Z"
          fill="#fff"
          stroke="#0e0f0c"
        />
        <path
          d="M3.03141 12.2863L24.8301 7.65284M8.90029 11.0388L10.1681 4.63532M16.446 9.43494L17.7138 3.03143M13.7369 21.5077L12.3718 15.0855C12.3555 14.9858 12.3639 14.8836 12.3963 14.788C12.4287 14.6923 12.4841 14.606 12.5577 14.5368C12.6312 14.4675 12.7206 14.4173 12.818 14.3907C12.9154 14.364 13.0179 14.3617 13.1165 14.3839L19.3643 16.4033C19.4663 16.4397 19.5572 16.5016 19.6285 16.5831C19.6998 16.6646 19.749 16.7629 19.7715 16.8688C19.794 16.9747 19.7891 17.0846 19.7571 17.1881C19.7251 17.2915 19.6672 17.385 19.5888 17.4597L14.7061 21.8625C14.6228 21.9195 14.527 21.9557 14.4269 21.9682C14.3267 21.9808 14.225 21.9692 14.1302 21.9345C14.0354 21.8998 13.9503 21.843 13.8819 21.7687C13.8135 21.6945 13.7638 21.605 13.7369 21.5077V21.5077Z"
          stroke="#0e0f0c"
        />
      </g>
      <g clipPath="url(#clip1_257_17)">
        <path
          d="M18.2229 5.8621L36.668 9.78272C37.3239 9.92215 37.7427 10.567 37.6032 11.2229L33.6826 29.668C33.5432 30.324 32.8984 30.7427 32.2424 30.6033L13.7973 26.6827C13.1414 26.5432 12.7226 25.8984 12.8621 25.2424L16.7827 6.79738C16.9221 6.14141 17.5669 5.72266 18.2229 5.8621Z"
          fill="#fff"
          stroke="#0e0f0c"
        />
        <path
          d="M15.4026 10.8855L37.2013 15.5189M21.2714 12.133L25.0341 6.7987M28.8171 13.7368L32.5799 8.40259M21.4318 23.664L22.7969 17.2418C22.8225 17.1441 22.8718 17.0542 22.9403 16.9799C23.0088 16.9057 23.0945 16.8494 23.1899 16.8161C23.2852 16.7827 23.3873 16.7732 23.4871 16.7885C23.587 16.8038 23.6816 16.8434 23.7626 16.9037L28.6488 21.2898C28.7272 21.3645 28.7851 21.458 28.8171 21.5614C28.8491 21.6649 28.854 21.7748 28.8315 21.8807C28.809 21.9866 28.7598 22.0849 28.6885 22.1664C28.6172 22.2479 28.5263 22.3098 28.4243 22.3462L22.1729 24.3823C22.0736 24.4005 21.9714 24.3946 21.8748 24.3653C21.7782 24.336 21.69 24.2841 21.6176 24.2138C21.5451 24.1436 21.4904 24.057 21.4581 23.9614C21.4258 23.8658 21.4168 23.7638 21.4318 23.664V23.664Z"
          stroke="#0e0f0c"
        />
      </g>
      <g clipPath="url(#clip2_257_17)">
        <path
          d="M10.5715 14.3571H29.4286C30.0992 14.3571 30.6429 14.9008 30.6429 15.5714V34.4285C30.6429 35.0992 30.0992 35.6428 29.4286 35.6428H10.5715C9.90083 35.6428 9.35718 35.0992 9.35718 34.4285V15.5714C9.35718 14.9008 9.90083 14.3571 10.5715 14.3571Z"
          fill="#fff"
          stroke="#0e0f0c"
        />
        <path
          d="M8.85718 19.8571H31.1429M14.8572 19.8571L17.4286 13.8571M22.5715 19.8571L25.1429 13.8571M17.4115 31.1028V24.5371C17.4163 24.4362 17.4457 24.338 17.4973 24.2512C17.5489 24.1643 17.621 24.0915 17.7074 24.039C17.7937 23.9866 17.8916 23.9561 17.9924 23.9503C18.0932 23.9445 18.194 23.9635 18.2857 24.0057L23.9772 27.28C24.0693 27.3368 24.1454 27.4162 24.1982 27.5107C24.251 27.6053 24.2787 27.7117 24.2787 27.82C24.2787 27.9282 24.251 28.0347 24.1982 28.1292C24.1454 28.2237 24.0693 28.3032 23.9772 28.36L18.2857 31.6514C18.1924 31.6898 18.0912 31.7053 17.9906 31.6967C17.8901 31.6881 17.793 31.6557 17.7075 31.602C17.622 31.5484 17.5505 31.4751 17.499 31.3883C17.4475 31.3014 17.4175 31.2036 17.4115 31.1028V31.1028Z"
          stroke="#0e0f0c"
        />
      </g>
      <defs>
        <clipPath id="clip0_257_17">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.767334 5.7572) rotate(-12)"
          />
        </clipPath>
        <clipPath id="clip1_257_17">
          <rect width="24" height="24" fill="white" transform="translate(15.9899 4) rotate(12)" />
        </clipPath>
        <clipPath id="clip2_257_17">
          <rect width="24" height="24" fill="white" transform="translate(8 13)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

IconVideos.defaultProps = {
  className: null,
  rootClassName: null,
};

IconVideos.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconVideos;
