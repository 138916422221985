import React from 'react';
import { string, func, object, bool, oneOfType } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { AvatarMedium, ExpandableBio, SecondaryButton, NamedLink } from '../../components';
import { ensureUser, ensureCurrentUser, userDisplayNameAsString } from '../../util/data';
import { propTypes } from '../../util/types';
import moment from 'moment';

import css from './UserCard.module.css';

const EXPANDABLE_BIO_LENGTH = 330; // Roughly 4 lines of text

const UserCard = props => {
  const { rootClassName, className, user, currentUser, onContactUser, isArtist } = props;

  const userIsCurrentUser = user && user.type === 'currentUser';
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isCurrentUser =
    ensuredUser.id && ensuredCurrentUser.id && ensuredUser.id.uuid === ensuredCurrentUser.id.uuid;
  const { bio, publicData } = ensuredUser.attributes.profile;
  const displayName = userDisplayNameAsString(user);

  const userCreatedAt = ensuredUser.attributes.createdAt;
  const formattedUserCreatedAt = moment(userCreatedAt).format('YYYY');
  const userFollowers = publicData?.followerIds ? publicData.followerIds.length : null;

  const handleContactUserClick = () => {
    onContactUser(user);
  };

  const hasBio = !!bio;
  const classes = classNames(rootClassName || css.root, className);

  const contact = !isCurrentUser ? (
    <SecondaryButton
      className={css.contact}
      onClick={handleContactUserClick}
      enforcePagePreloadFor="SignupPage"
    >
      <FormattedMessage id="UserCard.contactUser" />
    </SecondaryButton>
  ) : null;

  return (
    <div className={classes}>
      <div className={css.header}>
        <NamedLink name="ArtistProfilePage" params={{ id: ensuredUser.id.uuid }}>
          <AvatarMedium className={css.avatar} user={user} disableProfileLink />
        </NamedLink>
        <div className={css.headingRow}>
          <h3 className={css.heading}>{displayName}</h3>
          <div className={css.hostSinceAndFollowers}>
            <span>
              <FormattedMessage id="UserCard.hostSince" values={{ date: formattedUserCreatedAt }} />
            </span>
            {userFollowers ? (
              <>
                <span className={css.separator}>·</span>
                <span>
                  <FormattedMessage id="UserCard.followers" values={{ count: userFollowers }} />
                </span>
              </>
            ) : null}
            {ensuredUser?.id ? (
              <>
                <span className={css.separator}>·</span>
                <NamedLink
                  className={css.profileLink}
                  name={isArtist ? 'ArtistProfilePage' : 'ProfilePage'}
                  params={{ id: ensuredUser?.id?.uuid }}
                >
                  <FormattedMessage id="UserCard.viewProfile" />
                </NamedLink>
              </>
            ) : null}
          </div>
        </div>
      </div>
      {hasBio ? (
        <ExpandableBio className={css.bio} bio={bio} maxLength={EXPANDABLE_BIO_LENGTH} />
      ) : null}
      {contact}
    </div>
  );
};

UserCard.defaultProps = {
  rootClassName: null,
  className: null,
  user: null,
  currentUser: null,
  isArtist: false,
};

UserCard.propTypes = {
  rootClassName: string,
  className: string,
  user: oneOfType([propTypes.user, propTypes.currentUser, object]),
  currentUser: propTypes.currentUser,
  onContactUser: func.isRequired,
  isArtist: bool.isRequired,
};

export default UserCard;
