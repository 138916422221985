import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconRepeat.module.css';

const IconRepeat = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="14"
      width="14"
    >
      <g fill="none" strokeLinecap="round" strokeLinejoin="round">
        <path d="M6.5 13.5 4 11h8.5M13.5 4l-1 3.5L8 .5M2 3.5h3.5l-5 7" />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconRepeat.defaultProps = {
  className: null,
  rootClassName: null,
};

IconRepeat.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconRepeat;
