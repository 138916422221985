import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconClap.module.css';

const IconClap = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 456.165 456"
    >
      <path
        d="m353.96 186.227-44.413 25.64c12.254-7.058 16.465-22.715 9.406-34.969-7.058-12.253-22.715-16.464-34.969-9.41l-67.359 38.89-36.113 20.849a34.137 34.137 0 0 0 11.922-47.602c-4.946-7.563-14.97-9.895-22.743-5.29L140.09 191.41l-41.184 23.77c-59.703 34.504-84.586 110.039-52.96 171.293a127.899 127.899 0 0 0 77.71 64.304 127.91 127.91 0 0 0 100.145-12.007l133.058-76.801c12.246-6.742 17.18-21.817 11.286-34.496a25.593 25.593 0 0 0-15.543-12.953 25.606 25.606 0 0 0-20.098 2.378l41.84-24.16c12.265-6.738 17.207-21.828 11.304-34.511a25.604 25.604 0 0 0-35.632-10.586l28.312-16.344c12.246-6.738 17.176-21.813 11.281-34.488a25.59 25.59 0 0 0-15.546-12.961 25.583 25.583 0 0 0-20.102 2.379zm0 0"
        fill="#fec000"
        data-original="#fec000"
      />
      <path
        d="M306.402 222.922 202.73 282.777c-2.476 1.453-5.542 1.465-8.03.035a7.997 7.997 0 0 1 .03-13.89l103.672-59.856a7.999 7.999 0 0 1 10.883 2.954c2.2 3.808.91 8.68-2.883 10.902zM329.363 327.93l-75.418 43.543a7.999 7.999 0 0 1-12.047-6.918 8.002 8.002 0 0 1 4.047-6.938l75.418-43.543a7.994 7.994 0 0 1 10.88 2.953c2.198 3.809.913 8.68-2.88 10.903zM346.848 258.7 228.336 327.12a7.991 7.991 0 0 1-8.027.035 7.988 7.988 0 0 1-4.016-6.953 7.999 7.999 0 0 1 4.043-6.937l118.512-68.422c3.824-2.176 8.683-.856 10.882 2.953s.915 8.676-2.882 10.902zM177.371 238.281l-59.379 34.281a8.006 8.006 0 0 1-10.883-2.953c-2.195-3.808-.91-8.68 2.883-10.902l59.38-34.281a7.991 7.991 0 0 1 8.026-.035 7.988 7.988 0 0 1 4.016 6.953 7.999 7.999 0 0 1-4.043 6.937zm0 0"
        fill="#d9a600"
        data-original="#d9a600"
      />
      <g fill="#aeadb3">
        <path
          d="M396.05 91.977a7.998 7.998 0 0 1-5.655-13.657l52-52a7.998 7.998 0 1 1 11.313 11.313l-52.001 52a8.001 8.001 0 0 1-5.656 2.344zM408.05 127.977a7.998 7.998 0 0 1-4.128-14.856l40-24a7.996 7.996 0 0 1 8.078-.238 7.994 7.994 0 0 1 4.164 6.93 7.986 7.986 0 0 1-4.004 7.019l-40 24a7.932 7.932 0 0 1-4.11 1.145zM360.05 87.977a8.003 8.003 0 0 1-6.804-3.793 8.011 8.011 0 0 1-.348-7.782l24-48a8.005 8.005 0 0 1 10.735-3.586 8.01 8.01 0 0 1 3.586 10.739l-24 48a8.002 8.002 0 0 1-7.168 4.422zM176.05 71.977a7.995 7.995 0 0 1-7.35-4.848l-24-56A7.996 7.996 0 0 1 148.94.73a8.004 8.004 0 0 1 10.461 4.094l24 56a8.002 8.002 0 0 1-7.352 11.152zM208.05 71.977a8 8 0 0 1-8-8v-48a8 8 0 0 1 16 0v48a8 8 0 0 1-8 8zM144.05 87.977a7.99 7.99 0 0 1-6.257-3l-32-40a7.998 7.998 0 0 1 1.25-11.247 8.001 8.001 0 0 1 11.246 1.247l32 40a8.01 8.01 0 0 1 .969 8.464 8 8 0 0 1-7.207 4.536zm0 0"
          data-original="#aeadb3"
        />
      </g>
      <path
        d="m278.09 115.617-36.266 36.266c10.012-9.992 10.028-26.203.04-36.215-9.993-10.008-26.204-10.023-36.216-.035l-54.992 55c.465-.473-6.664 6.664-29.488 29.488a34.147 34.147 0 0 0 10.004-24.7 34.148 34.148 0 0 0-10.8-24.362c-6.74-6.024-17.028-5.672-23.337.8l-24.144 24.118-33.641 33.656C-9.55 258.379-14 337.785 32.402 388.777a127.932 127.932 0 0 0 91.707 41.993 127.94 127.94 0 0 0 93.63-37.512l108.64-108.64c10.082-9.68 10.941-25.516 1.965-36.231a25.603 25.603 0 0 0-18.364-8.496 25.618 25.618 0 0 0-18.796 7.496l34.16-34.168c10.09-9.676 10.953-25.516 1.976-36.235a25.604 25.604 0 0 0-37.16-1.007l23.121-23.118c10.078-9.687 10.938-25.523 1.969-36.242a25.6 25.6 0 0 0-37.16-1zm0 0"
        fill="#ffe670"
        data-original="#ffe670"
      />
      <path
        d="M241.648 163.379 157 248.027a8 8 0 0 1-11.215-.097 7.997 7.997 0 0 1-.094-11.215l84.645-84.649a8 8 0 0 1 11.312 11.313zM291.008 258.867l-61.598 61.598a8.001 8.001 0 0 1-13.496-3.531 8.012 8.012 0 0 1 2.184-7.782l61.601-61.597a7.996 7.996 0 0 1 11.211.097 7.997 7.997 0 0 1 .098 11.215zM289.984 187.465l-96.8 96.8a7.997 7.997 0 0 1-11.313.005 7.998 7.998 0 0 1-.004-11.317l96.797-96.8a8.005 8.005 0 0 1 11.316-.005c3.125 3.125 3.13 8.192.004 11.317zM120.992 211.61l-48.488 48.488a7.997 7.997 0 0 1-11.313-.004 7.998 7.998 0 0 1 .004-11.317l48.453-48.48a8 8 0 0 1 7.782-2.18 8 8 0 0 1 5.715 5.711 8.001 8.001 0 0 1-2.184 7.781zm0 0"
        fill="#ebb300"
        data-original="#ebb300"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconClap.defaultProps = {
  className: null,
  rootClassName: null,
};

IconClap.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconClap;
