import { useState, useEffect } from 'react';
import { sanitizeEntity } from './sanitize';
import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import config from '../config';

/**
 * Combine the given relationships objects
 *
 * See: http://jsonapi.org/format/#document-resource-object-relationships
 */
export const combinedRelationships = (oldRels, newRels) => {
  if (!oldRels && !newRels) {
    // Special case to avoid adding an empty relationships object when
    // none of the resource objects had any relationships.
    return null;
  }
  return { ...oldRels, ...newRels };
};

/**
 * Combine the given resource objects
 *
 * See: http://jsonapi.org/format/#document-resource-objects
 */
export const combinedResourceObjects = (oldRes, newRes) => {
  const { id, type } = oldRes;
  if (newRes.id.uuid !== id.uuid || newRes.type !== type) {
    throw new Error('Cannot merge resource objects with different ids or types');
  }
  const attributes = newRes.attributes || oldRes.attributes;
  const attributesOld = oldRes.attributes || {};
  const attributesNew = newRes.attributes || {};
  // Allow (potentially) sparse attributes to update only relevant fields
  const attrs = attributes ? { attributes: { ...attributesOld, ...attributesNew } } : null;
  const relationships = combinedRelationships(oldRes.relationships, newRes.relationships);
  const rels = relationships ? { relationships } : null;
  return { id, type, ...attrs, ...rels };
};

/**
 * Combine the resource objects form the given api response to the
 * existing entities.
 */
export const updatedEntities = (oldEntities, apiResponse) => {
  const { data, included = [] } = apiResponse;
  const objects = (Array.isArray(data) ? data : [data]).concat(included);

  const newEntities = objects.reduce((entities, curr) => {
    const { id, type } = curr;

    // Some entities (e.g. listing and user) might include extended data,
    // you should check if src/util/sanitize.js needs to be updated.
    const current = sanitizeEntity(curr);

    entities[type] = entities[type] || {};
    const entity = entities[type][id.uuid];
    entities[type][id.uuid] = entity ? combinedResourceObjects({ ...entity }, current) : current;

    return entities;
  }, oldEntities);

  return newEntities;
};

/**
 * Denormalise the entities with the resources from the entities object
 *
 * This function calculates the dernormalised tree structure from the
 * normalised entities object with all the relationships joined in.
 *
 * @param {Object} entities entities object in the SDK Redux store
 * @param {Array<{ id, type }} resources array of objects
 * with id and type
 * @param {Boolean} throwIfNotFound wheather to skip a resource that
 * is not found (false), or to throw an Error (true)
 *
 * @return {Array} the given resource objects denormalised that were
 * found in the entities
 */
export const denormalisedEntities = (entities, resources, throwIfNotFound = true) => {
  const denormalised = resources.map(res => {
    const { id, type } = res;
    const entityFound = entities[type] && id && entities[type][id.uuid];
    if (!entityFound) {
      if (throwIfNotFound) {
        throw new Error(`Entity with type "${type}" and id "${id ? id.uuid : id}" not found`);
      }
      return null;
    }
    const entity = entities[type][id.uuid];
    const { relationships, ...entityData } = entity;

    if (relationships) {
      // Recursively join in all the relationship entities
      return reduce(
        relationships,
        (ent, relRef, relName) => {
          // A relationship reference can be either a single object or
          // an array of objects. We want to keep that form in the final
          // result.
          const hasMultipleRefs = Array.isArray(relRef.data);
          const multipleRefsEmpty = hasMultipleRefs && relRef.data.length === 0;
          if (!relRef.data || multipleRefsEmpty) {
            ent[relName] = hasMultipleRefs ? [] : null;
          } else {
            const refs = hasMultipleRefs ? relRef.data : [relRef.data];

            // If a relationship is not found, an Error should be thrown
            const rels = denormalisedEntities(entities, refs, true);

            ent[relName] = hasMultipleRefs ? rels : rels[0];
          }
          return ent;
        },
        entityData
      );
    }
    return entityData;
  });
  return denormalised.filter(e => !!e);
};

/**
 * Denormalise the data from the given SDK response
 *
 * @param {Object} sdkResponse response object from an SDK call
 *
 * @return {Array} entities in the response with relationships
 * denormalised from the included data
 */
export const denormalisedResponseEntities = sdkResponse => {
  const apiResponse = sdkResponse.data;
  const data = apiResponse.data;
  const resources = Array.isArray(data) ? data : [data];

  if (!data || resources.length === 0) {
    return [];
  }

  const entities = updatedEntities({}, apiResponse);
  return denormalisedEntities(entities, resources);
};

/**
 * Denormalize JSON object.
 * NOTE: Currently, this only handles denormalization of image references
 *
 * @param {JSON} data from Asset API (e.g. page asset)
 * @param {JSON} included array of asset references (currently only images supported)
 * @returns deep copy of data with images denormalized into it.
 */
const denormalizeJsonData = (data, included) => {
  let copy;

  // Handle strings, numbers, booleans, null
  if (data === null || typeof data !== 'object') {
    return data;
  }

  // At this point the data has typeof 'object' (aka Array or Object)
  // Array is the more specific case (of Object)
  if (data instanceof Array) {
    copy = data.map(datum => denormalizeJsonData(datum, included));
    return copy;
  }

  // Generic Objects
  if (data instanceof Object) {
    copy = {};
    Object.entries(data).forEach(([key, value]) => {
      // Handle denormalization of image reference
      const hasImageRefAsValue =
        typeof value == 'object' &&
        value._ref &&
        value._ref?.type === 'imageAsset' &&
        value._ref?.id;
      // If there is no image included,
      // the _ref might contain parameters for image resolver (Asset Delivery API resolves image URLs on the fly)
      const hasUnresolvedImageRef =
        typeof value == 'object' && value._ref && value._ref?.resolver === 'image';

      if (hasImageRefAsValue) {
        const foundRef = included.find(inc => inc.id === value._ref?.id);
        copy[key] = foundRef;
      } else if (hasUnresolvedImageRef) {
        // Don't add faulty image ref
        // Note: At the time of writing, assets can expose resolver configs,
        //       which we don't want to deal with.
      } else {
        copy[key] = denormalizeJsonData(value, included);
      }
    });
    return copy;
  }

  throw new Error("Unable to traverse data! It's not JSON.");
};

/**
 * Denormalize asset json from Asset API.
 * @param {JSON} assetJson in format: { data, included }
 * @returns deep copy of asset data with images denormalized into it.
 */
export const denormalizeAssetData = assetJson => {
  const { data, included } = assetJson || {};
  return denormalizeJsonData(data, included);
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} transaction entity object, which is to be ensured against null values
 */
export const ensureTransaction = (transaction, booking = null, listing = null, provider = null) => {
  const empty = {
    id: null,
    type: 'transaction',
    attributes: {},
    booking,
    listing,
    provider,
  };
  return { ...empty, ...transaction };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} booking entity object, which is to be ensured against null values
 */
export const ensureBooking = booking => {
  const empty = { id: null, type: 'booking', attributes: {} };
  return { ...empty, ...booking };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureListing = listing => {
  const empty = {
    id: null,
    type: 'listing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureOwnListing = listing => {
  const empty = {
    id: null,
    type: 'ownListing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} user entity object, which is to be ensured against null values
 */
export const ensureUser = user => {
  const empty = { id: null, type: 'user', attributes: { profile: {} } };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} current user entity object, which is to be ensured against null values
 */
export const ensureCurrentUser = user => {
  const empty = { id: null, type: 'currentUser', attributes: { profile: {} }, profileImage: {} };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that properties exists.
 *
 * @param {Object} profile organization profile entity
 */
export const ensureOrganizationProfile = profile => {
  const empty = { id: null, name: null, bio: null, website: null, profileImage: {} };
  return { ...empty, ...profile };
};

/**
 * Get the current user reactions from the protected data
 *
 * @param {Object} currentUser
 */
export const getCurrentUserReactions = currentUser => {
  const protectedData = currentUser?.attributes?.profile?.protectedData || {};
  return protectedData.reactions || [];
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} time slot entity object, which is to be ensured against null values
 */
export const ensureTimeSlot = timeSlot => {
  const empty = { id: null, type: 'timeSlot', attributes: {} };
  return { ...empty, ...timeSlot };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureDayAvailabilityPlan = availabilityPlan => {
  const empty = { type: 'availability-plan/day', entries: [] };
  return { ...empty, ...availabilityPlan };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureAvailabilityException = availabilityException => {
  const empty = { id: null, type: 'availabilityException', attributes: {} };
  return { ...empty, ...availabilityException };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensureStripeCustomer = stripeCustomer => {
  const empty = { id: null, type: 'stripeCustomer', attributes: {} };
  return { ...empty, ...stripeCustomer };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensurePaymentMethodCard = stripePaymentMethod => {
  const empty = {
    id: null,
    type: 'stripePaymentMethod',
    attributes: { type: 'stripe-payment-method/card', card: {} },
  };
  const cardPaymentMethod = { ...empty, ...stripePaymentMethod };

  if (cardPaymentMethod.attributes.type !== 'stripe-payment-method/card') {
    throw new Error(`'ensurePaymentMethodCard' got payment method with wrong type.
      'stripe-payment-method/card' was expected, received ${cardPaymentMethod.attributes.type}`);
  }

  return cardPaymentMethod;
};

/**
 * Ensures that the passed user is currently online.
 *
 * @param {object} user
 *
 * @returns {bool} online flag
 */
export const ensureUserOnline = user => {
  const lastSeen = user?.attributes?.profile?.publicData?.lastSeen;

  if (lastSeen) {
    const currentDate = new Date();
    const dateFromDatabase = new Date(lastSeen);
    const fiveMin = 5 * 60 * 1000;

    return !(currentDate - dateFromDatabase > fiveMin);
  } else {
    return lastSeen;
  }
};

/**
 * Get the display name of the given user as string. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned or deleted users, a translated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayNameAsString = (user, defaultUserDisplayName) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName = hasProfile && user.attributes.profile.displayName;

  const hasPublicData = hasProfile && user.attributes.profile.publicData;
  const hasPublicDataName = hasPublicData && user.attributes.profile.publicData.name;
  const hasPublicDataDisplayName =
    hasPublicDataName && user.attributes.profile.publicData.name.displayName;

  if (hasPublicDataDisplayName) {
    return user.attributes.profile.publicData.name.displayName;
  } else if (hasDisplayName) {
    return user.attributes.profile.displayName;
  } else {
    return defaultUserDisplayName || '';
  }
};

/**
 * DEPRECATED: Use userDisplayNameAsString function or UserDisplayName component instead
 *
 * @param {propTypes.user} user
 * @param {String} bannedUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayName = (user, bannedUserDisplayName) => {
  console.warn(
    `Function userDisplayName is deprecated!
User function userDisplayNameAsString or component UserDisplayName instead.`
  );

  return userDisplayNameAsString(user, bannedUserDisplayName);
};

/**
 * Get the abbreviated name of the given user. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned  or deleted users, a default abbreviated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserAbbreviatedName
 *
 * @return {String} abbreviated name that can be rendered in the UI
 * (e.g. in Avatar initials)
 */
export const userAbbreviatedName = (user, defaultUserAbbreviatedName) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName = hasProfile && user.attributes.profile.abbreviatedName;

  if (hasDisplayName) {
    return user.attributes.profile.abbreviatedName;
  } else {
    return defaultUserAbbreviatedName || '';
  }
};

/**
 * A customizer function to be used with the
 * mergeWith function from lodash.
 *
 * Works like merge in every way exept that on case of
 * an array the old value is completely overridden with
 * the new value.
 *
 * @param {Object} objValue Value of current field, denoted by key
 * @param {Object} srcValue New value
 * @param {String} key Key of the field currently being merged
 * @param {Object} object Target object that is receiving values from source
 * @param {Object} source Source object that is merged into object param
 * @param {Object} stack Tracks merged values
 *
 * @return {Object} New value for objValue if the original is an array,
 * otherwise undefined is returned, which results in mergeWith using the
 * standard merging function
 */
export const overrideArrays = (objValue, srcValue, key, object, source, stack) => {
  if (isArray(objValue)) {
    return srcValue;
  }
};

/**
 * Humanizes a line item code. Strips the "line-item/" namespace
 * definition from the beginnign, replaces dashes with spaces and
 * capitalizes the first character.
 *
 * @param {string} code a line item code
 *
 * @return {string} returns the line item code humanized
 */
export const humanizeLineItemCode = code => {
  if (!/^line-item\/.+/.test(code)) {
    throw new Error(`Invalid line item code: ${code}`);
  }
  const lowercase = code.replace(/^line-item\//, '').replace(/-/g, ' ');

  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

/**
 * Moves an array elements in different position
 *
 * @param {array} array
 * @param {number} from
 * @param {number} to
 *
 * @return {array}
 */
export const arrayMove = (array, from, to) => {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);

  return array;
};

/**
 * Calculates the average of all reviews
 *
 * @param {array} reviews - Array of reviews with 'attributes.rating' as numbers or strings.
 *
 * @return {number} - The average of the reviews.
 */
export const calculateAverageReview = reviews => {
  if (!reviews) {
    throw new Error('You need to pass an array of reviews.');
  }

  const reviewRatings = reviews.map(r => Number(r.attributes.rating)); // Convert to numbers
  const averageReview =
    reviewRatings.length > 0 ? reviewRatings.reduce((a, b) => a + b, 0) / reviews.length : 0;

  // Check if the average has decimal values
  if (Number.isInteger(averageReview)) {
    return averageReview.toFixed(0); // Display as an integer
  } else {
    return averageReview.toFixed(1); // Display with one decimal place
  }
};

/**
 * Check if window isn't undefined as this
 * is SSR (Server side rendering) app and
 * whenever we're using a window object we
 * need to check if it's defined.
 */
export const isWindowDefined = () => typeof window !== 'undefined';

/**
 * Filters an array of objects by unique ID.
 *
 * @param {array} array array of objects
 *
 * @return {array} unique array of objects
 */
export const filterArrayByUniqueID = array =>
  array.filter((value, index, self) => self.findIndex(v => v.id === value.id) === index);

export const formattedTimeslotParams = params => {
  const hasTimeslotParams = !!(
    params &&
    params.id &&
    params.name &&
    params.access &&
    params.start &&
    params.end &&
    params.meeting &&
    params.quantity
  );

  return hasTimeslotParams
    ? {
        id: params.id,
        name: params.name,
        access: params.access,
        meeting: params.meeting,
        quantity: params.quantity,
        bookingDates: {
          bookingStart: new Date(params.start),
          bookingEnd: new Date(params.end),
        },
      }
    : null;
};

/**
 * Validates if two arrays has the same values.
 *
 * @param {array} arr
 * @param {array} target
 *
 * @return {boolean}
 */
export const validateArrayElements = (arr, target) => target.every(v => arr.includes(v));

/**
 * Finds the top review based on likes and dislikes.
 *
 * @param {Array} reviews - The array of review objects.
 *
 * @return {Object} - Featured review.
 */
export const findFeaturedReview = reviews => {
  if (!Array.isArray(reviews) || reviews.length === 0) {
    // Handle invalid input or empty array
    return null;
  }

  // Filter out reviews with no likes
  const reviewsWithLikes = reviews.filter(review => review.attributes.likes > 0);

  // If there are no reviews with likes, return null
  if (reviewsWithLikes.length === 0) {
    return null;
  }

  // Sort reviews with likes based on the criteria for a featured review
  const sortedReviews = reviewsWithLikes.sort((a, b) => {
    // Compare based on likes and dislikes
    const likesDiff = b.attributes.likes - a.attributes.likes;
    const dislikesDiff = a.attributes.dislikes - b.attributes.dislikes;

    // If likes are the same, prioritize fewer dislikes
    if (likesDiff === 0) {
      return dislikesDiff;
    }

    return likesDiff;
  });

  // Return the top review after sorting
  return sortedReviews[0];
};

/**
 * Gets the show ratings from the user's profile public data.
 *
 * @param {Object} user - The user object containing attributes, including profile and publicData.
 * @return {Array} - An array of show ratings.
 */
export const getUserShowRatings = user => {
  const showRatings = user?.attributes?.profile?.publicData?.showRatings || [];
  return showRatings;
};

/**
 * Gets the show ratings from the listing's metadata.
 *
 * @param {Object} user - The listing object.
 * @return {Array} - An array of show ratings.
 */
export const getListingShowRatings = listing => {
  const showRatings = listing?.attributes?.metadata?.showRatings || [];
  return showRatings;
};

/**
 * Finds the show rating for a specific listing in the given array of show ratings.
 *
 * @param {Array} showRatingsByUser - An array of show ratings for a user.
 * @param {string} listingId - The unique identifier of the listing to find the show rating for.
 * @return {Object|null} - The show rating object for the specified listing, or null if not found.
 */
export const findUserShowRatingByListingId = (showRatingsByUser, listingId) => {
  const showRating = showRatingsByUser.find(rating => rating.listingId === listingId);
  return showRating;
};

/**
 * Denormalizes show ratings from a listing for easier consumption.
 *
 * @param {Array} listingShowRatings - Array of show ratings from the listing.
 * @return {Array} - Denormalized show ratings with user id as 'id' and rating as 'attributes.rating'.
 */
export const denormaliseShowRatings = listingShowRatings => {
  return listingShowRatings.map(r => ({
    id: r.authorId,
    attributes: {
      rating: Number(r.rating),
    },
  }));
};

/**
 * Ensures http protocol in url.
 *
 * @param {string} url
 *
 * @return {string} url with https
 */
export const ensureHttps = url => {
  if (url && !url.startsWith('https://') && !url.startsWith('http://')) {
    url = 'https://' + url;
  }
  return url;
};

/**
 * Determines the user's tier based on their points and the provided tier configuration.
 *
 * @param {number} userPoints - The number of points the user has.
 * @param {object} [tiers=config.userTiers] - An optional tiers configuration object, defaults to `config.userTiers`.
 * @returns {object | null} - Returns the tier object for the user's tier, or null if no tier matches.
 */
export const getUserTier = (userPoints, tiers = config.userTiers) => {
  const tier = Object.values(tiers).find(({ min, max }) => userPoints >= min && userPoints <= max);
  return tier ? tier : null;
};

/**
 * Custom hook to generate a video thumbnail from a video URL.
 *
 * @param {string} videoUrl - The URL of the video.
 * @param {number} thumbnailTime - The time (in seconds) at which to capture the thumbnail (default is 1 second).
 * @returns {string | null} - The thumbnail as a base64 data URL or null if not yet generated.
 */
export const useVideoThumbnail = (videoUrl, thumbnailTime = 1) => {
  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    if (!videoUrl) return;

    const generateThumbnail = async () => {
      return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.src = videoUrl;
        video.crossOrigin = 'anonymous'; // Ensure cross-origin requests are allowed

        video.addEventListener('loadedmetadata', () => {
          video.currentTime = thumbnailTime; // Seek to the desired time for the thumbnail
        });

        video.addEventListener('seeked', () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          context.drawImage(video, 0, 0, canvas.width, canvas.height);

          try {
            const thumbnailUrl = canvas.toDataURL('image/png');
            resolve(thumbnailUrl);
          } catch (error) {
            console.error('Error generating thumbnail:', error);
            reject(null);
          }
        });

        video.addEventListener('error', err => {
          console.error('Error loading video:', err);
          reject(null);
        });
      });
    };

    const fetchThumbnail = async () => {
      try {
        const thumbnailUrl = await generateThumbnail();
        setThumbnail(thumbnailUrl); // Set the generated thumbnail in state
      } catch (error) {
        setThumbnail(null);
      }
    };

    fetchThumbnail();
  }, [videoUrl, thumbnailTime]);

  return thumbnail;
};

/**
 * Capitalizes the first letter of a given string.
 *
 * @param {string} string - The string to capitalize.
 * @returns {string|null} - The capitalized string or null if the input is falsy.
 */
export const capitalizeFirstLetter = string => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
};

/**
 * Function to generate a CSS linear gradient string based on an array of colors.
 *
 * @param {Array} colors - An array of color objects, each containing a "hex" property.
 *
 * @returns {string} A CSS gradient string that can be used in the `background-image` property.
 */
export const generateGradientFromColors = colors => {
  if (!colors || colors.length === 0) {
    return null;
  }

  const colorStops = colors.map((color, index) => {
    const opacity = 0.5 - index * 0.1;
    return `${color.hex}${Math.floor(opacity * 255)
      .toString(16)
      .padStart(2, '0')}`;
  });

  const gradient = `linear-gradient(90deg, ${colorStops.join(', ')})`;

  return gradient;
};

/**
 * Extracts the first part of a string before the first comma.
 *
 * @param {string} str - The input string from which to extract the first part.
 * @return {string|null} The first part of the string before the first comma, or null if the input is empty.
 */
export const getFirstStringPart = str => (str ? str.split(',')[0].trim() : null);

/**
 * Sums all views from the attributes.views property of each object in an array.
 *
 * @param {Array<Object>} data - The array of objects where each object contains an `attributes` property with a `views` field.
 * @return {number} The total sum of all `views` values found in the `attributes` of each object in the array. If no `views` field is found, it returns 0.
 */
export const sumViews = data =>
  data.reduce((total, item) => total + (item.attributes.views || 0), 0);

/**
 * Retrieves a specified number of upcoming events, starting from the current date and time.
 *
 * @param {Array} events - Array of event objects, each containing an `attributes.start` time in milliseconds.
 * @param {number} slideNumber - The number of soonest upcoming events to retrieve.
 * @return {Array} - Array of upcoming events, sorted by their start time, limited to the specified `slideNumber`.
 */
export const getUpcomingExceptions = (events, slideNumber = 2) => {
  if (!Array.isArray(events) || typeof slideNumber !== 'number' || slideNumber <= 0) {
    return [];
  }

  const currentTime = Date.now();

  return (
    events
      // Filter: keep only events with a start time in the future
      .filter(event => parseInt(event.attributes.start, 10) > currentTime)
      // Sort: order events by start time in ascending order (soonest first)
      .sort((a, b) => parseInt(a.attributes.start, 10) - parseInt(b.attributes.start, 10))
      // Limit: return only the specified number of upcoming events
      .slice(0, Math.floor(slideNumber))
  );
};

/**
 * Checks if the current listing belongs to an organization the user is invited to.
 *
 * @param {Object} currentListing - The current listing object.
 * @param {Array} organizationProfiles - List of user organization profiles.
 * @returns {boolean} - Returns `true` if the user is invited to the organization, otherwise `false`.
 */
export const isUserInvitedToOrganization = (currentListing, organizationProfiles) => {
  if (!currentListing || !organizationProfiles || !Array.isArray(organizationProfiles)) {
    return false;
  }

  const currentListingOrganizationId = currentListing?.attributes?.publicData?.organizationId;
  return organizationProfiles.some(org => org.id === currentListingOrganizationId);
};

/**
 * Checks if the current user is an active member of the team associated with the current listing.
 *
 * @param {Object} currentUser - The current user object.
 * @param {Object} currentListing - The current listing object.
 * @returns {boolean} - Returns `true` if the user is part of the team and not pending, otherwise `false`.
 */
export const isUserInvitedToTeam = (currentUser, currentListing) => {
  if (!currentUser || !currentListing) {
    return false;
  }

  const teamFromPublicData = currentListing?.attributes?.publicData?.team;
  return teamFromPublicData?.some(member => !member.pending && member.id === currentUser?.id?.uuid);
};

/**
 * Merges two translation objects by prioritizing the `hostedTranslations`.
 * Any missing keys in `hostedTranslations` are added from `localTranslations`.
 *
 * @param {Object} hostedTranslations - The primary translation object to be used.
 * @param {Object} localTranslations - The secondary translation object providing fallback keys.
 * @returns {Object} - A new object with the merged translations.
 */
function mergeTranslations(hostedTranslations, localTranslations) {
  // Create a copy of hostedTranslations to preserve original data
  const updatedTranslations = { ...hostedTranslations };

  // Add missing keys from localTranslations
  Object.keys(localTranslations).forEach(key => {
    if (!hostedTranslations.hasOwnProperty(key)) {
      updatedTranslations[key] = localTranslations[key];
    }
  });

  return updatedTranslations;
}
