import React from 'react';
import { oneOfType, node, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { userDisplayNameAsString } from '../../util/data';
import { Avatar } from '../../components';
import classNames from 'classnames';

import css from './HorizontalUserCard.module.css';

const HorizontalUserCard = props => {
  const { rootClassName, className, user, textContent, children } = props;
  const classes = classNames(rootClassName || css.root, className);

  const displayName = userDisplayNameAsString(user);

  return (
    <div className={classes}>
      <div className={css.userInfo}>
        <Avatar className={css.userAvatar} user={user} disableProfileLink />
        <div className={css.userText}>
          <span className={css.userDisplayName}>{displayName}</span>
          <div className={css.userAttributes}>
            <span className={css.userEmail}>{user.attributes.email}</span>
            {textContent ? (
              <>
                <span className={css.dotDivider}>·</span>
                {textContent}
              </>
            ) : null}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

HorizontalUserCard.defaultProps = {
  rootClassName: null,
  classNames: null,

  user: null,
};

HorizontalUserCard.propTypes = {
  rootClassName: string,
  classNames: string,

  user: propTypes.user,
  textContent: oneOfType([string, node]),
  children: node,
};

/**
 * Enhances a user object with email and profile attributes.
 *
 * @param {Object} user - The original user object to enhance.
 * @param {string} userEmail - The email address to add to the user object.
 * @returns {Object} - A new user object with updated attributes and profile.
 */
const enhanceUserWithProfile = (user, userEmail) => ({
  ...user,
  attributes: {
    ...user.attributes,
    email: userEmail,
    profile: {
      abbreviatedName: userEmail ? userEmail[0].toUpperCase() : '',
    },
  },
});

export const HorizontalNonExistingUserCard = props => {
  const { rootClassName, className, user, textContent, children } = props;
  const classes = classNames(rootClassName || css.root, className);

  const userEmail = user.email ? user.email : user.attributes.email;
  const updatedUser = enhanceUserWithProfile(user, userEmail);

  return (
    <div className={classes}>
      <div className={css.userInfo}>
        <Avatar className={css.userAvatar} user={updatedUser} />
        <div className={css.userText}>
          <span className={css.userDisplayName}>{userEmail}</span>
          {textContent ? <div className={css.userAttributes}>{textContent}</div> : null}
        </div>
      </div>
      {children}
    </div>
  );
};

export default HorizontalUserCard;
