import React, { useState } from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, array, string, bool, func } from 'prop-types';
import { THUMB_TYPE_DISLIKE, THUMB_TYPE_LIKE, propTypes } from '../../util/types';
import classNames from 'classnames';
import {
  Avatar,
  UserDisplayName,
  ExpandableBio,
  InlineTextButton,
  IconThumb,
  Badges,
} from '../../components';
import { CommentForm } from '../../forms';
import moment from 'moment';

import ControlMenu from './ControlMenu/ControlMenu';
import css from './Comments.module.css';

const COMMENT_LENGTH = 300;

export const Comment = props => {
  const [isEditComment, setEditComment] = useState(false);

  const {
    comment,
    currentUser,
    currentUserReactions,
    likeOrDislikeInProgress,
    likeOrDislikeError,
    editCommentInProgress,
    editCommentError,
    deleteCommentInProgress,
    deleteCommentError,
    handleCommentLikeOrDislike,
    handleEditComment,
    handleDeleteComment,
    intl,
  } = props;

  const authorForAvatar = comment.author ? comment.author : { attributes: { deleted: true } };
  const date = comment.attributes.createdAt;

  const showControlMenu =
    !isEditComment && currentUser?.id && currentUser?.id?.uuid === comment?.author?.id?.uuid;
  const thumbButtonDisabled = likeOrDislikeInProgress || !!likeOrDislikeError;

  const isLiked = !!currentUserReactions.find(
    reaction => reaction.commentId === comment.id && reaction.type === 'like'
  );
  const isDisliked = !!currentUserReactions.find(
    reaction => reaction.commentId === comment.id && reaction.type === 'dislike'
  );

  return (
    <div className={css.comment}>
      {isEditComment ? (
        <CommentForm
          id={`comment_${comment.id}`}
          // component props
          currentUser={currentUser}
          sendCommentInProgress={editCommentInProgress}
          sendCommentError={editCommentError}
          submitMessageTranslationId={'Comments.commentEdit'}
          isFocused
          // form props
          initialValues={{
            comment: comment.attributes.content,
          }}
          onSubmit={(values, form) => {
            handleEditComment(comment.id, values.comment).then(() => {
              setEditComment(false);
              form.restart();
            });
          }}
          onCancel={(values, form) => {
            setEditComment(false);
            form.restart();
          }}
        />
      ) : (
        <>
          <Avatar className={css.avatar} user={authorForAvatar} useUserTier />
          <div className={css.commentContent}>
            <div className={css.commentInfo}>
              <div className={css.commentInfoDetails}>
                {comment.author ? (
                  <UserDisplayName
                    className={css.userDisplayName}
                    user={comment.author}
                    intl={intl}
                  />
                ) : (
                  <span className={css.userDisplayName}>
                    {intl.formatMessage({
                      id: 'Comments.deletedUser',
                    })}
                  </span>
                )}
                <Badges user={authorForAvatar} />
                <span className={css.commentDate}>{moment(date).fromNow()}</span>
              </div>
            </div>
            <ExpandableBio
              className={css.commentBio}
              showMoreClassName={css.commentShowMore}
              bio={comment.attributes.content}
              maxLength={COMMENT_LENGTH}
            />
            <div className={css.commentThumbButtons}>
              <div className={css.commentThumbButtonContainer}>
                <InlineTextButton
                  type="button"
                  className={css.commentThumbButton}
                  onClick={() => handleCommentLikeOrDislike(THUMB_TYPE_LIKE, comment.id)}
                  disabled={thumbButtonDisabled}
                >
                  <IconThumb className={css.commentThumbIcon} direction="up" isFilled={isLiked} />
                </InlineTextButton>
                <span className={css.commentThumbCount}>{comment.attributes.likes}</span>
              </div>
              <div className={css.commentThumbButtonContainer}>
                <InlineTextButton
                  type="button"
                  className={css.commentThumbButton}
                  onClick={() => handleCommentLikeOrDislike(THUMB_TYPE_DISLIKE, comment.id)}
                  disabled={thumbButtonDisabled}
                >
                  <IconThumb
                    className={css.commentThumbIcon}
                    direction="down"
                    isFilled={isDisliked}
                  />
                </InlineTextButton>
                <span className={css.commentThumbCount}>{comment.attributes.dislikes}</span>
              </div>
            </div>
          </div>
        </>
      )}
      {showControlMenu ? (
        <ControlMenu
          className={css.controlMenu}
          deleteCommentInProgress={deleteCommentInProgress}
          deleteCommentError={deleteCommentError}
          onEditComment={() => setEditComment(true)}
          onDeleteComment={() => handleDeleteComment(comment.id)}
        />
      ) : null}
    </div>
  );
};

const CommentsComponent = props => {
  const {
    className,
    rootClassName,
    commentsClassName,
    intl,
    comments,
    currentUser,
    currentUserReactions,
    likeOrDislikeInProgress,
    likeOrDislikeError,
    editCommentInProgress,
    editCommentError,
    deleteCommentInProgress,
    deleteCommentError,
    handleCommentLikeOrDislike,
    handleEditComment,
    handleDeleteComment,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <ul className={classNames(css.comments, commentsClassName)}>
        {comments.map(c => {
          return (
            <li key={`Comment_${c.id}`} className={css.commentItem}>
              <Comment
                comment={c}
                intl={intl}
                currentUser={currentUser}
                currentUserReactions={currentUserReactions}
                likeOrDislikeInProgress={likeOrDislikeInProgress}
                likeOrDislikeError={likeOrDislikeError}
                editCommentInProgress={editCommentInProgress}
                editCommentError={editCommentError}
                deleteCommentInProgress={deleteCommentInProgress}
                deleteCommentError={deleteCommentError}
                handleCommentLikeOrDislike={handleCommentLikeOrDislike}
                handleEditComment={handleEditComment}
                handleDeleteComment={handleDeleteComment}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

CommentsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  commentsClassName: null,
  comments: [],
  currentUserReactions: [],
  likeOrDislikeInProgress: false,
  likeOrDislikeError: null,
  editCommentInProgress: false,
  editCommentError: null,
  deleteCommentInProgress: false,
  deleteCommentError: null,
};

CommentsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  commentsClassName: string,
  comments: arrayOf(propTypes.comment),
  currentUserReactions: array,
  likeOrDislikeInProgress: bool.isRequired,
  likeOrDislikeError: propTypes.error,
  editCommentInProgress: bool.isRequired,
  editCommentError: propTypes.error,
  deleteCommentInProgress: bool.isRequired,
  deleteCommentError: propTypes.error,
  handleCommentLikeOrDislike: func.isRequired,
  handleEditComment: func.isRequired,
  handleDeleteComment: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Comments = injectIntl(CommentsComponent);

export default Comments;
