import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { isTransactionsTransitionAlreadyReviewed } from '../../util/errors';
import { propTypes } from '../../util/types';
import { composeValidators, required, minLength } from '../../util/validators';
import { FieldReviewRating, Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './ShowReviewForm.module.css';

const REVIEW_CONTENT_MIN_LENGTH = 200;

const ShowReviewFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        intl,
        formId,
        invalid,
        reviewSent,
        sendReviewError,
        sendReviewInProgress,
      } = fieldRenderProps;

      const reviewRating = intl.formatMessage({ id: 'ShowReviewForm.reviewRatingLabel' });
      const reviewRatingRequiredMessage = intl.formatMessage({
        id: 'ShowReviewForm.reviewRatingRequired',
      });

      const reviewContent = intl.formatMessage({ id: 'ShowReviewForm.reviewContentLabel' });
      const reviewContentPlaceholderMessage = intl.formatMessage({
        id: 'ShowReviewForm.reviewContentPlaceholder',
      });
      const reviewContentRequiredMessage = intl.formatMessage({
        id: 'ShowReviewForm.reviewContentRequired',
      });
      const reviewContentMinLengthMessage = intl.formatMessage(
        {
          id: 'ShowReviewForm.reviewContentMinLength',
        },
        { minLength: REVIEW_CONTENT_MIN_LENGTH }
      );

      const errorMessage = isTransactionsTransitionAlreadyReviewed(sendReviewError) ? (
        <p className={css.error}>
          <FormattedMessage id="ShowReviewForm.reviewSubmitAlreadySent" />
        </p>
      ) : (
        <p className={css.error}>
          <FormattedMessage id="ShowReviewForm.reviewSubmitFailed" />
        </p>
      );
      const errorArea = sendReviewError ? errorMessage : null;

      const reviewSubmitMessage = intl.formatMessage({
        id: 'ShowReviewForm.reviewSubmit',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = sendReviewInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldReviewRating
            className={css.reviewRating}
            id={formId ? `${formId}.starRating` : 'starRating'}
            name="reviewRating"
            label={reviewRating}
            validate={required(reviewRatingRequiredMessage)}
            ratingCount={10}
          />

          <FieldTextInput
            className={css.reviewContent}
            type="textarea"
            id={formId ? `${formId}.reviewContent` : 'reviewContent'}
            name="reviewContent"
            label={reviewContent}
            placeholder={reviewContentPlaceholderMessage}
            validate={composeValidators(
              required(reviewContentRequiredMessage),
              minLength(reviewContentMinLengthMessage, REVIEW_CONTENT_MIN_LENGTH)
            )}
          />

          {errorArea}
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={reviewSent}
          >
            {reviewSubmitMessage}
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

ShowReviewFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
  sendReviewError: null,
};

const { bool, func, string } = PropTypes;

ShowReviewFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  reviewSent: bool.isRequired,
  sendReviewError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
};

const ShowReviewForm = compose(injectIntl)(ShowReviewFormComponent);
ShowReviewForm.displayName = 'ShowReviewForm';

export default ShowReviewForm;
