import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconComment.module.css';

const IconComment = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      height="24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M12.5.5h-11a1 1 0 0 0-1 1V10a1 1 0 0 0 1 1h2v2.5L6.62 11h5.88a1 1 0 0 0 1-1V1.5a1 1 0 0 0-1-1Z"
        style={{
          fill: 'none',
          stroke: '#7c7c7c',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '0.8',
        }}
        transform="scale(1.71429)"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconComment.defaultProps = {
  className: null,
  rootClassName: null,
};

IconComment.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconComment;
