/**
 * Denormalise transactions to events that
 * can be used in react/@fullcalendar.
 *
 * @param {array} transactions
 *
 * @returns {array} events
 */
export const transactionsToEvents = transactions => {
  return transactions.map(transaction => {
    const { listing, booking } = transaction;
    const { metadata } = transaction.attributes;

    return {
      id: transaction.id.uuid, // has to be ID string
      listingId: listing.id,
      title: metadata.timeslot.name,
      start: booking.attributes.start,
      end: booking.attributes.end,
    };
  });
};

/**
 * Sort events by their start dates to get the
 * upcoming events.
 *
 * @param {array} events
 *
 * @returns {array} sorted events
 */
export const sortEventsByDate = events =>
  events.sort(function(a, b) {
    var c = new Date(a.start);
    var d = new Date(b.start);
    return c - d;
  });

/**
 * Get's the upcoming bookings that's ahead of the
 * current date.
 *
 * @param {array} events
 *
 * @returns {array} upcoming events
 */
export const upcomingEventsByDate = events => events.filter(e => new Date(e.start) >= new Date());
