import React from 'react';
import { string, node } from 'prop-types';
import classNames from 'classnames';

import css from './Table.module.css';

const TableHeading = props => {
  const { rootClassName, className, children } = props;
  const classes = classNames(rootClassName || css.heading, className);

  return (
    <th className={classes}>
      <span className={css.headingText}>{children}</span>
    </th>
  );
};

TableHeading.defaultProps = {
  rootClassName: null,
  className: null,
  heading: null,
};

TableHeading.propTypes = {
  rootClassName: string,
  className: string,
  children: node.isRequired,
};

export default TableHeading;
