import React from 'react';
import { arrayOf, string, shape } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './ListRenderer.module.css';

const ListRenderer = props => {
  const { rootClassName, className, titleMsgId, items } = props;
  const classes = classNames(rootClassName || css.root, className);

  const title = titleMsgId ? (
    <FormattedMessage id={titleMsgId} />
  ) : (
    <FormattedMessage id="ListRenderer.title" />
  );
  return (
    <div className={classes}>
      <h2 className={css.title}>{title}</h2>
      <ul className={css.list}>
        {items.map(s => {
          return (
            <li key={s.key} className={css.listItem}>
              {s.text}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

ListRenderer.defaultProps = {
  rootClassName: null,
  className: null,
  titleMsgId: null,
  items: [],
};

ListRenderer.propTypes = {
  rootClassName: string,
  className: string,
  titleMsgId: string,
  items: arrayOf(
    shape({
      key: string.isRequired,
      text: string.isRequired,
    })
  ),
};

export default ListRenderer;
