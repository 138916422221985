import { wherebyAPI } from '../util/api';
import { storableError } from '../util/errors';

// ================ Action types ================ //

export const CREATE_ROOM_REQUEST = 'app/assets/CREATE_ROOM_REQUEST';
export const CREATE_ROOM_SUCCESS = 'app/assets/CREATE_ROOM_SUCCESS';
export const CREATE_ROOM_ERROR = 'app/assets/CREATE_ROOM_ERROR';

// ================ Reducer ================ //

const initialState = {
  createRoomInProgress: false,
  createRoomError: null,
};

export default function wherebyReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_ROOM_REQUEST:
      return { ...state, createRoomInProgress: true, createRoomError: null };
    case CREATE_ROOM_SUCCESS:
      return {
        ...state,

        createRoomInProgress: false,
        createRoomError: null,
      };
    case CREATE_ROOM_ERROR:
      return { ...state, createRoomInProgress: true, createRoomError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const createRoomRequest = () => ({ type: CREATE_ROOM_REQUEST });
export const createRoomSuccess = () => ({
  type: CREATE_ROOM_SUCCESS,
});
export const createRoomError = error => ({
  type: CREATE_ROOM_ERROR,
  payload: error,
});

// ================ Thunks ================ //

export const createRoom = bodyParams => (dispatch, getState, sdk) => {
  dispatch(createRoomRequest());

  return wherebyAPI
    .createRoom(bodyParams)
    .then(response => {
      dispatch(createRoomSuccess(response));
      return response;
    })
    .catch(e => dispatch(createRoomError(storableError(e))));
};
