import React from 'react';
import { func, string, bool } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  IconAvatar,
} from '../../components';

import MenuIcon from '../Topbar/MenuIcon';
import css from './TopbarDesktop.module.css';

const CONTENT_POSITION = 'left';

/**
 * Artist's menu (DashboardPage)
 */
export const MenuArtist = props => {
  const { menuItemBorderClasses, currentUser, currentPageClass, handleLogout } = props;

  return (
    <Menu contentPosition={CONTENT_POSITION}>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <div className={css.menuIconContainer}>
          <MenuIcon className={css.menuIcon} />
        </div>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink useVariationColor />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(
              css.navigationLink,
              css.navigationLinkBold,
              currentPageClass('ManageListingsPage')
            )}
            name="ManageListingsPage"
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDekstop.manageLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="StreamPage">
          <NamedLink
            className={classNames(
              css.navigationLink,
              css.navigationLinkBold,
              currentPageClass('StreamPage')
            )}
            name="StreamPage"
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.streamLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(
              css.navigationLink,
              css.navigationLinkBold,
              currentPageClass('InboxPage')
            )}
            name="InboxPage"
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.inboxLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AnalyticsPage">
          <NamedLink
            className={classNames(
              css.navigationLink,
              css.navigationLinkBold,
              currentPageClass('AnalyticsPage')
            )}
            name="AnalyticsPage"
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.analyticsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="OrganizationSettingsPage">
          <NamedLink
            className={classNames(
              css.navigationLink,
              css.navigationLinkBold,
              currentPageClass('OrganizationSettingsPage')
            )}
            name="OrganizationSettingsPage"
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.organizationSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AudiencePage">
          <NamedLink
            className={classNames(
              css.navigationLink,
              css.businessLink,
              currentPageClass('AudiencePage')
            )}
            name="LandingPage"
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.audienceLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="HelpPage">
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('HelpPage'))}
            name="HelpPage"
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.helpLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="CommunityPage">
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('CommunityPage'))}
            name="CommunityPage"
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.communityLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton
            rootClassName={classNames(css.navigationLink, css.logoutButton)}
            onClick={handleLogout}
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

MenuArtist.defaultProps = {
  menuItemBorderClasses: null,
  currentUser: null,
  currentPageClass: null,
  handleLogout: null,
};

MenuArtist.propTypes = {
  menuItemBorderClasses: string,
  currentUser: propTypes.currentUser,
  currentPageClass: func.isRequired,
  handleLogout: func.isRequired,
};

/**
 * Audience menu
 */
export const MenuAudience = props => {
  const {
    menuItemBorderClasses,
    currentUser,
    currentPageClass,
    isCurrentUserArtist,
    handleLogout,
  } = props;

  return (
    <Menu contentPosition={CONTENT_POSITION}>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <div className={css.menuIconContainer}>
          <MenuIcon className={css.menuIcon} />
        </div>
        <Avatar className={css.avatar} user={currentUser} useUserTier disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageSubscriptionsPage">
          <NamedLink
            className={classNames(
              css.navigationLink,
              css.navigationLinkBold,
              currentPageClass('ManageSubscriptionsPage')
            )}
            name="ManageSubscriptionsPage"
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.subscriptionLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="WatchlistPage">
          <NamedLink
            className={classNames(
              css.navigationLink,
              css.navigationLinkBold,
              currentPageClass('WatchlistPage')
            )}
            name="WatchlistPage"
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.watchlistLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="CalendarPage">
          <NamedLink
            className={classNames(
              css.navigationLink,
              css.navigationLinkBold,
              currentPageClass('CalendarPage')
            )}
            name="CalendarPage"
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.calendarLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="OrdersPage">
          <NamedLink
            className={classNames(
              css.navigationLink,
              css.navigationLinkBold,
              currentPageClass('OrdersPage')
            )}
            name="OrdersPage"
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.inboxLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="OrderHistoryPage">
          <NamedLink
            className={classNames(
              css.navigationLink,
              css.navigationLinkBold,
              currentPageClass('OrderHistoryPage')
            )}
            name="OrderHistoryPage"
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.ordersLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ManageListingsPage" isHidden={!isCurrentUserArtist}>
          <NamedLink
            className={classNames(
              css.navigationLink,
              css.businessLink,
              currentPageClass('ManageListingsPage')
            )}
            name="ManageListingsPage"
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.manageListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="HelpPage">
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('HelpPage'))}
            name="HelpPage"
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.helpLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="CommunityPage">
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('CommunityPage'))}
            name="CommunityPage"
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.communityLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton
            rootClassName={classNames(css.navigationLink, css.logoutButton)}
            onClick={handleLogout}
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

MenuAudience.defaultProps = {
  menuItemBorderClasses: null,
  currentUser: null,
  currentPageClass: null,
  handleLogout: null,
};

MenuAudience.propTypes = {
  menuItemBorderClasses: string,
  currentUser: propTypes.currentUser,
  currentPageClass: func.isRequired,
  handleLogout: func.isRequired,
};

/**
 * Unauthorized menu
 */
export const MenuUnauthorized = props => {
  const {
    menuItemBorderClasses,
    currentPageClass,
    isUnauthenticatedMenuOpen,
    setIsUnauthenticatedMenuOpen,
  } = props;

  return (
    <Menu
      isOpen={isUnauthenticatedMenuOpen}
      contentPosition={CONTENT_POSITION}
      onToggleActive={setIsUnauthenticatedMenuOpen}
    >
      <MenuLabel
        className={css.profileMenuLabel}
        isOpenClassName={css.profileMenuIsOpen}
        onClick={() => setIsUnauthenticatedMenuOpen(!isUnauthenticatedMenuOpen)}
      >
        <div className={css.menuIconContainer}>
          <MenuIcon className={css.menuIcon} />
        </div>
        <div className={css.avatarIconContainer}>
          <IconAvatar className={css.avatarIcon} />
        </div>
      </MenuLabel>
      <MenuContent className={css.profileMenuUnauthorizedContent}>
        <MenuItem key="LoginPage">
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('LoginPage'))}
            name="LoginPage"
            params={{ role: 'audience' }}
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.login" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="RoleSelectionPage">
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('SignupPage'))}
            name="RoleSelectionPage"
          >
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.signup" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="help">
          <NamedLink name="HelpPage" className={css.helpButton}>
            <span className={menuItemBorderClasses} />
            <FormattedMessage id="TopbarDesktop.help" />
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

MenuUnauthorized.defaultProps = {
  menuItemBorderClasses: null,
  currentPageClass: null,
  isUnauthenticatedMenuOpen: false,
  setIsUnauthenticatedMenuOpen: null,
};

MenuUnauthorized.propTypes = {
  menuItemBorderClasses: string,
  currentPageClass: func.isRequired,
  isUnauthenticatedMenuOpen: bool.isRequired,
  setIsUnauthenticatedMenuOpen: func.isRequired,
};
