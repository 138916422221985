import React from 'react';
import { FieldCheckboxGroup } from '../../components';

import SelectCategoryFields from './SelectCategoryFields';
import css from './TopbarFilterComponent.module.css';

const MAX_TWO_COLUMN_COUNT_LENGTH = 4;

const TopbarFilterComponent = props => {
  const { filterConfig, form, values } = props;
  const { type, label, config, queryParamNames } = filterConfig;

  const options = config.options;
  const queryParamName = queryParamNames[0];

  switch (type) {
    case 'SelectSingleFilter': {
      return (
        <SelectCategoryFields
          id={queryParamName}
          name={queryParamName}
          label={label}
          options={options}
          initialValue={values[queryParamName]}
          onUpdateForm={value => form.change(queryParamName, value)}
        />
      );
    }
    case 'SelectMultipleFilter': {
      return (
        <FieldCheckboxGroup
          className={css.field}
          itemClassName={css.fieldItem}
          id={queryParamName}
          name={queryParamName}
          label={label}
          options={options}
          twoColumns={options.length <= MAX_TWO_COLUMN_COUNT_LENGTH}
          threeColumns={options.length > MAX_TWO_COLUMN_COUNT_LENGTH}
        />
      );
    }
    default:
      return null;
  }
};

export default TopbarFilterComponent;
