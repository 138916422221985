import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconUpload.module.css';

const IconUpload = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      height="16"
      width="16"
    >
      <path d="M28 2v4H4V2h24zM13.879 9.293l-9.293 9.293 2.828 2.828L14 14.828V31h4V14.828l6.586 6.586 2.828-2.828-9.293-9.293a3.002 3.002 0 0 0-4.242 0z" />
    </svg>
  );
};

const { string } = PropTypes;

IconUpload.defaultProps = {
  className: null,
  rootClassName: null,
};

IconUpload.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconUpload;
