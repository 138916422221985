import React from 'react';
import { string, node } from 'prop-types';
import { NamedLink } from '../../components';
import classNames from 'classnames';

import css from './Table.module.css';

const TableRow = props => {
  const { rootClassName, className, children, ...rest } = props;
  const classes = classNames(rootClassName || css.row, className);

  return (
    <tr className={classes} {...rest}>
      {children}
    </tr>
  );
};

TableRow.defaultProps = {
  rootClassName: null,
  className: null,
};

TableRow.propTypes = {
  rootClassName: string,
  className: string,
  children: node.isRequired,
};

export default TableRow;
