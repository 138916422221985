import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ProfileImageForm } from '../../forms';
import { pathByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import classNames from 'classnames';

import { SIGNUP_EMAIL_STEP } from './SignupPage';
import css from './SignupPage.module.css';

const onImageUploadHandler = (values, fn) => {
  const { id, imageId, file } = values;
  if (file) {
    fn({ id, imageId, file });
  }
};

const ProfileImage = props => {
  const {
    user,
    currentRole,
    history,
    image,
    onImageUpload,
    onUpdateProfile,
    uploadInProgress,
    updateInProgress,
    uploadImageError,
    updateProfileError,
  } = props;

  const handleSubmit = () => {
    const uploadedImage = image;

    // Update profileImage only if file system has been accessed
    const updatedValues =
      uploadedImage && uploadedImage.imageId && uploadedImage.file
        ? { profileImageId: uploadedImage.imageId }
        : {};

    onUpdateProfile(updatedValues).then(() => {
      const routes = routeConfiguration();
      history.push(
        pathByRouteName('SignupPage', routes, { role: currentRole, step: SIGNUP_EMAIL_STEP })
      );
    });
  };

  const profileImageId = user.profileImage ? user.profileImage.id : null;
  const profileImage = image || { imageId: profileImageId };
  return (
    <div className={classNames(css.content, css.profileImageContent)}>
      <span className={css.step}>
        <FormattedMessage id="AuthenticationPage.profileImageStep" />
      </span>
      <h1 className={css.modalTitle}>
        <FormattedMessage id="AuthenticationPage.profileImageTitle" />
      </h1>
      <p className={css.modalMessage}>
        <FormattedMessage id="AuthenticationPage.profileImageText" />
      </p>

      <ProfileImageForm
        className={css.profileImageForm}
        currentUser={user}
        currentRole={currentRole}
        initialValues={{ profileImage: user.profileImage }}
        profileImage={profileImage}
        onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
        uploadInProgress={uploadInProgress}
        updateInProgress={updateInProgress}
        uploadImageError={uploadImageError}
        updateProfileError={updateProfileError}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default ProfileImage;
