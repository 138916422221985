import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconUnlocked.module.css';

const IconUnlocked = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="32" height="32">
      <path d="M24 14H12V8a4 4 0 0 1 8 0h2a6 6 0 0 0-12 0v6H8a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V16a2 2 0 0 0-2-2Zm0 14H8V16h16Z" />
      <path fill="none" d="M0 0h32v32H0Z" />
    </svg>
  );
};

const { string } = PropTypes;

IconUnlocked.defaultProps = {
  className: null,
  rootClassName: null,
};

IconUnlocked.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconUnlocked;
