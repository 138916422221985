import React from 'react';
import { ResponsiveImage } from '../../components';

import css from './TransactionPanel.module.css';

const DetailsCardImage = props => {
  const { listingTitle, firstImage } = props;
  return (
    <div className={css.detailsInfo}>
      <div className={css.detailsAspectWrapper}>
        <ResponsiveImage
          rootClassName={css.rootForImage}
          alt={listingTitle}
          image={firstImage}
          variants={['landscape-crop', 'landscape-crop2x']}
        />
      </div>
      <div className={css.detailsHeadings}>
        <h2 className={css.detailsTitle}>{listingTitle}</h2>
      </div>
    </div>
  );
};

export default DetailsCardImage;
