import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconGallery.module.css';

const IconGallery = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M22.855 1.868v20.264c0 .4-.324.723-.723.723H1.868a.724.724 0 01-.723-.723V1.868c0-.4.324-.723.723-.723h20.264c.4 0 .723.324.723.723z"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1.145 16.825l8.443-3.619 13.267 6.03M16.825 9.588a2.412 2.412 0 110-4.825 2.412 2.412 0 010 4.825z"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

IconGallery.defaultProps = {
  className: null,
  rootClassName: null,
};

IconGallery.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconGallery;
