import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPhoto.module.css';

const IconPhoto = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/20e0f0c/svg"
      height="24"
      width="24"
      fill="none"
      viewBox="-0.5 -0.5 24 24"
    >
      <path
        stroke="#0e0f0c"
        d="M13.417 7.667a1.917 1.917 0 1 0 3.833 0 1.917 1.917 0 1 0-3.833 0"
      />
      <path
        stroke="#0e0f0c"
        strokeLinecap="round"
        d="m1.917 11.98 1.678-1.47a2.204 2.204 0 0 1 3.01.1l4.111 4.112a1.917 1.917 0 0 0 2.458.213l.285-.201a2.875 2.875 0 0 1 3.577.215l3.089 2.78"
      />
      <path
        stroke="#0e0f0c"
        strokeLinecap="round"
        d="M21.083 11.5c0 4.518 0 6.776-1.403 8.18-1.404 1.403-3.662 1.403-8.18 1.403s-6.776 0-8.18-1.403c-1.403-1.404-1.403-3.662-1.403-8.18s0-6.776 1.403-8.18c1.404-1.403 3.662-1.403 8.18-1.403s6.776 0 8.18 1.403c.933.933 1.246 2.245 1.35 4.347"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconPhoto.defaultProps = {
  className: null,
  rootClassName: null,
};

IconPhoto.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPhoto;
