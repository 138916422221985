/**
 * Get the meeting ID from the meeting object.
 *
 * @param {Object} meeting
 * @returns {String} meetingId
 */
export const getMeetingId = meeting => {
  if (!meeting || !meeting.roomName) {
    return null;
  }

  const meetingId = meeting.roomName.replace('/', '');
  return meetingId;
};

/**
 * Get the meeting URL from the meeting ID.
 *
 * @param {String} meetingId
 * @returns {String} meetingUrl
 */
export const getMeetingUrl = meetingId => {
  if (!meetingId) {
    return null;
  }

  const meetingUrl = `${process.env.REACT_APP_CANONICAL_ROOT_URL}/meet/${meetingId}`;
  return meetingUrl;
};

/**
 * Get the meeting URL from the meeting object.
 *
 * @param {Object} meeting
 * @returns {String} meetingUrl
 */
export const getRoomKeyFromUrl = url => {
  if (!url) {
    return null;
  }

  const urlParams = new URLSearchParams(url.split('?')[1]);
  return urlParams.get('roomKey');
};

/**
 * Get the meeting URL from the meeting object.
 *
 * @param {String} meetingId
 * @param {String} roomKey
 */
export const getMeetingUrlWithRoomKey = (meetingId, roomKey) => {
  if (!meetingId || !roomKey) {
    return null;
  }

  return `${process.env.REACT_APP_CANONICAL_ROOT_URL}/meet/${meetingId}?roomKey=${roomKey}`;
};
