import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconList.module.css';

const IconList = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="13"
      width="13"
    >
      <g fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round">
        <circle cx={1} cy={2.5} r={0.5} />
        <path d="M4.5 2.5h9" />
        <circle cx={1} cy={7} r={0.5} />
        <path d="M4.5 7h9" />
        <circle cx={1} cy={11.5} r={0.5} />
        <path d="M4.5 11.5h9" />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconList.defaultProps = {
  className: null,
  rootClassName: null,
};

IconList.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconList;
