import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconLive.module.css';

const IconLive = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <g clipPath="url(#a)" fill="#0e0f0c">
        <path d="M4.16 8.642a.352.352 0 0 0-.352.352V15c0 .171.124.318.292.346.025.005.06.01.712.01.317 0 .779 0 1.455-.005a.352.352 0 0 0-.002-.703h-.002c-.533.003-1.314.006-1.752.005v-5.66a.352.352 0 0 0-.351-.352ZM8.13 8.994v6.012a.352.352 0 1 0 .703 0V8.994a.352.352 0 1 0-.703 0ZM19.934 9.373a.352.352 0 0 0 0-.703h-2.51a.352.352 0 0 0-.352.351v5.958c0 .194.158.351.352.351h2.51a.352.352 0 0 0 0-.703h-2.159v-2.276h1.974a.352.352 0 1 0 0-.703h-1.973V9.373h2.158ZM14.694 8.894 12.932 14.2l-1.847-5.32a.351.351 0 1 0-.664.23l2.056 5.925a.488.488 0 0 0 .46.324h.003a.489.489 0 0 0 .458-.333l1.963-5.91a.352.352 0 0 0-.667-.22Z" />
        <path d="M22.557 6.012h-1.163a.352.352 0 0 0 0 .703h1.163a.74.74 0 0 1 .74.74v9.09a.74.74 0 0 1-.74.74H4.109a.351.351 0 1 0 0 .703h18.448c.796 0 1.443-.648 1.443-1.443v-9.09c0-.796-.647-1.443-1.443-1.443ZM2.606 17.285H1.443a.74.74 0 0 1-.74-.74v-9.09a.74.74 0 0 1 .74-.74h18.448a.352.352 0 1 0 0-.703H1.443C.647 6.012 0 6.66 0 7.455v9.09c0 .796.647 1.443 1.443 1.443h1.163a.352.352 0 0 0 0-.703ZM7.116 3.154c.161.108.38.065.487-.097A5.28 5.28 0 0 1 12 .703a5.28 5.28 0 0 1 4.397 2.354.351.351 0 1 0 .584-.39A5.98 5.98 0 0 0 12 0a5.98 5.98 0 0 0-4.981 2.667.352.352 0 0 0 .097.487Z" />
        <path d="M14.834 4.1a.351.351 0 1 0 .584-.391A4.104 4.104 0 0 0 12 1.879a4.104 4.104 0 0 0-3.418 1.83.352.352 0 0 0 .584.39 3.403 3.403 0 0 1 5.668 0Z" />
        <path d="M12 3.758c-.746 0-1.44.37-1.855.993a.352.352 0 1 0 .585.39 1.526 1.526 0 0 1 2.54 0 .351.351 0 1 0 .585-.39A2.227 2.227 0 0 0 12 3.758ZM16.884 20.846a.352.352 0 0 0-.487.097A5.28 5.28 0 0 1 12 23.297a5.279 5.279 0 0 1-4.397-2.354.352.352 0 1 0-.584.39A5.98 5.98 0 0 0 12 24a5.98 5.98 0 0 0 4.981-2.666.352.352 0 0 0-.097-.488Z" />
        <path d="M9.166 19.9a.352.352 0 1 0-.584.391A4.104 4.104 0 0 0 12 22.121a4.104 4.104 0 0 0 3.418-1.83.352.352 0 1 0-.584-.39 3.402 3.402 0 0 1-5.668 0Z" />
        <path d="M12 20.242c.746 0 1.44-.37 1.855-.993a.352.352 0 1 0-.585-.39 1.526 1.526 0 0 1-2.54 0 .352.352 0 0 0-.585.39 2.227 2.227 0 0 0 1.855.994Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

IconLive.defaultProps = {
  className: null,
  rootClassName: null,
};

IconLive.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconLive;
