import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './CommunityGuidelines.module.css';

const CommunityGuidelines = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last Updated: October 28, 2024</p>
      <p>
        Attractr is a dynamic platform dedicated to fostering meaningful connections between
        creators and audiences, inviting users into the creative process and behind-the-scenes
        stories that bring performances to life. Focused on trust, inclusivity, and respect,
        Attractr supports a community-driven environment where creators and audiences can share,
        discover, and engage with unique content and experiences. Our Community Guidelines help
        maintain a safe, welcoming space, and violations may result in edited or removed content or
        account action to preserve the platform's integrity.
      </p>
      <p>
        If you have questions or need to report a concern, reach out at{' '}
        <a href="mailto:team@attractr.live">team@attractr.live</a>. Thank you for contributing to
        the Attractr community!
      </p>

      <h3>Core Principles</h3>
      <ul>
        <li>
          <strong>Respect and Inclusivity:</strong> Attractr fosters respect, welcoming users from
          all backgrounds. Discrimination, harassment, or hate speech are strictly prohibited.
        </li>
        <li>
          <strong>Authenticity and Integrity:</strong> Be truthful in your interactions.
          Misrepresentation of identity, services, or experience undermines trust and will not be
          tolerated.
        </li>
        <li>
          <strong>Content Standards:</strong> All content should reflect Attractr's commitment to
          professionalism. Any violent, inappropriate, or illegal content, including adult-only
          material in non-designated areas, is not allowed.
        </li>
        <li>
          <strong>Privacy and Security:</strong> Respect the privacy of others. Unauthorized sharing
          of personal information or attempts to breach user data security are serious violations.
        </li>
        <li>
          <strong>Constructive Engagement:</strong> Encourage positive, constructive interactions.
          Feedback should be given respectfully, and disputes handled courteously.
        </li>
        <li>
          <strong>Protection of Intellectual Property:</strong> Only share original work or work you
          have permission to use. Plagiarism and intellectual property infringement are violations.
        </li>
      </ul>
      <p>
        We rely on our community to help maintain these standards. Let’s work together to keep
        Attractr a safe, welcoming place for everyone in the performing arts community.
      </p>

      <h3>Creators & Organizations on Attractr must:</h3>
      <ul>
        <li>
          <strong>Comply with Local Laws and Tax Obligations:</strong> Attractr serves as a
          communication platform and is not liable for ensuring legal compliance. Creators and
          organizations are responsible for meeting all relevant local laws, zoning ordinances, and
          tax regulations, including accurate business reporting on any earnings derived from
          Attractr. If taxes apply to your services, you must inform users clearly of any additional
          charges.
        </li>
        <li>
          <strong>Provide Accurate Representation of Offerings and Pricing:</strong> Transparency is
          key—accurately describe your membership offerings and associated pricing.
          Misrepresentation of your services may lead to corrective actions, as Attractr is
          committed to maintaining integrity and trust on the platform.
        </li>
        <li>
          <strong>List Only Authorized Offerings:</strong> Ensure you have the rights and
          permissions to list the membership offerings you provide. Unauthorized listings will be
          removed, and accounts may face penalties if found in violation. Subletting services or
          unauthorized listings without consent from rights holders is strictly prohibited on
          Attractr.
        </li>
      </ul>

      <h3>Everyone on Attractr must:</h3>
      <ul>
        <li>
          <strong>Communicate Honestly:</strong> Trust is the foundation of Attractr. Communicate
          with genuine intent, avoid making false claims, and refrain from deceptive or fraudulent
          behavior.
        </li>
        <li>
          <strong>Use the Platform as Intended:</strong> Attractr is designed for audience members
          to explore, connect with, and book live performance services and for creators to list
          their offerings. Misuse, including messages unrelated to this purpose or those with
          harmful intent, may lead to review and potential action. All interactions and bookings
          must adhere to Attractr’s Terms of Service.
        </li>
      </ul>
    </div>
  );
};

CommunityGuidelines.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

CommunityGuidelines.propTypes = {
  rootClassName: string,
  className: string,
};

export default CommunityGuidelines;
