import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconHome.module.css';

const IconHome = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
      height="24"
      width="24"
    >
      <path
        fill="#333333"
        fillRule="evenodd"
        d="M6.46 9.071h1.08c.372.195.627.586.627 1.036v2.54a.5.5 0 0 0 .5.5h1.496c.916 0 1.696-.668 1.835-1.574.128-.829.18-1.666.16-2.502h1.092a.75.75 0 0 0 .568-1.24l-5.493-6.37a1.75 1.75 0 0 0-2.65 0L.182 7.83a.75.75 0 0 0 .568 1.24h1.093c-.022.836.031 1.674.159 2.502.14.906.918 1.574 1.835 1.574h1.495a.5.5 0 0 0 .5-.5v-2.54c0-.45.255-.84.628-1.036Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconHome.defaultProps = {
  className: null,
  rootClassName: null,
};

IconHome.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconHome;
