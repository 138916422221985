import React from 'react';
import { func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import { ValidationError } from '../../components';
import config from '../../config';
import classNames from 'classnames';

import IntlTelInput from 'react-intl-tel-input';
import './react-intl-tel-input.css';

import css from './FieldPhoneNumberInput.module.css';

const FieldPhoneNumberInputComponent = props => {
  const { rootClassName, className, inputRootClass, input, id, label, meta } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { name, value } = input;
  const { valid, invalid, touched, error } = meta;
  const hasError = !!(touched && invalid && error);

  const handleChange = (isValid, value, country) => {
    input.onChange({
      number: value,
      countryCode: country.iso2,
      dialCode: country.dialCode,
      isValid,
    });
  };

  const handleChangeCountry = country => {
    input.onChange({ ...value, number: '', countryCode: country.iso2, dialCode: country.dialCode });
  };

  const handleBlur = event => {
    input.onBlur(event);
  };

  // We get input value as an object that contains
  // number, dial and ISO2 country code
  const inputValue = value.number || '';
  const defaultCountry = value.countryCode || config.phoneNumberConfig.defaultCountry;

  const inputClasses =
    inputRootClass ||
    classNames(css.input, {
      [css.inputSuccess]: valid,
      [css.inputError]: hasError,
    });

  const inputProps = {
    inputClassName: inputClasses,
    fieldId: id,
    fieldName: name,
    defaultValue: inputValue,
    defaultCountry,
    value: inputValue,
    preferredCountries: config.phoneNumberConfig.preferredCountries,
    onPhoneNumberChange: (isValid, value, country) => handleChange(isValid, value, country),
    onPhoneNumberBlur: event => handleBlur(event),
    onSelectFlag: (value, country) => handleChangeCountry(country),
  };

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <IntlTelInput {...inputProps} />
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldPhoneNumberInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  customErrorText: null,
  id: null,
  label: null,
};

FieldPhoneNumberInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
  }).isRequired,
  meta: object.isRequired,
};

const FieldPhoneNumberInput = props => {
  return <Field component={FieldPhoneNumberInputComponent} {...props} />;
};

export default FieldPhoneNumberInput;
