import { emailAPI } from '../../util/api';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const SEND_EMAIL_REQUEST = 'app/ContactPage/SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'app/ContactPage/SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_ERROR = 'app/ContactPage/SEND_EMAIL_ERROR';

// ================ Reducer ================ //

const initialState = {
  sendEmailInProgress: false,
  sendEmailError: null,
  sendEmailSuccess: false,
};

export default function contactPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SEND_EMAIL_REQUEST:
      return {
        ...state,
        sendEmailInProgress: true,
        sendEmailError: null,
        sendEmailSuccess: false,
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmailInProgress: false,
        sendEmailError: null,
        sendEmailSuccess: true,
      };
    case SEND_EMAIL_ERROR:
      return {
        ...state,
        sendEmailInProgress: false,
        sendEmailError: payload,
        sendEmailSuccess: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const sendEmailRequest = () => ({
  type: SEND_EMAIL_REQUEST,
});

export const sendEmailSuccess = () => ({
  type: SEND_EMAIL_SUCCESS,
});

export const sendEmailError = e => ({
  type: SEND_EMAIL_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const sendEmail = params => (dispatch, getState, sdk) => {
  dispatch(sendEmailRequest());

  return emailAPI.contact
    .send(params)
    .then(response => {
      dispatch(sendEmailSuccess(response));
      return response;
    })
    .catch(e => dispatch(sendEmailError(storableError(e))));
};
