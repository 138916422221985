import React, { useRef } from 'react';
import PropTypes, { shape } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import {
  windowDefined,
  getElementHeight,
  useVideoPlayer,
  useVideoPlayerEvents,
} from './VideoPlayer.helpers';
import classNames from 'classnames';

import Controller, { ControllerOverlay } from './Controller';
import BigPlayButton from './BigPlayButton';
import css from './VideoPlayer.module.css';
import AspectRatioWrapper from '../AspectRatioWrapper/AspectRatioWrapper';

// Settings
const PRELOAD_VIDEO = 'auto'; // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video#attr-preload
const SHOW_PLAYER_TIME = true;
const SHOW_BIG_PLAY_BUTTON = false;
const PLAYER_DISABLED = false;

const VideoPlayer = props => {
  const {
    className,
    rootClassName,
    videoWrapperClassName,
    videoClassName,
    controlsClassName,
    controlsTextClassName,
    video,
    settings,
    aspectWidth,
    aspectHeight,
  } = props;

  // Hide the component if window or document object isn't
  // already defined, to avoid causing SSR server error
  if (!windowDefined) {
    return null;
  }

  const videoRef = useRef(null);
  const controllerRef = useRef(null);
  const seekTrackRef = useRef(null);
  const fullscreenElementRef = useRef(null);

  const player = useVideoPlayer();
  const playerEvents = useVideoPlayerEvents(player);

  // Player settings
  const showPlayerTime = settings.playerTime ?? SHOW_PLAYER_TIME;
  const showBigPlayButton = settings.bigPlayButton && !player.playing && !player.paused;

  // Player classNames
  const classes = classNames(rootClassName || css.root, className, {
    [css.disabledRoot]: settings.disabled || !player.canPlay,
    [css.bigPlayButtonRoot]: showBigPlayButton,
    [css.fullscreenRoot]: player.fullscreen,
  });

  const videoWrapperClasses = classNames(css.videoPlayerWrapper, videoWrapperClassName);
  const videoClasses = classNames(css.videoPlayer, videoClassName);
  const controlsClasses = classNames(css.controls, controlsClassName);
  const controlsTextClasses = classNames(css.controlsText, controlsTextClassName);

  if (!video || !video.src) {
    const loadingClasses = classNames(css.root, css.loadingRoot);
    return (
      <div className={loadingClasses}>
        <AspectRatioWrapper
          className={videoWrapperClasses}
          aspectPaddingClassName={css.aspectPadding}
          width={aspectWidth}
          height={aspectHeight}
        >
          <span className={css.tag}>
            <FormattedMessage id="VideoPlayer.noVideoMessage" />
          </span>
        </AspectRatioWrapper>
      </div>
    );
  } else if (player.error) {
    const errorClasses = classNames(css.root, css.errorRoot);
    return (
      <div className={errorClasses}>
        <AspectRatioWrapper
          className={videoWrapperClasses}
          aspectPaddingClassName={css.aspectPadding}
          width={aspectWidth}
          height={aspectHeight}
        >
          <span className={css.tag}>
            <FormattedMessage id="VideoPlayer.videoPlayerError" />
          </span>
        </AspectRatioWrapper>
      </div>
    );
  }

  const onTogglePlay = () => {
    if (player.playing) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };

  // Video element related props that are required
  // so that the component can properly work
  const videoProps = {
    ref: videoRef,
    muted: player.muted,
    preload: PRELOAD_VIDEO,

    // events
    onLoadedMetadata: playerEvents.onLoadedMetadata,
    onCanPlay: playerEvents.onCanPlay,
    onPlaying: playerEvents.onPlaying,
    onPause: playerEvents.onPause,
    onTimeUpdate: playerEvents.onTimeUpdate,
    onEnded: playerEvents.onEnded,
    onSuspend: playerEvents.onSuspend,
    onError: playerEvents.onError,
  };

  return (
    <div className={classes} ref={fullscreenElementRef}>
      <AspectRatioWrapper
        className={videoWrapperClasses}
        aspectPaddingClassName={css.aspectPadding}
        width={aspectWidth}
        height={aspectHeight}
        onClick={onTogglePlay}
      >
        <BigPlayButton show={showBigPlayButton} />
        <video className={videoClasses} playsInline {...videoProps}>
          <source src={`${video.src}#t=0.001`} />
        </video>
      </AspectRatioWrapper>
      <ControllerOverlay height={getElementHeight(controllerRef?.current)} />
      <Controller
        controlsClasses={controlsClasses}
        controlsTextClasses={controlsTextClasses}
        player={player}
        showPlayerTime={showPlayerTime}
        videoRef={videoRef}
        controllerRef={controllerRef}
        seekTrackRef={seekTrackRef}
        fullscreenElementRef={fullscreenElementRef}
        onTogglePlay={onTogglePlay}
      />
    </div>
  );
};

VideoPlayer.defaultProps = {
  className: null,
  rootClassName: null,
  videoWrapperClassName: null,
  videoClassName: null,
  controlsClassName: null,
  controlsTextClassName: null,
  aspectWidth: 5,
  aspectHeight: 7,

  // settings
  settings: {
    playerTime: SHOW_PLAYER_TIME,
    bigPlayButton: SHOW_BIG_PLAY_BUTTON,
    disabled: PLAYER_DISABLED,
  },
};

const { string, number, bool } = PropTypes;

VideoPlayer.propTypes = {
  className: string,
  rootClassName: string,
  videoWrapperClassName: string,
  videoClassName: string,
  controlsClassName: string,
  controlsTextClassName: string,
  video: propTypes.video,
  aspectWidth: number,
  aspectHeight: number,

  // settings
  settings: shape({
    playerTime: bool,
    bigPlayButton: bool,
    disabled: bool,
  }),
};

export default VideoPlayer;
