import { validateArrayElements } from '../../util/data';
import { TYPE_ORGANIZATION } from '../../util/invites';

/**
 * Filters pending invites based on recipient and organization criteria.
 *
 * @param {Array} pendingInvites - An array of pending invite objects.
 * @param {string} recipientId - The ID of the recipient for whom invites are being filtered.
 * @param {Array} organizationIds - An array of organization IDs to match against invite's organizationIds.
 *
 * @returns {Array} - An array of filtered pending invite objects.
 */
export const filterPendingInvites = (pendingInvites, recipientId, organizationIds) => {
  // Use the 'filter' method to iterate through each invite and apply the filtering condition.
  const filteredInvites = pendingInvites.filter(
    invite =>
      // Include invites where the invite's ID is not equal to the provided recipientId AND
      // the organizationIds in the invite's organizationIds array match the provided organizationIds.
      !(invite.id === recipientId && validateArrayElements(organizationIds, invite.organizationIds))
  );

  // Return the array of filtered pending invite objects.
  return filteredInvites;
};

/**
 * Find a pending invite based on recipient and organization criteria.
 *
 * @param {Array} pendingInvites - An array of pending invite objects to search within.
 * @param {string} recipientId - The ID of the recipient for whom invites are being searched.
 * @param {Array} organizationIds - An array of organization IDs to match against invite's organizationIds.
 *
 * @returns {Object|null} - The found pending invite object, or null if not found.
 */
export const findPendingInvite = (pendingInvites, recipientId, organizationIds) => {
  // Use the 'find' method to search for the first invite that matches the condition.
  const pendingInvite = pendingInvites.find(
    invite =>
      // Include invites where the invite's ID is equal to the provided recipientId AND
      // the organizationIds in the invite's organizationIds array match the provided organizationIds.
      invite.id === recipientId && validateArrayElements(organizationIds, invite.organizationIds)
  );

  // Return the found pending invite object, or null if not found.
  return pendingInvite || null;
};

/**
 * Updates a array with recipient information based on certain conditions.
 *
 * @param {Array} array - The original array.
 * @param {string} id - The ID of the recipient or sender.
 * @param {Array} organizationIds - An array of organization IDs associated with the recipient.
 *
 * @returns {Array} - The updated array with recipient information added based on conditions.
 */
export const updateArrayWithRecipientOrSenderInfo = (array, id, organizationIds) => {
  if (array?.length > 0) {
    // If the array is not empty, append the recipient's information to it.
    return [...array, { id, organizationIds, type: TYPE_ORGANIZATION }];
  }

  // If the array is empty, create a new array with the recipient's information.
  return [{ id, organizationIds, type: TYPE_ORGANIZATION }];
};

/**
 * Splits a string containing comma-separated values into an array.
 *
 * @param {string} nonFormattedOrganizationIds - The input string with comma-separated values.
 * @returns {Array} - An array containing the split values from the input string.
 */
export const formatOrganizationIds = nonFormattedOrganizationIds => {
  // Check if the input string is empty; if so, return an empty array.
  if (!nonFormattedOrganizationIds) {
    return [];
  }

  // Split the input string by commas and return the resulting array.
  return nonFormattedOrganizationIds.split(',');
};
