import React from 'react';
import { arrayOf, oneOfType, shape, array, string, bool, object, func } from 'prop-types';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatDuration } from '../../util/membership';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  Modal,
  PrimaryButton,
  InlineTextButton,
  SecondaryButton,
  ResponsiveImage,
  IconCheckmark,
  IconPremium,
} from '..';
import config from '../../config';
import classNames from 'classnames';

import css from './MembershipModal.module.css';

const { Money } = sdkTypes;

const MembershipPerks = props => {
  const { perks, perksConfig } = props;
  const selectedPerks = perksConfig.filter(p => perks.includes(p.key));
  return (
    <ul className={css.membershipPerksList}>
      {selectedPerks.map(p => (
        <li key={p.key} className={css.membershipPerksListItem}>
          <IconCheckmark className={css.checkmarkIcon} size="small" />
          <span>{p.label}</span>
        </li>
      ))}
    </ul>
  );
};

export const Membership = props => {
  const {
    intl,
    membership,
    perksConfig,
    isOwnListing,
    currentUserMemberships,
    onSubmitPayment,
  } = props;

  const { name, price, duration, perks, description } = membership;
  const hasMembership = currentUserMemberships.some(m => m.id === membership.id);
  const buttonDisabled = isOwnListing || hasMembership;

  return (
    <div className={css.membership}>
      <div className={css.membershipContent}>
        <h3 className={css.membershipName}>{name}</h3>
        <div>
          <span>{formatMoney(intl, new Money(price.amount, price.currency))}</span>
          <span className={css.separator}>·</span>
          <span>{formatDuration(intl, duration)}</span>
        </div>
        {description && <p className={css.membershipDescription}>{description}</p>}
      </div>
      <MembershipPerks perks={perks} perksConfig={perksConfig} />
      <PrimaryButton
        className={css.membershipButton}
        onClick={() =>
          onSubmitPayment({ membership, paymentType: config.paymentTypes['membership'] })
        }
        disabled={buttonDisabled}
      >
        <FormattedMessage
          id={
            hasMembership
              ? 'MembershipModal.membershipActiveButton'
              : 'MembershipModal.membershipButton'
          }
        />
      </PrimaryButton>
      {hasMembership && <IconPremium className={css.membershipActiveIcon} />}
    </div>
  );
};

/**
 * MembershipModal: Modal for displaying membership details and perks associated with a listing.
 * Allows users to view and inquire about available membership plans.
 */
const MembershipModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    isOwnListing,
    hideContactButton,
    authorPayoutsEnabled,
    listing,
    authorDisplayName,
    perksConfig,
    currentUserMemberships,
    onSubmitPayment,
    onCloseModal,
    onOpenEnquiryModal,
    onManageDisableScrolling,
  } = props;

  const { title, publicData } = listing?.attributes || {};
  const memberships = authorPayoutsEnabled || isOwnListing ? publicData?.memberships || [] : [];
  const hasMemberships = memberships.length > 0;
  const classes = classNames(rootClassName || css.root, className);

  const renderModalHeader = hasMemberships ? (
    <div>
      <p className={css.modalSubTitle}>
        <FormattedMessage id="MembershipModal.subTitle" />
      </p>
      <p className={css.modalSubTitle}>
        <FormattedMessage id="MembershipModal.subTitleSecondLine" />
      </p>
    </div>
  ) : (
    <p className={css.modalSubTitle}>
      <FormattedMessage
        id="MembershipModal.noMemberships"
        values={{ listingTitle: <strong>{title}</strong> }}
      />
    </p>
  );

  const renderMemberships = hasMemberships ? (
    <div className={css.memberships}>
      {memberships.map(membership => (
        <Membership
          key={membership.id}
          intl={intl}
          membership={membership}
          perksConfig={perksConfig}
          isOwnListing={isOwnListing}
          currentUserMemberships={currentUserMemberships}
          onSubmitPayment={onSubmitPayment}
        />
      ))}
    </div>
  ) : null;

  const showContactButton = !isOwnListing && !hideContactButton && hasMemberships;
  const renderContactButton = showContactButton ? (
    <div className={css.modalFooter}>
      <span className={css.contactNotice}>
        <FormattedMessage id="MembershipModal.contactNotice" />
      </span>
      <SecondaryButton className={css.contactButton} onClick={onOpenEnquiryModal}>
        <FormattedMessage id="MembershipModal.contact" values={{ authorDisplayName }} />
      </SecondaryButton>
    </div>
  ) : null;
  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <div className={css.modalHeader}>
        <h2 className={css.modalTitle}>
          <FormattedMessage id="MembershipModal.title" />
        </h2>
        {renderModalHeader}
      </div>
      {renderMemberships}
      {renderContactButton}
    </Modal>
  );
};

MembershipModal.defaultProps = {
  className: null,
  rootClassName: null,
  perksConfig: config.custom.perksConfig,
  id: null,
  isOpen: false,
  hideContactButton: false,
  currentUserMemberships: [],
  authorPayoutsEnabled: false,
};

MembershipModal.propTypes = {
  className: string,
  rootClassName: string,
  id: string,
  isOpen: bool.isRequired,
  isOwnListing: bool.isRequired,
  listing: oneOfType([propTypes.listing, propTypes.ownListing, object]).isRequired,
  authorDisplayName: string,
  perksConfig: array.isRequired,
  currentUserMemberships: array.isRequired,
  onSubmitPayment: func.isRequired,
  onCloseModal: func.isRequired,
  onOpenEnquiryModal: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  hideContactButton: bool,
  authorPayoutsEnabled: bool,
  intl: intlShape.isRequired,
};

export default injectIntl(MembershipModal);

/**
 * UpgradeMembershipModal: Modal component that displays available membership upgrade options for a listing.
 * Allows users to upgrade their membership by selecting available plans and handling payments if required.
 */
export const UpgradeMembershipModal = props => {
  const {
    className,
    rootClassName,
    intl,
    id,
    isOpen,
    listing,
    isOwnListing,
    authorPayoutsEnabled,
    currentUserMemberships,
    onSubmitPayment,
    perksConfig,
    onCloseModal,
    onManageDisableScrolling,
  } = props;

  const classes = classNames(rootClassName || css.root, className, css.upgradeMembershipModal);
  const { title, publicData } = listing.attributes;
  const hasImages = listing?.images?.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;

  // Determine available memberships based on payout status and ownership
  const membershipsFromPublicData = publicData?.memberships;
  const validMemberships = authorPayoutsEnabled
    ? membershipsFromPublicData
    : isOwnListing
    ? membershipsFromPublicData
    : [];
  const hasMemberships = validMemberships?.length > 0;
  const memberships = hasMemberships ? validMemberships : [];

  const renderModalHeader = (
    <div className={css.modalHeaderUpgrade}>
      <ResponsiveImage
        rootClassName={css.rootForImage}
        alt={title}
        image={firstImage}
        variants={['landscape-crop', 'landscape-crop2x']}
      />
      <div className={css.modalHeaderContent}>
        <h2 className={css.upgradeModalTitle}>
          <FormattedMessage id="UpgradeMembershipModal.title" />
        </h2>
      </div>
    </div>
  );

  // Render memberships or fallback text if none available
  const membershipContent = hasMemberships ? (
    <div className={css.upgradeMembershipContainer}>
      <h3 className={css.upgradeMembershipTitle}>
        <FormattedMessage id="UpgradeMembershipModal.upgradeMembershipTitle" />
      </h3>
      <p className={css.upgradeMembershipText}>
        <FormattedMessage id="UpgradeMembershipModal.upgradeMembershipText" />
      </p>
      <div className={css.memberships}>
        {memberships.map(membership => (
          <Membership
            key={membership.id}
            intl={intl}
            membership={membership}
            perksConfig={perksConfig}
            isOwnListing={isOwnListing}
            currentUserMemberships={currentUserMemberships}
            onSubmitPayment={onSubmitPayment}
          />
        ))}
      </div>
      <div className={css.modalFooter}>
        <InlineTextButton className={css.skipButton} type="button" onClick={onCloseModal}>
          <FormattedMessage id="UpgradeMembershipModal.skip" />
        </InlineTextButton>
      </div>
    </div>
  ) : (
    <p className={css.noMembershipsText}>
      <FormattedMessage
        id="MembershipModal.noMemberships"
        values={{ listingTitle: <strong>{title}</strong> }}
      />
    </p>
  );

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      {renderModalHeader}
      {membershipContent}
    </Modal>
  );
};

UpgradeMembershipModal.defaultProps = {
  className: null,
  rootClassName: null,
  perksConfig: config.custom.perksConfig,
  id: null,
  isOpen: false,
};

UpgradeMembershipModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  id: string,
  isOpen: bool.isRequired,
  listing: oneOfType([propTypes.listing, propTypes.ownListing, object]).isRequired,
  isOwnListing: bool.isRequired,
  authorPayoutsEnabled: bool.isRequired,
  currentUserMemberships: array.isRequired,
  onSubmitPayment: func.isRequired,
  perksConfig: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  onCloseModal: func.isRequired,
  onManageDisableScrolling: func.isRequired,
};

/**
 * FreeMembershipModal: Modal component that allows users to join a free membership plan,
 * displaying available benefits and confirming their subscription.
 */
export const FreeMembershipModal = props => {
  const {
    rootClassName,
    className,
    id,
    isOpen,
    onCreateFreeSubscription,
    createFreeSubscriptionInProgress,
    onCloseModal,
    onManageDisableScrolling,
  } = props;

  const classes = classNames(rootClassName || css.root, className, css.freeMembershipModal);

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <h2 className={css.freeModalTitle}>
        <FormattedMessage id="FreeMembershipModal.title" />
      </h2>
      <p className={css.modalSubTitle}>
        <FormattedMessage id="FreeMembershipModal.subTitle" />
      </p>
      <p className={css.modalSubTitle}>
        <FormattedMessage id="FreeMembershipModal.subTitleSecondLine" />
      </p>
      <PrimaryButton
        className={css.submitButton}
        onClick={onCreateFreeSubscription}
        inProgress={createFreeSubscriptionInProgress}
      >
        <FormattedMessage id="FreeMembershipModal.joinButton" />
      </PrimaryButton>
    </Modal>
  );
};

FreeMembershipModal.defaultProps = {
  className: null,
  rootClassName: null,
  id: null,
  isOpen: false,
  createFreeSubscriptionInProgress: false,
  onCloseModal: null,
  onCreateFreeSubscription: null,
  onManageDisableScrolling: null,
};

FreeMembershipModal.propTypes = {
  className: string,
  rootClassName: string,
  id: string,
  isOpen: bool.isRequired,
  createFreeSubscriptionInProgress: bool.isRequired,
  onCloseModal: func,
  onCreateFreeSubscription: func,
  onManageDisableScrolling: func,
};
