import React from 'react';
import { array, string, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { propTypes } from '../../util/types';
import { InlineTextButton, NamedLink, IconSpinner } from '../';
import { addListingToWatchlist, removeListingFromWatchlist } from '../../ducks/user.duck';
import classNames from 'classnames';

import { WatchlistIcon, PlusIcon, CheckmarkIcon } from './icons';
import { getWatchlistIdsFromPublicData } from './ButtonWatchlist.helpers';
import css from './ButtonWatchlist.module.css';

/**
 * Renders the appropriate secondary icon based on the provided flags.
 *
 * @param {boolean} isFilled - Determines if the item is filled/completed,
 *                             which decides between the checkmark or plus icon.
 * @param {boolean} inProgress - Indicates if the item is currently in progress.
 *                               If true, it renders a spinner icon.
 *
 * @returns {JSX.Element} - Returns a spinner icon if `inProgress` is true,
 *                          otherwise, it returns a checkmark or plus icon
 *                          based on the `isFilled` flag.
 */
const renderSecondaryIcon = (isFilled, inProgress) => {
  if (inProgress) {
    return <IconSpinner className={css.spinnerIcon} />;
  }

  return isFilled ? (
    <CheckmarkIcon className={css.checkmarkIcon} />
  ) : (
    <PlusIcon className={css.plusIcon} />
  );
};

const ButtonWatchlistComponent = props => {
  const {
    rootClassName,
    className,
    listing,
    currentUser,
    onAddListingToWatchlist,
    addListingToWatchlistInProgress,
    addListingToWatchlistError,
    onRemoveListingFromWatchlist,
    removeListingFromWatchlistInProgress,
    removeListingFromWatchlistError,
    currentWatchlistListingId,
    currentWatchlistErrorListingIds,
  } = props;

  const listingId = listing.id.uuid;
  const watchlistIdsFromPublicData = getWatchlistIdsFromPublicData(currentUser);

  const isFilled = watchlistIdsFromPublicData.includes(listingId);
  const inProgress =
    listingId === currentWatchlistListingId &&
    (addListingToWatchlistInProgress || removeListingFromWatchlistInProgress);
  const disabled =
    inProgress ||
    ((addListingToWatchlistError || removeListingFromWatchlistError) &&
      currentWatchlistErrorListingIds.includes(listingId));

  const handleAddOrRemoveToWatchlist = () => {
    if (isFilled) {
      onRemoveListingFromWatchlist(listingId);
    } else {
      onAddListingToWatchlist(listingId);
    }
  };

  const classes = classNames(rootClassName || css.root, className, {
    [css.rootFilled]: isFilled,
    [css.rootInProgress]: inProgress,
  });

  return currentUser ? (
    <InlineTextButton
      className={classes}
      type="button"
      onClick={handleAddOrRemoveToWatchlist}
      disabled={disabled}
    >
      <WatchlistIcon isFilled={isFilled} inProgress={inProgress} />
      {renderSecondaryIcon(isFilled, inProgress)}
    </InlineTextButton>
  ) : (
    <NamedLink className={classes} name="LoginPage">
      <WatchlistIcon />
      {renderSecondaryIcon(isFilled, inProgress)}
    </NamedLink>
  );
};

ButtonWatchlistComponent.defaultProps = {
  onAddListingToWatchlist: null,
  addListingToWatchlistInProgress: false,
  addListingToWatchlistError: null,
  onRemoveListingFromWatchlist: null,
  removeListingFromWatchlistInProgress: false,
  removeListingFromWatchlistError: null,
  currentWatchlistListingId: null,
  currentWatchlistErrorListingIds: [],
};

ButtonWatchlistComponent.propTypes = {
  listing: propTypes.listing.isRequired,
  currentUser: propTypes.currentUser,

  // watchlist
  onAddListingToWatchlist: func.isRequired,
  addListingToWatchlistInProgress: bool.isRequired,
  addListingToWatchlistError: propTypes.error,
  onRemoveListingFromWatchlist: func.isRequired,
  removeListingFromWatchlistInProgress: bool.isRequired,
  removeListingFromWatchlistError: propTypes.error,
  currentWatchlistListingId: string,
  currentWatchlistErrorListingIds: array.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onAddListingToWatchlist: listingId => dispatch(addListingToWatchlist(listingId)),
  onRemoveListingFromWatchlist: listingId => dispatch(removeListingFromWatchlist(listingId)),
});

const mapStateToProps = state => {
  const {
    currentUser,
    addListingToWatchlistInProgress,
    addListingToWatchlistError,
    removeListingFromWatchlistInProgress,
    removeListingFromWatchlistError,
    currentWatchlistListingId,
    currentWatchlistErrorListingIds,
  } = state.user;

  return {
    currentUser,
    addListingToWatchlistInProgress,
    addListingToWatchlistError,
    removeListingFromWatchlistInProgress,
    removeListingFromWatchlistError,
    currentWatchlistListingId,
    currentWatchlistErrorListingIds,
  };
};

const ButtonWatchlist = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ButtonWatchlistComponent);

export default ButtonWatchlist;
