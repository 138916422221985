import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconTheater.module.css';

const IconTheater = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <g clipPath="url(#a)" fill="#0e0f0c">
        <path d="m22.478.939-.387-.096a27.02 27.02 0 0 0-13.059 0l-.386.096a2.007 2.007 0 0 0-1.522 1.95v2.797a27.04 27.04 0 0 0-5.74 2.366l-.35.194a2.002 2.002 0 0 0-.965 2.275l1.17 4.372a12.464 12.464 0 0 0 7.773 8.479l1.206.442a3.635 3.635 0 0 0 4.008-1.077l.823-.983a12.226 12.226 0 0 0 1.867-3.024c.253-.098.495-.225.72-.378l1.05-.736A12.468 12.468 0 0 0 24 7.411V2.888a2.007 2.007 0 0 0-1.522-1.95Zm-8.044 20.298-.823.984a2.827 2.827 0 0 1-3.119.837l-1.205-.442a11.661 11.661 0 0 1-7.272-7.931l-1.17-4.373a1.2 1.2 0 0 1 .578-1.363l.35-.193c1.696-.94 3.49-1.69 5.35-2.234v.89a12.466 12.466 0 0 0 4.275 9.386 7.591 7.591 0 0 1-2.213.531l-3.022.245a.402.402 0 0 0-.133.767l4.006 1.809c.872.395 1.902.12 2.46-.659l.877-1.219.115.08a3.614 3.614 0 0 0 2.074.651c.12 0 .237-.015.355-.027-.403.81-.901 1.569-1.483 2.261Zm-2.328-3.867c.11.08.217.168.33.246l.278.195-.871 1.212a1.206 1.206 0 0 1-1.477.394L7.779 18.25l1.47-.12a8.412 8.412 0 0 0 2.857-.76Zm11.09-9.959a11.663 11.663 0 0 1-4.97 9.547l-1.05.736a2.822 2.822 0 0 1-3.227 0l-1.051-.736a11.662 11.662 0 0 1-4.97-9.547V2.888c0-.553.376-1.035.912-1.17l.387-.096a26.208 26.208 0 0 1 12.67 0l.387.097c.536.134.912.616.912 1.17V7.41Z" />
        <path d="m14.072 7.543.548-.588a3.218 3.218 0 0 0-4.546 0l-.02.02.588.548a2.411 2.411 0 0 1 3.43.02ZM16.503 6.955l-.02.02.588.548a2.411 2.411 0 0 1 3.43.02l.548-.588a3.218 3.218 0 0 0-4.546 0ZM5.73 13.366a2.41 2.41 0 0 1 1.856.257l.375-.712a3.215 3.215 0 0 0-4.391 1.176l-.014.025.347.2.362.177a2.393 2.393 0 0 1 1.464-1.123ZM19.91 11.283l-2.982.543a7.612 7.612 0 0 1-2.732 0l-2.983-.543a.401.401 0 0 0-.326.707l3.403 2.783c.74.603 1.803.603 2.544 0l3.402-2.784a.402.402 0 0 0-.326-.707v.001Zm-3.585 2.868a1.208 1.208 0 0 1-1.527 0l-2.2-1.799 1.454.264c.998.183 2.02.183 3.019 0l1.453-.264-2.199 1.8Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

IconTheater.defaultProps = {
  className: null,
  rootClassName: null,
};

IconTheater.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconTheater;
