import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCalendar.module.css';

const IconCalendar = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="#0e0f0c"
      aria-hidden="true"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

IconCalendar.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCalendar.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCalendar;
