import React from 'react';
import classNames from 'classnames';
import { BookingBreakdown, PaymentBreakdown } from '../../components';
import { PAYMENT_TYPE_DONATION, PAYMENT_TYPE_MEMBERSHIP } from '../../util/types';
import config from '../../config';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build BookingBreakdown
const BreakdownMaybe = props => {
  const { className, rootClassName, transaction, transactionRole, listing, timeslot } = props;

  const payment = transaction.attributes.metadata.payment;

  const bookingLoaded =
    transaction && transaction.id && transaction.booking && transaction.booking.id;
  const paymentLoaded = transaction && transaction.id && payment && payment.id;

  const classes = classNames(rootClassName || css.breakdown, className);

  if (payment && paymentLoaded) {
    const membershipMaybe =
      payment.type === PAYMENT_TYPE_MEMBERSHIP
        ? {
            membership: listing?.attributes?.publicData?.memberships?.find(
              m => m.id === payment.id
            ),
          }
        : {};
    const donationMaybe =
      payment.type === PAYMENT_TYPE_DONATION
        ? { donation: config.custom.donationConfig.find(d => d.id === payment.id) }
        : {};
    return (
      <div className={classes}>
        <PaymentBreakdown
          userRole={transactionRole}
          unitType={config.bookingUnitType}
          listing={listing}
          transaction={transaction}
          paymentType={payment.type}
          timeslot={timeslot}
          {...membershipMaybe}
          {...donationMaybe}
        />
      </div>
    );
  }

  return bookingLoaded ? (
    <div className={classes}>
      <BookingBreakdown
        className={classes}
        listing={listing}
        timeslot={timeslot}
        transactionRole={transactionRole}
      />
    </div>
  ) : null;
};

export default BreakdownMaybe;
